import { Template, TemplatePreConstructionItem, TemplateProfessionalFeesItem, templateFinancingItemIsDebt, templateFinancingItemIsEquity,
  ConstructionCosts, InputDataContextInterface, defaultConstructionCost, defaultPreConstructionCost, defaultProfessionalFee,
  defaultPurchaseCostItem, defaultSalesItem } from '../../../types';
import { defaultEquityItem, defaultFundingItem } from '../features/calculator/components/popups/finance/defaults';
import { getTotalSales } from './units';

interface ApplyTemplateOptions {
  updateConstruction?: boolean;
  updatePurchase?: boolean;
}

export const applyTemplate = (data: InputDataContextInterface, template: Template, options?: ApplyTemplateOptions): InputDataContextInterface => {
  const totalSales = getTotalSales(data.units_array) || data.gdv;

  const salesCompleteDate = new Date(data.project_start_date);
  salesCompleteDate.setDate(salesCompleteDate.getDate() + data.project_purchase_construction +
    data.project_start_purchase + (data.build_time_months || 1) * 30);

  const purchaseCosts = !template.isDefault ? (template.purchase || []).map((cost) => ({
    ...defaultPurchaseCostItem,
    cost_type: cost.name,
    total_cost: ((cost.percentPurchasePrice && data.site_purchase_price) ?
      (data.site_purchase_price * cost.percentPurchasePrice) / 100 : cost.totalCost) || 0,
  })) : data.purchase_costs_array;

  const financeCosts = (template?.financing || []).map((cost) => ({
    ...(cost.section === 'Debt funding' ? defaultFundingItem : defaultEquityItem),
    drawdowns: cost.drawdowns || 'single',
    fees: templateFinancingItemIsDebt(cost) ? cost.fee || 0 : 0,
    interest: templateFinancingItemIsDebt(cost) ? cost.interest || 0 : 0,
    percentage: cost.percentage,
    profit_share: templateFinancingItemIsEquity(cost) ? cost.profitShare || 0 : 0,
    type: cost.type,
  }));

  const constructionCosts: ConstructionCosts[] = (template?.construction?.construction || []).map((cost) => ({
    ...defaultConstructionCost(data.project_start_date),
    cost_type: cost.costType,
    unit: cost.unit,
    unit_cost: cost.unitCost,
    total_cost: (cost.unitCost && cost.unitQuantity ? cost.unitCost * cost.unitQuantity : cost.totalCost) || 0,
  }));

  let totalConstructionCost = constructionCosts.reduce((acc, cost) => acc + (cost.total_cost || 0), 0);

  if (data.total_construction_cost && totalConstructionCost < data.total_construction_cost) {
    constructionCosts.push({
      ...defaultConstructionCost(data.project_start_date),
      cost_type: 'Other construction costs',
      total_cost: data.total_construction_cost - totalConstructionCost,
    });

    totalConstructionCost = data.total_construction_cost;
  }

  const calcOtherConstructionTotalCost = (cost: TemplatePreConstructionItem | TemplateProfessionalFeesItem) => {
    if (cost.totalFloorAreaCost && data.total_floor_area) {
      return cost.totalFloorAreaCost * data.total_floor_area;
    } else if (cost.constructionCostPercent && totalConstructionCost) {
      return Math.round(totalConstructionCost * cost.constructionCostPercent / 100);
    } else {
      return cost.totalCost;
    }
  };

  const preConstructionCosts = (template?.construction?.preConstruction || []).map((cost) => ({
    ...defaultPreConstructionCost(data.project_start_date),
    cost_type: cost.costType,
    construction_cost_percent: cost.constructionCostPercent,
    total_floor_area_cost: cost.totalFloorAreaCost,
    total_cost: calcOtherConstructionTotalCost(cost) || 0,
  }));

  const professionalFees = (template?.construction?.professionalFees || []).map((cost) => ({
    ...defaultProfessionalFee(data.project_start_date),
    cost_type: cost.costType,
    construction_cost_percent: cost.constructionCostPercent,
    total_floor_area_cost: cost.totalFloorAreaCost,
    total_cost: calcOtherConstructionTotalCost(cost) || 0,
  }));

  const allConstuctionCosts = [...preConstructionCosts, ...constructionCosts, ...professionalFees];

  const salesCosts = ((template?.sales || []).length > 0 && !template.isDefault) ? (template?.sales || []).map((cost) => ({
    ...defaultSalesItem,
    cost_type: cost.name,
    total_cost: (cost.percentTotalSales && totalSales ? Math.round((totalSales || 0) * +cost.percentTotalSales) / 100 : cost.totalCost) || 0,
    percent_total_sales: cost.percentTotalSales,
    payment: salesCompleteDate.toISOString().slice(0, 10),
  })) : data.sales_costs_array;

  return {
    ...data,
    purchase_costs_array: (data.purchase_costs_array.length === 0 || options?.updatePurchase) ? purchaseCosts : data.purchase_costs_array,
    funding_array: financeCosts,
    construction_costs_array: (data.construction_costs_array.length === 0 || options?.updateConstruction) ? allConstuctionCosts : data.construction_costs_array,
    contingency: template?.construction?.contingencyPercentage,
    contingency_total: template?.construction?.contingencyTotal,
    sales_costs_array: salesCosts,
    'nia/gea': template?.construction?.totalFloorRatio,
    project_start_purchase: template?.base?.projectStartPurchase !== undefined ? template?.base?.projectStartPurchase : data.project_start_purchase,
    project_purchase_construction: template?.base?.projectPurchaseConstruction !== undefined  ? template?.base?.projectPurchaseConstruction : data.project_purchase_construction,
  };
};
