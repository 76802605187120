import React from 'react';
import { ReactComponent as LogoImage } from '../../../images/logo.svg';

export function Logo() {
  return (
    <LogoImage />
  );
}

export default Logo;
