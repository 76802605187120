import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: inline-block;

  font-family: "Work Sans", Arial;

  > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width: var(--width-common);
    padding: 16px 19px;
    border-radius: 8px;
    border: 1px solid var(--color-whiteish-3);
    background: var(--color-white);
    color: var(--color-black-1);
  }
`;


export const Items = styled.div<{active: boolean}>`
  display: ${(p) => p.active ? 'block' : 'none'};
  position: absolute;
  z-index: 1;
  width: var(--width-common);

  > div {
    max-height: 350px;
    margin-top: 8px;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--color-whiteish-3);
    background: var(--color-white);
    box-shadow: 0px 8px 16px 0px rgba(43, 70, 86, 0.16);
    overflow-y: auto;

    > div {
      display: block;
      padding: 12px 16px;

      color: var(--color-black-1);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-decoration: none;

      :hover {
        display: flex;
        align-items: center;
        align-self: stretch;

        border-radius: 8px;
        background: var(--color-whiteish-2);
      }
    }
  }
`;
