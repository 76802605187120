import React, { ReactNode, useEffect, useState } from 'react';
import Switch from 'react-switch';
import { TitleStyle, SwitchWrapper, Left, ArrowUp, ArrowDown, HeaderWrapper } from './style';

import theme from '../../../themes';
import { Link } from 'react-router-dom';

const { palette } = theme;

interface Props {
  text: string | ReactNode;
  width?: string;
  children?: ReactNode;
  linkText?: string;
  link?: string;
  switchText?: string;
  switchDisabled?: boolean;
  arrow?: 'up' | 'down';
  onSwitch?: (status: boolean) => void;
  onArrowClick?: () => void;
}

export const Title = ({ arrow, text, width, children, switchText, switchDisabled, link, linkText, onArrowClick, onSwitch }: Props) => {

  const [switchChecked, setSwitchChecked] = useState(false);

  useEffect(() => onSwitch?.(switchChecked), [onSwitch, switchChecked]);

  return <TitleStyle width={width}>
    <Left>
      <HeaderWrapper clickable={!!onArrowClick} onClick={onArrowClick}>
        {arrow === 'up' && <ArrowUp/>}
        {arrow === 'down' && <ArrowDown/>}
        <h2>{text}</h2>
      </HeaderWrapper>
      {switchText && <SwitchWrapper>
        <span>{switchText}</span>
        <Switch
          height={20}
          width={40}
          handleDiameter={16}
          offColor={palette.grayLight}
          onColor={palette.greenMedium}
          checked={switchChecked}
          checkedIcon={false}
          uncheckedIcon={false}
          disabled={!!switchDisabled}
          onChange={() => !switchDisabled ? setSwitchChecked(!switchChecked) : undefined}
        />
      </SwitchWrapper>}
      {linkText && <Link to={link}>{linkText}</Link>}
    </Left>
    {children}
  </TitleStyle>;
};
