import React from 'react';
import { Zoom } from 'react-toastify';
import { StyledToastContainer } from './style';
import 'react-toastify/dist/ReactToastify.css';

export const Toasts = () => {
  return <StyledToastContainer
    position='bottom-right'
    autoClose={5000}
    transition={Zoom}
    pauseOnHover={true}
  />;
};
