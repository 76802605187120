import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as LogoSVG } from '../../images/logo.svg';

const { palette } = theme;

export const Page = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  padding: 50px;
  z-index: 1000;
  background: ${palette.white};
  > * {
    width: 100%;
    max-width: 270px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    color: ${palette.grayLight};
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    color: ${palette.black};
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0;
    text-align: center;
  }
  button {
    width: 100%;
    &:last-child {
      margin-top: 20px;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Logo = styled(LogoSVG as any)`
  width: 118px;
`;
