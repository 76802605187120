import React, { ErrorInfo } from 'react';
import { Error } from './components';

export class ErrorBoundary extends React.Component {

  public state: { hasError?: boolean };

  constructor(props: React.PropsWithChildren<unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if ( document.location.href.includes('reloaded=1') ) {
        return <Error text='Error while loading this page. Please reload it manually'/>;
      }
      setTimeout(() => document.location.href = document.location.href.replace(/\?.*$/, '') + '?reloaded=1', 1000);
      return null;
    }

    return this.props.children;
  }
}
