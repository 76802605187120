import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Input } from '../..';
import { Popup, PopupTitle } from '../../../components/popup';
import { Actions } from '../../../redux';
import { Button, Checkbox } from '../../ui';
import { Buttons, CheckboxWrapper, Form } from './style';

interface Props {
  onClose: () => void;
}

export const CreateUser = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>();
  const [surname, setSurname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [subscription, setSubscription] = useState(false);

  const status = useSelector((state: RootState) => state.App.createUserStatus);
  const error = useSelector((state: RootState) => state.App.createUserError);

  const signUp = useCallback(() => {
    if ( email && name && surname ) {
      dispatch(Actions.App.createUser(email, name, surname, subscription));
    }
  }, [email, name, surname, subscription, dispatch]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        signUp();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [signUp]);

  useEffect(() => {
    if ( status === 'success' ) {
      dispatch(Actions.App.setCreateUserError(null));
      dispatch(Actions.App.setCreateUserStatus('new'));
      onClose();
    }
  }, [status, onClose, dispatch]);

  return <Popup padding={[60, 70, 60, 70]} onClose={onClose}>
    <PopupTitle>New user</PopupTitle>
    <Form>
      <Input
        placeholder='Enter name'
        value={name}
        onChange={(value: string) => setName(value)}
        marginBottom={24}
        noPadding
        setOnTyping
      />
      <Input
        placeholder='Enter surname'
        value={surname}
        onChange={(value: string) => setSurname(value)}
        marginBottom={24}
        noPadding
        setOnTyping
      />
      <Input
        placeholder='Enter email'
        value={email}
        onChange={(value: string) => setEmail(value)}
        noPadding
        setOnTyping
      />
      {error?.type === 'email' ? <span>{error?.text}</span> : <span>&nbsp;</span>}
      <div>
        <CheckboxWrapper>
          <Checkbox value={subscription} onChange={() => setSubscription(!subscription)} />
          <span>Subscribe to the newsletter</span>
        </CheckboxWrapper>
      </div>
    </Form>
    <Buttons>
      <Button variant='primary' onClick={signUp} disabled={!email || !name || !surname}>Create</Button>
      <Button variant='secondary' onClick={() => {
        dispatch(Actions.App.setCreateUserError(null));
        dispatch(Actions.App.setCreateUserStatus('new'));
        onClose();
      }}>Cancel</Button>
    </Buttons>
  </Popup>;
};
