import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Invite = styled.div<{ text: string; fontSize?: number }>`
  font-size: ${(p) => p.fontSize || 14}px;
  color: ${palette.blue};
  cursor: pointer;
  opacity: .7;
  :hover {
    opacity: 1;
  }
  svg {
    height: 14px;
    width: 14px;
    margin-right: 5px;
    * {
      fill: ${palette.blue};
    } 
  }
  :after {
    content: '${(p) => p.text}';
  }
`;
