import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Table as TableComponent } from '../table/table';
import { Props } from '../table/types';

export const Table = (props: Props) => {
  const layout = useSelector((state: RootState) => state.App.layout);
  return <TableComponent {...props} layout={layout}/>;
};
