import styled from 'styled-components';
import theme from './themes';

const { palette } = theme;

const AppStyle = styled.div`

  a
  {
    color: ${palette.blue};
  }
`;

export { AppStyle };
