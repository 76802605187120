import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  position: absolute;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 10px;
  .rdrDefinedRangesWrapper, .rdrDateDisplayWrapper {
    display: none;
  }
`;
