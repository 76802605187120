import React, { useEffect } from 'react';
import { gtag, install } from 'ga-gtag';
import { useLocation } from 'react-router';

interface Props {
  id: string;
}

export const GoogleTagTracker = ({ id }: Props) => {
  const location = useLocation();

  useEffect(() => {
    if ( id ) {
      install(id);
    }
  }, [id]);

  useEffect(() => {
    gtag('set', 'page_path', location.pathname);
    gtag('event', 'page_view');
  }, [location, id]);

  return <></>;
};
