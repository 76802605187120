import React from 'react';
import { firstUppercase } from '../../../../../../shared';
import { Country, Currency, PresetSection, TemplateBase, countryList, currencyList, presetSections } from '../../../../../../types';
import { Checkbox } from '../../../../components/ui';
import { CustomInput, Dropdown } from '../../../calculator/components/ui';
import { Label, TabContainer, TabContent } from '../../style';

interface Props {
  name: string;
  section?: PresetSection;
  isDefault?: boolean;
  isPreset?: boolean;
  isShared?: boolean;
  isAdmin: boolean;
  base: TemplateBase;
  setName?: (name: string) => void;
  setSection?: (section: PresetSection) => void;
  setIsShared?: (isShared: boolean) => void;
  setBase?: (base: TemplateBase) => void;
}

const presetSectionOptions = presetSections.map((value) => ({ value, label: firstUppercase(value) }));
const countryOptions = countryList.map((value) => ({ value, label: firstUppercase(value) }));
const currencyOptions = currencyList.map((value) => ({ value, label: firstUppercase(value) }));

export const BaseSettingsTab = ({ name, section, isDefault, isPreset, isShared, isAdmin, base,
  setName, setSection, setIsShared, setBase }: Props) => {

  return <TabContent>
    <div>
      <Label header bold margin='0 0 15px 0'>Base settings</Label>
      <TabContainer>
        <div>
          <Label width='175px'>{`${isPreset ? 'Preset' : 'Template'} name`}</Label>
          <CustomInput
            placeholder='Name'
            maxWidth='200px'
            inputValue={name}
            valueChanges={(value) => setName?.(value)}
            readOnly={isDefault || !setName}
          />
        </div>
        {isPreset && <div>
          <Label width='175px'>Preset type</Label>
          <Dropdown
            width='200px'
            placeholder={'Select type'}
            selectedValue={presetSectionOptions.find(({ value }) => section === value)}
            valueChanges={(value) => setSection?.(value)}
            options={presetSectionOptions}
            readOnly={!setSection}
            usePortal
          />
        </div>}
        {!isPreset && <>
          <div>
            <Label width='175px'>Country</Label>
            <Dropdown
              width='200px'
              placeholder={'Select country'}
              selectedValue={countryOptions.find(({ value }) => base?.country === value as Country)}
              valueChanges={(value) => setBase?.({ ...(base || {}), country: value })}
              options={countryOptions}
              readOnly={!setBase}
              usePortal
            />
          </div>
          <div>
            <Label width='175px'>Currency</Label>
            <Dropdown
              width='200px'
              placeholder={'Select currency'}
              selectedValue={currencyOptions.find(({ value }) => base?.currency === value as Currency)}
              valueChanges={(value) => setBase?.({ ...(base || {}), currency: value })}
              options={currencyOptions}
              readOnly={!setBase}
              usePortal
            />
          </div>
          <div>
            <Label width='175px'>Purchase stage in days</Label>
            <CustomInput
              placeholder='Set'
              maxWidth='200px'
              inputValue={base?.projectStartPurchase}
              valueChanges={(value) => setBase?.({ ...(base || {}), projectStartPurchase: value })}
              readOnly={!setBase}
            />
          </div>
          <div>
            <Label width='175px'>Preparation stage in days</Label>
            <CustomInput
              placeholder='Set'
              maxWidth='200px'
              inputValue={base?.projectPurchaseConstruction}
              valueChanges={(value) => setBase?.({ ...(base || {}), projectPurchaseConstruction: value })}
              readOnly={!setBase}
            />
          </div>
        </>}
        {!isDefault && isAdmin && <div>
          <Label width='175px'>Shared</Label>
          <Checkbox value={isShared || false} onChange={() => setIsShared?.(!isShared)} />
        </div>}
      </TabContainer>
    </div>
  </TabContent>;
};
