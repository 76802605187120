import styled from 'styled-components';

import { ReactComponent as BellImage } from './images/bell.svg';

export const Wrapper = styled.div<{ marginLeft?: number; clickable?: boolean }>`
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: ${(p) => p.marginLeft || 0}px;
  > div {
    position: relative;
    cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  }
`;

export const Image = styled(BellImage)`
  height: 20px;
  width: 16px;
`;
