import React from "react";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { ReactComponent as InfoImg } from "./assets/images/info.svg";
import "./info-icon.css";

interface Props {
  text: string;
}

export const InfoIcon = (props: Props) => {
  return (
    <Tooltip
      classes={{
        tooltip: "custom-tooltip-color",
        tooltipArrow: "custom-arrow-color",
      }}
      title={props?.text}
      placement="top"
      arrow
    >
      <InfoImg />
    </Tooltip>
  );
};
