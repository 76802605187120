import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import theme from '../../../themes';

const { font, palette } = theme;

export const Wrapper = styled.div`
  position: relative;
`;

export const InputStyle = styled(Form.Control).withConfig({
  shouldForwardProp: (prop) => !['borderRadius', 'border', 'noPadding' ].includes(prop as string),
})<{border?: string; borderRadius?: string; width?: string; noPadding?: boolean}>`
  &.input-line
  {
    font-size: ${font.small};
    padding-left: ${(p) => p.noPadding? 0 : '4px'};

    ${(p) => p.border ? `
      border: ${p.border};`
    : `
      border-width: 0 0 1px;
      border-radius: 0;
      border-color: ${palette.grayMedium};
    `}

    color: ${palette.grayDark};
    line-height: 1.4;
    height: initial;

    ${(p) => p.borderRadius && `
      border-radius: ${p.borderRadius};
    `}

    ${(p) => p.width && `
      width: ${p.width};
    `}
  }

  &.input-line:focus
  {
    box-shadow: none;
  }
`;

export const ErrorStyle = styled.p`
  position: absolute;
  right: 0;
  font-size: 12px;
  padding-top: 3px;
  color: ${palette.grayDark};
`;
