import React, { ReactNode } from 'react';
import { Logo, Page } from './style';

interface Props {
  text: string | ReactNode;
  skipText?: string;
  delay?: number;
  onSkip?: () => void;
}

export const Error = ({ text, skipText, delay, onSkip }: Props) => {
  return <Page delay={delay}>
    <h1>Oops!</h1>
    <p>{text}</p>
    {onSkip && <>
      {skipText ? <p><a onClick={onSkip}>{skipText}</a></p> : <p>Or just <a onClick={onSkip}>skip and continue</a> at your own risk</p>}
    </>}
    <Logo/>
  </Page>;
};
