import React, { useEffect } from 'react';

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUsersnapCXLoad: (api: any) => void;
  }
}

export const UserSnapWidget = () => {
  useEffect(() => {
    window.onUsersnapCXLoad = function(api) {
      api.init();
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://widget.usersnap.com/load/7d2fc585-9857-4614-915e-65dec8365e82?onload=onUsersnapCXLoad';
    document.querySelectorAll('head')[0].append(script);
  }, []);
  return <></>;
};
