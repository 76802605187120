import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Items = styled.ul`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li<{ active?: boolean }>`
  list-style: none;
  color: ${(p) => p.active ? palette.greenDark : palette.grayDark};
  cursor: ${(p) => p.active ? 'default' : 'pointer'};
  margin-right: 15px;
`;
