import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as BoxImage } from './images/box.svg';

const { palette } = theme;

export const Wrapper = styled.div<{height?: string; marginTop?: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(p) => p.height || '100%'};
  cursor: default;

  ${(p) => p.marginTop ? `
    margin-top: ${p.marginTop}px;
  ` : ''}
`;

export const Message = styled.div<{imgMargin?: string; fullWidth?: boolean; inline?: boolean}>`
  display: flex;
  flex-direction: ${(p) => p.inline ? 'row' : 'column'};
  align-items: center;
  justify-content: center;
  margin-bottom: ${(p) => p.inline ? 0 : 50}px;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${(p) => p.color || 'black'};
    margin: 15px 0;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    color: ${palette.grayDark};
    margin-left: ${(p) => p.inline ? 10 : 0}px;
    margin-bottom: ${(p) => p.inline ? 0 : 18}px;
  }

  ${(p) => p.imgMargin && `
    > svg {
      margin: ${p.imgMargin};
    }
  `}

  ${(p) => p.fullWidth && `
    width: 100%;
  `}
`;

export const Image = styled(BoxImage).withConfig({
  shouldForwardProp: (prop) => !['inline'].includes(prop as string),
})<{ inline?: boolean }>`
  height: ${(p) => p.inline ? 20 : 30}px;
  width: ${(p) => p.inline ? 20 : 30}px;
  margin-bottom: ${(p) => p.inline ? 0 : 18}px;
  filter: grayscale(${(p) => p.inline ? 100 : 0}%);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  > button {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }
`;
