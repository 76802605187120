import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;
const WIDTH = 2.5;
const HEIGHT = 1.25;

const SwitchStyle = styled.div`
  padding-left: 0;
  padding-right: 3rem;
  min-height: initial;

  .custom-control-input
  {
    right: 0;
    left: initial;
  }

  .custom-control-label:before
  {
    border: 1px solid ${palette.grayLight};
    background-color: ${palette.grayLight};
    height: ${HEIGHT}rem;
    width: ${WIDTH}rem;
    top: 2px;
    right: -3rem;
    left: initial;
    border-radius: 1rem;
  }

  .custom-control-label:after
  {
    background-color: white;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.07);
    top: 4px;
    right: -1.65rem;
    height: calc(${HEIGHT}rem - 4px);
    width: calc(${HEIGHT}rem - 4px);
    left: initial;
  }

  .custom-control-input:checked ~ .custom-control-label:before
  {
    background-color: ${palette.greenMedium};
    border: 1px solid ${palette.greenMedium};
  }

  .custom-control-input:checked ~ .custom-control-label:after
  {
    box-shadow: 3px 3px 10px rgba(23, 65, 57, 0.35);
    transform: translateX(1.2rem);
  }
`;

export { SwitchStyle };
