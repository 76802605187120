import styled from 'styled-components';
import theme from '../../../themes';
import { ReactComponent as StartTourSVG } from './images/start-tour.svg';

const { palette } = theme;

export const TitleAndBackStyle = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  .mobile .adaptive && {
    justify-content: space-between;
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  
    > div:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      > svg {
        margin-left: 20px;
  
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const StartTour = styled(StartTourSVG)`
  margin: auto 0 0 10px;
  cursor: pointer;
  scale: 0.8;
`;

export const Back = styled.div<{ noTitle?: boolean }>`
  color: ${palette.grayDark};
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: ${(p) => p.noTitle ? 0 : 10}px;
  ::before {
    content: '←';
    margin-right: 5px;
  }
`;

export const Title = styled.h1<{fontSize?: number}>`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: ${(p) => p.fontSize ? p.fontSize : 22}px;
  font-weight: 400;
  color: ${palette.black};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
