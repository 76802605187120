import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

const height = '50px';
const mobileMenuHeight = '80px';

export const ContentWrapper = styled.div`
  .mobile .adaptive &&, .tablet .adaptive && {
    width: 100vw;
    height: calc(100vh - ${mobileMenuHeight});
    overflow: auto;
  }

  background: white;
  border-radius: 5px;
  z-index: 1;
  width: calc(100% - 103px);
`;

export const SingleTab = styled.div<{ background?: string; height?: string }>`
  width: 100%;
  height: ${(p) => p.height || 'auto'};
  background: ${(p) => p.background || 'white'};
`;

export const Child = styled.div`
  height: 100%;
  padding: 5px;
  position: relative;
`;

export const Header = styled.div<{ inlineMobileHead?: boolean; noMobilePadding?: boolean }>`
  height: ${height};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${palette.grayBg};
  margin-right: 40px;
  .mobile .adaptive && {
    padding: ${(p) => p.noMobilePadding ? '0' : '0 8px'};
    flex-direction: ${(p) => !p.inlineMobileHead ? 'column' : 'row'};
    align-items: ${(p) => !p.inlineMobileHead ? 'start' : 'center'};
    height: auto;
  }
`;

export const Body = styled.div<{ heightFromTop?: number; strictHeight?: boolean; withScale?: boolean }>`
  ${(p) => p.heightFromTop ? `
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      .mobile .adaptive &&, .tablet .adaptive && {
        min-height: min(calc(100vh - ${p.heightFromTop - 65}px), calc(100% - 33px));
        height: min(calc(100vh - ${p.heightFromTop - 65}px), calc(100% - 33px));
      }
    }

    min-height: min(calc(100vh - ${p.heightFromTop}px), calc(100% - 33px));
    height: min(calc(100vh - ${p.heightFromTop}px), calc(100% - 33px));
    ${p.strictHeight ? `
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        .mobile .adaptive &&, .tablet .adaptive && {
          height: calc(100vh - ${p.heightFromTop - 65}px);
        }
      }
      height: calc(100vh - ${p.heightFromTop}px);
      overflow: ${p.withScale ? 'hidden' : 'auto'};
    ` : ''}
  ` : ''};
`;

export const Scale = styled.div<{scale?: number; strictHeight?: boolean; offset?: number; withFlex: boolean}>`
  position: relative;
  transform-origin: top left;
  width: ${(p) => p.scale ? 100 / p.scale : 100}%;
  height: calc(${(p) => p.scale ? 100 / p.scale : 100}% - ${(p) => p.offset ? p.offset + 90 : 0}px);

  ${(p) => p.scale && p.scale !== 1 ? `transform: scale(${p.scale});` : ''}
  ${(p) => p.strictHeight ? 'overflow: initial;' : ''}
  ${(p) => p.withFlex ? 'display: flex;' : ''}
`;

export const Padding = styled.div`
  padding: 33px;
  height: 100%;
`;

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  height: ${height};
  line-height: ${height};
  width: 100%;
  /* max-width: 78%; */
  min-width: 78%;
  > div {
    height: ${height};
    display: flex;
    align-items: center;
    .mobile .adaptive && {
      justify-content: center;
      margin-left: 0;
    }
  }
  button {
    min-width: 140px;
    max-height: 35px;
  }
  .mobile .adaptive && {
    justify-content: center;
    width: 100%;
    border-top: 1px solid ${palette.grayBg};
  }
`;

export const MobileButtons = styled.div<{noPadding?: boolean}>`
  ${(p) => !p.noPadding ? `
    padding: 20px 0;
  ` : ''}

  display: flex;
  width: 100%;
  > button {
    flex: 1;
  }
`;

export const TitleWithButton = styled.div`
  max-width: 100%;
  width: 100%;

  .mobile .adaptive && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 5px;
    margin: 0;
  }
`;

export const MobileBeforeBodyControls = styled.div`
  width: 100%;
`;

export const HeaderControls = styled.div`
  flex-grow: 1;
  justify-content: space-between;
  line-height: normal;
  width: 100%;
  gap: 20px;
  .mobile .adaptive &&, .tablet .adaptive && {
    flex-grow: unset;
    width: 100%;
    justify-content: center;
  }
`;

export const ContentControls = styled.div`
  display: flex;
  width: 100%;
  .mobile .adaptive &&, .tablet .adaptive && {
    justify-content: space-around;
  }
`;
