import React, { useCallback, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { TemplateFinancingItem, TypeOfFinanceTemplate, typeOfFinanceDebt, typeOfFinanceEquity } from '../../../../../../types';
import { Option } from '../../../../types';
import { ReactComponent as AddImage } from '../../images/add.svg';
import { ReactComponent as ArrowImage } from '../../images/arrow.svg';
import { AddItem, CollapsibleHeader, Label, SectionContainer, TabContainer, TabContent } from '../../style';
import { Line } from './line';

interface Props {
  items: TemplateFinancingItem[];
  defaultItems?: TemplateFinancingItem[];
  isSummary?: boolean;
  setItems?: (items: TemplateFinancingItem[]) => void;
}

const typeOfFinanceDebtOptions = typeOfFinanceDebt.filter((name) => name !== '').map((name) => ({ value: name, label: name }));
const typeOfFinanceEquityOptions = typeOfFinanceEquity.filter((name) => name !== '').map((name) => ({ value: name, label: name }));

export const FinancingTab = ({ items, defaultItems, isSummary, setItems }: Props) => {
  const [debtIsOpen, setDebtIsOpen] = useState(true);
  const [equityIsOpen, setEquityIsOpen] = useState(true);

  const updateItem = useCallback((i: number, item: Partial<TemplateFinancingItem>) => setItems?.([
    ...items.slice(0, i),
    { ...items[i], ...item as TemplateFinancingItem },
    ...items.slice(i + 1),
  ]), [items, setItems]);

  const getDefaultValue = useCallback((type: TypeOfFinanceTemplate, key: string) => {
    const found = defaultItems?.find((defaultItem) => type === defaultItem.type);
    return found && key in found ? found[key] : 0;
  }, [defaultItems]);

  const getDefaultValueDropdown = useCallback((type: TypeOfFinanceTemplate, key: string, options: Option[]) => {
    const found = defaultItems?.find((defaultItem) => type === defaultItem.type);
    return found && key in found ? options.find(({ value }) => found[key] === value) : '';
  }, [defaultItems]);

  const removeItem = useCallback((i: number) => setItems?.([...items.slice(0, i), ...items.slice(i + 1)]), [items, setItems]);
  const copyItem = useCallback((i: number) => setItems?.([...items.slice(0, i), items[i], ...items.slice(i)]), [items, setItems]);

  const nextDebtType = useMemo(() => typeOfFinanceDebt.find((type) => type !== '' && !items.find((item) => item.type === type)), [items]);
  const nextEquityType = useMemo(() => typeOfFinanceEquity.find((type) => type !== '' && !items.find((item) => item.type === type)), [items]);

  return <SectionContainer isSummary={isSummary}>
    <TabContent>
      <CollapsibleHeader isOpen={debtIsOpen} onClick={() => setDebtIsOpen(!debtIsOpen)}>
        <ArrowImage />
        <span>Debt funding</span>
      </CollapsibleHeader>
      {debtIsOpen && <TabContainer spaceBetween>
        {items.map((item, i) => ({ ...item, id: i })).filter((item) => item.section === 'Debt funding').map(({ id, ...item }, i) => <Line
          key={uuid()}
          item={item}
          firstItem={i === 0}
          typeOfFinanceTemplateOptions={typeOfFinanceDebtOptions}
          updateItem={setItems ? (item) => updateItem(id, item) : undefined}
          getDefaultValue={defaultItems ? getDefaultValue : undefined}
          getDefaultValueDropdown={defaultItems ? getDefaultValueDropdown : undefined}
          removeItem={setItems ? () => removeItem(id) : undefined}
          copyItem={setItems ? () => copyItem(id) : undefined}
        />)}
        {items.filter((item) => item.section === 'Debt funding').length === 0 && <Label>No costs</Label>}
        {setItems && <div>
          <AddItem disabled={!nextDebtType} onClick={nextDebtType ? () => setItems([...items, { type: nextDebtType, section: 'Debt funding' }]) : undefined}>
            <AddImage />
            <span>Add new</span>
          </AddItem>
        </div>}
      </TabContainer>}
    </TabContent>
    <TabContent>
      <CollapsibleHeader isOpen={equityIsOpen} onClick={() => setEquityIsOpen(!equityIsOpen)}>
        <ArrowImage />
        <span>Equity</span>
      </CollapsibleHeader>
      {equityIsOpen && <TabContainer spaceBetween>
        {items.map((item, i) => ({ ...item, id: i })).filter((item) => item.section === 'Equity').map(({ id, ...item }, i) => <Line
          key={i}
          item={item}
          firstItem={i === 0}
          typeOfFinanceTemplateOptions={typeOfFinanceEquityOptions}
          updateItem={setItems ? (item) => updateItem(id, item) : undefined}
          getDefaultValue={defaultItems ? getDefaultValue : undefined}
          getDefaultValueDropdown={defaultItems ? getDefaultValueDropdown : undefined}
          removeItem={setItems ? () => removeItem(id) : undefined}
          copyItem={setItems ? () => copyItem(id) : undefined}
        />)}
        {items.filter((item) => item.section === 'Equity').length === 0 && <Label>No costs</Label>}
        {setItems && <div>
          <AddItem disabled={!nextEquityType} onClick={nextEquityType ? () => setItems([...items, { type: nextEquityType, section: 'Equity' } ]) : undefined}>
            <AddImage />
            <span>Add new</span>
          </AddItem>
        </div>}
      </TabContainer>}
    </TabContent>
  </SectionContainer>;
};
