import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 10px;

  > span {
    font-family: 'Open Sans', Helvetica, sans-serif;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 5px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin: 20px 0 20px 0;

  > span {
    margin-left: 14px;
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-left: 140px; 

  > button {
    width: 120px;

    :not(last-of-type) {
      margin-right: 20px;
    }
  }
`;
