import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import theme from '../../themes';

const { palette } = theme;

export const StyledToastContainer = styled(ToastContainer)`
  z-index: 100000;

  .Toastify__toast {
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    background: white;
    padding: 25px 10px 10px 10px;
    border-left: 3px solid ${palette.greenMedium};
    color: ${palette.grayMedium};
    font-weight: 400;
    font-size: 12px;
    margin-top: 6px;
    :after {
      content: 'Notification';
      font-size: 12px;
      font-weight: 600;
      color: black;
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }
  .Toastify__close-button {
    display: none;
  }
  .Toastify__toast--warning, .Toastify__toast--error {
    border-left: 3px solid ${palette.red};
    :after {
      content: 'Warning';
    }
  }
  .Toastify__progress-bar {
    background: white;
  }
`;
