import React, { useState } from 'react';
import { LocalAreaStatMigration } from '../../../../../../../../types';
import { Arrow, BoldCell, CollapsibleHeader, Table } from '../style';

const titleMapping = {
  from_other_parts_of_stockholm: 'From other parts of Stockholm',
  from_other_parts_of_the_county: 'From other parts of the county',
  from_other_parts_of_sweden: 'From other parts of Sweden',
  from_abroad: 'From abroad',
  total_influx: 'Total influx',
  to_other_parts_of_stockholm: 'To other parts of Stockholm',
  to_other_parts_of_the_county: 'To other parts of the county',
  to_other_parts_of_sweden: 'To other parts of Sweden',
  to_abroad:'To abroad',
  total_outflow:'Total outflow',
};

interface Props {
  data: LocalAreaStatMigration;
}

export const Migration = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return <div onClick={!isOpen ? () => setIsOpen(!isOpen) : undefined}>
    <CollapsibleHeader onClick={() => setIsOpen(!isOpen)}>
      <Arrow isOpen={isOpen} />
      <span>Migration</span>
    </CollapsibleHeader>
    {isOpen && <div>
      <Table>
        <table>
          <thead>
            <tr>
              {['', 'Total'].map((item, i) => <th key={i}>{item}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr><BoldCell>In Migration</BoldCell><td>{data?.in_migration?.total_influx}</td></tr>
            {Object.keys(data?.in_migration || []).map((key, i) => <tr key={i}>
              <td>{titleMapping[key]}</td>
              <td>{data.in_migration[key]}</td>
            </tr>)}
            <tr><td colSpan={2}></td></tr>
            <tr><BoldCell>Out Migration</BoldCell><td>{data?.out_migration?.total_outflow}</td></tr>
            {Object.keys(data?.out_migration || []).map((key, i) => <tr key={i}>
              <td>{titleMapping[key]}</td>
              <td>{data.out_migration[key]}</td>
            </tr>)}
            <tr><td colSpan={2}></td></tr>
            <tr><BoldCell>Net Migration</BoldCell><td>{data?.net_migration}</td></tr>
          </tbody>
        </table>
      </Table>
    </div>}
  </div>;
};
