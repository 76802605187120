/* eslint-disable @typescript-eslint/no-explicit-any */

import debounce from "lodash.debounce";
import mapboxgl from "mapbox-gl";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, { FullscreenControl, Layer, MapboxMap, Marker, MarkerDragEvent, NavigationControl, Popup, Source, ViewStateChangeEvent } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetGeoCodes } from "../../../../types/calculator/get-geo-codes";
import { handlePopup } from "../../features/calculator/components/project-summary/components/popups/utils";
import { ComparablesItem } from "../../features/calculator/components/project-summary/components/result-screens/components/comparables-result/get-list.interface";
import SwitchButton from '../../features/calculator/components/project-summary/shared/elements/switch-button/switch-button';
import { getLandPlot } from "../../features/calculator/components/sidebar/shared/services/calculation.service";
import { setIsBlockApisUsage } from "../../redux/calculate/calculate";
import { RootState } from "../../redux/store";
import { useLocale } from "../../shared";
import { ReactComponent as CustomLocalMarkerImg } from "./images/circle.svg";
import location from "./images/location.svg";
import { ReactComponent as CustomMarkerImg } from "./images/pin-filled.svg";
import { ReactComponent as CustomSchoolMarkerImg } from "./images/pin-school.svg";
import { ReactComponent as CustomStationMarkerImg } from "./images/pin-station.svg";
import "./map-box.css";

const satelliteMapStyle = "mapbox://styles/mapbox/satellite-v9";
const streetMapStyle = "mapbox://styles/mapbox/streets-v9";

export interface GetLandResponse {
  _id: string;
  gmlId: string;
  inspiried: number;
  label: number;
  nationalCadastralReference: number;
  validFrom: string | Date; // actually those are date
  beginLifeSpanVersion: string | Date; // actually those are date
  centroid: number[];
  extreme0: number;
  extreme1: number;
  extreme2: number;
  extreme3: number;
  area: number;
  geojson: {
    type: string | "Polygon";
    coordinates: number[][];
  };
}
export interface LinePlotData {
  type: "Feature";
  properties: any; //{},
  geometry: {
    type: "LineString" | "Polygon";
    coordinates: number[];
  };
}

export interface MapMarker {
  location: {
    longitude: number;
    latitude: number;
  },
  groupData: {
    color: string;
  },
}

interface Props {
  width: string | number;
  height: string | number;
  haveMarker?: boolean;
  isDisabled?: boolean;
  isPolygonFunctionality?: boolean;
  markerDraggable?: boolean;
  isInteractive?: boolean;
  zoomLevel?: number;
  site_address?: string;
  coordinates?: number[] | null;
  customMarkers?: ComparablesItem[] | Marker[];
  markersType?: string;
  currentlySearchedProperty?: GetGeoCodes;
  resetViewFullscreen?: boolean;
  noCustomMarkersPopup?: boolean;
  legend?: React.ReactNode;
  setInspiried?: Dispatch<SetStateAction<string>>;
  setAcres?: (val: number) => void;
}

const MapBox= ({ width, height, haveMarker, site_address, coordinates, isDisabled, isPolygonFunctionality, markerDraggable, isInteractive,
  zoomLevel, customMarkers, markersType, currentlySearchedProperty, resetViewFullscreen, noCustomMarkersPopup, legend, setInspiried, setAcres }: Props) => {
  const dispatch = useDispatch();

  const { toCurrency } = useLocale({ defaultCurrencyValue: '' });

  const mapRef = useRef<MapboxMap | any>(null);

  const [correctCoordinates, setCoordinates] = useState([-0.07485488080595815, 51.50880992724973]);
  const [isUpdateCoordinates, setIsUpadeCoordinates] = useState(false);
  const [isStreetsView, setIsStreetsView] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [mapStyle, setMapStyle] = useState<string>(satelliteMapStyle);
  // const [mapViewBounds, setMapViewBounds] = useState<LngLatBounds | any>(null);
  const [getLandResponse, setGetLandResponse] = useState<GetLandResponse[]>();
  const [linePlotData, setLinePlotData] = useState<LinePlotData[]>([]);
  const [getLandPlotResponse, setGetLandPlotResponse] = useState<GetLandResponse>();
  const [selectedLandPlot, setSelectedLandPlot] = useState<any | LinePlotData>();
  const [getLandPager, setGetLandPager] = useState(1);
  const [popupData, setPopupData] = useState<any>();

  const mapboxToken = useSelector((state:RootState)=>state?.App?.config?.calculator?.REACT_APP_MAPBOX_ACCESS_TOKEN);
  const isBlockApisUsage = useSelector((state: RootState) => state.calculate.analysePage.isBlockApisUsage);

  const maxZoomLevel = useMemo(() => isInteractive && zoomLevel ? zoomLevel : 17, [isInteractive, zoomLevel]);

  useEffect(() => {
    setMapStyle(isStreetsView ? streetMapStyle : satelliteMapStyle);
  }, [isStreetsView]);

  const fitToMapView = useCallback(() => {
    const bounds = new mapboxgl.LngLatBounds();
    if (customMarkers && customMarkers?.length > 0) {
      customMarkers?.forEach((item) => {
        if (item?.location?.longitude && item?.location?.latitude)
          bounds.extend([item?.location?.longitude, item?.location?.latitude]);
      });
      mapRef?.current?.fitBounds([bounds.getSouthWest(), bounds.getNorthEast()], { padding: 40 });
    }
  }, [customMarkers]);

  const mapToPlotData = (landRes: GetLandResponse[]) => {
    const linePlotArray: LinePlotData[] = [];
    landRes.forEach((item) => {
      const linePlotData: LinePlotData = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: item?.geojson?.coordinates?.[0],
        },
      };
      linePlotArray.push(linePlotData);
      //TODO: item.geojson.coordinates[0] possible to have more than 1 polygon
    });
    return linePlotArray;
  };

  const getSinglePlot = (lat: number, lng: number) => {
    getLandPlot(lat, lng).then(res => {
      dispatch(setIsBlockApisUsage(true));
      if (res?.data) {
        if (setInspiried && res?.data?.inspiried) {
          setInspiried(res?.data?.inspiried);
        }
        if (setAcres && res?.data?.area) {
          setAcres(res?.data?.area);
        }
        setGetLandPlotResponse(res?.data);
      }
    }).finally(() => dispatch(setIsBlockApisUsage(false))).catch((error) => console.log(`getSinglePlot error`, error));};

  useEffect(() => {
    if (customMarkers) {
      fitToMapView();
    }
  }, [customMarkers, fitToMapView]);

  useEffect(() => {
    if (getLandResponse) {
      const plotData = mapToPlotData(getLandResponse);
      setLinePlotData((prev: LinePlotData[]) => {
        if (prev) {
          return [...prev, ...plotData];
        } else return [...plotData];
      });
    }
  }, [getLandResponse]);

  useEffect(() => {
    if (getLandPlotResponse) {
      const plotData = mapToPlotData([getLandPlotResponse]);
      setSelectedLandPlot(null);
      setSelectedLandPlot(plotData[0]);
    }
  }, [getLandPlotResponse]);

  useEffect(() => {
    (async () => {
      if (site_address) {
        const info = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${site_address}.json?access_token=${mapboxToken}&type=postcode`,
        );
        const json = await info.json();
        if (json?.features) {
          setCoordinates(json?.features?.[0]?.geometry?.coordinates);
        }
      }
    })();
  }, [site_address]);

  useEffect(() => {
    if (coordinates) {
      setIsUpadeCoordinates(true);
      setCoordinates(coordinates);

      if (mapRef && mapRef.current) {
        mapRef.current?.setZoom(maxZoomLevel);
        mapRef.current?.setCenter([coordinates?.[0], coordinates?.[1]]);
      }

      if (isPolygonFunctionality && !isDisabled) {
        getSinglePlot(correctCoordinates[1], correctCoordinates[0]);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    if (notificationCounter >= 4) {
      setNotificationCounter(0);
    }
  }, [notificationCounter]);

  useEffect(() => {
    const event = () => {
      if (coordinates && resetViewFullscreen) {
        mapRef.current?.setCenter([coordinates?.[0], coordinates?.[1]]);
        mapRef.current?.setZoom(maxZoomLevel);
      }
    };

    addEventListener("fullscreenchange", event);

    return () => removeEventListener("fullscreenchange", event);
  }, [coordinates, maxZoomLevel, resetViewFullscreen]);

  const debouncedResults = useMemo(() => {
    return debounce((event: ViewStateChangeEvent) => {
      setGetLandPager(1);
      setNotificationCounter((prevVal) => {
        return prevVal++;
      });
      // if (isInitialLoading) {
      //   setIsInitialLoading(false);
      //   return
      // }
      // if (!mapViewBounds) return
      if (
        isPolygonFunctionality &&
        mapRef.current?.getZoom() >= maxZoomLevel - 1 &&
        !isDisabled
      ) {
        // setMapViewBounds(event?.target?.getBounds());
        getSinglePlot(correctCoordinates[1], correctCoordinates[0]);

      } else if (isPolygonFunctionality && notificationCounter === 3) {
        toast('Zoom in on the map for more features', { type: "info" });

        console.log("render! mapZoomLevel", mapRef.current?.getZoom());
      }
    }, 500);
  }, [
    isPolygonFunctionality,
    maxZoomLevel,
    notificationCounter,
    getLandPager,
    isInitialLoading,
  ]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleMapInit = () => {
    // if custom markers functionality (from comparables Map)
    if (customMarkers) {
      fitToMapView();
    }
    if (isPolygonFunctionality && !isDisabled) {
      getSinglePlot(correctCoordinates[1], correctCoordinates[0]);
    }
  };

  const renderPopup = () => {
    return (
      popupData && (
        <Popup
          className="custom-marker-popup"
          anchor="bottom"
          longitude={popupData?.location?.longitude}
          latitude={popupData?.location?.latitude}
          closeButton={false}
          closeOnClick={false}
          onClose={() => setPopupData(null)}
        >
          {popupData?.info && (
            <p>
              {popupData?.info?.objectType ? popupData.info.objectType : "-"}{" "}
              <br />
              {popupData?.info?.name ? popupData.info.name : "-"}
            </p>
          )}
          {!popupData?.info && (
            <p>
              {popupData?.displayAddress ? popupData.displayAddress : "-"}
              <br />
              {popupData?.propertySubType ? popupData.propertySubType : "-"}
              <br />
              {toCurrency(popupData?.price?.amount, '-')}
              <br />
            </p>
          )}
        </Popup>
      )
    );
  };

  const openMapPopup = (item: any) => {
    if (!noCustomMarkersPopup) {
      handlePopup("comparables-item", item);
    }
  };

  const renderCityMarker = (item: any, index: number) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={item?.location?.longitude}
        latitude={item?.location?.latitude}
      >
        {item?.info?.objectType === "Station" && (
          <CustomStationMarkerImg
            onMouseEnter={() => {
              item.info ? setPopupData(item) : setPopupData(null);
            }}
            onMouseLeave={() => setPopupData(null)}
            onClick={() => openMapPopup(item)}
            className="custom-marker"
          />
        )}
        {item?.info?.objectType === "School" && (
          <CustomSchoolMarkerImg
            onMouseEnter={() => {
              item.info ? setPopupData(item) : setPopupData(null);
            }}
            onMouseLeave={() => setPopupData(null)}
            onClick={() => openMapPopup(item)}
            className="custom-marker"
          />
        )}
        {markersType == "circle" && (
          <CustomLocalMarkerImg
            stroke="#FFFF"
            fill={item?.groupData?.color}
            onMouseEnter={() => {
              item.info ? setPopupData(item) : setPopupData(null);
            }}
            onMouseLeave={() => setPopupData(null)}
            onClick={() => openMapPopup(item)}
            className="custom-marker"
          />
        )}
        {markersType === "pin" && (
          <CustomMarkerImg
            fill={`#${item?.groupData?.color}`}
            onMouseEnter={() => {
              item.info ? setPopupData(item) : setPopupData(null);
            }}
            onMouseLeave={() => setPopupData(null)}
            onClick={() => openMapPopup(item)}
            className="custom-marker"
          />
        )}
      </Marker>
    );
  };

  const onMarkerDragEnd = useCallback(
    (event: MarkerDragEvent) => {
      setCoordinates([event.lngLat.lng, event.lngLat.lat]);
      if (isPolygonFunctionality && !isDisabled) {
        getSinglePlot(event.lngLat.lat, event.lngLat.lng);
      }
    },
    [isPolygonFunctionality],
  );

  return (
    <div className={`map ${isInteractive ? "" : "map_disabled-ui"}`}>
      { isBlockApisUsage && <div className="is-apis-loading"><div className="is-apis-loading_background"></div><div className="is-apis-loading_rotate"></div></div>}
      { isDisabled && <div className="is-apis-loading"><div className="is-apis-loading_background"></div></div>}
      <Map
        ref={mapRef}
        onDragEnd={debouncedResults}
        onZoomEnd={debouncedResults}
        scrollZoom={false}
        key={isUpdateCoordinates}
        onClick={(event) => {
          if (isPolygonFunctionality && !isDisabled && isInteractive) {
            setCoordinates([event.lngLat.lng, event.lngLat.lat]);
            getSinglePlot(event.lngLat.lat, event.lngLat.lng);
          }
        }}
        onLoad={() => handleMapInit()}
        // interactive={isInteractive ? true : false}
        // scrollZoom={isInteractive ? true : false}
        // dragPan={isInteractive ? true : false}
        // maxZoom={maxZoomLevel}
        initialViewState={{
          longitude: correctCoordinates?.[0],
          latitude: correctCoordinates?.[1],
          zoom: maxZoomLevel,
          bearing: 0,
          pitch: 0,
          padding: { top: 0, bottom: 0, right: 0, left: 0 },
        }}
        mapboxAccessToken={mapboxToken}
        style={{ width, height }}
        mapStyle={`${isPolygonFunctionality ? mapStyle : streetMapStyle}`}
      >
        {customMarkers &&
          customMarkers?.length > 0 &&
          customMarkers.map(renderCityMarker)}
        ;{customMarkers && customMarkers?.length > 0 && renderPopup()}
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        {haveMarker && (
          <Marker
            draggable={!!markerDraggable}
            onDragEnd={onMarkerDragEnd}
            longitude={correctCoordinates?.[0]}
            latitude={correctCoordinates?.[1]}
            anchor="bottom"
          >
            <img
              onMouseEnter={() =>
                customMarkers
                  ? setPopupData({
                    location: {
                      longitude: coordinates ? coordinates?.[0] : 0,
                      latitude: coordinates ? coordinates?.[1] : 0,
                    },
                    displayAddress: currentlySearchedProperty?.placeName
                      ? "Property from address/postcode search: "
                      : "-",
                    propertySubType: currentlySearchedProperty?.placeName
                      ? currentlySearchedProperty?.placeName
                      : "-",
                  })
                  : null
              }
              onMouseLeave={() => (customMarkers ? setPopupData(null) : null)}
              src={location}
              alt="location"
            />
          </Marker>
        )}
        {isPolygonFunctionality && selectedLandPlot && (
          <Source
            key={`plot-selected`}
            id={`polylineLayer-selected`}
            type="geojson"
            data={selectedLandPlot}
          >
            <Layer
              key={`layer-selected`}
              id={`lineLayer-selected`}
              type="line"
              source="route"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "red",
                "line-width": 3,
              }}
            />
          </Source>
        )}
        {isPolygonFunctionality &&
          linePlotData &&
          linePlotData.map((item: LinePlotData, index: number) => {
            return (
              <>
                <Source
                  key={`plot-selected`}
                  id={`polylineLayer-selected`}
                  type="geojson"
                  data={selectedLandPlot}
                >
                  <Layer
                    key={`layer-selected`}
                    id={`lineLayer-selected`}
                    type="line"
                    source="route"
                    layout={{
                      "line-join": "round",
                      "line-cap": "round",
                    }}
                    paint={{
                      "line-color": "red",
                      "line-width": 3,
                    }}
                  />
                </Source>

                <Source
                  key={`plot-${index}`}
                  id={`polylineLayer-${index}`}
                  type="geojson"
                  data={item}
                >
                  {isPolygonFunctionality && selectedLandPlot && (
                    <Layer
                      key={`layer-selected`}
                      id={`lineLayer-selected`}
                      type="line"
                      source={{ type: "geojson", data: selectedLandPlot }}
                      layout={{
                        "line-join": "round",
                        "line-cap": "round",
                      }}
                      paint={{
                        "line-color": "red",
                        "line-width": 5,
                      }}
                    />
                  )}
                  <Layer
                    key={`layer-${index}`}
                    id={`lineLayer-${index}`}
                    type="line"
                    source="route"
                    layout={{
                      "line-join": "round",
                      "line-cap": "round",
                    }}
                    paint={{
                      "line-color": "#3698af",
                      "line-width": 3,
                    }}
                  />
                  {/* {(isPolygonFunctionality && selectedLandPlot) && <Layer
                  key={`layer-selected`}
                  id={`lineLayer-selected`}
                  type="line"
                  source={{ type: "geojson", data: selectedLandPlot }}
                  layout={{
                    "line-join": "round",
                    "line-cap": "round"
                  }}
                  paint={{
                    "line-color": "red",
                    "line-width": 5
                  }}
                />} */}
                </Source>
              </>
            );
          })}
      </Map>
      {isPolygonFunctionality && (
        <div className="switch-button">
          <SwitchButton
            isChecked={setIsStreetsView}
            option1={"Satelite"}
            option2={"Map"}
          ></SwitchButton>
        </div>
      )}
      {legend && <div className="legend">{legend}</div>}
    </div>
  );
};

export default MapBox;
