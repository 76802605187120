import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const checkEmail = (email: string) => !!/^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(email.toLowerCase());

export const checkPassword = (password: string) => !!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!"$-/:-?[\]^`{-~]{8,}$/.test(password);

export const passwordRequirement = 'Use 8+ lower/uppercase and symbol signs';

export const checkPhone = (phone: string) => isPossiblePhoneNumber(phone) && !!/^[\d ()+-]*$/.test(phone);

export const phoneRequirement = 'Phone should be valid and include country code';
