import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    background: rgba(246, 248, 250, 0.7);
    border: 1px solid var(--color-whiteish-3);
    border-radius: 8px;
  }

  > div:last-of-type {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }
`;

