import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

const Hint = styled.span<{ marginTop?: string; marginBottom?: string; space?: number }>`
  margin-top: ${(p) => p.marginTop || 0};
  margin-bottom: ${(p) => p.marginBottom || '16px'};
  display: flex;
  align-items: center;
  line-height: 48px;

  span {
    padding: 0 ${(p) => p.space || 55}px;
    color: ${palette.grayMedium}
  }

  hr {
    flex-grow: 1;
    border-color: ${palette.grayLight};
  }
`;

export { Hint };
