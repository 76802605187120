import { equals } from 'ramda';
import { toast } from 'react-toastify';
import { authFailure, authSetLoadPictureStatus, authSuccess, setAuthStatus, setNotVerified, setSaveProfileStatus, setUser } from './auth';
import { InitResponse, Profile } from '../../../../types';
import { apiFetch } from '../api';
import { AppThunk } from '../store';

export const setPictureAction = (picture: Blob, contentType: string): AppThunk => async (dispatch) => {
  try {
    dispatch(authSetLoadPictureStatus('load'));

    const res = await apiFetch('userpic', {
      method: 'post',
      body: picture,
      headers: { 'Content-Type': contentType },
      returnError: true,
    });

    if ('error' in res) {
      console.error(res.error);
      dispatch(authSetLoadPictureStatus('error'));
      toast('Unable to load picture', { type: 'error' });
    } else {
      dispatch(authSetLoadPictureStatus('success'));
    }
  } catch (error) {
    console.error(error);
    dispatch(authSetLoadPictureStatus('error'));
    toast('Unable to load picture', { type: 'error' });
  }
};

export const authInitAction = (): AppThunk => async (dispatch, getState) => {

  try {
    dispatch(setAuthStatus('load'));
    const auth: InitResponse = await apiFetch('auth/init');

    dispatch(authSuccess(auth.user));
    dispatch(setNotVerified(auth.notVerifiedEmail ?? null));

  } catch (error) {
    dispatch(authFailure((error as Error).toString()));
  }

  const checkAuthPeriod = 30 * 60 * 1000;

  const checkAuth = async () => {
    try {
      const user = getState().Auth.user;
      const userId = user?.id;
      const auth: InitResponse = await apiFetch('auth/init');
      const logout = getState().Auth.logout;

      if ( logout ) {
        window.location.href = '/api/sso-logout';
        return;
      }

      if (!equals(auth.user, user) || auth.user.id !== userId) {
        dispatch(authSuccess(auth.user));
        dispatch(setNotVerified(auth.notVerifiedEmail ?? null));
      }
      if (userId && !auth.user) {
        document.location.href = '/api/sso-login';
      }
    } catch (error) {
      dispatch(authFailure((error as Error).toString()));
    }
    setTimeout(checkAuth, checkAuthPeriod);
  };

  setTimeout(checkAuth, checkAuthPeriod);
};

export const authSaveProfileAction = (profile: Profile, callback?: () => void): AppThunk => async (dispatch) => {
  try {
    dispatch(setSaveProfileStatus('load'));
    const resp = await apiFetch('auth/profile', {
      method: 'POST',
      body: JSON.stringify(profile),
    });

    if (!resp.error) {
      dispatch(setSaveProfileStatus('success'));
      dispatch(setUser(resp.user));
      toast('Profile saved');
      callback?.();
    } else {
      dispatch(setSaveProfileStatus('error'));
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    toast('Unable to save profile', { type: 'error' });
    console.error(error);
  }
};

export const sendDeleteConfirmationToken = (email: string): AppThunk => async () => {
  try {
    const resp = await apiFetch('auth/delete-token', { method: 'post', body: JSON.stringify({ email }), returnError: true });

    if (!resp || resp.error) {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    toast('Unable to send delete confirmation token', { type: 'error' });
    console.error(error);
  }
};

export const deleteUser = (userId: string, token: string, callbacks?: { success?: () => void, fail?: () => void }): AppThunk => async () => {
  try {
    const resp = await apiFetch('auth/delete', { method: 'delete', body: JSON.stringify({ userId, token }), returnError: true });

    if (resp && !resp.error) {
      callbacks?.success?.();
    } else {
      callbacks?.fail?.();
    }
  } catch (error) {
    toast('Unable delete user with confirmation token', { type: 'error' });
    console.error(error);
  }
};
