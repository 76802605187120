import { StylesConfig } from 'react-select';
import theme from '../../../../themes';

const { palette } = theme;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dropdownStyle: StylesConfig<any, any> = {
  container: (provided, state) => ({
    ...provided,
    height: '25px',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    alignItems: 'baseline',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    paddingTop: '0',
    height: '25px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0',
    height: '25px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '25px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'inherit',
    background: state.isFocused ? palette.blueLight : 'white',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: `${palette.greenLight}80`,
    borderRadius: '5px',
    flex: '0.5 1 0%',
    minWidth: '50px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: palette.grayDark,
    padding: '1px 3px 1px 3px',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    marginLeft: 'auto',
  }),
};
