export const supportTypeOptions = ['Payments & subscription'].map((value) => ({ value, label: value }));

export interface SupportTicketNote {
  id: number;
  createdAt: number;
  updatedAt: number;
  text: string;
}

export interface SupportTicket {
  id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  closedAt?: string | Date;
  title: string;
  message: string;
}

export interface SupportTicketCreateRequest {
  type: string;
  message: string;
  email?: string;
  captcha?: string;
  documents: File[];
}
