import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Title = styled.div`
  font-family: 'Basis Grotesque Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  color: ${palette.black};
  border-bottom: 1px solid ${palette.grayBg};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Padding = styled.div`
  padding: 20px 24px 30px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Subtitle = styled.div`
  color: ${palette.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
`;

export const Buttons = styled.div`
  > * {
    width: 160px;
  }
  display: flex;
  justify-content: flex-end;
`;

export const Captcha = styled.div`
  display: flex;
  justify-content: center;
`;
