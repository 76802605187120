import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const SubHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  border-bottom: 3px solid ${palette.grayBg};
  padding: 30px;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    width: 100vw;
    padding: 10px 15px;

    > div {
      max-width: 60%;
      min-width: 60px;
      justify-content: center;

      :first-of-type {
        width: 20%;
      }
    }

    > span {
      width: 20%;
      text-align: right;
    }
  }
`;
