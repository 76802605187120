import styled from 'styled-components';

export const Wrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  div {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-right: 8.25px;
    color: ${(p) => p.color};
  }

  svg {
    height: 13.5px;
    width: 13.5px;
    fill: ${(p) => p.color};
  }
`;

