import React from 'react';
import { CheckboxStyle } from './checkbox.style';

export function StandaloneCheckbox(props) {
  return (
    <CheckboxStyle className="custom-control custom-checkbox" margin={props.margin}>
      <input
        className="custom-control-input"
        type="checkbox"
        {...props}
      />
      {props.label &&
        <label className="custom-control-label" htmlFor={props.id}>{props.label}</label>
      }
    </CheckboxStyle>
  );
}

export default StandaloneCheckbox;
