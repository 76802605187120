import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

export const ListButtonWrapper = styled.div<{disabled: boolean}>`
  width: 430px;
  height: 120px;
  display: flex;
  box-shadow: 10px 10px 40px #e9f3f9;
  margin-bottom: 20px;
  border-radius: 15px;

  :hover {
    cursor: pointer;
    box-shadow: ${(p) => p.disabled ? '10px 10px 40px #e9f3f9' : '10px 10px 40px #d3e6f2'};
  }

  opacity: ${(p) => p.disabled ? '0.5' : '1'};
`;

export const Title = styled.div`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  
  padding-top: 20px;
  padding-bottom: 9px;

  b {
    font-weight: 700;
  };
`;

export const Text = styled.div`
  font-family: 'Roboto', 'Gotham Pro', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: ${palette.grayDark};
`;

export const IconWrapper = styled.div`
  width: 90px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ArrowWrapper = styled.div`
  width: 90px;
  align-items: center;
  display: flex;
  padding-left: 30px;
`;

export const TextWrapper = styled.div`
  width: 250px;
`;
