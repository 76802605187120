import { RangeWithKey } from 'react-date-range';
import { MultipleCalendarValue } from './types';

export const toRangeWithKey = (value?: MultipleCalendarValue): RangeWithKey => {
  return {
    startDate: value?.[0],
    endDate: value?.[1],
    key: 'selection',
  };
};

export const fromRangeWithKey = (range?: RangeWithKey): MultipleCalendarValue => {
  return [range?.startDate, range?.endDate];
};
