import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const PageStyle = styled.div`
  .mobile &.adaptive, .mobile .adaptive, .mobile.adaptive, .tablet &.adaptive, .tablet .adaptive, .tablet.adaptive {
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    overflow: hidden;
    padding: 0;
  }

  overflow-x: hidden;
  min-width: 1024px;
  padding: 20px 0 20px 0;
  background: ${palette.white};
  min-height: 100vh;
  height: 100vh;
  display: flex;
`;
