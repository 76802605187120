import styled from 'styled-components';
import { ReactComponent as ArrowImage } from '../assets/images/arrow.svg';

export const Popup = styled.div`
  background: var(--color-white);
  border-radius: 8px;
`;

export const Header = styled.div`
  position: relative;

  width: 100%;
  padding: 12px 0;
  margin: 0;
  border-bottom: 1px solid var(--color-greyish);

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Basis Grotesque Pro", Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--color-black-1);
`;

export const Content = styled.div`
  height: 100%;
  max-height: 650px;
  padding: 24px;
  overflow: auto;
  background: var(--color-whiteish-2);

  display: flex;
  flex-direction: column;
  gap: 15px;

  > div:not(:first-of-type) {
    padding: 24px;
    border-radius: 8px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

export const Buttons = styled.div`
  border-top: 1px solid var(--color-greyish);
  padding: 0 24px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  > div {
    height: 100%;
    padding: 16px 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;

export const Button = styled.button<{variant: 'submit' | 'cancel'}>`
    border: 1px solid var(--color-greyish);
    border-radius: 8px;
    padding: 12px 24px;

    font-family: "Basis Grotesque Pro", Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    ${(p) => p.variant === 'submit' ? `
      background: var(--color-aqua);
      color: var(--color-white);
    ` : `
      background: var(--color-white);
      color: var(--color-black-1);
    `}
`;

export const CollapsibleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  margin: 0;
  margin-left: 10px;
  
  font-family: 'Basis Grotesque Pro';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  cursor: pointer;
`;

export const Table = styled.div<{noTotal?: boolean}>`
  padding: 16px;
  background: #f6f8fab2;
  border: 1px solid #ebf1f4;
  border-radius: 16px;

  font-family: 'Basis Grotesque Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: right;

  td, th {
    padding: 16px;

    :first-of-type {
      text-align: left;
    }
  }

  th {
    color: #7e869b;
    font-weight: 400;
  }

  tr {
    height: 48px;
  }

  tr:not(:last-of-type) td, th {
    border-bottom: 1px solid #ebf1f4;
  }

  ${(p) => !p.noTotal ? `}
    tr:last-of-type {
      font-weight: 600;
      color: #404657;
    }
  ` : ''}
`;

export const BoldCell = styled.td`
  font-weight: 600;
  color: #404657;
`;

export const Tabs = styled.div`
  padding: 5px;
  background: #ebf1f4;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tab = styled.div<{active: boolean}>`
  padding: 6px 24px;
  border-radius: 6px;

  font-family: 'Basis Grotesque Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  cursor: pointer;

  ${(p) => p.active ? 'background: white;' : ''}
`;

export const Arrow = styled(ArrowImage).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop as string),
})<{isOpen: boolean}>`
  transform: ${(p) => p.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  transition: 0.2s;
`;
