/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from "react";
import Select, { components } from "react-select";
import "./dropdown.css";
import { useDispatch } from "react-redux";
import { setIsFormChanged } from "../../../../../redux/calculate/calculate";

function Props(value: any) {
  return value;
}

interface DropdownProps {
  required?: boolean;
  isDirty?: boolean;
  placeholder?: string;
  width?: string;
  color?: string;
  errorMessage?: string;
  selectedValue?: any;
  valueChanges: typeof Props;
  options?: Array<{
    value: string;
    label: string;
  }>;
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = (props: DropdownProps) => {
  return (
    <ValueContainer {...props} className="dropdown">
      <Placeholder
        className="dropdown-placeholder"
        style={{ top: "-8px" }}
        {...props}
      >
        {props.selectProps.placeholder}{props.required ? '*' : ''}
      </Placeholder>
      {React.Children.map(props.children, (child) => child && child.type !== Placeholder ? child : null)}
      {(props?.errorMessage && props?.isDirty) && <span
        style={{ color: "rgb(168, 22, 22)" }}
        className="form-input-error-message"
      >
        {`*${props?.errorMessage}`}
      </span>}
    </ValueContainer>
  );
};

interface Props {
  width?: string;
  minWidth?: string;
  placeholder: string;
  selectedValue?: any;
  isDirty?: boolean;
  isOpenTop?: boolean;
  disabled?: boolean;
  options?: { value: string|number; label: string }[];
  color?: string;
  required?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  isClearable?: boolean;
  usePortal?: boolean;
  valueChanges?: typeof Props;
}

export function Dropdown({ width, minWidth, isDirty, placeholder, disabled, options, color,
  isOpenTop, required, errorMessage, selectedValue, readOnly, isClearable, usePortal, valueChanges }: Props) {

  const dispatch = useDispatch();

  const [isCurrentlyFocused, setIsCurrentlyFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState<any>();

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: width,
      minWidth,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      height: "56px",
      border: "none",
      backgroundColor: color ? color : "#F3F6F9",
      cursor: "pointer",
      opacity: disabled ? "0.6" : "",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      overflow: "visible",
      padding: "14px 0 14px 12px",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      position: "relative",
      bottom: -24,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      position: 'absolute',
      right: '0',
      top: 'calc(50% - 16px)',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 999999 }),
  };

  return (
    <Select
      menuPlacement={isOpenTop ? 'top' : 'auto'}
      className={`
          ${(isDirty && errorMessage) ? 'dropdown-select-error' : ''}
          ${(selectedValue || currentValue) ? 'dropdown-placeholder-top' : ''}
      `}
      isDisabled={disabled || readOnly}
      value={selectedValue}
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary25: "var(--color-whiteish-3)",
          primary: "var(--color-whiteish-3)",
        },
      })}
      components={{
        ValueContainer: (props) =>
          CustomValueContainer({ ...props, errorMessage, required, isDirty }),
      }}
      onChange={(selected) => {
        if (selected?.value) setCurrentValue(selected?.value);
        if (valueChanges !== undefined) {
          if (selected?.value) dispatch(setIsFormChanged(true));
          valueChanges(selected?.value);
        }
      }}
      onFocus={() => setIsCurrentlyFocused(true)}
      onBlur={() => setIsCurrentlyFocused(false)}
      options={options}
      placeholder={placeholder}
      menuPortalTarget={usePortal ? document.body : undefined}
      isClearable={isClearable}
      isSearchable={false}
    />
  );
}
