import styled from 'styled-components';
import theme from '../../../../themes';

const { palette } = theme;

export const TwoLevelMenu = styled.div`
  position: absolute;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;

  display: flex;
  width: 100%;
  height: 252px;
  left: 0px;
  top: 100%;
  margin-top: 0;
  border-radius: 5px;
  padding: 15px 5px;
  font-size: 14px;
  color: rgba(126, 134, 155, 1);
  background: white;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  z-index: 5;
  > div {
    width: 50%;
    height: 100%;
    padding: 0 10px;
    overflow: auto;
    > h2 {
      font-size: 12px;
      font-weight: 600;
      color: rgba(54, 59, 73, 1);
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .mobile && {
    padding: 15px 5px 15px 10px;
    > div {
      padding: 0;
    }
  }
`;

export const TwoLevelMenuItem = styled.li<{ active?: boolean }>`
  background: ${(p) => p.active ? `${palette.greenLight}80` : 'transparent'};
  border-radius: 5px;
  padding: 3px 5px;
  .mobile && {
    margin-right: 0;
  }
`;

export const MultiSelectOptions = styled.div<{ hasPlaceholder?: boolean; count: number }>`
  position: absolute;
  left: 0;
  top: ${(p) => p.hasPlaceholder ? 23 : 13}px;
  bottom: 0;
  width: 90%;
  overflow: hidden;
  > div {
    display: flex;
    white-space: nowrap;
    height: 150%; // To move scrollbar below visible area
    overflow-x: auto;
    overflow-y: hidden;
    > div {
      display: flex;
      align-items: center;
      position: relative;
      height: 24px;
      max-width: ${(p) => p.count < 3 ? `${95 / p.count}%` : '150px'};
      border-radius: 5px;
      padding-right: 20px;
      background: white;
      margin-right: 5px;
      z-index: 2;
      background: ${palette.greenLight}80;
      > p {
        margin: 1px 5px 0 10px;
        overflow: hidden;
        font-family: 'Basis Grotesque Pro', Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-overflow: ellipsis;
      }
      > svg {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 4px;
        cursor: pointer;
      }
    }
  }
  .mobile && {
    max-width: 80%;
  }
`;
