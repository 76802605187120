import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './style';

interface Props {
  label: string | [string, string];
  value: boolean;
  small?: boolean;
  onChange: () => void;
}

export const Toggle = ({ label, value, small, onChange }: Props) => {
  const { t } = useTranslation();

  return <Container active={value} small={small} onClick={() => onChange()}>
    {Array.isArray(label) && <span className="popup-buttons-checkbox-label" style={{ color: !value ? "var(--color-black-1)" : "var(--color-grey)" }}>
      {t(label[0])}
    </span>}
    <div className="popup-toggle-wrapper">
      <div className="popup-toggle-component" style={{ background: !value ? "var(--color-grey)" : "var(--color-aqua)" }}>
        <div className="popup-toggle" style={{ marginLeft: !value ? "2px" : "14px" }} />
      </div>
    </div>
    <span className="popup-buttons-checkbox-label" style={{ color: value ? "var(--color-black-1)" : "var(--color-grey)" }}>
      {t(Array.isArray(label) ? label[1] : label)}
    </span>
  </Container>;
};
