import React, { useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Text, CheckboxContainer, ButtonContainer, ControlsContainer } from './style';
import { Popup, PopupTitle } from '../popup';
import { Button, Checkbox, HorizontalLine } from '../ui';
import ErrorIcon from './images/error.svg';

const icons = {
  'Error': ErrorIcon,
};

interface ConfirmationResult {
  selectedIndex?: number;
  checkboxes?: CheckBoxState;
}

interface CheckBoxState {
  [name: string]: boolean;
}

interface ConfirmationProps {
  icon?: 'Error';
  title: string;
  text?: ReactNode;
  select?: string[];
  selected?: number;
  buttons: Array<{
    text: string;
    variant?: 'primary' | 'secondary';
    disabled?: boolean;
    onClick?: (result?: ConfirmationResult) => void;
  }>;
  adaptive?: boolean;
  verticalButtons?: boolean;
  controls?: ReactNode;
  checkboxes?: CheckBoxState;
  onClose?: () => void;
}

export const Confirmation = ({ icon, title, text, select, buttons, selected, adaptive, verticalButtons, controls, checkboxes, onClose }: ConfirmationProps) => {
  const layout = useSelector((state: RootState) => state.App.layout);

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>((select || []).length > 0 ? (selected || 0) : undefined);
  const [checkboxesState, setCheckboxesState] = useState(checkboxes);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        buttons.find(({ variant }) => variant === 'primary')?.onClick?.({ selectedIndex });
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [buttons, selectedIndex]);

  return <Popup
    padding={['mobile', 'tablet'].includes(layout) && adaptive ? [60, 35, 60, 35] : [60, 70, 60, 70]}
    onClose={onClose}
    adaptive={adaptive}
    noPortal
  >
    {icon && <div><img src={icons[icon]} alt='' /></div>}
    <PopupTitle marginTop='30px' width={['mobile', 'tablet'].includes(layout) && adaptive ? 'calc(100vw - 86px)': '285px'}>{title}</PopupTitle>
    {text && <Text>{text}</Text>}
    {select && <CheckboxContainer marginBottom={Object.keys(checkboxes || {}).length > 0}>
      {select.map((item, i) => <Checkbox
        key={i}
        label={item}
        radio
        value={i === selectedIndex}
        marginBottom={i < select.length - 1 ? '20px' : '0'}
        fontSize='14px'
        onChange={() => setSelectedIndex(i) }
      />)}
    </CheckboxContainer>}
    {controls && <ControlsContainer>{controls}</ControlsContainer>}
    {checkboxesState && <CheckboxContainer>
      {Object.entries(checkboxesState).map(([name, checked], i) => <Checkbox
        key={i}
        label={name}
        value={checked}
        marginBottom={i < Object.keys(checkboxesState).length - 1 ? '20px' : '0'}
        fontSize='14px'
        onChange={() => setCheckboxesState({ ...checkboxesState, [name]: !checked }) }
      />)}
    </CheckboxContainer>
    }
    <ButtonContainer vertical={verticalButtons}>
      {buttons.map((button, i) => <div key={i}>
        <Button
          variant={button.variant}
          width='100%'
          onClick={() => button.onClick?.({ selectedIndex, checkboxes: checkboxesState })}
          nowrap
          disabled={button.disabled}
        >{button.text}</Button>
        {verticalButtons && i < buttons.length - 1 && <HorizontalLine text='or' marginTop='5px' marginBottom='5px' />}
      </div>)}
    </ButtonContainer>
  </Popup>;
};
