import styled from 'styled-components';

export const ButtonStyle = styled.div<{active?: boolean; arrow?: boolean}>`
  display: flex;
  height: 48px;
  padding: 13px 24px;
  background: ${(p) => p.active ? '#0A6232' : 'white'};
  border: ${(p) => p.active ? 'none' : '1px solid #dfe5eb'};
  border-radius: 8px;
  cursor: pointer;

  > svg {
    height: 18px;
    width: 19px;
    margin-right: 10px;

    > path {
      stroke: ${(p) => p.active ? 'white' : '#7e869b'};
    }
  }

  > span {
    font-family: 'Basis Grotesque Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${(p) => p.active ? 'white' : '#222222'};
  }
`;
