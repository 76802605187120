import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const InputStyle = styled.div<{showDropdown: boolean; marginBottom?: number; error?: boolean}>`
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  ${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  `: ''}

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    > input, > textarea {
      font-family: 'Basis Grotesque Pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: #7e869b;
      background: #f3f6f9;
      border: ${(p) => p.error ? `1px solid ${palette.red}` : '1px solid #f3f6f9'};
      padding: 16px 0 12px 12px;
      border-radius: 8px;
      width: 100%;
      outline: none;
      resize: none;
    }

    > svg {
      position: absolute;
      right: 16px;
      cursor: pointer;

      ${(p) => p.showDropdown ? `
        transform: rotate(180deg);
      `: ''}
    }
  }

  > span {
    height: 16px;
    width: 100%;
    font-family: 'Basis Grotesque Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: #7e869b;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  background: #f3f6f9;
  z-index: 2;
  border-radius: 8px;
  width: 100%;
  box-shadow: 10px 10px 40px rgba(29, 59, 78, 0.15);

  > div {
    font-family: 'Basis Grotesque Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #7e869b;
    cursor: pointer;
    padding: 10px;

    :hover {
      background: #d7dbdd;
    }

    :first-of-type {
      border-radius: 8px 8px 0 0;
    }

    :last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
`;
