import styled from 'styled-components';

export const Container = styled.div<{active: boolean; small?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 24px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 24px;

    > div {
      width: 36px;
      height: 24px;
      border-radius: 100px;
      background: ${(p) => p.active ? 'var(--color-aqua)' : 'var(--color-grey)'};
      cursor: pointer;

      > div {
        margin-top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin-left: ${(p) => p.active ? '14px' : '2px'};
        background: var(--color-white);
      }
    }
  }

  > span {
    white-space: nowrap;
    color: ${(p) => p.active ? 'var(--color-black-1)' : 'var(--color-grey)'};

    ${(p) => p.small ? `
      font-size: 13px;
    ` : ''}
  }
`;
