import React, { useCallback, useState } from 'react';
import { TemplateConstruction, TemplateConstructionItem, TemplatePreConstructionItem, TemplateProfessionalFeesItem } from '../../../../../../types';
import { Option } from '../../../../types';
import { CustomInput } from '../../../calculator/components/ui';
import { ReactComponent as AddImage } from '../../images/add.svg';
import { ReactComponent as ArrowImage } from '../../images/arrow.svg';
import { AddItem, CollapsibleHeader, Label, SectionContainer, TabContainer, TabContent } from '../../style';
import { Line } from './line';

type Section = 'preConstruction' | 'construction' | 'professionalFees';
type PartialItem = Partial<TemplateConstructionItem | TemplatePreConstructionItem | TemplateProfessionalFeesItem>

interface Props {
  data: TemplateConstruction;
  defaultData?: TemplateConstruction;
  sizeName: 'NIA' | 'BOA';
  sizeUnit: 'sqft' | 'sqm';
  isSummary?: boolean;
  setData?: (data: TemplateConstruction) => void;
}

export const ConstructionTab = ({ data, defaultData, sizeName, sizeUnit, isSummary, setData }: Props) => {
  const [totalFloorRatioIsOpen, setTotalFloorRatioIsOpen] = useState(true);
  const [preConstructionIsOpen, setPreConstructionIsOpen] = useState(true);
  const [constructionIsOpen, setConstructionIsOpen] = useState(true);
  const [professionalFeesIsOpen, setProfessionalFeesIsOpen] = useState(true);
  const [contingencyIsOpen, setContingencyIsOpen] = useState(true);

  const updateItem = useCallback((section: Section, i: number, item: PartialItem) => setData?.({
    ...data,
    [section]: [
      ...data[section].slice(0, i),
      { ...data[section][i], ...item },
      ...data[section].slice(i + 1),
    ],
  }), [data, setData]);

  const getDefaultValue = useCallback((section: Section, cost_type: string, key: string) => {
    const found = defaultData?.[section]?.find?.((defaultItem) => cost_type === defaultItem.costType);
    return (found && key in found ? found[key] : 0) || '';
  }, [defaultData]);

  const getDefaultValueDropdown = useCallback((section: Section, cost_type: string, key: string, options: Option[]) => {
    const found = defaultData?.[section]?.find?.((defaultItem) => cost_type === defaultItem.costType);
    return (found && key in found ? options.find(({ value }) => found[key] === value) : '') || '';
  }, [defaultData]);

  const removeItem = useCallback((section: Section, i: number) => setData?.({
    ...data,
    [section]: [...data[section].slice(0, i), ...data[section].slice(i + 1)],
  }), [data, setData]);

  const copyItem = useCallback((section: Section, i: number) => setData?.({
    ...data,
    [section]: [...data[section].slice(0, i), data[section][i], ...data[section].slice(i)],
  }), [data, setData]);

  const getLineParams = useCallback((section: Section, i: number) => ({
    type: section === 'construction' ? 'construction' as const : 'other' as const,
    sizeName,
    sizeUnit,
    getDefaultValue: defaultData ? (cost_type: string, key: string) => getDefaultValue(section, cost_type, key) : undefined,
    getDefaultValueDropdown: defaultData ? (cost_type: string, key: string, options: Option[]) => getDefaultValueDropdown(section, cost_type, key, options) : undefined,
    removeItem: setData ? () => removeItem(section, i) : undefined,
    copyItem: setData ? () => copyItem(section, i) : undefined,
  }), [sizeName, sizeUnit, defaultData, setData, getDefaultValue, getDefaultValueDropdown, removeItem, copyItem]);

  return <SectionContainer isSummary={isSummary}>
    <TabContent>
      <CollapsibleHeader isOpen={totalFloorRatioIsOpen} onClick={() => setTotalFloorRatioIsOpen(!totalFloorRatioIsOpen)}>
        <ArrowImage />
        <span>Construction settings</span>
      </CollapsibleHeader>
      {totalFloorRatioIsOpen && <TabContainer>
        <div>
          <Label width='200px'>BOA/BTA ratio</Label>
          {defaultData && <CustomInput
            placeholder='Default'
            maxWidth='200px'
            inputValue={defaultData.totalFloorRatio}
            readOnly
          />}
          <CustomInput
            placeholder='Set'
            maxWidth='200px'
            inputValue={data.totalFloorRatio}
            valueChanges={(value) => setData?.({ ...data, totalFloorRatio: value })}
            readOnly={!setData}
          />
        </div>
        <div>
          <Label width='200px'>Build cost hint</Label>
          {defaultData && <CustomInput
            placeholder='Default'
            maxWidth='200px'
            inputValue={defaultData.buildCostHint}
            readOnly
          />}
          <CustomInput
            placeholder='Set'
            maxWidth='200px'
            inputValue={data.buildCostHint}
            valueChanges={(value) => setData?.({ ...data, buildCostHint: value })}
            readOnly={!setData}
          />
        </div>
      </TabContainer>}
    </TabContent>
    <TabContent>
      <CollapsibleHeader isOpen={preConstructionIsOpen} onClick={() => setPreConstructionIsOpen(!preConstructionIsOpen)}>
        <ArrowImage />
        <span>{`Pre-construction${!constructionIsOpen ? ' costs' : ''}`}</span>
      </CollapsibleHeader>
      {preConstructionIsOpen && <TabContainer spaceBetween>
        {data.preConstruction.map((item, i) => <Line
          key={i}
          item={item}
          firstItem={i === 0}
          updateItem={setData ? (item: PartialItem) => updateItem('preConstruction', i, item) : undefined}
          {...getLineParams('preConstruction', i)}
        />)}
        {data.preConstruction.length === 0 && <Label>No costs</Label>}
        {setData && <div>
          <AddItem onClick={() => setData({ ...data, preConstruction: [...data.preConstruction, { costType: '' }] })}>
            <AddImage />
            <span>Add new cost</span>
          </AddItem>
        </div>}
      </TabContainer>}
    </TabContent>
    <TabContent>
      <CollapsibleHeader isOpen={constructionIsOpen} onClick={() => setConstructionIsOpen(!constructionIsOpen)}>
        <ArrowImage />
        <span>{`Construction${!constructionIsOpen ? ' costs' : ''}`}</span>
      </CollapsibleHeader>
      {constructionIsOpen && <TabContainer spaceBetween>
        {data.construction.map((item, i) => <Line
          key={i}
          item={item}
          firstItem={i === 0}
          updateItem={setData ? (item: PartialItem) => updateItem('construction', i, item) : undefined}
          {...getLineParams('construction', i)}
        />)}
        {data.construction.length === 0 && <Label>No costs</Label>}
        {setData && <div>
          <AddItem onClick={() => setData({ ...data, construction: [...data.construction, { costType: '' }] })}>
            <AddImage />
            <span>Add new cost</span>
          </AddItem>
        </div>}
      </TabContainer>}
    </TabContent>
    <TabContent>
      <CollapsibleHeader isOpen={professionalFeesIsOpen} onClick={() => setProfessionalFeesIsOpen(!professionalFeesIsOpen)}>
        <ArrowImage />
        <span>Professional fees</span>
      </CollapsibleHeader>
      {professionalFeesIsOpen && <TabContainer spaceBetween>
        {data.professionalFees.map((item, i) => <Line
          key={i}
          item={item}
          firstItem={i === 0}
          updateItem={setData ? (item: PartialItem) => updateItem('professionalFees', i, item) : undefined}
          {...getLineParams('professionalFees', i)}
        />)}
        {data.professionalFees.length === 0 && <Label>No costs</Label>}
        {setData && <div>
          <AddItem onClick={() => setData({ ...data, professionalFees: [...data.professionalFees, { costType: '' }] })}>
            <AddImage />
            <span>Add new cost</span>
          </AddItem>
        </div>}
      </TabContainer>}
    </TabContent>
    <TabContent>
      <CollapsibleHeader isOpen={contingencyIsOpen} onClick={() => setContingencyIsOpen(!contingencyIsOpen)}>
        <ArrowImage />
        <span>Contingency</span>
      </CollapsibleHeader>
      {contingencyIsOpen && <TabContainer>
        <div>
          <Label width='200px'>Contingency</Label>
          {defaultData && <CustomInput
            placeholder='Default percentage'
            maxWidth='200px'
            inputValue={defaultData.contingencyPercentage}
            readOnly
          />}
          <CustomInput
            placeholder='Set percentage'
            maxWidth='200px'
            inputValue={data.contingencyPercentage}
            valueChanges={(value) => setData?.({ ...data, contingencyPercentage: value })}
            readOnly={!setData}
          />
          {defaultData && <CustomInput
            placeholder='Default total'
            maxWidth='200px'
            inputValue={defaultData.contingencyTotal}
            readOnly
          />}
          <CustomInput
            placeholder='Set total'
            maxWidth='200px'
            inputValue={data.contingencyTotal}
            valueChanges={(value) => setData?.({ ...data, contingencyTotal: value })}
            readOnly={!setData}
          />
        </div>
      </TabContainer>}
    </TabContent>
  </SectionContainer>;
};
