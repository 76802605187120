import { FundingSection, RepaymentType, TypeOfFinance } from "../../../../../../../types";

export const defaultFundingItem = {
  type: "" as TypeOfFinance,
  ratio: "",
  repayment_type: "" as RepaymentType,
  drawdowns: "",
  percentage: undefined,
  interest: undefined,
  fees: undefined,
  security: "",
  profit_share: undefined,
  section: 'Debt funding' as FundingSection,
  rolled_up_interest: true,
};

export const defaultEquityItem = {
  type: "Developer's equity" as TypeOfFinance,
  ratio: "",
  repayment_type: "" as RepaymentType,
  drawdowns: "",
  percentage: undefined,
  interest: undefined,
  fees: undefined,
  security: "",
  profit_share: undefined,
  section: 'Equity' as FundingSection,
  rolled_up_interest: false,
};

export const defaultOtherCapitalsItem = {
  type: "" as TypeOfFinance,
  ratio: "",
  repayment_type: "" as RepaymentType,
  drawdowns: "",
  percentage: undefined,
  interest: undefined,
  fees: undefined,
  security: "",
  profit_share: undefined,
  section: 'Other' as FundingSection,
  rolled_up_interest: false,
};
