import React, { useState } from 'react';
import { LocalAreaStatTable } from '../../../../../../../../types';
import { Arrow, CollapsibleHeader, Table } from '../style';

interface Props {
  data: LocalAreaStatTable;
}

export const ForeignBackgroundByLocationSection = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return <div onClick={!isOpen ? () => setIsOpen(!isOpen) : undefined}>
    <CollapsibleHeader onClick={() => setIsOpen(!isOpen)}>
      <Arrow isOpen={isOpen} />
      <span>Foreign Background By Location</span>
    </CollapsibleHeader>
    {isOpen && <div>
      <Table>
        <table>
          <thead>
            <tr>
              {data?.[0]?.map?.((item, i) => <th key={i}>{item}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.slice(1, 4)?.map?.((item, i) => <tr key={i}>
              {item.map((item, j) => <td key={j}>{item}</td>)}
            </tr>)}
            <tr><td colSpan={6}></td></tr>
            {data?.slice(4, 9)?.map?.((item, i) => <tr key={i}>
              {item.map((item, j) => <td key={j}>{item}</td>)}
            </tr>)}
            <tr><td colSpan={6}></td></tr>
            {data?.slice(9, 10)?.map?.((item, i) => <tr key={i}>
              {item.map((item, j) => <td key={j}>{item}</td>)}
            </tr>)}
          </tbody>
        </table>
      </Table>
    </div>}
  </div>;
};
