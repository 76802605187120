import React from 'react';
import { CheckboxStyle, Check, CheckImage, RadioCheck } from './style';

interface Props {
  label?: string;
  value: boolean;
  size?: number;
  onChange?: (e: React.MouseEvent) => void;
  radio?: boolean;
  marginBottom?: string;
  fontSize?: string;
  disabled?: boolean;
  className?: string;
}

export const Checkbox = ({ value, onChange, size = 20, label, radio, marginBottom, fontSize, disabled, className }: Props) => {

  return <CheckboxStyle onClick={(e) => !disabled && onChange?.(e)} marginBottom={marginBottom} fontSize={fontSize} className={className}>
    <Check size={size} radio={radio} checked={value} disabled={disabled}>
      {value && !radio && <CheckImage/>}
      {value && !!radio && <RadioCheck/>}
    </Check>
    {label && <label>{label}</label>}
  </CheckboxStyle>;
};
