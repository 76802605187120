import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { GoogleTagTracker, ScrollToTop } from './components';

interface Props {
  googleTagId?: string;
}

const PublicRoutes = ({ googleTagId }: Props) => {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <ScrollToTop />
        {googleTagId && <GoogleTagTracker id={googleTagId} />}
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path={[ '/' ]} component={lazy(() => import('./features/homepage'))} />
            <Route exact path='/privacy-policy' component={lazy(() => import('./features/homepage/privacy-policy'))} />
            <Route exact path='/calculator/:id?/:type(lead)?' component={lazy(() => import('./features/calculator'))} />
            <Route exact path='/report/:idParam' component={lazy(() => import('./features/report'))} />
            <Route exact path='/report/teaser/:teaserReportId' component={lazy(() => import('./features/report-teaser'))} />
            <Route exact path='/reports' component={lazy(() => import('./features/reports'))} />
            <Route exact path={'/profile/:pageId(profile|account-settings)?'} component={lazy(() => import('./features/profile/profile'))} />
            <Route exact path={'/settings'} component={lazy(() => import('./features/settings'))} />
            <Route exact path={'/users'} component={lazy(() => import('./features/users'))}/>
            <Route exact path={'/user/:userId'} component={lazy(() => import('./features/user'))}/>
            <Route exact path={'/error/:type'} component={lazy(() => import('./features/error'))} />
            <Route path="*" component={lazy(() => import('./features/not-found'))} />
          </Switch>
        </Suspense>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

export default PublicRoutes;
