import React from 'react';
import { LoaderWrapper, LoaderBackground, LoaderContainer, Animation, LoaderImage } from './style';

interface Props {
  loading?: boolean;
  zIndex?: number;
  width?: number;
  top?: number;
  left?: number;
  maxHeight?: boolean;
  sticky?: boolean;
  maxWidth?: boolean;
}

export const Loader = ({ children, loading, zIndex, width = 60, top, left, maxHeight, sticky, maxWidth }: React.PropsWithChildren<Props>) => {

  return <LoaderWrapper maxHeight={maxHeight} maxWidth={maxWidth}>
    {loading && <>
      <LoaderContainer zIndex={(zIndex || 0) + 1} width={width} top={top} left={left} sticky={sticky}>
        <Animation width={width}><LoaderImage width={width}/></Animation>
        <Animation width={width} delay={.3}><LoaderImage width={width} bright={1}/></Animation>
      </LoaderContainer>
      <LoaderBackground zIndex={zIndex}/>
    </>}
    {children}
  </LoaderWrapper>;
};
