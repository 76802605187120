import React, { Fragment } from 'react';
import { MeasurementUnit, TemplateConstructionItem, TemplatePreConstructionItem, measurementUnitList, measurementUnitListPlural, measurementUnitListSingle } from '../../../../../../types';
import { Option } from '../../../../types';
import { CustomInput, Dropdown } from '../../../calculator/components/ui';
import { ReactComponent as CopyImage } from '../../images/copy.svg';
import { ReactComponent as RemoveImage } from '../../images/remove.svg';
import { FieldContainer, FieldHeader, ItemButtons, Label } from '../../style';

const unitOptions: Option<MeasurementUnit>[] = measurementUnitList.map((unit) => ({ value: unit, label: `Per ${measurementUnitListSingle[unit]}` }));

interface BaseProps {
  firstItem?: boolean;
  sizeName: 'NIA' | 'BOA';
  sizeUnit: 'sqft' | 'sqm';
  getDefaultValue?: (cost_type: string, key: string) => string;
  getDefaultValueDropdown?: (cost_type: string, key: string, options: Option[]) => Option | undefined | '';
  removeItem?: () => void;
  copyItem?: () => void;
}

interface ConstructionProps extends BaseProps {
  type: 'construction';
  item: TemplateConstructionItem;
  updateItem?: (items: Partial<TemplateConstructionItem>) => void;
}

interface OtherProps extends BaseProps {
  type: 'other';
  item: TemplatePreConstructionItem;
  updateItem?: (items: Partial<TemplatePreConstructionItem>) => void;
}

type Props = ConstructionProps | OtherProps;

export const Line = ({ item, firstItem, type, sizeName, sizeUnit, updateItem, getDefaultValue, getDefaultValueDropdown, removeItem, copyItem }: Props) => {
  return <Fragment>
    <div>
      <div>
        {!updateItem && <Label width='200px'>{item.costType}</Label>}
        {updateItem && <CustomInput
          placeholder='Cost type'
          maxWidth='200px'
          inputValue={item.costType}
          valueChanges={(value) => updateItem?.({ costType: value })}
        />}
        {type === 'other' && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>% of construction costs</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='100px'
            inputValue={getDefaultValue(item.costType, 'construction_cost_percent')}
            isNumber
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : '% of construction costs'}
            maxWidth={getDefaultValue ? '100px' : '200px'}
            inputValue={item.constructionCostPercent}
            valueChanges={(value) => updateItem?.({ constructionCostPercent: value })}
            readOnly={!updateItem}
            isNumber
          />
        </FieldContainer>}
        {type === 'other' && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>{`Cost p${sizeUnit} of ${sizeName}`}</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='100px'
            inputValue={getDefaultValue(item.costType, 'total_floor_area_cost')}
            isNumber
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : `Cost p${sizeUnit} of ${sizeName}`}
            maxWidth={getDefaultValue ? '100px' : '200px'}
            inputValue={item.totalFloorAreaCost}
            valueChanges={(value) => updateItem?.({ totalFloorAreaCost: value })}
            readOnly={!updateItem}
            isNumber
          />
        </FieldContainer>}
        {type === 'construction' && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Cost type</FieldHeader>}
          {getDefaultValueDropdown && <Dropdown
            width='150px'
            minWidth='150px'
            placeholder='Default'
            selectedValue={getDefaultValueDropdown(item.costType, 'unit', unitOptions)}
            options={unitOptions}
            readOnly
          />}
          <Dropdown
            width='150px'
            minWidth={getDefaultValue ? '150px' : '300px'}
            placeholder={getDefaultValue ? 'Set' : 'Cost type'}
            selectedValue={unitOptions.find(({ value }) => item.unit === value)}
            valueChanges={(value) => updateItem?.({ unit: value })}
            options={unitOptions}
            readOnly={!updateItem}
          />
        </FieldContainer>}
        {type === 'construction' && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Cost per unit</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='100px'
            inputValue={getDefaultValue(item.costType, 'unit_cost')}
            isNumber
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : `Cost per ${item.unit ? measurementUnitListSingle[item.unit] : 'unit'}`}
            maxWidth={getDefaultValue ? '100px' : '200px'}
            inputValue={item.unitCost}
            valueChanges={(value) => updateItem?.({ unitCost: value })}
            readOnly={!updateItem}
            disabled={!item.unit}
            isNumber
          />
        </FieldContainer>}
        {type === 'construction' && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Total units</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='100px'
            inputValue={getDefaultValue(item.costType, 'unit_quantity')}
            isNumber
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : `Total ${item.unit ? measurementUnitListPlural[item.unit] : 'units'}`}
            maxWidth={getDefaultValue ? '100px' : '200px'}
            inputValue={item.unitQuantity}
            valueChanges={(value) => updateItem?.({ unitQuantity: value })}
            readOnly={!updateItem}
            disabled={!item.unit}
            isNumber
          />
        </FieldContainer>}
        <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Total cost</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='100px'
            inputValue={getDefaultValue(item.costType, 'total_cost')}
            isNumber
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : 'Total cost'}
            maxWidth={getDefaultValue ? '100px' : '200px'}
            inputValue={item.totalCost}
            valueChanges={(value) => updateItem?.({ totalCost: value })}
            readOnly={!updateItem}
            isNumber
          />
        </FieldContainer>
      </div>
      {(removeItem || copyItem) && <ItemButtons>
        <CopyImage onClick={copyItem} />
        <RemoveImage onClick={removeItem} />
      </ItemButtons>}
    </div>
  </Fragment>;
};
