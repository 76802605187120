import React, { PropsWithChildren } from 'react';
import { AdaptiveCheck, SupportRequest } from '../..';
import { MainMenu } from '../../main-menu';
import { Toasts } from '../../toasts';
import { PageStyle } from './style';

interface Props {
  adaptive?: boolean;
  pure?: boolean;
}

export const Page = ({ adaptive, children, pure }: PropsWithChildren<Props>) => {

  return <>
    {!adaptive && <AdaptiveCheck/>}
    <PageStyle className={adaptive ? 'adaptive' : undefined}>
      {!pure && <>
        <Toasts />
        <SupportRequest/>
        <MainMenu adaptive={adaptive}/>
      </>}
      {children}
    </PageStyle>
  </>;
};
