import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

const overlap = .3;

export const Wrapper = styled.div<{ size?: number; clickable?: boolean }>`
  width: ${(p) => p.size ? `${p.size}px` : '100%'};
  height: ${(p) => p.size ? `${p.size}px` : '100%'};
  min-width: ${(p) => p.size ? `${p.size}px` : '100%'};
  min-height: ${(p) => p.size ? `${p.size}px` : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
`;

export const Image = styled.img<{ withBorder?: boolean; borderColor?: string; circle?: boolean; radius?: number; opacity?: number }>`
  border-radius: ${(p) => p.circle ? '50%' : `${p.radius || 5}px`};
  border: ${(p) => p.withBorder ? `1px solid ${(p) => p.borderColor || 'white'}` : 'none'};
  opacity: ${(p) => p.opacity || 1};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Placeholder = styled.div<{ size?: number; radius?: number; withBorder?: boolean; circle?: boolean; small?: boolean; clickable?: boolean; opacity?: number }>`
  border-radius: ${(p) => p.circle ? '50%' : `${p.radius || 5}px`};
  border: ${(p) => p.withBorder ? '1px solid white' : 'none'};
  width: ${(p) => p.size ? `${p.size}px` : '100%'};
  height: ${(p) => p.size ? `${p.size}px` : '100%'};
  min-width: ${(p) => p.size ? `${p.size}px` : '100%'};
  min-height: ${(p) => p.size ? `${p.size}px` : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.size ? `${p.size * .5 * (p.small ? .7 : 1)}px` : '30px'};
  color: white;
  background: ${palette.greenMedium};
  position: relative;
  opacity: ${(p) => p.opacity || 1};
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
`;

export const SeriesStyle = styled.div<{ size: number }>`
  display: flex;
  flex-direction: row-reverse;
  margin-left: ${(p) => overlap * p.size}px;
  > div {
    margin-left: -${(p) => overlap * p.size}px;
  }
`;
