import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { CurrentUserPic, HoverHint } from '../../components';
import { Actions } from '../../redux';
import theme from '../../themes';
import { Background, Calendar, CalendarBlock, CurrentUser, Day, Expand, Head, Title, Username, Wrapper } from './style';

const { palette } = theme;

const dayToMs = 60 * 60 * 24 * 1000;

export const NotificationBar = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: RootState) => state.Auth.user);
  const expanded = useSelector((state: RootState) => state.App.notificationBarExpanded);

  const [renderTime, setRenderTime] = useState(new Date());

  useEffect(() => {
    dispatch(Actions.App.setNotificationBarExpanded(false));
  }, [dispatch, history.location]);

  useEffect(() => {
    const timer = setTimeout(() => setRenderTime(new Date()), 20000);
    return () => clearTimeout(timer);
  }, [renderTime]);

  const monday = useMemo(() => new Date(+renderTime - ((renderTime.getDay() || 7) - 1) * dayToMs), [renderTime]);
  const monthName = useMemo(() => ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"][renderTime.getMonth()], [renderTime]);

  return <>
    {expanded && <Background onClick={() => dispatch(Actions.App.setNotificationBarExpanded(false))}/>}
    <Wrapper expanded={expanded}>
      {user && <div>
        <Head expanded={expanded}>
          <Expand expanded={expanded} onClick={() => dispatch(Actions.App.setNotificationBarExpanded(!expanded))}>{expanded ? '→' : '←'}</Expand>
          <HoverHint label='Profile' type='right' noHint={expanded}>
            <CurrentUser className='user-pic' expanded={expanded} onClick={() => history.push('/profile')}>
              <div>
                <CurrentUserPic size={expanded ? 42 : 46} borderColor={palette.grayLight}/>
                <p>{user.name || user.email}</p>
              </div>
            </CurrentUser>
          </HoverHint>
          {!expanded && <Username>{user.name || user.email}</Username>}
        </Head>

      </div>}
      {user && <div>
        {expanded && <CalendarBlock>
          <Title>{monthName}</Title>
          <Calendar>
            {new Array(5).fill(0).map((_, i) => new Date(+monday + i * dayToMs)).map((date, i) => <Day
              key={i}
              today={renderTime.toDateString() === date.toDateString()}
              holiday={i > 4}
            >
              <span>{['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'][i]}</span>
              <span>{date.getDate()}</span>
            </Day>)}
          </Calendar>
        </CalendarBlock>}
      </div>}
    </Wrapper>
  </>;
};
