import styled from 'styled-components';

export const ColorSchemeStyle = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  width: ${(p) => p.width ? `${p.width}px` : '100%' };
`;

export const Color = styled.div<{ color: string; size: number }>`
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  background: ${(p) => p.color};
  border-radius: 5px;
  border: 1px solid white;
`;
