import React, { useEffect, useState, ReactNode, useMemo } from 'react';
import { TabsWrapper, Head, Body, Tab, Counter } from './style';
import { ReactComponent as InfoSVG } from './images/info.svg';

interface Props {
  tabNames: string[];
  type?: TabsType;
  tab?: number;
  background?: string;
  linePadding?: number;
  fontSize?: number;
  triggerBackground?: string;
  activeTriggerBackground?: string;
  children: ReactNode | ReactNode[];
  controls?: ReactNode;
  counters?: { [tabName: string]: number };
  height?: string;
  noWrap?: boolean;
  noUpperCase?: boolean;
  headPadding?: string;
  bodyBorder?: string;
  renderOnOpenTab?: boolean;
  className?: string;
  tabIcons?: {
    [name: string]: ReactNode;
  },
  showInfoIcon?: boolean;
  infoDescription?: { title: string, iconDescription: { english: string, swedish: string } }[]
  country?: string;
  onOpenTab?: (tab: number) => void;
  onTabClick?: (tab: number) => void;
}

export const Tabs = ({ country, infoDescription, showInfoIcon, tabNames, type = 'basic', tab, background, triggerBackground, activeTriggerBackground, className, children, linePadding,
  fontSize, counters, height = 'auto', noUpperCase, headPadding, bodyBorder, controls, renderOnOpenTab, tabIcons, noWrap, onOpenTab, onTabClick }: Props) => {

  const childrenArray = useMemo(() => Array.isArray(children) ? children : [children], [children]);

  const [currentTab, setCurrentTab] = useState(tab || 1);

  useEffect(() => {
    if (tab && tab > 0) {
      setCurrentTab(tab);
    }
  }, [tab]);

  const visibleBlock = useMemo(() => {
    if (currentTab > childrenArray.length - 1) {
      return childrenArray.length;
    } else {
      return currentTab;
    }
  }, [childrenArray, currentTab]);

  return <TabsWrapper background={background} height={height} className={className}>
    <Head type={type} padding={headPadding} className='tabs-head'>
      <div>
        {tabNames.map((tabName, i) => <Tab
          key={i}
          className={`tab-${i + 1}`}
          type={type}
          linePadding={linePadding}
          fontSize={fontSize}
          background={background}
          triggerBackground={i === currentTab - 1 ? activeTriggerBackground || triggerBackground : triggerBackground}
          active={i === currentTab - 1}
          width={100 / (tabNames.length || 1)}
          noUpperCase={noUpperCase}
          noWrap={noWrap}
          onClick={onTabClick ? () => onTabClick(i) : () => {
            setCurrentTab(i + 1);
            onOpenTab?.(i + 1);
          }}
        >
          <span>{tabName}{showInfoIcon && country === 'SWE' && <a className="info-anchor" title={country === 'SWE' ? infoDescription?.[i]?.['iconDescription']?.['swedish'] : ''}><InfoSVG className="info-icon" /></a>}</span>
          {(counters?.[tabName] || 0) > 0 && <Counter className='tab-counter'>{counters?.[tabName]}</Counter>}
          {tabIcons?.[tabName]}
        </Tab>)}
      </div>
      {controls && type === 'line' ? controls : null}
    </Head>
    <Body
      visibleBlock={!renderOnOpenTab ? visibleBlock : 1}
      type={type}
      border={bodyBorder}
      className='tabs-body'
    >
      {!renderOnOpenTab ? children : childrenArray.slice(currentTab - 1, currentTab)}
    </Body>
  </TabsWrapper>;
};

export type TabsType = 'basic' | 'line' | 'thin' | 'thin-line';
