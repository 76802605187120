
import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const IncomingMessagesStyle = styled.div<{ small?: boolean; inline?: boolean }>`
  position: ${(p) => p.inline ? 'static' : 'absolute'};
  right: -10px;
  top: -10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: ${palette.blue};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.small ? 9 : 12}px;
  line-height: 100%;
  font-weight: 600;
`;
