import styled from 'styled-components';
import { ReactComponent as CloseImage } from './images/close.svg';
import theme from '../../themes';

const { palette } = theme;

export const Content = styled.div`
  .desktop .adaptive && {
    button.mobile {
      display: none;
    }
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    button.mobile {
      display: block;
    }
  }
`;

export const DropZone = styled.div<{dragging: boolean; disabled: boolean; typeError: boolean; background?: string}>`
  position: relative;

  .desktop .adaptive && {
    width: 652px;
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    min-width: 300px;
  }

  height: 198px;
  border-color: ${(p) => p.typeError && !p.disabled ? '#db7070' : '#828d94'};
  border-radius: 15px;
  border-style: dashed;
  border-width: 1px;
  background: ${(p) => p.background || (p.dragging || p.disabled ? palette.whiteMedium : ( (p) => p.typeError ? '#db70700d' : 'white' )) };
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  div, button {
    opacity: ${(p) => p.dragging || p.disabled ? 0.5 : 1};
  };

  .mobile .adaptive &&, .tablet .adaptive && {
    display: none;
  }
`;

export const Remove = styled(CloseImage)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

export const Text = styled.div`
  color: ${palette.grayDark};
  margin-top: 8px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  margin: 30px 0 30px 0;
  display: flex;

  .mobile .adaptive &&, .tablet .adaptive && {
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 272px);
    overflow: scroll;
  }
`;

export const SecondaryWrapper = styled.div<{scrollable?: boolean}>`
  .desktop .adaptive && {
    width: 408px;
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  display: inline;

  ${(p) => p.scrollable && `
    height: 170px;
    overflow-y: scroll;
    overflow-x: hidden;
  `}
`;

export const PictureStyle = styled.div<{
  type: 'main';
  url: string;
  isDragging?: boolean;
} | {
  type: 'secondary';
  url: string;
  index: number;
  maxPictures: number;
  isDragging?: boolean;
}>`
  display: inline-block;
  position: relative;
  border-radius: 15px;
  background-image: ${(p) => `url(${p.url})`};
  background-size: cover;

  width: ${(p) => p.type === 'main' ? 244 : 120}px;
  height: ${(p) => p.type === 'main' ? 170 : 77}px;

  margin-bottom: ${(p) => p.type === 'secondary' && p.index < p.maxPictures - 4 ? '16px' : '0px'};

  > svg {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 11px;
    right: 11px;
  }

  ${(p) => p.isDragging ? `
    opacity: 0.7;
  ` : ''}

  ${(p) => p.type === 'main' ? `
    .mobile .adaptive &&, .tablet .adaptive && { {
      min-height: 170px;
      margin-bottom: 20px;
    }
  ` : `
    .desktop .adaptive && { {
      margin-left: 16px;
    }

    .mobile .adaptive &&, .tablet .adaptive && { {
      :nth-of-type(2n) {
        margin-left: 16px;
      }

      :nth-of-type(2n + 1) {
        margin-left: 21px;
      }
    }
  `}
`;

export const Drop = styled.div<{isOver?: boolean}>`
  width: 100%;
  height: 100%;
  background: ${palette.blue};
  border-radius: 15px;
  opacity: ${(p) => p.isOver ? 0.7 : 0};
`;
