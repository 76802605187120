import React, { useEffect, useState } from 'react';
import { Input } from '../..';
import { Button } from '../../ui';
import { Popup, PopupTitle } from '../popup';
import { Buttons, Error } from './style';

interface Props {
  type: 'Report' | 'Scenario';
  defaultName: string
  restrictedNames: string[];
  mobile?: boolean;
  submit: (name: string) => void;
  onClose: () => void;
}

export const NewName = ({ defaultName, restrictedNames, type, mobile, submit, onClose }: Props) => {
  const [name, setName] = useState(defaultName);

  const isRestricted = (restrictedNames || []).includes(name);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        if ( !isRestricted ) {
          submit(name);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [name, isRestricted, submit]);

  return <Popup size={[450, 350]} padding={mobile ? [60, 35, 60, 35] : [60, 70, 60, 70]} onClose={() => onClose()}>
    <PopupTitle>Enter new name</PopupTitle>
    <Input
      type='text'
      placeholder='Name'
      value={name}
      onChange={(value: string) => setName(value)}
      noPadding
      setOnTyping
    />
    <Error>{isRestricted ? `${type} with this name is already exists` : ''}</Error>
    <Buttons>
      <Button variant='secondary' width='100%' onClick={() => onClose()}>Cancel</Button>
      <Button variant='primary' width='100%' disabled={isRestricted} onClick={() => {
        submit(name);
        onClose();
      }}>Save</Button>
    </Buttons>
  </Popup>;
};
