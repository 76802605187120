import { User } from '../../../types';

export type MainMenuImage = 'users' | 'calculator' | 'reports' | 'settings';

interface Page {
  name: string;
  match: RegExp;
  image?: string;
  link?: string;
  comingSoonFeature?: string;
  paymentFeature?: string;
  check?: (user?: User) => boolean;
}

export const getPages = (scenarioShortId?: number) => [
  { name: 'New project', link: `/calculator${scenarioShortId ? '/' + scenarioShortId : ''}`, match: /^\/calculator(\/|$)$/, image: 'calculator' },
  { name: 'Reports', link: '/reports', match: /^\/reports(\/|$)$/, image: 'reports' },
  { name: 'Users', link: '/users', match: /^\/users(\/|$)$/, image: 'users', check: (user) => user?.role === 'admin' },
  { name: 'Settings', link: '/settings', match: /^\/settings(\/|$)$/, image: 'settings', check: (user) => ['admin', 'analyst'].includes(user?.role || '') },
] as Page[];
