import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

export const Wrapper = styled.div<{ top: number; left: number; zIndex?: number; position: 'bottom-right' | 'top-right' | 'bottom-left' | 'top-left'; borderPosition: 'left' | 'right'; borderColor?: string; width?: number }>`
  position: absolute;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width || 270}px;
  padding: 12px 10px 5px 10px;
  ${(p) => p.borderPosition === 'left' ? `
    border-left: 3px solid ${p.borderColor || palette.greenMedium};
    border-top-left-radius: ${['bottom-right', 'bottom-left'].includes(p.position) ? 0 : 10}px;
    border-bottom-left-radius: ${['top-right', 'top-left'].includes(p.position) ? 0 : 10}px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  ` : `
    border-right: 3px solid ${p.borderColor || palette.greenMedium};
    border-top-right-radius: ${['bottom-right', 'bottom-left'].includes(p.position) ? 0 : 10}px;
    border-bottom-right-radius: ${['top-right', 'top-left'].includes(p.position) ? 0 : 10}px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`}
  background: white;
  z-index: ${(p) => p.zIndex || 5};
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  transform: ${(p) => ({
    'bottom-right': `translateY(0)`,
    'bottom-left': `translateY(0)`,
    'top-right': `translateY(max(-100%, -${p.top}px))`,
    'top-left': `translateY(max(-100%, -${p.top}px))`,
  })[p.position]};
  animation: fade-in-hint .5s ease-in-out forwards;
  @keyframes fade-in-hint {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;

export const Title = styled.h3`
  margin: 5px 0;
  font-size: 12px;
  font-weight: 600;
  color: black;
`;

export const Text = styled.p`
  margin: 5px 10px 5px 0;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.grayMedium};
`;

export const Controls = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.grayMedium};
`;

export const Button = styled.button<{ primary?: boolean }>`
  margin-right: 5px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: transparent;
  outline: none !important;
  color: ${(p) => p.primary ? palette.blue : palette.grayDark};
  cursor: pointer;
`;
