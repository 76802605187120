import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

const optionHeight = 64;

export const SearchWrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${(p) => p.width || '300px'};
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid transparent;
  font-size: 12px;
  padding: 0 10px;
  outline: none;
  background: ${palette.whiteMedium};
  :focus {
    border: 1px solid ${palette.blue};
  }
`;

export const Options = styled.ul<{ width?: string; withEmbeddedList?: boolean }>`
  position: ${(p) => p.withEmbeddedList ? 'relative' : 'absolute'};
  margin-top: 10px;
  width: ${(p) => p.width || '300px'};
  background: white;
  border-radius: 5px;
  padding: 0;
  border: ${(p) => p.withEmbeddedList ? 'none' : `1px solid ${palette.grayLight}`};
  box-shadow: ${(p) => p.withEmbeddedList ? 'none' : '0px 7px 20px rgba(0, 0, 0, 0.1)'};
  max-height: ${optionHeight * 3}px;
  min-height: ${(p) => p.withEmbeddedList ? optionHeight * 3 : 0}px;
  overflow-x: hidden;
  overflow-y: auto;
  ${(p) => p.withEmbeddedList ? '' : 'z-index: 50;'}
`;

export const Option = styled.li<{ withoutPadding?: boolean }>`
  list-style: none;
  padding: ${(p) => p.withoutPadding ? '0' : '0 20px'};
  height: ${optionHeight}px;
  display: flex;
  justify-content: space-between;
  color: ${palette.grayDark};
  border-bottom: 1px solid ${palette.grayLight};
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  :last-child {
    border-bottom: none;
  }
`;

export const Item = styled.div<{ hasPrefix?: boolean }>`
  margin-left: ${(p) => p.hasPrefix ? '10px' : '0'};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ItemTitle = styled.p`
  color: black;
  font-weight: 600;
  margin: 0;
  b {
    font-weight: inherit;
    color: ${palette.blue};
  }
`;

export const ItemSubtitle = styled.p`
  color: ${palette.grayMedium};
  margin: 0;
  b {
    font-weight: inherit;
    color: ${palette.blue};
  }
`;
