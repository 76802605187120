import React from 'react';
import { ButtonStyle } from './style';

export type ButtonVariant = 'primary' | 'secondary' | 'approved' | 'rejected';

interface Props {
  children: string;
  variant: ButtonVariant;
  disabled?: boolean;
  isSubmit? : boolean;
  margin?: string;
  width?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const ButtonCalculator = ({ variant, disabled, children, isSubmit, margin, width, onClick }: Props) => {
  return <ButtonStyle
    variant={variant}
    disabled={disabled}
    type={isSubmit ? 'submit' : undefined}
    margin={margin}
    width={width}
    onClick={!disabled ? (e) => onClick?.(e) : undefined}
  >
    {children}
  </ButtonStyle>;
};
