import React, { MutableRefObject, MouseEvent } from 'react';
import { Spinner } from 'react-bootstrap';
import { ButtonStyle, Label } from './button.style';

interface Props {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  centered?: boolean;
  load?: 'load' | '';
  setRef?: MutableRefObject<HTMLButtonElement>;
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  flat?: boolean;
  green?: boolean;
  nowrap?: boolean;
  round?: boolean;
  justify?: string;
  padding?: string;
  className?: string;
  maxWidth?: number;
  icon?: string;
  flatNoBorder?: boolean;
  scale?: number;
}

export function Button({ width, height, marginTop, marginBottom, marginLeft, marginRight, centered, load, setRef: ref, onClick,
  disabled, variant, children, green, flat, nowrap, round, justify, padding, className, maxWidth, icon, flatNoBorder, scale }: React.PropsWithChildren<Props>) {
  return (
    <ButtonStyle
      width={width}
      height={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      centered={centered}
      load={load}
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      green={green}
      flat={flat}
      nowrap={nowrap}
      round={round}
      justify={justify}
      padding={padding}
      className={className}
      maxWidth={maxWidth}
      flatNoBorder={flatNoBorder}
      scale={scale}
    >
      {icon ? <Label>
        <img src={icon} />
        {children}
      </Label> : <>
        {load === 'load' ? (
          <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
          />
        ) : null}
        {children}
      </>}
    </ButtonStyle>
  );
}

export default Button;
