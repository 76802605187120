import styled from 'styled-components';
import theme from '../../../themes';
import { UserAreaType } from './user';
import { ReactComponent as ArrowSVG } from './images/arrow.svg';
import { ReactComponent as DeleteSVG } from '../../../images/cross.svg';


const { palette } = theme;

interface WrapperProps {
  type: UserAreaType;
  withMargin?: boolean;
  withPadding?: boolean;
  background?: string;
  clickable?: boolean;
  height?: number
  textAlign?: 'left' | 'right' | 'center';
}

export const Wrapper = styled.div<WrapperProps>`
  padding: ${(p) => p.withPadding ? 15 : 0}px;
  display: flex;
  align-items: center;
  border-radius: ${(p) => p.type === 'notificationBar' ? 15 : 5}px;
  width: 100%;
  ${(p) => p.height ? `height: ${p.height}px;` : ''}
  margin: ${(p) => p.withMargin ? '10px 0' : '0'};
  text-align: ${(p) => p.textAlign || 'left'};
  background: ${(p) => ({
    card: p.background || palette.whiteMedium,
    highlight: p.background || palette.greenLightest,
    notificationBar: p.background || palette.whiteMedium,
  }[p.type]) || 'transparent'};
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  > :nth-child(2) {
    margin-left: 7px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  > :last-child {
    position: relative;
    width: 100%;
  }
`;

export const Name = styled.div<{ text: string }>`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.black};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  :after {
    content: '${(p) => p.text.replace('\'', '')}';
  }
  :hover:before {
    content: '${(p) => p.text.replace('\'', '')}';
    display: ${(p) => p.text.length > 11 ? 'block' : 'none'};
    position: absolute;
    font-size: 12px;
    margin-top: 30px;
    margin-left: -20px;
    margin-right: 15px;
    box-shadow: 0px 4px 8px rgba(43, 70, 86, 0.12);
    background: #ebf4ee;
    z-index: 30;
    padding: 5px;
    font-weight: 400;
    white-space: normal;
    word-break: break-word;
    animation: fade-user-area-hint 1s linear forwards;
    @keyframes fade-user-area-hint {
      0% { opacity: 0 }
      50% { opacity: 0 }
      100% { opacity: 1 }
    }
  }
`;

export const Time = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.grayDark};
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.grayDark};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const Arrow = styled(ArrowSVG)`
  cursor: pointer;
`;

export const Controls = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Delete = styled.div`
  padding: 5px;
  display: flex;
  opacity: .7;
  :hover {
    opacity: 1;
  }
`;

export const DeleteImage = styled(DeleteSVG)`
  cursor: pointer;
`;
