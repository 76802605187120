import React, { useCallback, useEffect, useState } from 'react';
import { Popup, SupportRequestCreated } from '..';
import { SupportTicketCreateRequest, SupportTicket } from '../../../../../types';
import { Tabs } from '../../tabs';
import { Loader } from '../../ui';
import { Confirmation } from '../..';
import { Subtitle, Tab, Title } from './style';
import { CreteTicket, Tickets } from './tabs';
import { checkEmail } from '../../../shared';

interface Props {
  tickets: SupportTicket[];
  currentUserEmail?: string;
  mobile?: boolean;
  loading?: boolean;
  error?: string;
  onSubmit: (args: SupportTicketCreateRequest) => void;
  onDelete: (ticketId: string) => void;
  onClose: () => void;
  onClearError: () => void;
}

export const SupportRequest = ({ mobile, loading, error, tickets, currentUserEmail, onSubmit, onDelete, onClose, onClearError }: Props) => {

  const [tab, setTab] = useState(1);
  const [ticketCreatedEmail, setTicketCreatedEmail] = useState<string | null>();
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (error) {
      setTicketCreatedEmail(null);
    }
  }, [error]);

  const onCreate = useCallback((args: SupportTicketCreateRequest) => {
    const email = args.email || currentUserEmail;
    if (args.type && args.message && email && checkEmail(email)) {
      setTicketCreatedEmail(email);
      setShowErrors(false);
      onSubmit(args);
    } else {
      setShowErrors(true);
    }
  }, [currentUserEmail, onSubmit]);

  if (!error && ticketCreatedEmail) {
    return <SupportRequestCreated
      email={ticketCreatedEmail}
      myTicketsDisabled={tickets.length === 0}
      mobile={mobile}
      onOpenTickets={currentUserEmail ? () => {
        setTab(2);
        setTicketCreatedEmail(null);
      } : undefined}
      onClose={onClose}
    />;
  } else {
    return <Popup
      padding={mobile ? [30, 20, 30, 20] : [60, 50, 60, 50]}
      size={[674, tickets.length === 0 ? 629 : (tab === 1 ? 700 : 613)]}
      paddingTitle={30}
      adaptive
      maxWidthMobile='calc(100vw - 10px)'
    >
      {error && <Confirmation
        title='An error occured'
        text={error}
        buttons={[
          { text: 'Close', variant: 'primary', onClick: onClearError },
        ]}
        onClose={() => onClearError()}
      />}
      <Loader loading={loading} zIndex={10}>
        <Title><span>Proverest</span> support</Title>
        <Subtitle>Please describe your issue or question</Subtitle>
        {tickets.length === 0
          ? <CreteTicket requireEmail={!currentUserEmail} onSubmit={onCreate} onClose={onClose}/>
          : <Tabs
            tabNames={['New', 'Opened', 'Resolved']}
            tab={tab}
            onOpenTab={setTab}
            type='line'
            linePadding={10}
            noUpperCase
          >
            <Tab tall>
              <CreteTicket requireEmail={!currentUserEmail} showErrors={showErrors} onSubmit={onCreate} onClose={onClose}/>
            </Tab>
            <Tab>
              <Tickets tickets={tickets.filter((ticket) => !ticket.closedAt)} onClose={onClose} onDelete={onDelete}/>
            </Tab>
            <Tab>
              <Tickets tickets={tickets.filter((ticket) => ticket.closedAt)} onClose={onClose} onDelete={onDelete}/>
            </Tab>
          </Tabs>}
      </Loader>
    </Popup>;
  }

};
