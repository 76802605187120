import { toast } from "react-toastify";
import { Country, InputDataContextInterface, OutputDataContextInterface } from "../../../../../../../../types";
import { store } from "../../../../../../redux/store";

export interface AddressAutofillInterface {
  address: string;
  name: string;
  geometry: {type: string, coordinates: number[]};
}

export interface CalculatorResponse {
  input: InputDataContextInterface,
  output: OutputDataContextInterface,
}

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

export async function getCalculation(data: InputDataContextInterface): Promise<Response | any> {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };
  try {
    const response = await fetch('/api/calculator/calculate', requestOptions);
    const textRes = await response.text();

    if (!response.ok) {
      throw new Error(textRes);
    }

    return { textRes, cleanRes: response };
  } catch (error) {
    toast('Calculation error', { type: 'error' });
    console.error("getCalculation error", error);
    return error;
  }
}

export async function getSensitivityAnalyses(data: InputDataContextInterface): Promise<Response | any> {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const response = await fetch('/api/calculator/sensivity-analysis', requestOptions);
    return await response;
  } catch (error) {
    console.error("getSensitivityAnalyses error", error);
    return error;
  }
}

export async function getDefaultValues(data: InputDataContextInterface): Promise<Response | any> {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const response = await fetch('/api/calculator/default-values', requestOptions);
    return await response;
  } catch (error) {
    console.error("getDefaultValues error", error);
    return error;
  }
}

const countryMapping = {
  'UK': 'EN',
  'SWE': 'SE',
  'ESP': 'ES',
  'DEU': 'DE',
};

export function getAddressAutofill(searchBy: string, country: Country): Promise<{data: AddressAutofillInterface[]}> {
  myHeaders.delete("language");
  myHeaders.append("language", countryMapping[country]);

  const requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${store.getState().App.config?.calculator.REACT_APP_ADDRESS_AUTOFILL}search=${searchBy}`,
    requestOptions,
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(`getAddressAutofill error`, error));
}

export function getLand(
  minLat: number,
  minLng: number,
  maxLat: number,
  maxLng: number,
  page = 1,
) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${store.getState().App.config?.calculator.REACT_APP_GET_LAND}limit=10&page=${page}&minLatitude=${minLat}&minLongitude=${minLng}&maxLatitude=${maxLat}&maxLongitude=${maxLng}`,
    requestOptions,
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(`getLand error`, error));
}

export function getLandPlot(latitude: number, longitude: number) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${store.getState().App.config?.calculator.REACT_APP_GET_LAND_PLOT}latitude=${latitude}&longitude=${longitude}`,
    requestOptions,
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(`getLand error`, error));
}
