import React from "react";
import { Dispatch } from "react";
import Close from "./assets/images/close.svg";
import "./close-button.css";

interface Props {
  closeClicked: Dispatch<any>;
}

export default function CloseButton(props: Props) {
  return (
    <img
      className="close-button"
      src={Close}
      alt=""
      onClick={(event) => {
        props.closeClicked(event);
      }}
    />
  );
}
