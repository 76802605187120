import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationBarStyle, MobileMenu } from './style';
import { ReactComponent as LogoImage } from '../../images/logo-small.svg';
import { ReactComponent as LogoMobileMenuImage } from './images/logo-mobile-menu.svg';
import { ReactComponent as MenuImage } from './images/menu.svg';
import { ReactComponent as CloseImage } from './images/close.svg';
import MenuBackgroundImg from './images/menu-background.svg';

interface Props {
  fixed?: boolean;
  transparent?: boolean;
  desktopMinWidth?: number;
  background?: string;
  width?: number;
  maxWidthMobile?: number;
  noMargin?: boolean;
  mobilePadding?: string;
}

export const NavigationBar = ({ fixed, transparent, desktopMinWidth, background, width,
  maxWidthMobile, noMargin, mobilePadding }: Props) => {

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>();
  const location = useLocation();

  useEffect(() => {
    if ( location.hash ) {
      setShowMobileMenu(false);
    }
  }, [location]);

  return <>
    {!showMobileMenu && <NavigationBarStyle
      fixed={fixed}
      transparent={transparent}
      desktopMinWidth={desktopMinWidth || 1170}
      background={background}
      width={width}
      maxWidthMobile={maxWidthMobile}
      noMargin={noMargin}
      mobilePadding={mobilePadding}
    >
      <div>
        <div>
          <LogoImage onClick={() => window.location.href = '/'}/>
          <div>
            <a href='/#WhatIsProverest'>What is Proverest</a>
            <a href='/#WhoWeAre'>Who we are</a>
            <a href='/#OurProgress'>Our progress</a>
          </div>
        </div>
        <div>
          <div onClick={() => document.location.href = '/api/sso-login'}>
            <span>Get started - </span><span>it's free</span>
          </div>
          <div>
            <div onClick={() => document.location.href = '/api/sso-login'}>Get started</div>
            <MenuImage onClick={() => setShowMobileMenu(true)}/>
          </div>
        </div>
      </div>
    </NavigationBarStyle>}
    {showMobileMenu && <MobileMenu>
      <div>
        <div>
          <LogoMobileMenuImage />
          <CloseImage onClick={() => setShowMobileMenu(false)} />
        </div>
        <div>
          <a href='/#WhatIsProverest'>What is Proverest</a>
          <a href='/#WhoWeAre'>Who we are</a>
        </div>
      </div>
      <div>
        <div onClick={() => document.location.href = '/api/sso-login'}>Get started</div>
      </div>
      <img src={MenuBackgroundImg} />
    </MobileMenu>}
  </>;
};
