import React from 'react';
import { TitleAndBackStyle, Title, Back, StartTour } from './style';

interface Props {
  title?: string;
  titleBack?: string;
  icon?: React.ReactNode;
  hasTour?: boolean;
  onBack?: () => void;
}

export const TitleAndBack = ({ titleBack, title, icon, hasTour, onBack }: Props) => {
  return <TitleAndBackStyle>
    <div>
      {titleBack && !!onBack && <Back noTitle={!title} onClick={onBack}>
        {titleBack}
      </Back>}
      {title && <div>
        <Title fontSize={18}>
          {title}
        </Title>
        {icon}
      </div>}
    </div>
    {hasTour && <StartTour className='start-tour'/>}
  </TitleAndBackStyle>;
};
