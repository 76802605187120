import React, { ReactNode, useMemo } from 'react';
import { CardStyle, Header, Footer, Block } from './style';

import { ReactComponent as ScenarioImage } from './images/scenario.svg';
import { ReactComponent as ReportImage } from './images/report.svg';
import { ReactComponent as LoanImage } from './images/loan.svg';

import { Input } from '../../input';
import theme from '../../../themes';

const { palette } = theme;

interface Props {
  title?: string | ReactNode;
  headerControls?: ReactNode;
  footerControls?: ReactNode;
  icon?: 'scenario' | 'report' | 'loan';
  withPadding?: boolean;
  children: ReactNode | ReactNode[];
  maxWidth?: number;
  minWidth?: number;
  restrictedNames?: string[];
  onClick?: () => void;
  onChangeTitle?: (value: string) => void;
}

export const Card = ({ title, icon, headerControls, footerControls, withPadding, children, maxWidth, minWidth, restrictedNames, onClick, onChangeTitle }: Props) => {

  const childrenWrapped = useMemo(() => Array.isArray(children)
    ? children.map((child, i) => <Block key={i} withPadding={withPadding} roundedBottom={!footerControls}>{child}</Block>)
    : <Block withPadding={withPadding} roundedBottom={!footerControls}>{children}</Block>, [children, footerControls, withPadding]);

  return <CardStyle maxWidth={maxWidth} minWidth={minWidth}>
    {title && <Header clickable={!!onClick}>
      <div>
        {icon === 'scenario' && <ScenarioImage/>}
        {icon === 'report' && <ReportImage/>}
        {icon === 'loan' && <LoanImage/>}
        {!onChangeTitle && title}
        {onChangeTitle && <Input
          colorOnHover={palette.blue}
          value={title.toString()}
          title={title.toString()}
          restrictedValues={restrictedNames}
          onChange={(value: string) => {
            if (value !== title) {
              onChangeTitle(value);
            }
          }} onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains('field') && onClick) {
              onClick();
            }
          }}
          preventFocusByFieldClick
          inline
          noHorizontalPadding
        />}
      </div>
      {headerControls}
    </Header>}
    {childrenWrapped}
    {footerControls && <Footer>{footerControls}</Footer>}
  </CardStyle>;
};
