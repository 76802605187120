import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { HashLink } from 'react-router-hash-link';
import { Item, Menu, Trigger } from './style';

type ItemBase = {
  title: string;
  type?: 'primary' | 'warning';
  disabled?: boolean;
};

type Item = ItemBase & ({
  id: string | number;
  link?: string;
} | {
  submenu: Item[];
});

interface Props {
  menu: Item[];
  withPadding?: boolean;
  onSelect?: (id: string | number, e?: React.MouseEvent) => void;
  children: ReactNode;
  bindToMouse?: boolean;
  triggerWhenMounted?: boolean;
  className?: string;
  maxHeight?: number;
  offset?: [number, number];
}

export const ContextMenu = ({ menu, withPadding, children, bindToMouse, triggerWhenMounted, className, maxHeight, offset, onSelect }: Props) => {

  const menuRef = useRef<HTMLUListElement>() as MutableRefObject<HTMLUListElement>;

  const [currentLevel, setCurrentLevel] = useState<Item[] | null>();
  const [position, setPosition] = useState<[number, number] | null>();
  const [rightAligment, setRightAligment] = useState(false);
  const [scrolledParent, setScrolledParent] = useState<HTMLElement | null>(document.querySelector('body'));

  useEffect(() => {
    if (menuRef.current && scrolledParent) {
      if (scrolledParent.getBoundingClientRect().right < menuRef.current.getBoundingClientRect().right) {
        setRightAligment(true);
      }
    } else {
      setRightAligment(false);
    }
  }, [currentLevel, scrolledParent, menuRef]);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as HTMLElement)) {
        setCurrentLevel(null);
        setPosition(null);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [menuRef]);

  const getItem = (item: Item, i: number) => {
    return <Item
      key={i}
      type={item.type}
      disabled={item.disabled}
      onClick={item.disabled ? undefined : (e: React.MouseEvent) => {
        if ('submenu' in item) {
          setCurrentLevel(item.submenu);
        } else {
          onSelect?.(item.id, e);
          setPosition(null);
          setCurrentLevel(null);
        }
      }}
    >
      {item.title}
    </Item>;
  };

  useEffect(() => {
    if ( triggerWhenMounted ) {
      setCurrentLevel(menu);
    }
  }, [triggerWhenMounted, menu, setCurrentLevel]);

  if (menu.length === 0) {
    return null;
  }

  return <span className={className}>
    <Trigger
      withPadding={withPadding}
      onClick={(e) => {
        e?.stopPropagation();
        setCurrentLevel(menu);
        const [x, y] = [e?.clientX-(offset?.[0] || 0), e?.clientY-(offset?.[1] || 0)];
        setPosition(x && y ? [x, y] : null);
        let element = e?.target as HTMLElement;
        if (element) { // Will be empty in unit tests
          while (element.parentElement) {
            const { overflow, position } = window.getComputedStyle(element);
            const isScrolled = overflow.includes('hidden') || overflow.includes('scroll') || overflow.includes('auto');
            const isAbsolute = position.includes('absolute') || position.includes('relative');
            if ((isScrolled && isAbsolute) || element.nodeName === 'BODY') {
              setScrolledParent(element);
              return;
            }
            element = element.parentElement;
          }
          setScrolledParent(document.querySelector('body'));
        }
      }}
    >
      {children}
    </Trigger>
    {currentLevel?.length && document.querySelector('body') && createPortal(<Menu
      ref={menuRef}
      position={bindToMouse ? position : undefined}
      rightAligment={rightAligment}
      maxHeight={maxHeight}
    >
      {currentLevel.map((item, i) => 'link' in item ? <HashLink key={i} to={item.link}>
        {getItem(item, i)}
      </HashLink> : getItem(item, i))}
    </Menu>,
    document.querySelector('body') as HTMLBodyElement)}
  </span>;
};
