import styled from 'styled-components';

export const Wrapper = styled.div<{width: number; margin?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-family: 'Basis Grotesque Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-black-1); 
  }

  > div {
    width: ${(p) => p.width}px;
    height: 32px;
    background-color: var(--color-white);
    position: relative;
    z-index: 10;
    display: flex;
    background: var(--color-whiteish-1);
    border-radius: 6px;
    cursor: pointer;

    > div:first-of-type {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
    }
  }

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const Item = styled.div<{count: number; disabled?: boolean}>`
  width: ${(p) => `${100 / p.count}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  font-size: 14px;
  line-height: var(--height-common);
  color: var(--color-black-2);
  user-select: none;

  ${(p) => p.disabled ? 'color: var(--color-grey);' : ''}}
`;

export const SwitcherButton = styled.div<{move: number; count: number}>`
  width: ${(p) => `calc(${100/p.count}% - 4px)`};
  height: calc(100% - 4px);
  position: absolute;
  transition: all 300ms ease;
  z-index: 0;
  overflow: hidden;
  margin-top: 2px;
  background: var(--color-white);
  box-shadow: 0px 4px 8px rgba(43, 70, 86, 0.12);
  border-radius: 6px;

  ${(p) => `left: ${p.move * 100 / p.count}%;`}
`;
