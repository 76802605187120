export const dateToString = (date: Date) => `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;

export const formatDate = (date: string) => date.slice(0, 16).replace('T', ' ');

export const getCurrentDateTZ = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date();

  const formattedDate: string = new Intl.DateTimeFormat('en-CA', { timeZone: timezone, year: 'numeric' as const, month: '2-digit' as const, day: '2-digit' as const }).format(date);
  const formattedTime: string = new Intl.DateTimeFormat('en-GB', { timeZone: timezone, hour: '2-digit' as const, minute: '2-digit' as const, hour12: false }).format(date);

  return `${formattedDate} ${formattedTime}`;
};
