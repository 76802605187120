import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Wrapper = styled.div<{ left: number; top: number }>`
  @media print {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  transform: translateY(-50%);
  z-index: 10;
`;

export const Num = styled.div<{ color: string }>`
  color: ${(p) => p.color};
  margin: 12px 0;
`;

export const Cursor = styled.div<{ color: string }>`
  background: ${(p) => p.color};
  width: 6px;
  height: 20px;
  margin-bottom: 12px;
`;

export const Page = styled.div<{ color: string }>`
  background: ${(p) => p.color + '77'};
  width: 6px;
  height: 6px;
  margin-bottom: 2px;
  cursor: pointer;
  :hover {
    > div {
      display: flex;
    }
  }
  > div {
    display: none;
    flex-direction: column;
    padding: 10px 30px 10px 20px;
    position: absolute;
    left: 40px;
    margin-top: 30px;
    border-bottom-left-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    background: white;
    box-shadow: 10px 10px 40px rgba(29, 59, 78, 0.15);
    border-left: 1px solid ${palette.greenMedium};
    white-space: nowrap;
    animation: fade-in-page-counter-hint .3s ease-in-out forwards;
    z-index: 40;
    h3 {
      font-size: 12px;
      color: ${palette.black};
      font-weight: 600;
      margin: 0 0 5px 0;
    }
    p {
      font-size: 12px;
      color: ${palette.grayMedium};
      font-weight: 400;
      margin: 0;
    }
    @keyframes fade-in-page-counter-hint {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
  }
`;
