import styled from 'styled-components';

export const Text = styled.div<{left: number; top: number; width: number}>`
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  width: ${(p) => p.width}px;
  position: fixed;
  font-size: 12px;
  color: white;
  margin-top: 10px;
  padding: 0 5px;
  animation: fade-in-help-text .5s ease-in-out forwards;
  @keyframes fade-in-help-text {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;
