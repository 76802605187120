import styled from 'styled-components';
import { ReactComponent as RemoveImage } from '../../../images/remove.svg';
import { ReactComponent as CompareImage } from '../../../images/compare.svg';

export const Wrapper = styled.div<{padding?: string}>`
  display: flex;
  flex-direction: column;

  ${(p) => p.padding ? `
    padding: ${p.padding};
  ` : ''}
`;

export const Header = styled.div<{marginRight?: number}>`
  display: flex;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 15px;

  ${(p) => p.marginRight ? `
    margin-right: ${p.marginRight}px;
  ` : ''}

  > div {
    white-space: nowrap;
  }
`;

export const Row = styled.div<{marginBottom?: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    margin-right: auto;
    width: 100%;

    > div {
      .mobile .adaptive &, .tablet .adaptive & {
        flex-direction: column;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      width: 100%;
    }
  }

  ${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  ` : ''}

  .mobile .adaptive &&, .tablet .adaptive && {
    flex-direction: column;

    > div {
      flex-direction: column;
      padding: 0;
      width: 100%;
    }
  }
`;

export const Item = styled.div<{width?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(p) => p.width ? `
    flex: 0 0 ${p.width * 100}%;
  ` : `
    flex: 1;
  `};

  :not(:first-of-type) {
    padding-left: 15px;
  }

  :not(:last-of-type) {
    padding-right: 15px;
  }

  > svg {
    flex-shrink: 0;
    margin-left: 30px;
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    padding: 0;
    width: 100%;
  }

  ${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  ` : ''}
`;

export const MobileTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;

  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const Remove = styled(RemoveImage)<{disabled?: boolean}>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: ${(p) => !p.disabled ? 'pointer' : 'cursor'};
  opacity: ${(p) => !p.disabled ? 1 : 0.7};
  margin-left: 30px;
`;

export const Compare = styled(CompareImage)<{disabled?: boolean}>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: ${(p) => !p.disabled ? 'pointer' : 'cursor'};
  opacity: ${(p) => !p.disabled ? 1 : 0.7};
  margin-left: 30px;
`;

export const Footer = styled.div<{marginRight?: number}>`
  ${(p) => p.marginRight ? `
    margin-right: ${p.marginRight}px;
  ` : ''}
`;
