import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const ToolsStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div<{ disabled?: boolean; color?: string }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p.color || (p.disabled ? palette.grayDark : palette.greenMedium)};
  opacity: ${(p) => p.disabled ? 0.5 : 1};
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
  svg {
    margin-right: 10px;
  }
  :hover {
    color: ${(p) => p.color || (p.disabled ? palette.grayDark : palette.greenMedium)};
  }
  :not(:last-child) {
    margin-right: 20px;
  }
  transition: color .3s, opacity .3s;
`;
