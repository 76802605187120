import React from 'react';
import { IncomingMessagesStyle } from './incoming-messages.style';

interface Props {
  count?: number;
  inline?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export function IncomingMessages({ count, inline, onClick }: Props) {
  return count ? <IncomingMessagesStyle onClick={onClick} inline={inline}>
    {count}
  </IncomingMessagesStyle> : null;
}

