interface Separators {
  decimalSeparator: string;
  thousandsSeparator: string;
}

const isDecimal = (value: number | string, separators: Separators) => {
  if ( typeof value === 'number' ) {
    return value % 1 === 0;
  } else {
    return Number.isFinite(parseNumber(value, separators)) && !value.includes(separators.decimalSeparator);
  }
};

// @ts-ignore // Fix types error: Property 'replaceAll' does not exist on type 'string'
export const parseNumber = (value: string, { decimalSeparator, thousandsSeparator }: Separators, isDecimal?: boolean) => !isDecimal ? +value?.replaceAll?.(thousandsSeparator, '')?.replaceAll?.(decimalSeparator, '.') :
  // @ts-ignore // Fix types error: Property 'replaceAll' does not exist on type 'string'
  Number.parseInt(value?.replaceAll?.(thousandsSeparator, '')?.replaceAll?.(decimalSeparator, '.'));

export const isStringParsable = (value: string, separators: Separators) => {
  // Not convertable to number: not-a-number (e.g. 'xyz'), decimal ending with decimal separator (e.g. '10.') or float ending with zero (e.g. '10.10')
  const number = parseNumber(value, separators);
  const isFloat = !isDecimal(value, separators);
  const lastSymbol = value[value.length - 1];
  return value !== '' && Number.isFinite(number) && (lastSymbol !== separators.decimalSeparator) && (!isFloat || lastSymbol !== '0');
};
