import React, { ReactNode } from 'react';
import { toLocaleValue } from '../../../../../shared';
import { Popup } from '../popup';
import { Icon, Text, Link, Title } from './style';

interface Props {
  variant?: 'simple' | 'big';
  email: string;
  title?: string;
  text?: ReactNode;
  bottomText?: ReactNode;
  mobile?: boolean;
  onClose?(): void;
}

export const EmailSent = ({ title, text, email, variant = 'simple', mobile, bottomText, onClose }: Props) => {

  return <Popup
    padding={mobile ? [68, 15, 30, 15] : (variant === 'simple' ? [68, 30, 30, 25] : [120, 70, 60, 70])}
    size={variant === 'simple' ? [382, 153] : [460, 470]}
    onClose={onClose}
    maxWidthMobile='calc(100vw - 10px)'
    closeOnClickOutside
    adaptive
  >
    <Icon big={variant === 'big'}/>
    {title && <Title>{title}</Title>}
    <Text thin={!!title}>{text || 'Email has sent to'}</Text>
    <Link>{toLocaleValue({ value: email, length: 28 })}</Link>
    {bottomText && <Text thin>{bottomText}</Text>}
  </Popup>;
};
