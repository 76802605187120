import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

export const Bullet = styled.div<{ lineColor?: string; width?: string; minWidth?: string; scale: number; clickable?: boolean; masonry?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${(p) => p.masonry ? `flex: 1;` : ''}
  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.minWidth ? `min-width: ${p.minWidth};` : ''}
  p {
    font-size: ${(p) => 12 * p.scale}px !important;
    margin: 0;
    color: ${palette.grayMedium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    margin-top: ${(p) => 10 * p.scale}px;
    padding-left: ${(p) => 10 * p.scale}px;
    font-size: ${(p) => 20 * p.scale}px;
    border-left: 4px solid ${(p) => p.lineColor || palette.blue};
    min-height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-top: 3px;
  }
  .mobile .adaptive && {
    p {
      font-size: ${(p) => 10 * p.scale}px !important;
    }
    span {
      font-size: ${(p) => 16 * p.scale}px;
      margin-top: ${(p) => 5 * p.scale}px;
      min-height: 30px;
    }
  }
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
`;

export const BulletsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const MetricsWrapper = styled.div<{ variant: 'horizontal' | 'vertical' }>`
  display: flex;
  flex-direction: ${(p) => p.variant === 'horizontal' ? 'column' : 'row'};
  flex-wrap: wrap;
  width: 100%;
`;

export const Metric = styled.div<{ variant: 'horizontal' | 'vertical'; width?: string; clickable?: boolean; center?: boolean }>`
  display: flex;
  justify-content: ${(p) => p.center ? 'center' : 'space-between'};
  flex-direction: ${(p) => p.variant === 'horizontal' ? 'row' : 'column'};
  margin: 8px 0;
  ${(p) => p.width ? `width: ${p.width};` : (p.variant === 'vertical' ? '' : 'width: 100%;') }
  font-size: 14px;
  > * {
    color: ${(p) => p.variant === 'horizontal' ? 'inherit' : palette.grayDark};
  }
  > :not(:first-of-type) {
    color: ${(p) => p.variant === 'horizontal' ? 'inherit' : palette.black};
  }
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
`;
