import styled from "styled-components";
import { ReactComponent as CalendarImage } from "./images/calendar.svg";

export const DatePickerWrapper = styled.div<{width?: string, minWidth?: string, height?: string, color?: string, focused?: boolean, disabled?: boolean, error?: boolean}>`
  position: relative;
  border-radius: 8px;
  background-color: ${(p) => p.color || "#f3f6f9"};

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.minWidth ? `min-width: ${p.minWidth};` : ''}
  ${(p) => p.height ? `height: ${p.height};` : ''}
  ${(p) => p.disabled ? 'opacity: 0.6;' : ''}
  ${(p) => p.error ? 'border: 1px solid var(--color-red);' : ''}
  ${(p) => p.focused ? `
    font-size: 0.75rem;
    transition: all 0.1s;
    top: 9px;
  ` : ''}

  .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown,
  .react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    position: relative;
    top: -1px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: #2579ba;
      position: relative;
      color: #fff;
  }
`;

export const DatepickerInput = styled.div`
  height: 50px;
  border: 0;
  margin: 0;
  background: none;
  border-radius: 8px;
  padding-top: 22px;
  padding-left: 12px;
  width: 90%;
  outline: none;
`;

export const DatePickerLabel = styled.div<{focused?: boolean}>`
  position: absolute;
  top: ${(p) => !p.focused ? '23px' : '9px'};
  left: 12px;
  width: 90%;
  
  font-family: "Basis Grotesque Pro", Arial;
  font-size: ${(p) => !p.focused ? '1rem' : '0.75rem'};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;

  color: var(--color-grey-dark);
  pointer-events: none;
  transition: all 0.1s;
`;

export const CalendarIcon = styled(CalendarImage)`
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
`;

export const ErrorLabel = styled.div`
  position: absolute;
  left: 0px;
  top: -15px;

  font-size: 0.75rem;
  line-height: 1rem;
  transition: all 0.1s;
  color: #a81616;
`;
