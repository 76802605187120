import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Chart = styled.div<{ percent: number; size: number }>`
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  animation: colors 1s infinite;
  border-radius: 50%;
  border: 1px solid ${palette.greenLight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${palette.greenDark};

  > :first-child {
    width: 50%;
    height: 100%;
    right: 0px;
    position: absolute;
    overflow: hidden;
    transform-origin: left center;
    transform: rotate(${(p) => p.percent >= 50 ? 180 : 0}deg);
  }
`;

export const HalfCircle = styled.div<{ type: 'clipped' | 'fixed'; percent: number }>`
  box-sizing: border-box;
  width: ${(p) => p.type === 'clipped' ? 200 : 100}%;
  height: 100%;
  right: 0px;
  position: absolute;
  border: solid 3px transparent;
  border-top-color: ${palette.greenDark};
  border-left-color: ${palette.greenDark};
  border-radius: 50%;
  transform: rotate(${(p) => p.type === 'clipped' ? 360 * (p.percent < 50 ? p.percent : p.percent - 50) / 100 - 45 : 135}deg);
  opacity: ${(p) => p.percent < 50 && p.type === 'fixed' ? 0 : 1};
`;
