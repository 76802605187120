import styled from 'styled-components';
import theme from '../../themes';
import { HoverHintType } from './hover-hint';

const { palette } = theme;

const getHintParams = (type: HoverHintType, space?: number) => ({
  left: `
    top: 10px;
    left: ${space || 100}px;
    border-bottom-left-radius: 15px;
    border-left: 1px solid ${palette.greenMedium};
  `,
  right: `
    top: 10px;
    right: ${space || 65}px;
    border-bottom-right-radius: 15px;
    border-right: 1px solid ${palette.greenMedium};
  `,
  top: `
    top: -35px;
    left: ${space || 40}px;
    border-top-left-radius: 15px;
    border-left: 1px solid ${palette.greenMedium};
  `,
  bottom: `
    bottom: -30px;
    left: ${space || 0}px;
    border-bottom-left-radius: 15px;
    border-left: 1px solid ${palette.greenMedium};
  `,
}[type]);

export const HoverHintStyle = styled.div<{noHint?: boolean; alwaysOn?: boolean}>`
  position: relative;

  :hover {
    > div.hint {
      display: ${(p) => !p.noHint ? 'flex' : 'none'};
    }
  }

  ${(p) => p.alwaysOn ? `
    > div.hint {
      display: flex;
    }
  ` : ''}
`;

export const Hint = styled.div<{type: HoverHintType; marginTop?: number; space?: number; maxWidth?: number}>`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  background: white;
  box-shadow: 10px 10px 40px rgba(29, 59, 78, 0.15);
  animation: fade-in-menu-hint .3s ease-in-out forwards;
  z-index: 40;
  color: ${palette.grayDark};

  ${(p) => p.maxWidth ? `
    max-width: ${p.maxWidth}px;
  ` : `
    white-space: nowrap;
  `}

  ${(p) => getHintParams(p.type, p.space)}

  ${(p) => p.marginTop ? `
    transform: translateY(${p.marginTop}px);
  ` : ''};

  @keyframes fade-in-menu-hint {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`;
