import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import "./PopupWrapper.css";

const PopupWrapper: React.FC<{
  children: any;
  onOutssideClick?: any;
  maxWidth?: string;
  onPressEscape?: () => void;
}> = ({ children, onOutssideClick, maxWidth, onPressEscape }) => {
  useEffect(() => {
    if (onPressEscape) {
      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.code === "Escape") {
          onPressEscape();
        }
      };

      document.addEventListener("keydown", handleEscapeKey);
      return () => document.removeEventListener("keydown", handleEscapeKey);
    }
  }, [onPressEscape]);

  const handleOutsideClick = useCallback((e: MouseEvent) => {
    // dropdown is opened in portal

    const dropdownOptionRegex = /^react-select.*-option-.*$/g;
    const id = (e?.target as HTMLElement)?.id;

    if ( dropdownOptionRegex.test(id) ) {
      return;
    }

    onOutssideClick?.();
  }, [onOutssideClick]);

  return document.querySelector('body') ? createPortal(
    <div className="main-popup-wrapper">
      <div
        className="main-popup-wrapper-backdrop"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
      <div className="main-popup-content">
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div className="main-popup" style={{ maxWidth }}>
            {children}
          </div>
        </OutsideClickHandler>
      </div>
    </div>,
    document.querySelector('body') as HTMLBodyElement,
  ) : null;
};

export default PopupWrapper;
