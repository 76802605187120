import styled, { CSSProperties } from 'styled-components';
import theme from '../../themes';
import { ReactComponent as ArrowSVG } from './images/arrow.svg';
import { ReactComponent as AscSVG } from './images/asc.svg';
import { ReactComponent as AskSVG } from './images/ask.svg';
import { ReactComponent as DescSVG } from './images/desc.svg';
import { ReactComponent as GroupTriggerSVG } from './images/group-trigger.svg';

const { palette } = theme;

export const TableControls = styled.div<{ top?: boolean }>`
  height: ${(p) => p.top ? 40 : 80}px;
  margin-bottom: ${(p) => p.top ? 20 : 0}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface TableStyleProps {
  fixedLayout?: boolean;
  noTopRadius?: boolean;
  noBottomRadius?: boolean;
  noBorders?: boolean;
  headerBackground?: string;
  cellHeight?: number;
  headHeight?: number;
  hasMobileView?: boolean;
  cellsOverflow?: boolean;
  isRowClickable?: boolean;
  showTopRadius?: boolean;
}

export const TableStyle = styled.table<TableStyleProps>`
  width: 100%;
  ${(p) => p.fixedLayout ? 'table-layout: fixed;' : ''}
  border-top-left-radius: ${(p) => p.noTopRadius ? 0 : 5}px;
  border-top-right-radius: ${(p) => p.noTopRadius ? 0 : 5}px;
  border-bottom-left-radius: ${(p) => p.noBottomRadius ? 0 : 5}px;
  border-bottom-right-radius: ${(p) => p.noBottomRadius ? 0 : 5}px;
  font-size: 14px;
  color: ${palette.grayDark};
  background: ${palette.whiteMedium};
  .mobile .adaptive & {
    ${(p) => p.hasMobileView ? 'background: transparent;' : ''}
  }
  th, td {
    border-bottom: ${(p) => !p.noBorders ? `1px solid ${palette.grayLight}` : 'none'};
    padding: 0 10px 0 10px;
    .mobile .adaptive & {
      ${(p) => p.hasMobileView ? `
        border-bottom: none;
        padding: 0;
      ` : ''}
    }
  }
  th {
    position: relative;
    background: ${(p) => p.headerBackground || palette.whiteMedium};
    height: ${(p) => p.headHeight || 62}px;

    ${(p) => p.showTopRadius ? `
      :last-child {
        border-top-right-radius: 5px;
      }

      :first-child {
        border-top-left-radius: 5px;
      }
    ` : ''}
  }
  td {
    height: ${(p) => p.cellHeight || 62}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: ${(p) => p.cellsOverflow ? 'visible' : 'hidden'};
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    :hover {
      color: ${palette.blue};
    }
  }
  th:last-child, td:last-child {
    padding-right: 40px;
    .mobile .adaptive & {
      padding-right: 0;
    }
  }
  th:first-child, td:first-child {
    padding-left: 15px;
    .mobile .adaptive & {
      padding-left: 0;
    }
  }
  tr:last-child td {
    border-bottom: none;
  }
  tr:hover td {
    background: #E5EAF27F;
  }
  ${(p) => p.isRowClickable ? `
    tr td {
      cursor: pointer;
    }
  ` : ''}

  .mobile .adaptive & {
    tr:hover td {
      background: transparent;
    }
  }
`;

export const NoData = styled.td<{noDataAlign?: CSSProperties['textAlign']}>`
  padding: 80px 0;
  text-align: ${(p) => p.noDataAlign || 'center'};
`;

export const Sort = styled.div`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  > span {
    color: ${palette.blue};
    :after {
      content: '⌄';
      margin-left: 5px;
      position: relative;
      top: -5px;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  span {
    margin-right: 5px;
  }
`;

const Arrow = styled(ArrowSVG)<{ disabled?: boolean }>`
  margin: 0 5px;
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
  :hover {
    opacity: ${(p) => p.disabled ? .7 : 1};
  }
`;

export const Previous = styled(Arrow)`
  transform: scaleX(-1);
  opacity: .7;
  height: 16px;
  width: 10px;
`;

export const Next = styled(Arrow)`
  transform: scaleX(1);
  opacity: .7;
  height: 16px;
  width: 10px;
`;

export const Asc = styled(AscSVG)`
  position: absolute;
  margin-left: 10px;
`;

export const Desc = styled(DescSVG)`
  position: absolute;
  margin-left: 10px;
`;

export const Ask = styled(AskSVG)`
  position: absolute;
  margin-left: 10px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
    color: ${palette.greenDark};
    font-weight: 600;
  }
`;

export const GroupTrigger = styled(GroupTriggerSVG).withConfig({
  shouldForwardProp: (prop) => !['expanded'].includes(prop as string),
})<{ expanded?: boolean }>`
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -7px;
  transform: rotate(${(p) => p.expanded ? '0deg' : '180deg'});
  cursor: pointer;
  * {
    ${(p) => p.expanded ? 'fill: white;' : ''}
  }
`;

export const TableInnerControls = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
`;

export const MobileCard = styled.div<{ maxWidth?: string }>`
  background: ${palette.grayBg};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 5px 0;
  max-width: ${(p) => p.maxWidth || '98vw'};
  overflow: hidden;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
`;

export const MobileCardHeader = styled.div<{ height?: number }>`
  > h1 {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
    min-width: 50%;
    height: ${(p) => p.height ? `${p.height}px` : 'auto'};
    > * {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
  > span {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const MobileCardMetrics = styled.div<{ variant: 'columns' | 'rows'; amount: number }>`
  flex-direction: ${(p) => p.variant === 'columns' ? 'row' : 'column'};
  > span {
    display: flex;
    flex-direction: ${(p) => p.variant === 'columns' ? 'column' : 'row'};
    ${(p) => p.variant === 'rows' ? `
      justify-content: space-between;
      align-items: center;
      width: 100%;
    ` : `
      flex: 1;
      max-width: ${100 / p.amount}%;
      padding: 0 5px;
    `};
    > p {
      font-weight: 600;
      margin: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > h2 {
      font-size: 14px;
      margin: 0;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  hr {
    display: ${(p) => p.variant === 'columns' ? 'block' : 'none'};
    height: 40px;
    width: 1px;
    background: ${palette.grayLight};
  }
`;

export const MobileCardCTA = styled.div<{ clickable?: boolean }>`
  border-top: 1px solid white;
  min-height: 45px;
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  > span {
    ${(p) => p.clickable ? '' : 'width: 100%;'}
  }
`;
