const Theme = {
  palette: {
    black: '#1B2124',
    blackLight: '#404657',
    greenDark: '#0A6232',
    greenMedium: '#779E86',
    greenLight: '#B9CDC1',
    greenLightest: '#EBF0ED',
    greenBlue: '#3698AF',
    lightGreenBlue: '#4E8C9B',
    grey: "#7E869B",
    grayDark: '#5F636A',
    grayMedium: '#828D94',
    grayLight: '#D7DBDD',
    grayBlue: '#EBF1F4',
    lightGrayBlue: '#F3F6F9FF',
    white: '#FFF',
    grayBg: '#F4F6F9',
    grayBorder: '#DFE5EB',
    grayMediumLight: '#F3F6F9',
    grayBackground: '#F6F8FA',
    whiteMedium: '#F8F9FB',
    red: '#DB7070',
    blue: '#6BACD4',
    blueLight: '#E9F1F8',
    blueDark: '#363B49',
    yellow: '#F5B942',
  },
  font: {
    large: '36px',
    big: '22px',
    normal: '16px',
    small: '14px',
  },
};

export default Theme;
