import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from '../../../../types';
export * from './actions';

interface State {
  selected?: string;
  templates: Template[];
  presets: Template[];
}

export const initialState: State = {
  templates: [],
  presets: [],
};

const Slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
    setTemplates(state, action: PayloadAction<Template[]>) {
      state.templates = action.payload;
    },
    setPresets(state, action: PayloadAction<Template[]>) {
      state.presets = action.payload;
    },
  },
});

export const {
  setSelected,
  setTemplates,
  setPresets,
} = Slice.actions;

export default Slice.reducer;
