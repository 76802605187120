import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SupportTicketCreateRequest } from '../../../../types';
import { Actions } from '../../redux';
import * as Popup from '../popup';

interface Props {
  variant?: 'default' | 'homepage';
}

export const SupportRequest = ({ variant = 'default' }: Props) => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.Auth.user);
  const createSupportRequest = useSelector((state: RootState) => state.App.createSupportRequest);
  const supportUpdateStatus = useSelector((state: RootState) => state.App.supportUpdateStatus);
  const supportTickets = useSelector((state: RootState) => state.App.supportTickets);
  const layout = useSelector((state: RootState) => state.App.layout);
  const reCaptchaSiteKey = useSelector((state: RootState) => state.App.config?.reCaptchaSiteKey);

  const [error, setError] = useState<string | null>();

  const mobile = layout === 'mobile';

  useEffect(() => {
    if (createSupportRequest && user) {
      dispatch(Actions.App.loadSupportTickets());
    }
  }, [dispatch, user, createSupportRequest]);

  const onSubmit = useCallback((request: SupportTicketCreateRequest) => dispatch(Actions.App.addSupportTicket(request, (error) => {
    if (error) {
      setError(error);
    }
  })), [dispatch]);

  const onDelete = useCallback((ticketId: string) => dispatch(Actions.App.closeSupportTicket(ticketId, (error) => {
    if (error) {
      setError(error);
    }
  })), [dispatch]);

  if (!createSupportRequest) {
    return null;
  }

  if (variant === 'default') {
    return <Popup.SupportRequest
      mobile={mobile}
      tickets={user ? supportTickets : []}
      loading={supportUpdateStatus === 'load'}
      error={error ?? undefined}
      currentUserEmail={user?.email}
      onClose={() => dispatch(Actions.App.setCreateSupportRequest(false))}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onClearError={() => setError(null)}
    />;
  } else {
    return <Popup.SupportRequestHomepage
      mobile={mobile}
      error={error ?? undefined}
      reCaptchaSiteKey={reCaptchaSiteKey}
      onClose={() => dispatch(Actions.App.setCreateSupportRequest(false))}
      onSubmit={onSubmit}
      onClearError={() => setError(null)}
    />;
  }

};
