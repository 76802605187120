/* eslint-disable indent */ // Wrong indent error displayed inside content block
import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const CommentNumber = styled.div<{ number: number; absolute?: boolean }>`
  position: ${(p) => p.absolute ? 'absolute' : 'relative'};
  border-radius: 50%;
  color: ${palette.white};
  background: ${palette.red};
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  overflow: hidden;
  margin-left: 10px;
  ${(p) => p.absolute ? `
    right: -30px;
    top: 0;
  ` : ''};
  :after {
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '${(p) => p.number}';
  }
`;
