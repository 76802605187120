import styled from 'styled-components';
import { ReactComponent as ArrowImage } from './images/arrow.svg';

type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export const SubSectionCell = styled.td<{cellPosition?: Position; isTitle?: boolean; isSubSectionTitle?: boolean; isTotal?: boolean; isScrolled?: boolean; clickable?: boolean}>`
  padding: 0;
  height: 24px;

  ${(p) => p.isSubSectionTitle ? 'background: #f0f4f7;' : 'background: #f9fbfc;'};
  ${(p) => p.clickable ? `
    cursor: pointer;
    user-select: none;
  ` : ''};

  ${(p) => p.isTitle || p.isSubSectionTitle ? `
    width: 170px;
    position: sticky;
    left: 0;
    z-index: 1;
    white-space: nowrap;
  ` : `
    width: 90px;
  `}

  > div {
    display: flex;
    align-items: center;
    height: 32px;

    background: #f0f4f7;
    padding: 8px;

    overflow: hidden;
    text-overflow: ellipsis;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${(p) => p.cellPosition ? `border-${p.cellPosition}-radius: 8px;` : ''}

    ${(p) => p.isTotal ? 'border-top: 1px solid #dfe5eb;' : ''}
    
    ${(p) => !p.isTitle && !p.isSubSectionTitle ? 'justify-content: flex-end;' : ''}

    ${(p) => (p.isTitle || p.isSubSectionTitle) && p.isScrolled ? `
      box-shadow: 0px 0px 4px 0px #2b465614;
      box-shadow: 6px 0px 4px 0px #2b46560a;
    ` : ''}

    ${(p) => p.isSubSectionTitle && !p.isTotal ? `
      margin-left: 32px;
      padding-left: 14px;
      border-left: 1px solid #dfe5eb;
    ` : ''}

    ${(p) => p.isSubSectionTitle && p.isTotal ? `
      margin-left: 0px;
      padding-left: 46px;
      border-top: 1px solid #dfe5eb;
    ` : ''}
  }
`;

export const Arrow = styled(ArrowImage).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop as string),
})<{isOpen: boolean}>`
  min-width: 16px;
  min-height: 16px;
  flex-shrink: 1;
  margin-right: 6px;

  cursor: pointer;

  transition: transform 0.2s ease-in-out;
  
  > path {
    transition: stroke 1s ease-in-out;
  }

  ${(p) => !p.isOpen ? `
    transform: rotate(180deg);
    > path {
      stroke: #A8ACB7;
    }
  ` : ''}
`;
