import React from 'react';
import { LocalAreaStatTable } from '../../../../../../../../types';
import { useLocale } from '../../../../../../shared';
import { CollapsibleHeader, Table } from '../style';

interface Props {
  data: LocalAreaStatTable;
}

export const MedianIncomeSection = ({ data }: Props) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '' });

  return <div>
    <CollapsibleHeader>
      <span>Median Income</span>
    </CollapsibleHeader>
    <div>
      <Table>
        <table>
          <thead>
            <tr>
              {data?.[0]?.map?.((item, i) => <th key={i}>{item}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.slice(1)?.map?.((item, i) => <tr key={i}>
              {item.map((item, j) => <td key={j}>{j === 0 ? item : toCurrency(item)}</td>)}
            </tr>)}
          </tbody>
        </table>
      </Table>
    </div>
  </div>;
};
