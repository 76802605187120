export const unitTypes = ['Detached', 'Semi-detached', 'Terraced', 'Apartment', 'Unspecified'] as const;

export type UnitType = typeof unitTypes[number];

export const finishTypes = ['Basic', 'Basic plus', 'Market Average', 'Premium', 'Luxury', 'N/A'] as const;

export type FinishType = typeof finishTypes[number];

export type BedroomType = number | 'N/A' | 'Unspecified';

export const bedroomIsValid = (bedroom: BedroomType): bedroom is number => typeof bedroom === 'number';

export const storeyTypes = ['1', '2', '3', '3+'] as const;

export type StoreyType = typeof storeyTypes[number];

export interface ResidentialUnit {
  type: 'residential';
  property_type: UnitType;
  bedroom_no: BedroomType;
  unit_name?: string;
  finish_type: FinishType;
  number?: number;
  price_per_unit?: number;
  size?: number | 'Unspecified';
  build_cost?: number;
  total_annual_rent_per_unit?: number;
  storey?: StoreyType;
  yield?: number;
}

export interface CommercialUnit {
  type: 'commercial';
  property_type?: string,
  unit_name?: string,
  finish_type?: string,
  number?: number;
  yield?: number;
  rent_total?: number;
  price?: number;
  size?: number | 'Unspecified';
}

export interface OtherUnit {
  type: 'other';
  property_type?: string;
  number?: number;
  price?: number;
  total_price?: number;
  size?: number | 'Unspecified';
  sqft?: number;
  unit_name?: string;
  build_cost?: number;
}

export type Unit = ResidentialUnit | CommercialUnit | OtherUnit;
