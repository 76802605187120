import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Input as InputComponent } from './input';
import { Props } from './types';

export const Input = (props: Props) => {
  const locale = useSelector((state: RootState) => state.App.config?.locale);

  return <InputComponent
    lang={locale?.lang}
    decimalSeparator={locale?.decimalSeparator}
    thousandsSeparator={locale?.thousandsSeparator}
    {...props}
  />;
};
