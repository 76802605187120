import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { isStringParsable, parseNumber } from '../../helpers';
import { Field, InlineWrapper, Pen } from '../../style';
import { TextWrapper } from './style';
import { Props } from './types';

export const Text = (props: Props) => {

  const fieldRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const { currentValue, focus, unit, disabled, inline, preventFocus, preventFocusByFieldClick, preventFocusBySignClick,
    decimalSeparator = '.', thousandsSeparator = ',', lang = 'en', setCurrentValue, setFocus } = props;

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (fieldRef.current && !fieldRef.current.contains(event.target as HTMLDivElement)) {
        fieldRef?.current?.blur();
        setFocus(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [fieldRef, setFocus]);

  useEffect(() => {
    if (focus) {
      fieldRef.current?.focus?.();
    }
  }, [fieldRef, focus]);

  const restrictedValues = useMemo(() => 'restrictedValues' in props ? props.restrictedValues?.filter((cur) => cur !== props.currentValue).reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {} as { [name: string]: boolean }) || {} : {}, [props]);

  const checkInputValue = useCallback((value: string) => {
    if (restrictedValues[value]) {
      return props.currentValue?.toString();
    }
    if (props.type === 'number' && value) {
      const number = parseNumber(value as string, { decimalSeparator, thousandsSeparator }, props.decimal);
      if (number && Number.isFinite(number)) {
        if ( props.min !== undefined && number < props.min ) {
          return props.min.toString();
        } else if ( props.max !== undefined && number > props.max ) {
          return props.max.toString();
        }
      }
      return number.toString();
    }
    return value;
  }, [decimalSeparator, props, restrictedValues, thousandsSeparator]);

  return <>
    {(inline && !focus) && <InlineWrapper
      right={inline === 'right'}
      className='field inline'
    >
      {unit && !['%', 'sq ft', 'sq.m.'].includes(unit) ? <>{unit}&nbsp;</> : ''}
      <TextWrapper className='field'>{currentValue}</TextWrapper>
      {unit && ['%', 'sq ft', 'sq.m.'].includes(unit) ? <>&nbsp;{unit}</> : ''}
      <span
        onClick={!preventFocusByFieldClick || preventFocus || preventFocusBySignClick || disabled ?
          undefined :
          (e: React.MouseEvent) => {
            setFocus(true);
            e.preventDefault();
            e.stopPropagation();
          }
        }
      >
        <Pen className='sign' />
      </span>
    </InlineWrapper>}
    {(!inline || focus) && <Field
      className='field'
      type={props.type === 'password' ? 'password' : undefined}
      ref={fieldRef}
      name={props.name}
      autoComplete={props.autoComplete}
      value={(() => {
        if ( typeof currentValue === 'string' ) {
          if ( props.type === 'password' && !currentValue && !props.newPassword ) {
            return focus ? '' : '********';
          } else if ( props.type === 'number' && currentValue ) {
            return isStringParsable(currentValue, { decimalSeparator, thousandsSeparator }) ? parseNumber(currentValue, { decimalSeparator, thousandsSeparator }, props.decimal).toLocaleString(lang) : currentValue;
          } else {
            return currentValue || '';
          }
        } else {
          return '';
        }
      })()}
      disabled={disabled}
      onChange={(e) => {
        if (!disabled) {
          if (props.type === 'number') {
            setCurrentValue(isStringParsable(e.target.value, { decimalSeparator, thousandsSeparator }) ? parseNumber(e.target.value, { decimalSeparator, thousandsSeparator }).toString() : e.target.value);
          } else {
            setCurrentValue(e.target.value);
          }
          if (('setOnTyping' in props) && props.setOnTyping) {
            const checkedValue = checkInputValue(e.target.value);
            if ( props.type !== 'number' || !checkedValue || Number.isFinite(+checkedValue) ) {
              props.onChange?.(checkedValue || '');
            } else {
              props.onChange?.(checkedValue || '');
            }
          }
        }
      }}
      onFocus={(e) => {
        if ( disabled || preventFocusByFieldClick ) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
        const checkedValue = checkInputValue(currentValue as string);
        if ( props.type === 'number' && checkedValue && !Number.isFinite(+checkedValue) ) {
          setCurrentValue('');
          if (!props.setOnTyping) {
            props.onChange?.('');
          }
        } else if (!('setOnTyping' in props) || !props.setOnTyping) {
          setCurrentValue(checkedValue || '');
          (props as Props).onChange?.(checkedValue || '');
        }
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          (e.target as HTMLInputElement).blur();
          if ('onEnter' in props) {
            props.onEnter?.();
          }
        }
      }}
    />}
  </>;
};
