import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from '../../..';
import { SupportTicketCreateRequest, supportTypeOptions } from '../../../../../../types';
import { PopupTextArea } from '../../popup';
import { Button } from '../../../ui';
import { ReactComponent as ChainSVG } from './images/chain.svg';
import { Buttons, DeleteImage, Dropzone, File } from './style';
import { checkEmail } from '../../../../shared';

interface Props {
  requireEmail?: boolean;
  showErrors?: boolean;
  onSubmit: (args: SupportTicketCreateRequest) => void;
  onClose: () => void;
}

export const CreteTicket = ({ requireEmail, showErrors, onSubmit, onClose }: Props) => {

  const [type, setType] = useState(supportTypeOptions[0].value);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [documents, setDocuments] = useState<File[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (newDocuments) => setDocuments([...newDocuments, ...documents]),
    maxSize: 1024 * 1024 * 10,
    accept: ['image/jpeg', 'image/png', 'application/pdf'],
  });

  return <>
    <Input
      type='dropdown'
      placeholder='Select topic'
      options={supportTypeOptions}
      value={supportTypeOptions.find((item) => item.value === type)}
      onChange={(value) => setType(value.value)}
      error={showErrors && !supportTypeOptions}
      mandatory
    />
    <PopupTextArea
      placeholder='Message (max 500 symbols)'
      maxLength={500}
      value={message}
      height={108}
      onChange={(value) => setMessage(value)}
      error={showErrors && !message}
      mandatory
    />
    {requireEmail && <Input
      type='text'
      placeholder='Your email'
      value={email}
      onChange={(value: string) => setEmail(value)}
      error={showErrors && requireEmail && (!email || !checkEmail(email))}
      mandatory
      setOnTyping
    />}
    <Dropzone {...getRootProps()} highlight={isDragActive || documents.length > 0}>
      <input {...getInputProps()} />
      {documents.length === 0 ? <span>Drop here your file (jpg, png, pdf)</span> : <File>
        <div>
          <ChainSVG />
          {documents[0].name} {documents.length > 1 ? ` and ${documents.length - 1} other` : ''}
        </div>
        <DeleteImage red onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDocuments([]);
        }} />
      </File>}
    </Dropzone>
    <Buttons>
      <Button variant='primary' onClick={() => onSubmit({ type, message, email, documents })}>Send</Button>
      <Button variant='secondary' onClick={onClose}>Cancel</Button>
    </Buttons>
  </>;
};
