// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rounding = (elem: any, type?: "%" | "num") => {
  if (!Number.isNaN(Math.ceil(elem * (type === "%" ? 100 : 1)) / (type === "%" ? 100 : 1))) {
    return `${(Math.ceil(elem * (type === "%" ? 100 : 1)) / (type === "%" ? 100 : 1)).toString().replace(/(?=\B(?:\d{3})+(?!\d))/g, " ").replaceAll(" ", ",")}`;
  } else {
    return elem === undefined ? "" : elem;
  }
};

export const chartRound = (unit: 'M' | 'k', sum?: number) => sum ? Math.round(Math.round(sum) / (unit === 'M' ? 1000000 : 1000) * 10) / 10 : undefined;
