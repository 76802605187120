import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import Switch from 'react-switch';
import { useExpanded, useGroupBy, usePagination, useSortBy, useTable } from 'react-table';
import { useLocalStorage } from '../../shared/hooks';
import theme from '../../themes';
import { Button } from '../ui/button/button';
import { ContextMenu } from '../ui/context-menu';
import { Asc, Ask, Desc, GroupTrigger, MobileCard, MobileCardCTA, MobileCardHeader, MobileCardMetrics, Next, NoData, Pagination, Previous, Sort, SwitchWrapper, TableControls, TableInnerControls, TableStyle } from './style';
import { DataItem, MobileDataItem, Props, SortOption } from './types';

const { palette } = theme;

export const Table = ({ columns, data, rowsPerPage, sortOptions, groupOption, headerBackground, fixedLayout, cellsOverflow, externalSorting, mobileMaxWidth,
  noTopRadius, noBottomRadius, noBorders, noDataMessage = 'No data found', className, controls, cellHeight, headHeight, noDataAlign, layout, showTopRadius,
  mobileView, onRowClick }: Props) => {

  const [sort, setSort] = useState<SortOption | null>(sortOptions?.[0] ?? null);
  const [group, setGroup] = useLocalStorage(`group.${groupOption?.id || 'other'}`, false);

  const hasSortableColumns = useMemo(() => columns.find((column) => column.sortAccessor), [columns]);
  const sortByColumnClick = useMemo(() => hasSortableColumns && !groupOption, [groupOption, hasSortableColumns]);
  const mobile = layout === 'mobile' && !!mobileView;

  const sortBy = useMemo(() => sort && sort.order !== 'ask' ? [{
    id: sort.accessor,
    desc: sort.order === 'desc',
  }] : [], [sort]);

  const groupBy = useMemo(() => groupOption ? {
    column: groupOption.accessor,
    triggerColumn: groupOption.triggerAccessor,
    value: group,
  } : undefined, [group, groupOption]);

  const aggregateColumns = useMemo(() => columns.map((column) => ({ aggregate: (leafValues: unknown[]) => leafValues[0], ...column })), [columns]);
  const sortOptionsAuto = useMemo(() => [...columns
    .filter((column) => column.sortAccessor && column.Header)
    .map(({ Header, sortAccessor }, id) => ({ id, title: Header as string, accessor: sortAccessor })),
  ...(sort ? [{ id: 1000, title: 'Restore', type: 'warning' as const }] : []) ], [columns, sort]);

  const {
    rows, page, headerGroups, canPreviousPage, canNextPage, state,
    getTableProps, getTableBodyProps, prepareRow, nextPage, previousPage, setPageSize, setSortBy, toggleGroupBy,
  } = useTable({ columns: aggregateColumns, data, initialState: {
    sortBy,
    hiddenColumns: columns.filter((col) => col.hide).map((col) => col.accessor),
  } }, ...[
    ...(groupOption ? [useGroupBy] : []),
    ...(hasSortableColumns ? [useSortBy] : []),
    ...(groupOption ? [useExpanded] : []),
    ...(rowsPerPage ? [usePagination] : []),
  ]);

  useEffect(() => setPageSize?.(rowsPerPage || 0), [setPageSize, rowsPerPage]);
  useEffect(() => setSortBy?.(sortBy), [setSortBy, sortBy]);
  useEffect(() => groupBy && toggleGroupBy?.(groupBy.column, groupBy.value), [toggleGroupBy, groupBy]);

  const isHeader = useMemo(() => !!columns.find((item) => item.Header), [columns]);

  return <>
    {(groupOption || (externalSorting && hasSortableColumns)) && !mobile && <TableControls top>
      {groupOption ? <SwitchWrapper>
        <span>{groupOption.title}</span>
        <Switch
          height={20}
          width={40}
          handleDiameter={16}
          offColor={palette.grayLight}
          onColor={palette.greenMedium}
          checkedIcon={false}
          uncheckedIcon={false}
          checked={group}
          onChange={() => setGroup(!group)}
        />
      </SwitchWrapper> : <div/>}
      {hasSortableColumns ? <ContextMenu
        menu={sortOptionsAuto}
        onSelect={(id) => setSort(sortOptionsAuto[id]?.accessor ? { title: sortOptionsAuto[id].title, accessor: sortOptionsAuto[id].accessor, order: 'ask' } : null)}
      >
        <Button variant='secondary'>{sort ? 'Restore sorting' : 'Sorting'}</Button>
      </ContextMenu> : <div/>}
    </TableControls>}

    {mobile && (rowsPerPage || sortOptions) && <TableControls>
      {sortOptions ? <Sort>
        Sort by: <span>
          <ContextMenu
            menu={sortOptions.map(({ title }, id) => ({ id, title }))}
            onSelect={(id) => setSort(sortOptions[id])}
          >
            {sort?.title}
          </ContextMenu>
        </span>
      </Sort> : <div/>}
      {rowsPerPage && <Pagination>
        <span>
          {rowsPerPage !== 1 && <>{state.pageIndex * state.pageSize + 1}-</>}{Math.min(rows.length, (state.pageIndex + 1) * state.pageSize) || 1}
          &nbsp;of&nbsp;
          {rows.length || 1}
        </span>
        <Previous disabled={!canPreviousPage} onClick={previousPage}/>
        <Next disabled={!canNextPage} onClick={nextPage}/>
      </Pagination>}
    </TableControls>}

    <TableStyle
      {...getTableProps()}
      className={className}
      headerBackground={headerBackground}
      noTopRadius={noTopRadius}
      noBottomRadius={noBottomRadius}
      noBorders={noBorders}
      fixedLayout={fixedLayout}
      cellHeight={cellHeight}
      headHeight={headHeight}
      cellsOverflow={cellsOverflow}
      showTopRadius={showTopRadius}
      hasMobileView={!!mobileView}
      isRowClickable={!!onRowClick}
    >
      {isHeader && !mobile && <thead>
        {headerGroups.map((headerGroup) => <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((header, headerIndex) => {
            // @ts-ignore
            const { style, sortAccessor } = header;
            const canBeSorted = sortAccessor && (sortByColumnClick || sort?.accessor === sortAccessor);
            const isSorted = sortAccessor && sortAccessor === sort?.accessor;
            return <th
              {...header.getHeaderProps({ style: {
                ...style,
                ...(isSorted ? { background: '#DAE8F4' } : {}),
                cursor: canBeSorted ? 'pointer' : 'default',
              } })}
              onClick={canBeSorted ? () => setSort({
                title: sortOptions?.find((item) => item.accessor === sortAccessor)?.title || 'Custom',
                accessor: sortAccessor,
                order: sort?.accessor === sortAccessor && sort?.order !== 'desc' ? 'desc' : 'asc',
              }) : undefined}
            >
              <span>{header.render('Header')}</span>
              {sortAccessor && sort?.accessor === sortAccessor ? <>
                {!sort?.order || sort?.order === 'asc' && <Asc/>}
                {sort?.order === 'desc' && <Desc/>}
                {sort?.order === 'ask' && <Ask/>}
              </> : null}
              {controls && headerIndex === headerGroup.headers.length - 1 && <TableInnerControls>{controls}</TableInnerControls>}
            </th>;
          })}
        </tr>)}
      </thead>}
      <tbody {...getTableBodyProps()}>
        {(page || rows).length > 0 ? (() => {
          let rowNumberInGroup = 0;
          return (page || rows).map((row) => {
            const rowIsGroupHead = groupBy?.value && row.subRows.length > 1;
            if (rowIsGroupHead) {
              rowNumberInGroup = 0;
            } else if (groupBy?.value && !row.groupByID) {
              rowNumberInGroup = rowNumberInGroup + 1;
            }
            if (rowNumberInGroup === 1) {
              return null; // Skip first row in group cause we already show it above
            }
            prepareRow(row);
            return <tr {...row.getRowProps({ style: ((row.original as DataItem)?.rowStyle || {}) as CSSProperties })}>
              {!mobile ? row.cells.map((cell, cellIndex) => {
                const isGroupColumn = !!(groupBy?.value && groupBy?.triggerColumn === cell.column.id);
                const isGroupTrigger = !!(rowIsGroupHead && isGroupColumn);
                const cellIsInGroup = groupBy?.value && !cell.isAggregated;
                const isSorted = columns[cellIndex]?.sortAccessor && columns[cellIndex]?.sortAccessor === sort?.accessor;
                return <td
                  {...cell.getCellProps({
                    style: {
                      ...(columns[cellIndex]?.cellStyle || {}),
                      ...(isGroupTrigger ? { position: 'relative', paddingRight: '20px' } : {}),
                      ...(cellIsInGroup ? { background: palette.blueLight } : {}),
                      ...(isSorted ? { background: palette.blueLight } : {}),
                      ...(row.isExpanded ? { background: '#DAE8F4' } : {}),
                    },
                  })}
                  onClick={onRowClick ? () => onRowClick(cell.row.original as DataItem) : undefined}
                >
                  {isGroupColumn && !isGroupTrigger && !cell.isAggregated ? '' : cell.render('Cell')}
                  {isGroupTrigger && <GroupTrigger {...row.getToggleRowExpandedProps()} expanded={row.isExpanded}/>}
                </td>;
              }) : <td>
                {(() => {
                  const data = mobileView?.(row.original as DataItem) as MobileDataItem;
                  return <MobileCard maxWidth={mobileMaxWidth}>
                    <MobileCardHeader height={data.titleHeight}>
                      <h1>{data.title}</h1>
                      {data.controls && <span>{data.controls}</span>}
                    </MobileCardHeader>
                    <MobileCardMetrics variant={data.variant || 'columns' as 'columns' | 'rows'} amount={data.metrics.length}>
                      {data.metrics.map((item, i) => <React.Fragment key={i}>
                        {i > 0 && <hr/>}
                        <span>
                          <h2>{item.name}</h2>
                          <p>{item.value}</p>
                        </span>
                      </React.Fragment>)}
                    </MobileCardMetrics>
                    {data.footer && <div>{data.footer}</div>}
                    {data.cta && <MobileCardCTA className='table-mobile-card-cta' clickable={!!data.onCtaClick} onClick={data.onCtaClick}>
                      <span>{data.cta}</span>
                      {data.onCtaClick && <span>&gt;</span>}
                    </MobileCardCTA>}
                  </MobileCard>;
                })()}
              </td>}
            </tr>;
          });
        })() : <tr>
          <NoData colSpan={Object.keys(columns).length} noDataAlign={noDataAlign}>
            {noDataMessage}
          </NoData>
        </tr>}
      </tbody>
    </TableStyle>

    {(!mobile && (rowsPerPage || (sortOptions && !groupOption && !externalSorting))) && <TableControls>
      {(sortOptions && !groupOption) ? <Sort>
        Sort by: <span>
          <ContextMenu
            menu={sortOptions.map(({ title }, id) => ({ id, title }))}
            onSelect={(id) => setSort(sortOptions[id])}
          >
            {sort?.title}
          </ContextMenu>
        </span>
      </Sort> : <div/>}
      {rowsPerPage && <Pagination>
        <span>{state.pageIndex * state.pageSize + 1}-{Math.min(rows.length, (state.pageIndex + 1) * state.pageSize) || 1} of {rows.length || 1}</span>
        <Previous disabled={!canPreviousPage} onClick={previousPage}/>
        <Next disabled={!canNextPage} onClick={nextPage}/>
      </Pagination>}
    </TableControls>}
  </>;
};
