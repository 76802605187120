import styled from 'styled-components';
import theme from '../../../themes';
import { ReactComponent as LoaderSVG } from './images/loader.svg';

const { palette } = theme;

const scale = .61;
const timingFunction = 'ease-in-out';
const period = '2s';

export const LoaderWrapper = styled.div<{ maxHeight?: boolean; withPadding?: boolean; maxWidth?: boolean }>`
  position: relative;
  ${(p) => p.withPadding && `
    padding: 33px;
  `}
  ${(p) => p.maxHeight && `
    height: 100%;
  `}
  ${(p) => p.maxWidth && `
    width: 100%;
  `}
`;

export const LoaderBackground = styled.div<{ zIndex?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);

  @supports ( not ( backdrop-filter: blur(5px) ) ) {
    background: #ffffff85;
  }

  ${(p) => p.zIndex ? `z-index: ${p.zIndex};` : ''}
`;

export const LoaderContainer = styled.div<{ width: number; top?: number; left?: number; zIndex?: number; sticky?: boolean }>`
  position: ${(p) => p.sticky ? 'sticky' : 'absolute'};
  width: ${(p) => p.width}px;
  height: ${(p) => p.width * scale}px;
  top: ${(p) => p.top || '50%'};
  left: ${(p) => p.left || '50%'};
  transform: translate(-50%);
  overflow: hidden;
  ${(p) => p.zIndex ? `z-index: ${p.zIndex};` : ''}
`;

export const Animation = styled.div<{ width: number; zIndex?: number; delay?: number }>`
  position: absolute;
  width: ${(p) => p.width}px;
  height: ${(p) => p.width * scale}px;
  left: -${(p) => p.width}px;
  animation: move-loader-side ${period} ${timingFunction} infinite;
  animation-delay: ${(p) => p.delay ? `${p.delay}s` : 0};
  overflow: hidden;
  @keyframes move-loader-side {
    0% {
      left: -${(p) => p.width}px;
    }
    100% {
      left: ${(p) => p.width}px;
    }
  }
  svg {
    position: absolute;
    animation: move-loader-image ${period} ${timingFunction} infinite;
    animation-delay: inherit;
    left: ${(p) => p.width}px;
    @keyframes move-loader-image {
      0% {
        left: ${(p) => p.width}px;
      }
      100% {
        left: -${(p) => p.width}px;
      }
    }
  }
`;

export const LoaderImage = styled(LoaderSVG)<{ width: number; bright?: number }>`
  width: 100%;
  height: 100%;
  g {
    stroke: ${(p) => p.bright ? palette.greenDark : '#D4DAE3'};
  }
`;
