import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { PaymentSchedule } from '../../../../../types';
import { Calendar, Container, Placeholder } from './style';
import { Input } from '../../input';
import { ButtonCalculator } from '../button-calculator';
import { genLabel } from './gen-label';

export const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const genKey = (startYear: number, startMonth: number) => `${startYear}-${(startMonth + 1).toString().padStart(2, '0')}`;
const genItem = (startYear: number, startMonth: number, value: number) => ({ [genKey(startYear, startMonth)]: value });

interface Props {
  value?: PaymentSchedule;
  placeholder: string;
  width?: string;
  minWidth?: string;
  disabled?: boolean;
  monthsCount?: number;
  startDate?: string;
  setValue: (value: PaymentSchedule | null) => void;
}

export const Schedule = ({ value, minWidth, width, disabled, placeholder, monthsCount, startDate, setValue }: Props) => {
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  // TBD: in fact we have +1 month for now, need to be investigated
  const monthsCountCorrected = useMemo(() => (monthsCount || 0) + 1, [monthsCount]);

  const { startYear, startMonth, finishYear, finishMonth, yearsList } = useMemo(() => {
    const startYear = startDate ? new Date(startDate).getFullYear() : new Date().getFullYear();
    const startMonth = startDate ? new Date(startDate).getMonth() : new Date().getMonth();
    const finishDate = new Date(startYear, startMonth + (monthsCountCorrected || 0) - 1, 1);
    const finishYear = finishDate.getFullYear();
    const finishMonth = finishDate.getMonth();
    const yearsList = Array.from({ length: finishYear - startYear + 1 }, (_, index) => startYear + index);

    return { startYear, startMonth, finishYear, finishMonth, yearsList };
  }, [monthsCountCorrected, startDate]);

  const defaultValue = useMemo(() => value ? ({ ...value }) : genItem(startYear, startMonth, 100), [startMonth, startYear, value]);

  const [schedule, setSchedule] = useState(defaultValue);
  const [opened, setOpened] = useState(false);

  const isDefault = useMemo(() => Object.keys(schedule).length === 1 && schedule[genKey(startYear, startMonth)] === 100, [schedule, startMonth, startYear]);
  const label = useMemo(() => !isDefault ? genLabel(schedule, startYear === finishYear) : undefined, [isDefault, schedule, startYear, finishYear]);

  const rest = useMemo(() => 100 - Object.values(schedule || {}).reduce((acc, cur) => acc + cur, 0), [schedule]);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setOpened(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  }, [defaultValue, ref, startMonth, startYear, value]);

  const updateSchedule = useCallback((year: number, month: number, value: number) => {
    const rest = 100 - Object.entries(schedule).filter(([date]) => date !== genKey(year, month))
      .reduce((acc, [, value]) => acc + value, 0);

    value = Math.min(value, rest);

    if (value === 0) {
      delete schedule[genKey(year, month)];
      setSchedule({ ...schedule });
    } else {
      setSchedule({
        ...schedule,
        ...genItem(year, month, value),
      });
    }
  }, [schedule]);

  useEffect(() => {
    if (!opened) {
      setSchedule(defaultValue);
    }
  }, [defaultValue, opened]);

  return <Container ref={ref} width={width} minWidth={minWidth} disabled={disabled} onClick={!disabled ? () => setOpened(!opened) : undefined}>
    <span title={label}>{label}</span>
    <Placeholder focused={!isDefault}>{placeholder}</Placeholder>
    {opened && <Calendar onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
      <div>
        {['', ...monthLabels].map((label, index) => <div key={index}>{label}</div>)}
      </div>
      {yearsList.map((year) => <div key={year}>
        {[year, ...monthLabels].map((_, i) => <div key={i}>
          {i === 0 && year}
          {i > 0 && <Input
            value={schedule[genKey(year, i - 1)] || 0}
            type='number'
            min={0}
            minWidth={36}
            maxWidth={36}
            disabled={
              (year === startYear && (i - 1) < startMonth) ||
              (year === finishYear && (i - 1) > finishMonth) ||
              (rest === 0 && !schedule[genKey(year, i - 1)])}
            noPadding
            onChange={(value) => updateSchedule(year, i - 1, +value)}
          />}
        </div>)}
      </div>)}
      <div>
        <ButtonCalculator
          variant='secondary'
          margin='12px 0 0 0'
          width='100px'
          onClick={() => {
            setValue(null);
            setOpened(false);
          }}
        >Reset</ButtonCalculator>
        <ButtonCalculator
          variant='primary'
          margin='12px 0 0 auto'
          width='100px'
          disabled={rest > 0}
          onClick={() => {
            setValue(isEqual(schedule, genItem(startYear, startMonth, 100)) ? null : schedule);
            setOpened(false);
          }}
        >Apply</ButtonCalculator>
      </div>
    </Calendar>}
  </Container>;
};
