import styled from 'styled-components';

export const SnappedWrapper = styled.div<{ top: number; fixed?: boolean }>`
  ${(p) => p.fixed ? `
    position: fixed;
    top: ${p.top}px;
  `  : ''}
`;

export const Marker = styled.div`
  width: 1px;
  height: 1px;
  opacity: 0;
`;
