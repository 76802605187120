import { parse } from 'date-fns';
import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Calendar as DatePicker, DateRangePicker, RangeWithKey } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Field } from '../../style';
import { CalendarWrapper } from './style';
import { fromRangeWithKey, toRangeWithKey } from './to-value';
import { MultipleCalendarValue, Props } from './types';

export const Calendar = (props: Props) => {

  const fieldRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const { currentValue, disabled, preventFocusByFieldClick, lang = 'en', focus, setFocus, setCurrentValue } = props;

  useEffect(() => {
    if (focus) {
      fieldRef.current?.focus?.();
    }
  }, [fieldRef, focus]);

  const calendarCallback = useCallback((date: Date) => {
    if (props.type !== 'calendar') {
      return;
    }
    setCurrentValue(date.toLocaleDateString(lang));

    if (!Number.isFinite(+date)) {
      setCurrentValue('');
      props.onChange?.(null);
    } else {
      props.onChange?.(date.toDateString());
    }
    setFocus(false);
  }, [lang, props, setCurrentValue, setFocus]);

  const calendarRangeCallback = useCallback((range: RangeWithKey) => {
    if (props.type !== 'calendar-range') {
      return;
    }
    setCurrentValue(fromRangeWithKey(range));
    if (range.startDate && range.endDate && range.startDate.toString() !== range.endDate.toString()) {
      props.onChange?.([range.startDate, range.endDate]);
      setFocus(false);
    }
  }, [props, setCurrentValue, setFocus]);

  return <>
    <Field
      ref={fieldRef}
      className='field'
      value={(() => {
        if (props.type === 'calendar') {
          return (currentValue ?? '') as string;
        } else if (props.type === 'calendar-range') {
          const { startDate, endDate } = toRangeWithKey(currentValue as MultipleCalendarValue) || {};
          if ( !startDate && !endDate ) {
            return 'Not specified';
          } else {
            return `${startDate ? startDate.toDateString() : 'Unlimited'} - ${endDate ? endDate.toDateString() : 'Unlimited'}`;
          }
        }
      })()}
      disabled={disabled}
      onChange={(e) => !disabled && props.type === 'calendar' && setCurrentValue(e.target.value)}
      onFocus={(e) => {
        if (disabled || preventFocusByFieldClick) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        setFocus(true);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          (e.target as HTMLInputElement).blur();
          const date = parse(currentValue as string, 'd.M.y', new Date());
          calendarCallback(date);
        }
      }}
    />
    {focus && <CalendarWrapper>
      {props.type === 'calendar' && <DatePicker
        date={Number.isFinite(new Date(currentValue as string || '')) ? new Date(currentValue as string || '') : new Date()}
        onChange={(date) => calendarCallback(date as Date)}
        maxDate={props.maxDate}
        minDate={props.minDate}
      />}
      {props.type === 'calendar-range' && <DateRangePicker
        ranges={[toRangeWithKey(currentValue as MultipleCalendarValue)]}
        onChange={(range) => calendarRangeCallback((range as { selection: RangeWithKey }).selection)}
        maxDate={props.maxDate}
        minDate={props.minDate}
      />}
    </CalendarWrapper>}
  </>;
};
