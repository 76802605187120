import { useCallback, useMemo } from 'react';
import { CommercialUnit, Country, OtherUnit, ResidentialUnit, UnitValuationType } from '../../../../types';
import { rounding } from '../rounding';
import { getTotalSales, unitIsResidental } from '../units';
import { useLocale } from './use-locale';

export const useUnitsResult = (units: Array<ResidentialUnit | CommercialUnit | OtherUnit>, country: Country, sizeUnits?: 'sqft' | 'sqm', valuation?: UnitValuationType) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '-' });

  const isCommValuation = useMemo(() => valuation === 'commercial', [valuation]);

  const unitFormat = useCallback((value: string) => (value || '') === '' ? 'Unspecified unit(s)' : value, []);
  const priceFormatAvg = useCallback((value: string, isTotal?: boolean) => value === '-' ? '-' : ((value || '') === '' ? 'Unspecified' : toCurrency(value, `${isTotal ? ' (avg)' : ''}`)), [toCurrency]);
  const priceFormat = useCallback((value: string) => value === '-' ? '-' : ((value || '') === '' ? 'Unspecified' : toCurrency(value)), [toCurrency]);
  const rentFormat = useCallback((value: string) => value === '-' ? '-' : (value ? toCurrency(value) : 'Unspecified'), [toCurrency]);
  const sizeFormatAvg = useCallback((value: string, isTotal?: boolean) => value === '-' ? '-' : (value ? `${rounding(value)}${isTotal ? ' (avg)' : ''}` : 'Unspecified'), []);
  const sizeFormat = useCallback((value: string) => value === '-' ? '-' : (value ? rounding(value) : 'Unspecified'), []);
  const percentFormat = useCallback((value: string) => value === '-' ? '-' : (value ? `${rounding(value)}%` : 'Unspecified'), []);

  const genUnitsColumns = useCallback((isCommValuation: boolean, isUK?: boolean, sizeUnits?: 'sqft' | 'sqm', brfLoan?: boolean) => (!brfLoan ? [
    { key: 'unit', title: 'Units', width: '16%', format: unitFormat },
    { key: 'rooms', title: isUK ? 'Bedrooms' : 'Rooms', width: '12%' },
    { key: 'quantity', title: 'Quantity', width: '12%' },
    !isCommValuation ? { key: 'sizePrice', title: `Price per ${sizeUnits}`, width: '12%', format: priceFormatAvg } :
      { key: 'yield', title: `Yield`, width: '12%', format: percentFormat },
    { key: 'rents', title: 'Total annual rent', width: '12%', format: rentFormat },
    ...(!isCommValuation ? [{ key: 'unitSize', title: `Size per unit, ${sizeUnits}`, width: '12%', format: sizeFormatAvg }] : []),
    ...(!isCommValuation ? [{ key: 'unitPrice', title: 'Price per unit', width: '12%', format: priceFormatAvg }] : []),
    { key: 'totalPrice', title: 'Total price', width: '12%', format: priceFormat },
  ] : [{ key: 'unit', title: 'Other', width: isCommValuation ? '28%' : '40%' },
    { key: 'sizePrice', title: `Price per ${sizeUnits}`, width: isUK ? '24%' : '12%', format: priceFormat },
    { key: 'unitSize', title: `Size, ${sizeUnits}`, width: '24%', format: sizeFormat },
    { key: 'totalPrice', title: 'Total price', width: '12%', format: priceFormat },
  ]).filter((column) => column.key !== 'rents' || isUK || isCommValuation), [unitFormat, priceFormatAvg, percentFormat, rentFormat, sizeFormatAvg, priceFormat, sizeFormat]);

  const unitsColumns = useMemo(() => genUnitsColumns(isCommValuation, country === 'UK', sizeUnits, false), [isCommValuation, country, sizeUnits, genUnitsColumns]);
  const brfLoanColumns = useMemo(() => genUnitsColumns(isCommValuation, country === 'UK', sizeUnits, true), [isCommValuation, country, sizeUnits, genUnitsColumns]);

  const unitsData = useMemo(() => (units || []).filter((unit) => unit.property_type !== 'BRF Loan (Sweden only)').map((unit) => ({
    unit: unit.property_type,
    rooms: ('bedroom_no' in unit && unit.bedroom_no) ? unit.bedroom_no : '-',
    quantity: unit.number || '-',
    unitPrice: 'price_per_unit' in unit ? unit?.price_per_unit : '',
    rents: (('total_annual_rent_per_unit' in unit && unit.total_annual_rent_per_unit) || unitIsResidental(unit)) ? unit.total_annual_rent_per_unit : '-',
    unitSize: unit.size,
    sizePrice: ('price_per_unit' in unit && unit.size && unit.size !== 'Unspecified') ? (unit.price_per_unit || 0) / unit.size : '',
    totalPrice: getTotalSales([unit]),
    yield: unitIsResidental(unit) ? unit.yield : '',
  })), [units]);

  const groups = useMemo(() => unitsData.map(({ unit, rooms }) => `${unit}/${rooms}`).filter((value, index, self) => self.indexOf(value) === index), [unitsData]);

  const groupedUnitsData = useMemo(() => groups.map((group) => {
    const [unit, rooms] = group.split('/');

    const subData = unitsData.filter((data) => data.unit === unit && data.rooms.toString() === rooms.toString());

    if (subData.length === 1) {
      return { values: subData[0] };
    } else {
      const values = subData.reduce((acc, unit) => ({
        unit: unit.unit,
        rooms: unit.rooms,
        quantity: (acc['quantity'] || 0) + +(unit.quantity || 0),
        unitPrice: '-',
        rents: (acc['rents'] || 0) + +(unit.rents || 0) * +(unit.quantity || 0),
        unitSize: (acc['unitSize'] || 0) + +(unit.unitSize || 0) * +(unit.quantity || 0),
        sizePrice: '-',
        totalPrice: (acc['totalPrice'] || 0) + unit.totalPrice,
      }), {});

      values['unitSize'] = values['unitSize'] / values['quantity'];
      values['unitPrice'] = Math.round(values['totalPrice'] / values['quantity']);
      values['sizePrice'] = values['unitSize'] ? Math.round(values['totalPrice'] / values['unitSize'] / values['quantity']) : '';
      values['rents'] = Math.round(values['rents'] / values['quantity']);

      return { values, subData };
    }
  }), [groups, unitsData]);

  const unitsDataTotal = useMemo(() => {
    const totals = (units || []).filter((unit) => unit.property_type !== 'BRF Loan (Sweden only)').reduce((acc, unit) => ({
      unit: 'Total',
      rooms: '-',
      quantity: (acc['quantity'] || 0) + (unit.number || 0),
      unitPrice: '-',
      rents: '-',
      unitSize: (acc['unitSize'] || 0) + +(unit.number || 0) * (unit.size === 'Unspecified' ? 0 : unit.size || 0),
      sizePrice: '-',
      totalPrice: (acc['totalPrice'] || 0) + getTotalSales([unit]),
    }), {});

    return {
      ...totals,
      unitSize: totals['unitSize'] ? rounding(totals['unitSize']) : '0',
    };
  }, [units]);

  const brfLoanData = useMemo(() => (units || []).filter((unit) => unit.property_type === 'BRF Loan (Sweden only)').map((unit) => ({
    unit: 'BRF Loan',
    unitSize: unit.size,
    sizePrice: ('price_per_unit' in unit && unit.size !== 'Unspecified') ? (unit.price_per_unit || 0) / (unit.size || 1) : '',
    totalPrice: 'total_price' in unit && unit.total_price ? unit.total_price : '-',
  })), [units]);

  return { unitsColumns, brfLoanColumns, unitsData: [...groupedUnitsData, unitsDataTotal], brfLoanData };
};
