/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ComparablesTableRowPopup from "./comparables/comparables-table-row/comparables-table-row";
import ComparablesFilter from "./comparables/comparables-filter/comparables-filter";
import { RootState, store } from "../../../../../../redux/store";
import { setPopupOpen } from "../../../../../../redux/calculate/calculate";
import { useSelector } from "react-redux";
import { LocalAreaStat } from "../../../popups";

export type PopupName = "comparables-item" | "comparables-filter" | 'local-area-population' | 'local-area-median-income' | 'local-area-unemployment-rate' | 'local-area-housing';

export const handlePopup = (name: PopupName, data?: any) => { // any here is intentional, because we can't know what data will be passed
  store.dispatch(
    setPopupOpen({ popupName: name, popupData: data ? data : null }),
  );
};

export const GetCorrectPopup = () => {
  const correctPopupName = useSelector((state : RootState) => state.calculate.openedPopup.popupName);

  switch (correctPopupName) {
    case "comparables-item":
      return <ComparablesTableRowPopup />;
    case "comparables-filter":
      return <ComparablesFilter />;
    case "local-area-population":
      return <LocalAreaStat type='population' />;
    case "local-area-median-income":
      return <LocalAreaStat type='median-income' />;
    case "local-area-unemployment-rate":
      return <LocalAreaStat type='unemployment-rate' />;
    case "local-area-housing":
      return <LocalAreaStat type='housing' />;
    default:
      return <></>;
  }
};
