import React from 'react';
import { HashLink } from 'react-router-hash-link';
import theme from '../../../themes';
import { Cursor, Num, Page, Wrapper } from './style';

const { palette } = theme;
interface Props {
  currentPage: number;
  totalPages: number;
  pageNames?: string[];
  color?: string;
  left?: number;
  top?: number;
}

export const PageCounter = ({ currentPage, totalPages, pageNames, color = palette.grayMedium, left = 30, top = 500 }: Props) => {

  return <Wrapper color={color} left={left} top={top}>
    <Num color={color}>
      {currentPage < 10 ? '0' : ''}{currentPage}
    </Num>
    {new Array(Math.max(0, currentPage - 1)).fill(0).map((_, i) => <HashLink key={`before-${i}`} to={`#${i + 1}`}>
      <Page color={color}>
        {pageNames?.[i] && <div>
          <h3>Page #{i + 1}</h3>
          <p>{pageNames[i]}</p>
        </div>}
      </Page>
    </HashLink>)}
    <Cursor color={color}/>
    {new Array(Math.max(0, totalPages - currentPage)).fill(0).map((_, i) => <HashLink key={`after-${i}`} to={`#${i + currentPage + 1}`}>
      <Page color={color}>
        {pageNames?.[i + currentPage] && <div>
          <h3>Page #{i + currentPage + 1}</h3>
          <p>{pageNames[i + currentPage]}</p>
        </div>}
      </Page>
    </HashLink>)}
    <Num color={color}>
      {totalPages < 10 ? '0' : ''}{totalPages}
    </Num>
  </Wrapper>;
};
