import styled from 'styled-components';

export const AutoScaleStyle = styled.div<{ scale: number; width: number; maxScale?: number; minScale?: number }>`
  > div {
    transform: scale(${(p) => p.scale});
    transform-origin: top left;
  }
  width: ${(p) => p.width * p.scale}px;
  ${(p) => p.maxScale ? `max-width: ${p.width * p.maxScale}px;` : ''}
  ${(p) => p.minScale ? `min-width: ${p.width * p.minScale}px;` : ''}
  flex: 1;
  overflow: hidden;
`;
