import React, { ReactNode } from 'react';
import { toLocaleValue } from '../../../../shared';
import { BulletsWrapper, MetricsWrapper, Bullet, Metric } from './style';

interface Props {
  scale?: number;
  variant?: 'bullets' | 'horizontal' | 'vertical';
  metrics: Array<{
    title: string | ReactNode;
    value: string | number | ReactNode;
    onClick?: () => void;
    lineColor?: string;
    width?: string;
    money?: boolean;
    time?: boolean;
    percent?: boolean;
    length?: number;
  }>;
  className?: string;
  metricWidth?: string;
  minMetricWidth?: string;
  masonry?: boolean;
}

export const Metrics = ({ metrics, scale = 1, variant = 'bullets', className, metricWidth, minMetricWidth, masonry }: Props) => {

  return variant === 'bullets' ? <BulletsWrapper className={className}>
    {metrics.map((metric, i) => <Bullet
      key={i}
      scale={scale}
      lineColor={metric.lineColor}
      width={metric.width || metricWidth}
      minWidth={minMetricWidth}
      masonry={masonry}
      onClick={metric.onClick}
      clickable={!!metric.onClick}
    >
      <p>{metric.title}</p>
      <span>{toLocaleValue(metric)}</span>
    </Bullet>)}
  </BulletsWrapper> : <MetricsWrapper variant={variant} className={className}>
    {metrics.map((metric, i) => <Metric
      key={i}
      variant={variant}
      width={metric.width || metricWidth}
      onClick={metric.onClick}
      clickable={!!metric.onClick}
    >
      <span>{metric.title}</span>
      <span>{toLocaleValue(metric)}</span>
    </Metric>)}
  </MetricsWrapper>;
};
