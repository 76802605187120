import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';

const useDeepCompareMemoize = (value: React.DependencyList[0]) => {
  const ref = useRef();
  if (!R.equals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

// Use it with caution cause it can slow down the app working with deep objects (deeper then unit structure):
export const useDeepCompareEffect = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, deps?.map(useDeepCompareMemoize));
};
