import React from 'react';
import { TextArea, TextAreaCounter } from './style';

interface Props {
  maxLength: number;
  placeholder?: string;
  value?: string;
  height?: number;
  fontSize?: number;
  error?: boolean;
  mandatory?: boolean;
  onChange: (text: string) => void;
}

export const PopupTextArea = ({ maxLength, placeholder, value, height, fontSize, error, mandatory, onChange }: Props) => {
  return <>
    <TextArea
      placeholder={`${mandatory ? '* ' : ''}${placeholder}`}
      maxLength={maxLength}
      value={value}
      height={height}
      fontSize={fontSize}
      error={error}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
    />
    <TextAreaCounter>{`${value?.length || 0}/${maxLength}`}</TextAreaCounter>
  </>;
};
