import * as App from './app/app';
import * as Auth from './auth/auth';
import * as calculate from './calculate';
import * as Template from './template/template';
export * from './store';

export const Actions = {
  App,
  Auth,
  calculate,
  Template,
};
