import React, { useState, useEffect } from 'react';
import { CSSProperties } from 'styled-components';
import { IncomingMessages } from '../incoming-messages';
import { Wrapper, Image, Placeholder } from './style';

interface Props {
  id?: string;
  userName: string;
  incomingMessages?: number;
  size?: number;
  style?: CSSProperties;
  noPicture?: boolean;
  hash?: string;
  withBorder?: boolean;
  borderColor?: string;
  circle?: boolean;
  picLetterCount?: number;
  radius?: number;
  iconOpacity?: number;
  className?: string;
  onIncomingMessagesClick?: (e: React.MouseEvent) => void;
  onClick?: () => void;
}

export const UserPic = ({ id, userName, style, size, incomingMessages, noPicture, className,
  hash, withBorder, circle, borderColor, picLetterCount = 1, radius, iconOpacity, onIncomingMessagesClick, onClick }: Props) => {

  const [isFailed, setIsFailed] = useState(noPicture);

  useEffect(() => {
    setIsFailed(noPicture);
  }, [id, hash, noPicture, setIsFailed]);

  return !isFailed ? <Wrapper style={style} className={className} size={size} onClick={onClick} clickable={!!onClick}>
    <Image
      src={`/api/userpic${id ? `/${id}` : ''}${hash ? `?${hash}`: ''}`}
      onError={() => setIsFailed(true)}
      withBorder={withBorder}
      borderColor={borderColor}
      circle={circle}
      radius={radius}
      opacity={iconOpacity}
    />
    <IncomingMessages count={incomingMessages} onClick={onIncomingMessagesClick}/>
  </Wrapper> : <Placeholder
    style={style}
    className={className}
    size={size}
    withBorder={!!(withBorder || borderColor)}
    circle={circle}
    radius={radius}
    opacity={iconOpacity}
    onClick={onClick}
    clickable={!!onClick}
    small={picLetterCount >= 2}
  >
    {userName.slice(0, picLetterCount).toUpperCase()}
    <IncomingMessages count={incomingMessages}/>
  </Placeholder>;
};
