/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { styled } from "@mui/system";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import "./switch-button.css";

const blue = {
  500: "var(--color-aqua)",
};

const grey = {
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
};

const Root = styled("span")(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 20px;
      top: 4px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${blue[500]};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);

interface Props {
  isChecked: React.Dispatch<React.SetStateAction<boolean>>;
  option1?: string;
  option2?: string;
}

export default function SwitchButton(props: Props) {
  const label = { slotProps: { input: { "aria-label": "switch" } } };
  const [isChecked, setIsChecked] = React.useState(false);

  return (
    <div className="switch-button_wrapper">
      {props.option1 && (
        <span className={isChecked ? "grayed-out" : ""}>{props.option1}</span>
      )}
      <SwitchUnstyled
        onChange={(prop: any) => {
          setIsChecked(prop.target.checked);
          props.isChecked(prop.target.checked);
        }}
        component={Root}
        {...label}
      />
      {props.option2 && (
        <span className={!isChecked ? "grayed-out" : ""}>{props.option2}</span>
      )}
    </div>
  );
}
