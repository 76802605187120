import styled from 'styled-components';
import theme from '../../themes';

export { ReactComponent as BellImage } from './images/bell.svg';
export { ReactComponent as MessagesImage } from './images/messages.svg';
export { ReactComponent as TeamsImage } from './images/teams.svg';

const { palette } = theme;

export const barExpandedWidth = 235;
export const barCollapsedWidth = 118;

export const Background = styled.div`
  display: none;
  .mobile .adaptive &&, .tablet .adaptive && {
    display: block;
    background: #00000077;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
`;

export const Wrapper = styled.div<{ expanded: boolean }>`
  .mobile .adaptive &&, .tablet .adaptive && {
    display: ${(p) => p.expanded ? 'flex' : 'none'};
    position: fixed;
    z-index: 10;
    width: 1%;
    opacity: 0;
    box-shadow: -10px 4px 20px rgba(0, 0, 0, 0.1);
    background: ${palette.grayBg};
    top: 0;
    bottom: 0;
    right: 0;
    margin-top: 0;
    max-width: 330px;
    overflow: auto;
    > div {
      width: 100%;
    }
    animation: fade-in-mobile-menu .2s ease-in-out forwards;
    @keyframes fade-in-mobile-menu {
      0% { width: 1%; opacity: 0; }
      100% { width: 97%; opacity: 1; }
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    width: ${(p) => p.expanded ? barExpandedWidth : barCollapsedWidth}px;
    padding-left: ${(p) => p.expanded ? 20 : 0}px;
    padding-right: ${(p) => p.expanded ? 20 : 0}px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & > div:last-child {
    padding-bottom: 100px;
    align-items: flex-start;
  }
  margin-top: 10px;
  box-sizing: border-box;
`;

export const Head = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex-direction: ${(p) => p.expanded ? 'row' : 'column'};
  align-items: center;
  margin-bottom: ${(p) => p.expanded ? 5 : 30}px;

  .mobile .adaptive &&, .tablet .adaptive && {
    width: 100%;
    justify-content: space-between;
  }
`;

export const BlockHead = styled.div<{ expanded?: boolean; clickable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.expanded ? 'space-between' : 'center'};
  margin-top: ${(p) => p.expanded ? 20 : 0}px;
  height: 60px;
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  width: 100%;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const BlockEmpty = styled.div<{ expanded?: boolean }>`
  width: 195px;
  height: 60px;
  display: ${(p) => p.expanded ? 'block' : 'none'};
  border-radius: ${(p) => p.expanded ? 15 : 5}px;
  background: white;
  .mobile .adaptive &&, .tablet .adaptive && {
    width: 100%;
  }
`;

export const ViewAll = styled.div`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 200;
  color: ${palette.grayDark};
  :after {
    content: 'View all';
  }
`;

export const Expand = styled.div<{ expanded?: boolean }>`
  height: 74px;
  padding: ${(p) => p.expanded ? '0 10px 0 20px' : '0 23px'};
  line-height: 74px;
  text-align: center;
  color: ${palette.grayDark};
  font-size: 22px;
  transition: all .5s;
  cursor: pointer;
  :hover {
    filter: contrast(5);
  }
`;

export const CurrentUser = styled.div<{ expanded?: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  width: ${(p) => p.expanded ? '155px' : '50px'};
  height: ${(p) => p.expanded ? '74px' : '50px'};
  background: transparent;
  padding: ${(p) => p.expanded ? '5px 5px 5px 10px' : '0'};
  cursor: pointer;

  .mobile .adaptive &&, .tablet .adaptive && {
    width: auto;
    > div {
      flex-direction: row-reverse;
      > p {
        width: 100px;
        margin-right: 10px;
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) => !p.expanded && `
      width: 100%;
      height: 100%;
    `}
  }

  p {
    width: ${(p) => p.expanded ? 'auto' : '140px'};
    margin: 0 0 0 ${(p) => p.expanded ? 10 : 0}px;
    overflow: hidden;
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
  }
  p, svg {
    display: ${(p) => p.expanded ? 'block' : 'none'};
  }
`;

export const CalendarBlock = styled.div`
  .mobile .adaptive &&, .tablet .adaptive && {
    display: none;
  }
`;

export const Title = styled.h2<{ center?: boolean }>`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${palette.grayDark};
  text-align: ${(p) => p.center ? 'center' : 'left'};
  margin: 0 0 0 10px;
`;

export const Calendar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px -10px 0 -10px;
`;

export const Day = styled.div<{ today?: boolean; holiday?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.today ? palette.greenMedium : 'transparent'};
  border-radius: 5px;
  cursor: default;
  span {
    padding: 10px 12px;
  }
  span:first-child {
    color: ${(p) => p.today ? 'white' : palette.greenMedium};
    font-size: 14px;
    font-weight: 400;
  }
  span:last-child {
    color: ${(p) => p.today ? 'white' : ( p.holiday ? palette.grayMedium : 'bloack' )};
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Username = styled.div`
  margin-top: 10px;
  font-size: 12px;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  word-break: break-word;
`;

export const List = styled.div<{ expanded: boolean }>`
  padding: 0 ${(p) => p.expanded ? 3 : 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const UserPicWrapper = styled.div`
  margin: 10px 0;
`;
