import { ButtonVariant } from './button-calculator';
import styled from 'styled-components';

const color: Record<ButtonVariant, string> = {
  primary: '#ffffff',
  secondary: '#363b49',
  approved: '#363b49',
  rejected: '#363b49',
};

const background: Record<ButtonVariant, string> = {
  primary: '#3698af',
  secondary: '#f6f8fa',
  approved: '#ccf0cb',
  rejected: '#f0cdcb',
};

export const ButtonStyle = styled.button<{variant: ButtonVariant; disabled?: boolean; margin?: string; width?: string}>`
    cursor: pointer;

    width: 50%;
    height: 48px;
    padding: ${(p) => p.variant === 'primary' ? '7px 16px' : '7px 24px'};

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: ${(p) => p.variant === 'secondary' ? '1px solid #d2d6dd' : 'none'};

    color: ${(p) => color[p.variant]};
    font-size: 16px;
    background: ${(p) => background[p.variant]};
    border-radius: 8px;

    ${(p) => p.disabled ? `
      cursor: default;
      opacity: 0.3;
    ` : ''}

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
  ${(p) => p.width ? `width: ${p.width};` : ''}

  font-family: "Basis Grotesque Pro";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`;
