
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import theme from '../../../themes';

const { palette } = theme;

const ButtonStyle = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'green', 'centered', 'flat', 'nowrap', 'round', 'maxWidth', 'flatNoBorder', 'scale'].includes(prop as string),
})<{width?: string; height?: string; marginTop?: string; marginBottom?: string; marginLeft?: string; marginRight?: string; green?: boolean; centered?: boolean;
  flat?: boolean; nowrap?: boolean; round?: boolean; justify?: string; padding?: string; maxWidth?: number; flatNoBorder?: boolean;
  scale?: number;
}>`
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: ${(p) => p.flat ? `3px solid ${palette.grayLight}40` : 'none'};
  border-radius: ${(p) => p.flatNoBorder ? '0' : '5px'};
  padding: ${(p) => p.padding ? p.padding : `10px 22px`};
  transition: all .1s linear;
  box-sizing: border-box;
  line-height: ${(p) => p.height || '20px'};
  width: ${(p) => p.width || 'auto'};

  scale: ${(p) => p.scale ? p.scale : 1};

  display: ${(p) => p.centered ? 'block' : 'inline-block'};
  margin-left: ${(p) => p.centered ? 'auto' : p.marginLeft || 0};
  margin-right: ${(p) => p.centered ? 'auto' : p.marginRight || 0};
  margin-top: ${(p) => p.marginTop || 0};
  margin-bottom: ${(p) => p.marginBottom || 0};
  min-height: 38px;

  white-space: nowrap;

  ${(p) => p.round && `
    border-radius: 50%;
  `}

  ${(p) => p.justify && `
    justify-content: ${p.justify};
  `}

  ${(p) => p.nowrap && `
    white-space: nowrap;
  `}

  ${(p) => p.maxWidth && `
    max-width: ${p.maxWidth}px;
  `}

  a
  {
    color: inherit;
    text-decoration: none;
  }

  .spinner-border
  {
    margin: 0 4px 2px;
  }

  &.btn-secondary
  {
    background-color: white;
    box-shadow: ${(p) => p.flat || p.flatNoBorder ? 'none' : `0px 7px 20px rgba(0, 0, 0, 0.1)`};
    font-weight: ${(p) => p.flat ? '400' : 'inherit'};
    color: ${palette.grayMedium};
    &:disabled {
        opacity: 0.3;
    }
  }

  &.btn-secondary:not(.disabled):active,
  &.btn-secondary:hover
  {
    background-color: white;
    box-shadow: ${(p) => p.flat ? 'none' : `0px 7px 20px rgba(0, 0, 0, 0.2)`};
  }

  &.btn-primary
  {
    background-color: ${(p) => p.green ? '#0f6233': (p.flatNoBorder ? 'white' : palette.blue)};
    color: ${(p) => p.flatNoBorder ? palette.blue : 'white'};
    box-shadow: ${(p) => p.flat || p.flatNoBorder ? 'none' : `0px 7px 20px rgba(26, 79, 112, 0.25)`};
    &:disabled {
        opacity: 0.3;
    }
  }

  &.btn-primary:not(.disabled):active,
  &.btn-primary:hover
  {
    box-shadow: ${(p) => p.flat ? 'none' : `0px 7px 20px rgba(26, 79, 112, 0.45)`};
    border-color: transparent;
  }

  ${(p) => p.flat && `
    &.btn-primary, &.btn-secondary {
      background-color: white;
      color: ${palette.grayMedium};

      :hover {
        border-color: ${palette.grayLight};
      }

      :not(:disabled) {
        border-color: ${palette.grayLight}40 !important;
        ouline: none !important;
      }
    }
  `}

  ${(p) => p.flatNoBorder ? `
    border: 1px solid ${palette.grayBg};
    margin: 0;
    font-size: 12px;
    
    :focus {
      border-color: ${palette.grayBg};
      outline: none;
    }
  ` : ''}

  > img {
    margin-right: 10px;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  > img {
    margin-right: 10px;
  }
`;

export { ButtonStyle, Label };
