import { Note } from './note';
import { File } from './file';
import { Scenario, ScenarioShort } from './scenario';

export interface Project {
  name: string;
}

export interface Users {
  createdBy: {
    name?: string,
    surname?: string,
    phoneNumber?: string,
    email?: string,
    id: string
  };
  preOwnedBy: {
    name?: string,
    surname?: string,
    phoneNumber?: string,
    email?: string,
    id: string
  };
  currentlyOwnedBy: {
    name?: string,
    surname?: string,
    phoneNumber?: string,
    email?: string,
    id: string
  };
}

export interface TimelineFinancingRequest {
  section: string;
  totalFunds: number;
  fee: number;
  ltc: number;
  ltgdv: number;
  profitShare: number;
  interestRate: number;
  debtRequestId: string;
  partnerId: string;
}

export interface TimelineTenderingRequest {
  constructionCost: number;
  constructionPsqm: number;
  recalculateNote: string;
  buildTime: number;
  partnerFee: number;
  partnerName: string;
  requestType: string;
  quotationId?: string;
};

export type TimelineSlug = 'CALCULATOR_TENDERING_RECALCULATE_REQUEST' | 'CALCULATOR_FINANCING_RECALCULATE_REQUEST' | 'ASSET_ADDED_LISTING_DESCRIPTION';
export type TimelineSection = 'CALCULATOR' | 'ASSET';

export const slugMapping: Record<TimelineSlug, string> = {
  CALCULATOR_TENDERING_RECALCULATE_REQUEST: 'Recalculation requested by',
  CALCULATOR_FINANCING_RECALCULATE_REQUEST: 'Recalculation requested by',
  ASSET_ADDED_LISTING_DESCRIPTION: 'Project Description Edited by'
}

export interface TimelineData {
  financingRequest?: TimelineFinancingRequest;
  tenderingRequest?: TimelineTenderingRequest;
  scenariosApplied?: number[];
}

export interface Timeline {
  _id: string;
  section: TimelineSection;
  projectId: string;
  leadId: string;
  slug: TimelineSlug;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  message?: string;
  data?: TimelineData;
  userName?: string;
}

export type ProjectGetResponse = {
  isOwned: boolean;
  project: Project;
  scenario: Scenario & { _id: string };
  notes: Note[];
  files: File[];
  users: Users;
  allScenarios: ScenarioShort[];
  timeline: Timeline[];
} | { error: string };

export interface ProjectItem {
  _id: string;
  isOpened?: boolean;
  userId: string;
  updatedAt: Date;
  createdAt: Date;
  name: string;
  ip: string;
  geography: any;
  scenarios: Scenario[];
}

export interface ReportList {
  metadata: Array<{
    total: number,
    page: number,
  }>,
  projects: ProjectItem[]
}

export type ReportListResponse = ReportList | { error: string };


export interface LocalMarketDistribution {
  [range: string]: number;
}

export type LocalMarketTimeSeries = Array<{
  month: string;
  median: number;
  count: number;
  movingAverage: number;
}>;

export interface LocalMarketMappingValues {
  result: Array<{
    color: string;
    price: number;
    latitude: number;
    longitude: number;
  }>;
  bbox: [
    [number, number],
    [number, number],
    [number, number],
    [number, number],
  ];
}

export interface LocalMarket {
  narrative?: string;
  hint: string;
  market: {
    quartile50: number;
    quartile75: number;
    quartile90: number;
    total: number;
  };
  region?: {
    quartile50: number;
    quartile75: number;
    quartile90: number;
  };
  distribution: LocalMarketDistribution;
  timeSeries: LocalMarketTimeSeries;
  marketMappingValues: LocalMarketMappingValues;
}

export type LocalAreaStatType = 'population' | 'median-income' | 'unemployment-rate' | 'housing';

export type LocalAreaStatTable = (string | number)[][];
export interface LocalAreaStatMigration {
  in_migration: {
    from_other_parts_of_stockholm: number;
    from_other_parts_of_the_county: number;
    from_other_parts_of_sweden: number;
    from_abroad: number;
    total_influx: number;
  };
  out_migration: {
    to_other_parts_of_stockholm: number;
    to_other_parts_of_the_county: number;
    to_other_parts_of_sweden: number;
    to_abroad: number;
    total_outflow: number;
  };
  net_migration: number;
}

interface LocalAreaDistrictInfo {
  name: string;
  population: LocalAreaStatTable;
  populationChanges: LocalAreaStatTable;
  foreignBackground: {
    byLocation: LocalAreaStatTable;
    byType: LocalAreaStatTable;
  };
  migration: LocalAreaStatMigration;
  highestEducationLevel: LocalAreaStatTable;
  medianIncome: LocalAreaStatTable;
  openUnemploymentRate: LocalAreaStatTable;
  housingByTypeAndOwnership: LocalAreaStatTable;
  housingInMultiDwellingAndOtherBuildingBySize: LocalAreaStatTable;
}

export interface LocalArea {
  title: string;
  narrative: string;
  locality: string;
  population: number;
  local: {
    meanIncomeLocal: number;
    medianAgeLocal: number;
    crimesPer10000Local: number;
  };
  region: {
    meanIncomeRegion: number
    medianAgeRegion: number;
    crimesPer10000Region: number;
  };
  districtInfo?: LocalAreaDistrictInfo;
}

export interface Station {
  name: string
  address: string;
  distance: number;
  coordinates: {
    type: string;
    geometry: {
      type: string;
      coordinates: [
        number,
        number,
      ];
    };
  };
  closestDestination: {
    name: string;
    priority: number;
    trainCounter: number;
    meanTripTimeToClosest: number;
  };
  time: {
    duration: number;
    unit: string;
    message: string;
  };
}

export interface School {
  school: string;
  type: string;
  coordinates: {
    type: string;
    geometry: {
      type: string;
      coordinates: [
        number,
        number,
      ]
    }
  };
  ofstedRating: string;
  totalNumberOfPupils: number;
  distance: number;
  time: {
    duration: number;
    unit: string;
    message: string;
  };
}
export const projectTypes = ['New build apartments', 'New build houses', 'New build houses and apartments', 'Investment', 'Conversion', 'Permitted development'] as const;

export type ProjectType = typeof projectTypes[number];
