import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupOpen } from '../../../../../redux/calculate/calculate';
import { RootState } from '../../../../../redux/store';
import PopupWrapper from '../../sidebar/shared/components/popup-wrapper/popup-wrapper';
import { Button, Buttons, Content, Header, Popup, Tab, Tabs } from './style';
import { EducationLevelSection, ForeignBackgroundByLocationSection, ForeignBackgroundByTypeSection, HousingByTypeSection, MedianIncomeSection, Migration, PopulationChangesSection, PopulationSection, UnemploymentRateSection } from './sections';
import { LocalAreaStatType } from '../../../../../../../types';
import { HousingBySizeSection } from './sections/housing-by-size';

const tabs: Record<LocalAreaStatType, string> = {
  'population': 'Population',
  'median-income': 'Median income',
  'unemployment-rate': 'Unemployment rate',
  'housing': 'Housing',
};

interface Props {
  type: LocalAreaStatType;
}

export const LocalAreaStat = ({ type }: Props) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState<LocalAreaStatType>(type);

  const districtInfo = useSelector((state: RootState) => state.calculate.analysePage.localArea?.data?.districtInfo);

  return <PopupWrapper onOutssideClick={() => console.log()}>
    <Popup>
      <Header>Details</Header>
      <Content>
        <Tabs>
          {Object.entries(tabs).map(([key, value]) => <Tab key={key} active={tab === key} onClick={() => setTab(key as LocalAreaStatType)}>{value}</Tab>)}
        </Tabs>
        {tab === 'population' && <>
          {districtInfo?.population && <PopulationSection data={districtInfo?.population} />}
          {districtInfo?.populationChanges && <PopulationChangesSection data={districtInfo?.populationChanges} />}
          {districtInfo?.foreignBackground?.byType && <ForeignBackgroundByTypeSection data={districtInfo?.foreignBackground?.byType} />}
          {districtInfo?.foreignBackground?.byLocation && <ForeignBackgroundByLocationSection data={districtInfo?.foreignBackground?.byLocation} />}
          {districtInfo?.migration && <Migration data={districtInfo?.migration} />}
          {districtInfo?.highestEducationLevel && <EducationLevelSection data={districtInfo?.highestEducationLevel} />}
        </>}
        {tab === 'median-income' && districtInfo?.medianIncome && <MedianIncomeSection data={districtInfo?.medianIncome} />}
        {tab === 'unemployment-rate' && districtInfo?.openUnemploymentRate && <UnemploymentRateSection data={districtInfo?.openUnemploymentRate} />}
        {tab === 'housing' && <>
          {districtInfo?.housingByTypeAndOwnership && <HousingByTypeSection data={districtInfo?.housingByTypeAndOwnership} />}
          {districtInfo?.housingInMultiDwellingAndOtherBuildingBySize && <HousingBySizeSection data={districtInfo?.housingInMultiDwellingAndOtherBuildingBySize} />}
        </>}
      </Content>
      <Buttons>
        <div>
          <Button variant='cancel' onClick={() => dispatch(setPopupOpen(null))}>Close</Button>
        </div>
      </Buttons>
    </Popup>
  </PopupWrapper>;
};
