import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as ExpandImage } from '../../images/expand.svg';
import { ReactComponent as CheckImage } from './images/check.svg';
import { ReactComponent as FailImage } from './images/fail.svg';
import { ReactComponent as StarImage } from './images/star.svg';

const { palette } = theme;

export const CollapsibleItem = styled.div<{ background?: string }>`
  background: ${(p) => p.background || 'white'};
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 100%;

  .desktop .adaptive && {
    margin: 10px 0;
  }

  .mobile .adaptive &&, .tablet .adaptive && {
    margin: 30px 10px;
    width: calc(100% - 20px);
  }
`;

export const Head = styled.div<{horizontalPadding?: number}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px ${(p) => p.horizontalPadding || 30}px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0 10px 0 0;
`;

export const Check = styled(CheckImage)`
  width: 24px;
  height: 24px;
  margin-right: 17px;
  animation: fade-in-collapsible-check .2s ease-in-out forwards;
  @keyframes fade-in-collapsible-check {
    0% { transform: scale(0.1) rotate(90deg); }
    100% { transform: none; }
  }
`;

export const Fail = styled(FailImage)`
  width: 24px;
  height: 24px;
  margin-right: 17px;
  animation: fade-in-collapsible-fail .2s ease-in-out forwards;
  @keyframes fade-in-collapsible-fail {
    0% { transform: scale(0.1) rotate(90deg); }
    100% { transform: none; }
  }
`;

export const Star = styled(StarImage)`
  width: 22px;
  height: 22px;
  margin-left: 17px;
`;

export const Trigger = styled.div<{expanded?: boolean}>`
  padding: 3px;
  ${(p) => p.expanded ? `
    transform: rotate(180deg);
    transform-origin: center center;
  ` : ''}
`;

export const TriggerIcon = styled(ExpandImage)`
  width: 16px;
  height: 10px;
`;

export const Content = styled.div<{hidden?: boolean; paddingBottom?: number; background?: string; noPadding?: boolean; horizontalPadding?: number}>`
  & > div {
    padding: ${(p) => p.noPadding ? '0' : `0 ${p.horizontalPadding || 30}px`};
  }
  background: ${(p) => p.background || 'white'};
  padding-bottom: ${(p) => p.paddingBottom ?? '20px'};
  ${(p) => p.hidden ? 'display: none;' : '' }
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

export const Subtotal = styled.div`
  .mobile .adaptive &&, .tablet .adaptive && {
    display: flex;
    flex-direction: column;
    margin-right: 5px;

    > span {
      font-size: 12px;

      :first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  margin-right: 20px;

  > span {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    :first-of-type {
      color: ${palette.black};
      margin-right: 10px;
    }

    :not(:first-of-type) {
      color: ${palette.blue};
    }
  }
`;
