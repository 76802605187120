import React from 'react';
import { Wrapper } from './style';
import { ReactComponent as EnterImage } from './images/enter.svg';

type Mode = 'register' | 'login' | 'register or login';

const modeTitles = {
  'register': 'Sign up',
  'login': 'Log in',
  'register or login': 'Log in / Sign up',
};

interface Props {
  color: string;
  mode: Mode;
  onClick?: () => void;
}

export const RegisterOrLoginButton = ({ color, mode, onClick }: Props) => {
  return <Wrapper color={color} onClick={onClick}>
    <div>{modeTitles[mode]}</div>
    <EnterImage />
  </Wrapper>;
};
