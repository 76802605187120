import { AveragePricesStatPropertyType } from './average-prices-stat';
import { UnitType, FinishType } from './calculator/units';

export const averagePriceTypes = ['sale', 'rent'] as const;

export type AveragePriceType = typeof averagePriceTypes[number];

const averagePricePropertyTypes = ['room', 'flats', 'terraced', 'detached', 'semi-detached'] as const;

export type AveragePricePropertyType = typeof averagePricePropertyTypes[number];

const averagePriceFinishTypes = [ 'Simple finish', 'Lower-end finish', 'Market average', 'High-end finish', 'Luxury finish' ] as const;

export type AveragePriceFinishType = typeof averagePriceFinishTypes[number];

type AveragePricePropertyTypesMapping = {
  // eslint-disable-next-line no-unused-vars
  [type in UnitType]?: AveragePricePropertyType;
}

type AveragePricePropertyTypesReverseMapping = {
  // eslint-disable-next-line no-unused-vars
  [type in AveragePricePropertyType]?: UnitType;
}

export const averagePricePropertyTypesMapping: AveragePricePropertyTypesMapping = {
  'Detached': 'detached',
  'Semi-detached': 'semi-detached',
  'Terraced': 'terraced',
  'Apartment': 'flats',
};

export const averagePricePropertyTypesReverseMapping: AveragePricePropertyTypesReverseMapping = {
  'terraced': 'Detached',
  'flats': 'Apartment',
  'detached': 'Detached',
  'semi-detached': 'Semi-detached',
};

type AveragePriceFinishTypesMapping = {
  // eslint-disable-next-line no-unused-vars
  [type in FinishType]?: AveragePriceFinishType;
}

export const averagePricePropertyFinishMapping: AveragePriceFinishTypesMapping = {
  'Basic': 'Simple finish',
  'Basic plus': 'Lower-end finish',
  'Market Average': 'Market average',
  'Premium': 'High-end finish',
  'Luxury': 'Luxury finish',
};

export interface AveragePricesPlatformItem {
  averagePrice: number;
  minPrice: number;
  maxPrice: number;
  count: number;
  rooms: number;
  medianPrice: number;
  propertyType: AveragePricePropertyType;
  radius: string;
}

export const averagePricesPlatformTypeMapping = (type: AveragePricePropertyType) => (type === 'flats' ? 'flat' : type) as AveragePricesStatPropertyType;
