import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { rounding } from '../../shared';
import { BaseCell, Header, Container, FullscreenButton } from './style';
import { CashflowPopup, SubSectionRow } from './components';
import { ReactComponent as FullscreenImage } from './images/fullscreen.svg';

export interface SubSection {
  title?: string;
  values?: string[];
  subSections?: Array<{
    title?: string;
    values?: string[];
    totalValue?: boolean;
  }>
}

interface RowProps {
  title?: string;
  values?: string[];
  subSections?: SubSection[];
  total?: {
    title?: string;
    values?: string[];
  };
}

export interface CashflowTableProps {
  data: RowProps[];
  header: string[];
  width?: string;
  margin?: string;
  noFullscreen?: boolean;
}

export const CashflowTable = ({ data, header, width, margin, noFullscreen }: CashflowTableProps) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  const [isScrolled, setIsScrolled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const handleScroll = () => {
      if (element) {
        setIsScrolled(element.scrollLeft > 0);
      }
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return <>
    {showPopup && <CashflowPopup {...{ data, header, width, margin }} onClose={() => setShowPopup(false)} />}
    <Container ref={ref} width={width} margin={margin} fullscreen={!noFullscreen}>
      <table>
        <thead>
          <tr>
            <Header isScrolled={isScrolled}>
              <div>
                {!noFullscreen && <FullscreenButton onClick={() => setShowPopup(true)}>
                  <FullscreenImage />
                </FullscreenButton>}
              </div>
            </Header>
            {header.map((item, i) => <Header key={i}><div>{item}</div></Header>)}
          </tr>
        </thead>
        <tbody>
          {data?.map?.((item, i) => <React.Fragment key={i}>
            <tr key={i}><BaseCell isScrolled={isScrolled}><div title={item.title}>{`${i + 1}. ${item.title}`}</div></BaseCell></tr>
            <SubSectionRow data={item.subSections} isScrolled={isScrolled}/>
            <tr>
              <BaseCell isTotal isScrolled={isScrolled}><div>Total</div></BaseCell>
              {item.values?.map((item, i) => <BaseCell key={i} isTotal>
                <div>
                  {Number.isNaN(item) ? item : rounding(item)}
                </div>
              </BaseCell>)}
            </tr>
          </React.Fragment>)}
        </tbody>
      </table>
    </Container>
  </>;
};
