import { ProjectType, ResidentialUnit, Unit, UnitType } from "../../../types";

export const unitIsResidental = (unit: Unit): unit is ResidentialUnit => {
  return !unit.type || unit.type === 'residential';
};

export const getTotalSales = (units: Unit[]) => units.filter((unit) => unit.property_type !== 'BRF Loan (Sweden only)')
  .reduce((acc, cur) => acc + (unitIsResidental(cur) ? (cur.price_per_unit || 0) : (cur.price || 0)) * (cur.number || 0), 0);

export const getUnitTypesByProjectType = (projectType?: ProjectType): UnitType[] => {
  if ((projectType || 'New build houses and apartments') === 'New build houses and apartments') {
    return ['Apartment', 'Detached', 'Semi-detached', 'Terraced'];
  } else if (projectType === 'New build houses') {
    return ['Detached', 'Semi-detached', 'Terraced'];
  } else {
    return ['Apartment'];
  }
};
