import styled from 'styled-components';
import { ReactComponent as CloseImage } from './images/close.svg';
import { ReactComponent as PictureCloseImage } from './images/picture-close.svg';
import theme from '../../../themes';
import TextareaAutosize from 'react-textarea-autosize';

const { palette } = theme;

type Padding = [number, number, number, number];

export const BackgroundBlur = styled.div<{ noBlur?: boolean; notFixed?: boolean; parentDOMRect?: { top: number, right: number, bottom: number, left: number } }>`
  ${(p) => !p.notFixed ? `
    position: fixed;
  ` : ''}
  overflow-y: scroll;
  top: ${(p) => p.parentDOMRect ? -Math.max(p.parentDOMRect.top, p.parentDOMRect.bottom) : 0}px;
  left: ${(p) => p.parentDOMRect ? -p.parentDOMRect.left : 0}px;
  right: ${(p) => p.parentDOMRect ? -p.parentDOMRect.right : 0}px;
  bottom: ${(p) => p.parentDOMRect ? -Math.max(p.parentDOMRect.top, p.parentDOMRect.bottom) : 0}px;
  z-index: 10000;
  ${(p) => p.noBlur ? '' : `
    backdrop-filter: blur(5px);

    @supports ( not ( backdrop-filter: blur(5px) ) ) {
      background: #ffffff85;
    }
  `}

  /* overflow: hidden; */

  .mobile .adaptive &&, .tablet .adaptive && {
    overflow-y: scroll;
    padding-top: 30px;
  }
`;

interface WindowProps {
  padding: Padding;
  title?: string;
  size?: [number | null, number | null];
  paddingTitle?: number;
  maxHeight?: number;
  topOffset?: number;
  noMarginTop?: boolean;
  maxWidthMobile?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

export const Window = styled.div<WindowProps>`
  top: ${(p) => p.top || '50%'};
  left: ${(p) => p.left || '50%'};
  right: ${(p) => p.right || 'auto'};
  bottom: ${(p) => p.bottom || 'auto'};
  background-color: white;
  border-radius: 15px;
  box-shadow: 10px 10px 40px #d3e6f2;
  animation: fade-in-popup .5s ease-in-out forwards;
  position: relative;
  :after {
    content: '${(p) => p.title}';
    display: ${(p) => p.title ? 'flex' : 'none'};
    padding-left: ${(p) => p.paddingTitle || p.padding[3]}px;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 59px;
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: ${palette.grayDark};
    border-bottom: 2px solid ${palette.grayBg};
  }
  @keyframes fade-in-popup {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
  ${(p) => p.size ? `
    position: absolute;
    width: ${p.size[0] ? `${p.size[0]}px` : '90%'};
    height: ${p.size[1] ? `${p.size[1]}px` : '80%'};
    max-width: ${p.size[0]}px;
    max-height: ${p.size[1]}px;
  ` : `
    position: fixed;
  `}
  ${(p) => p.left || p.right || p.top || p.bottom ? '' : `
    transform: translate(-50%, -50%);
  `}
  ${(p) => p.maxHeight && `
    max-height: ${p.maxHeight}px;
  `}

  .mobile .adaptive && {
    width: 98%;
    margin-left: ${window.innerWidth < 500 ? - window.innerWidth / 2 + 5 : -250}px;
    max-width: ${(p) => p.maxWidthMobile || '500px'};

    ${(p) => !p.size && `
      margin-left: unset;
    `}

    ${(p) => p.noMarginTop && `
      top: unset;
      margin-top: unset;
    `}
  }
`;

export const CloseButton = styled(CloseImage).withConfig({
  shouldForwardProp: (prop) => !['hasTitle', 'closeButtonTop'].includes(prop),
})<{ hasTitle?: boolean; color?: string; closeButtonTop?: number }>`
  cursor: pointer;
  position: absolute;
  top: ${(p) => p.hasTitle ? 25: 40}px;
  right: 40px;
  z-index: 5;
  fill: ${(p) => p.color || '#828D94'};

  ${(p) => p.closeButtonTop && `
    top: ${p.closeButtonTop}px;
  `}
`;

export const Content = styled.div<{padding: Padding; noMaxHeight?: boolean}>`
  padding: ${(p) => p.padding.map((item) => `${item}px`).join(' ')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  a {
    text-decoration: underline;
  }

  ${(p) => !p.noMaxHeight ? 'max-height: 900px;' : ''}
`;

export const PopupTitle = styled.div<{ width?: string; marginTop?: string }>`
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 900;
  margin: 0;
  margin-top: ${(p) => p.marginTop || 0};
  margin-bottom: 15px;
  padding: 0;
  width: ${(p) => p.width || 'auto'};
`;

export const PopupSubTitle = styled.div<{ width?: string; marginTop?: string; marginBottom?: string; fontSize?: string }>`
  font-size: ${(p) => p.fontSize || '14px'};
  font-weight: 400;
  color: ${palette.grayDark};
  margin-top: ${(p) => p.marginTop || 0};
  margin-bottom: ${(p) => p.marginBottom || 0};
  padding: 0;
`;

export const PopupInputError = styled.div<{ marginBottom?: string }>`
  font-size: 80%;
  font-weight: 400;
  color: ${palette.grayDark};
  margin: 0;
  margin-bottom: ${(p) => p.marginBottom || 0};
  padding: 0;
  text-align: right;
`;

export const PopupDecoratedText = styled.div<{ marginBottom?: string }>`
  font-size: 18px;
  font-weight: 600;
  color: ${palette.grayDark};
  margin: 0;
  margin-bottom: ${(p) => p.marginBottom || 0};
  padding: 0;
`;

export const PopupText = styled.div<{ marginTop?: string }>`
  font-size: 18px;
  font-weight: 700;
  color: #1E2427;
  margin: 0;
  margin-top: ${(p) => p.marginTop || 0};
  padding: 0;
`;

export const Picture = styled.div<{ url: string }>`
  display: inline-block;
  width: 160px;
  height: 102px;
  border-radius: 15px;
  background-image: ${(p) => `url(${p.url})`};
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 19px;
`;

export const PictureHover = styled.div`
  display: inline-block;
  width: 160px;
  height: 102px;
  border-radius: 15px;
  background-color: #000000;
  opacity: 0.0;

  :hover {
    opacity: 0.3;
    cursor: pointer;
  }
`;

export const PictureCloseButton = styled(PictureCloseImage)`
  cursor: pointer;
  margin-top: 11px;
  margin-left: 139px;
`;

export const TextArea = styled(TextareaAutosize).withConfig({
  shouldForwardProp: (prop) => !['height'].includes(prop as string),
})<{height?: number; fontSize?: number; error?: boolean}>`
  border-style: none;
  border-bottom-style: solid;
  width: 100%;
  max-height: 200px;
  resize: none;
  font-size: ${(p) => `${p.fontSize || 14}px`};
  font-weight: 400;
  padding: 0;

  :focus {
    outline: none;
  }

  ${(p) => p.height ? `
    min-height: ${p.height}px;
    max-height: ${p.height}px;
    overflow-y: scroll;
    overflow-x: hidden;
  ` : ''}

  ${(p) => p.error ? `
    border-bottom: 1px solid #db7070;
  ` : ''}
`;

export const TextAreaCounter = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: ${palette.grayDark}
`;
