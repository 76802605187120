import { SquareUnit } from './calculator';

export const countryList = ['UK', 'SWE', 'DEU', 'ESP'] as const;

export type Country = typeof countryList[number];

export interface LocaleOptions {
  lang?: string;
  currency?: string;
  distance?: string;
  square?: string;
  decimalSeparator?: string;
  thousandsSeparator?: string;
}

export const currencyList = ['EUR', 'GBP', 'SEK', 'USD'] as const;

export type Currency = typeof currencyList[number];

export const currencyByCountry: Record<Country, Currency> = {
  'UK': 'GBP',
  'SWE': 'SEK',
  'ESP': 'EUR',
  'DEU': 'EUR',
};

export const  squareUnitByCountry: Record<Country, SquareUnit> = {
  'UK': 'Sq.ft',
  'SWE': 'Sq.m',
  'ESP': 'Sq.m',
  'DEU': 'Sq.m',
};

export const langByCurrency: Record<Currency, string> = {
  'EUR': 'en-UK',
  'GBP': 'en-UK',
  'SEK': 'sv-SE',
  'USD': 'en-UK',
};
