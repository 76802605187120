import React, { PropsWithChildren } from 'react';

interface Props {
}

export const ComingSoon = ({ children }: PropsWithChildren<Props>) => {
  return <div>
    {children}
  </div>;
};
