export const debtFinanceTypeOptions = [
  { value: 'Senior debt', label: 'Senior' },
  { value: 'Junior debt', label: 'Junior' },
  { value: 'Bridge debt', label: 'Bridge' },
  { value: 'Other debt', label: 'Other' },
];
export const debtRatioTypeOptions = [
  { value: 'LTC', label: 'LTC' },
  // { value: 'LTGDV', label: 'LTGDV' },
  // { value: 'LTP', label: 'LTP' },
  // { value: 'LTCC', label: 'LTCC' },
];

export const debtDrawdownsTypeOptions = [
  { value: 'single', label: 'Single at start' },
  { value: 'single-when-needed', label: 'Single as required' },
  { value: 'periodic', label: 'Periodic' },
  // { value: 'Quarterly as required', label: 'Quarterly as required' },
  // { value: 'Monthly as required', label: 'Monthly as required' },
  // { value: '2 as required', label: '2 as required' },
  // { value: '3 as required', label: '3 as required' }
];
export const debtSecurityTypeOptions = [
  { value: 'First charge', label: '1st charge' },
  { value: 'Second charge', label: '2st charge' },
];
export const debtRepaymentTypeOptions = [
  { value: 'single', label: 'Single' },
  // { value: 'Monthly as able', label: 'Monthly as able' },
  // { value: '2 as able', label: '2 as able' },
  // { value: t3 as able', label: '3 as able' }
];
export const equityFinanceTypeOptions = [
  { value: 'JV Equity', label: 'JV Equity' },
  { value: "Developer's equity", label: 'Developer’s equity' },
];
export const equityRatioTypeOptions = [
  { value: 'LTC', label: 'LTC' },
  // { value: 'LTGDV', label: 'LTGDV' },
  // { value: 'LTP', label: 'LTP' },
  // { value: 'LTCC', label: 'LTCC' },
];
export const equityDrawdownsTypeOptions = [
  { value: 'single', label: 'Single at start' },
  { value: 'single-when-needed', label: 'Single as required' },
  { value: 'periodic', label: 'Periodic' },
  // { value: 'Quarterly as required', label: 'Quarterly as required' },
  // { value: 'Monthly as required', label: 'Monthly as required' },
  // { value: '2 as required', label: '2 as required' },
  // { value: '3 as required', label: '3 as required' }
];
export const equitySecurityTypeOptions = [
  { value: '1st charge', label: '1st charge' },
  { value: '2st charge', label: '2st charge' },
];
export const otherFinanceTypeOptions = [{ value: 'Delayed payment', label: 'Delayed payment' }];
export const otherRatioTypeOptions = [
  { value: 'LTC', label: 'LTC' },
  { value: 'LTGDV', label: 'LTGDV' },
  { value: 'LTP', label: 'LTP' },
  { value: 'LTCC', label: 'LTCC' },
];
export const otherSecurityTypeOptions = [
  { value: '1st charge', label: '1st charge' },
  { value: '2nd charge', label: '2nd charge' },
];
export const otherRepaymentTypeOptions = [
  { value: 'On exit', label: 'On exit' },
];
export const rolledUpInterestOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];
