import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui';
import { Input } from '../../input';
import { Popup, PopupInputError } from '../popup';

interface Props {
  type: 'report' | 'scenario';
  mobile?: boolean;
  onSubmit?: (email: string) => void;
  onClose?: () => void;
}

export const SendViaEmail = ({ type, mobile, onSubmit, onClose }: Props) => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const submit = useCallback(() => {
    if ( email.match(/^.*@.*\..*$/) ) {
      onSubmit?.(email);
    } else {
      setError('Bad email format');
    }
  }, [email, onSubmit]);

  useEffect(() => setError(''), [email]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        submit();
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [submit]);

  return <Popup
    title='Send via email'
    size={[382, 276]}
    padding={mobile ? [83, 15, 32, 15] : [83, 30, 32, 30]}
    onClose={onClose}
    maxWidthMobile='calc(100vw - 10px)'
  >
    <div>
      <Input
        placeholder='Enter email address'
        setOnTyping
        value={email}
        onChange={(value: string) => setEmail(value)}
      />
      <PopupInputError>&nbsp;{error}</PopupInputError>
    </div>
    <Button width='100%' onClick={submit}>{`Send ${type}`}</Button>
  </Popup>;
};
