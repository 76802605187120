import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as AutoImage } from './images/auto.svg';
import { ReactComponent as CalendarImage } from './images/calendar.svg';
import { ReactComponent as PenImage } from './images/pen.svg';

const { palette } = theme;

interface InputWrapperProps {
  error?: boolean;
  isAutoCalculated?: boolean;
  marginBottom?: number;
  marginTop?: number;
  color?: string;
  colorOnHover?: string;
  title?: string;
  minWidth?: number;
  maxWidth?: number;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  color: ${(p) => p.isAutoCalculated ? palette.greenDark : palette.grayDark};
  ${(p) => p.error ? `color: ${palette.red};` : ''}
  ${(p) => p.color ? `color: ${p.color};` : ''}
  ${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  ` : ''}
  ${(p) => p.marginTop ? `
    margin-top: ${p.marginTop}px;
  ` : ''}
  :hover {
    ${(p) => p.colorOnHover ? `color: ${p.colorOnHover};` : ''}
  }

  ${(p) => p.minWidth ? `
    min-width: ${p.minWidth}px;
  ` : ''}

  ${(p) => p.maxWidth ? `
    max-width: ${p.maxWidth}px;
  ` : ''}
`;

interface FieldContainerProps {
  error?: boolean;
  disabled?: boolean;
  expert?: boolean;
  noPadding?: boolean;
  noHorizontalPadding?: boolean;
  noUnderline?: boolean;
  unitSize?: number;
  auto?: boolean;
}

export const FieldContainer = styled.div<FieldContainerProps>`
  margin: ${(p) => p.noPadding ? 0 : '20px'} 0;
  width: 100%;
  opacity: ${(p) => p.disabled ? .5 : 1};
  text-align: left;
  border-bottom: 1px solid ${(p) => {
    if ( p.noUnderline ) {
      return 'transparent';
    } else if ( p.error ) {
      return palette.red;
    } else {
      return p.expert ? palette.greenMedium : palette.grayMedium;
    }
  }};
  padding: 5px ${(p) => p.auto ? '27px' : 0} 5px ${(p) => p.noHorizontalPadding ? '0' : `${p.unitSize ? p.unitSize + 5 : 12}px`};
  ${(p) => p.error ? `color: ${palette.red};` : ''}
`;

export const Field = styled.input`
  width: 100%;
  border: none;
  outline: none;
  color: inherit;
  background: transparent;
`;

export const Placeholder = styled.p<{ active?: boolean; isDefaultCursor?: boolean; disabled?: boolean; expert?: boolean; noPadding?: boolean }>`
  position: absolute;
  top: ${(p) => p.active ? 2 - (p.noPadding ? 12 : 0) : 23 - (p.noPadding ? 16 : 0)}px;
  left: 0;
  font-size: ${(p) => p.active ? 12 : 14}px;
  font-weight: 400;
  opacity: ${(p) => p.disabled ? .7 : 1};
  color: ${(p) => p.expert ? palette.greenMedium : palette.grayDark};
  transition: .3s all ease;
  cursor: ${(p) => (p.active || p.isDefaultCursor) ? 'default' : 'text'};
  user-select: none;
  span {
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Unit = styled.div<{ disabled?: boolean; hasPlaceholder?: boolean; noPadding?: boolean; after?: boolean }>`
  position: absolute;
  top: ${(p) => (!p.hasPlaceholder || p.noPadding) ? 5 : 25}px;
  ${(p) => p.after ? `
    right: 0;
  ` : `
    left: 0;
  `}
  user-select: none;
  opacity: ${(p) => p.disabled ? .7 : 1};
`;

interface AutoWrapperProps {
  isAutoCalculated?: boolean;
  color?: string;
  showHint?: boolean;
  hintTop?: number;
  hintLeft?: number;
  hintWidth?: number
  noPadding?: boolean;
}

export const AutoWrapper = styled.div<AutoWrapperProps>`
  position: absolute;
  right: 0;
  top: ${(p) => p.noPadding ? 2 : 22}px;
  height: 18px;
  width: 24px;
  padding: 3px;
  cursor: pointer;
  ${(p) => !p.isAutoCalculated ? `
    filter: grayscale(100%);
  ` : ''};
  :hover {
    & > div:last-of-type {
      display: ${(p) => p.showHint ? 'flex' : 'none'};
    }
  }
  & > div:last-of-type {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    position: absolute;
    left: ${(p) => p.hintLeft || -0}px;
    top: ${(p) => p.hintTop || -190}px;
    border-bottom-left-radius: 15px;
    font-size: 12px;
    font-weight: 600;
    color: ${palette.black};
    background: white;
    box-shadow: 10px 10px 40px rgba(29, 59, 78, 0.15);
    border-left: 1px solid ${(p) => p.color || palette.greenMedium};
    animation: fade-in-menu-hint .3s ease-in-out forwards;
    z-index: 40;
    ${(p) => p.hintWidth && `
      min-width: ${p.hintWidth}px;
    `}
    @keyframes fade-in-menu-hint {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }

    > div:last-of-type {
      font-weight: 400;
      margin-top: 10px;
      color: ${palette.grayMedium};
    }
  }

  > svg {
    > circle {
      stroke: ${(p) => p.color || palette.greenMedium};
    }

    > path {
      fill: ${(p) => p.color || palette.greenMedium};
    }
  }
`;

export const Auto = styled(AutoImage)`
  width: 18px;
  height: 19px;
`;

export const SignWrapper = styled.div<{ hasPlaceholder?: boolean }>`
  position: absolute;
  right: -7px;
  top: ${(p) => p.hasPlaceholder ? 22 : 2}px;
  padding: 3px;
`;

export const InlineWrapper = styled.div<{ right?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.right ? 'flex-end' : 'flex-start'};
  cursor: pointer;
  svg {
    margin-left: 10px;
    margin-right: 20px;
  }
`;

export const Pen = styled(PenImage)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

export const Cal = styled(CalendarImage)`
  width: 14px;
  height: 14px;
`;

export const Mandatory = styled.span`
  color: ${palette.blue};
  padding-right: 5px; 
`;
