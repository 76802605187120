import React, { useState } from 'react';
import { SubSection } from '../../cashflow-table';
import { Arrow, SubSectionCell } from './style';
import { rounding } from '../../../../shared';

interface SubSectionRowProps {
  data?: SubSection[];
  isScrolled?: boolean;
}

export const SubSectionRow = ({ data, isScrolled }: SubSectionRowProps) => {
  const [isOpen, setIsOpen] = useState<Array<number>>([]);

  return <>
    {data?.map?.((item, i) => <React.Fragment key={i}>
      <tr key={i} onClick={() => setIsOpen(isOpen.includes(i) ? isOpen.filter((num) => num !== i) : [...isOpen, i])}>
        <SubSectionCell
          isTitle
          cellPosition={i === 0 ? 'top-left' : (i === data.length - 1 ? 'bottom-left' : undefined)}
          isScrolled={isScrolled}
          clickable={!!item.subSections}
        >
          <div>
            {item.subSections && <Arrow isOpen={isOpen.includes(i)} />}
            <span title={item.title}>{item.title}</span>
          </div>
        </SubSectionCell>
        {item.values?.map((value, j) => <SubSectionCell
          key={j}
          cellPosition={j === (item.values?.length || 0) - 1 && i === 0 ? 'top-right' : (j === (item.values?.length || 0) - 1 && i === data.length - 1 ? 'bottom-right' : undefined)}
        >
          <div>
            {!isOpen.includes(i) || !item.subSections ? (Number.isNaN(value) ? value : rounding(value)) : ''}
          </div>
        </SubSectionCell>)}
      </tr>
      {item.subSections && isOpen.includes(i) && item.subSections.map((subSection, j) => <tr key={j}>
        <SubSectionCell
          isTitle
          isSubSectionTitle
          isTotal={subSection.totalValue}
          isScrolled={isScrolled}
        >
          <div title={subSection.title}><span>{subSection.title}</span></div>
        </SubSectionCell>
        {subSection.values?.map((value, j) => <SubSectionCell key={j} isTotal={subSection.totalValue}>
          <div>{rounding(value)}</div>
        </SubSectionCell>)}
      </tr>)}
    </React.Fragment>)}
  </>;
};
