import React, { useEffect } from 'react';

interface Props {
  trackingCode: string;
}

export const HubSpotTracker = ({ trackingCode }: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://js.hs-scripts.com/${trackingCode}.js`;
    document.querySelectorAll('head')[0].append(script);
  }, [trackingCode]);

  return <></>;
};
