import * as Browserslist from 'browserslist-useragent';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { default as packageJson } from '../package.json';
import { AppStyle } from './app.style';
import { Error, UserSnapWidget, HubSpotTracker } from './components';
import { Actions } from './redux';
import { RootState } from './redux/store';
import PublicRoutes from './router';
import { useLocalStorage } from './shared/hooks';

const App = () => {

  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.Auth.status);
  const config = useSelector((state: RootState) => state.App.config);
  const layout = useSelector((state: RootState) => state.App.layout);
  const configStatus = useSelector((state: RootState) => state.App.configStatus);
  const configError = useSelector((state: RootState) => state.App.configError);
  const userId = useSelector((state: RootState) => state.Auth.user?.id);
  const userEmail = useSelector((state: RootState) => state.Auth.user?.email);

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth < 600) {
        dispatch(Actions.App.setLayout('mobile'));
      } else if (window.innerWidth < 900) {
        dispatch(Actions.App.setLayout('tablet'));
      } else {
        dispatch(Actions.App.setLayout('desktop'));
      }
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [dispatch]);

  const [browserApproved, setBrowserApproved] = useLocalStorage<boolean | undefined>('app.browserApproved', undefined, true);

  useEffect(() => {
    if (!browserApproved) {
      try {
        const browsers = packageJson?.browserslist[process.env.NODE_ENV || 'production'] as string[];
        if (!Browserslist.matchesUA(navigator.userAgent, { allowHigherVersions: true, browsers })) {
          const { family, version } = Browserslist.resolveUserAgent(navigator.userAgent);
          console.warn(`Possible unsupported browser ${family} version ${version}${browsers ? `. Supported browsers: ${browsers.join(', ')}` : ''}`);
          setBrowserApproved(false);
        } else {
          setBrowserApproved(true);
        }
      } catch (error) {
        console.error(error);
        setBrowserApproved(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserApproved]);

  useEffect(() => {
    if (status === 'new') {
      dispatch(Actions.Auth.authInitAction());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if ( config === undefined ) {
      dispatch(Actions.App.loadConfig());
    }
  }, [config, dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(Actions.Template.loadTemplates());
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if ( status === 'success' && !!userId && !!userEmail) {
      // @ts-ignore
      window._hsq = window._hsq || [];
      // @ts-ignore
      window._hsq.push(['identify', { email: userEmail }]);
    }
  }, [userId, userEmail, status]);

  if (configStatus === 'error') {
    return <Error
      text={`Error while loading application: ${configError}`}
      skipText='Reload page'
      onSkip={() => location.reload()}
    />;
  }

  if (status !== 'success') {
    return <div />;
  }

  if (browserApproved === false) {
    return <Error
      text={<>Sorry, your current browser's version doesn't<br/>support this page. Please update it and try again</>}
      onSkip={() => setBrowserApproved(true)}
    />;
  }

  return (
    <AppStyle className={layout}>
      <DndProvider backend={HTML5Backend}>
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no'/>
        </Helmet>
        <PublicRoutes googleTagId={config?.googleTag?.id} />
        <UserSnapWidget />
        {config?.hubspot?.trackingCode && <HubSpotTracker trackingCode={config?.hubspot?.trackingCode} />}
      </DndProvider>
    </AppStyle>
  );
};

export default App;
