import { Drawdowns, MeasurementUnit, typeOfFinanceDebt, typeOfFinanceEquity } from "./calculator";
import { Country, Currency } from "./locale";

export const templateTabs = ['base settings', 'purchase', 'financing', 'construction', 'sales', 'all'] as const;
export type TemplateTab = typeof templateTabs[number];

export const presetSections = ['construction', 'purchase', 'financing'] as const;
export type PresetSection = typeof presetSections[number];

export interface TemplatePurchaseItem {
  name: string;
  isPredefined: boolean;
  totalCost?: number;
  percentPurchasePrice?: number;
}

export interface TemplateSalesItem {
  name: string;
  isPredefined: boolean;
  totalCost?: number;
  percentTotalSales?: number;
}

export const typeOfFinanceTemplate = [...typeOfFinanceDebt, ...typeOfFinanceEquity] as const;
export type TypeOfFinanceTemplate = typeof typeOfFinanceTemplate[number];
type TypeOfFinanceDebt = typeof typeOfFinanceDebt[number];
type TypeOfFinanceEquity = typeof typeOfFinanceEquity[number];

export interface TemplateFinancingItemDebt {
  type: TypeOfFinanceDebt;
  section: 'Debt funding';
  percentage?: number;
  drawdowns?: Drawdowns;
  interest?: number;
  fee?: number;
}

export interface TemplateFinancingItemEquity {
  type: TypeOfFinanceEquity;
  section: 'Equity';
  percentage?: number;
  drawdowns?: Drawdowns;
  profitShare?: number;
}

export type TemplateFinancingItem = TemplateFinancingItemDebt | TemplateFinancingItemEquity;

export const templateFinancingItemIsDebt = (item: TemplateFinancingItem): item is TemplateFinancingItemDebt =>
  typeOfFinanceDebt.includes(item?.type as TypeOfFinanceDebt);

export const templateFinancingItemIsEquity = (item: TemplateFinancingItem): item is TemplateFinancingItemEquity =>
  typeOfFinanceEquity.includes(item?.type as TypeOfFinanceEquity);

export interface TemplateConstructionItem {
  costType: string;
  totalCost?: number;
  unit?: MeasurementUnit;
  unitCost?: number;
  unitQuantity?: number;
}

export interface TemplatePreConstructionItem {
  costType: string;
  totalCost?: number;
  constructionCostPercent?: number;
  totalFloorAreaCost?: number;
}

export interface TemplateProfessionalFeesItem {
  costType: string;
  totalCost?: number;
  constructionCostPercent?: number;
  totalFloorAreaCost?: number;
}

export interface TemplateConstruction {
  totalFloorRatio: number;
  buildCostHint: number;
  contingencyPercentage?: number;
  contingencyTotal?: number;
  preConstruction: TemplatePreConstructionItem[];
  construction: TemplateConstructionItem[];
  professionalFees: TemplateProfessionalFeesItem[];
}

export interface TemplateBase {
  country?: Country;
  currency?: Currency;
  projectStartPurchase?: number;
  projectPurchaseConstruction?: number;
}

export interface Template {
  id: string;
  name: string;
  isDefault?: boolean;
  isShared?: boolean;
  base: TemplateBase;
  purchase?: TemplatePurchaseItem[];
  financing?: TemplateFinancingItem[];
  construction?: TemplateConstruction;
  sales?: TemplateSalesItem[];
  section?: PresetSection;
  userId?: string;
}
