import React from 'react';
import { SwitchStyle } from './switch.style';

export function Switch(props) {
  return (
    <SwitchStyle className="custom-control custom-switch">
      <input
        className="custom-control-input"
        type="checkbox"
        {...props}
      />
      {props.label &&
        <label className="custom-control-label" htmlFor={props.id}>{props.label}</label>
      }
    </SwitchStyle>
  );
}

export default Switch;
