import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

export const NavigationBarStyle = styled.div<{
  fixed?: boolean;
  transparent?: boolean;
  desktopMinWidth: number;
  background?: string;
  width?: number;
  maxWidthMobile?: number;
  noMargin?: boolean;
  mobilePadding?: string;
}>`
  z-index: 20;
  width: 100vw;

  ${(p) => p.fixed ? `
    position: fixed;
    top: 0;
  ` : ''}

  ${(p) => !p.transparent && p.fixed ? `
    background: white;
    box-shadow: 0px 4px 8px rgba(43, 70, 86, 0.12);
  ` : ''}
  
  ${(p) => p.background ? `
    background: ${p.background};
  `: ''}
  
  @media (max-width: ${(p) => p.desktopMinWidth}px) {
    height: 68px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    @media (min-width: ${(p) => p.desktopMinWidth}px) {
      ${(p) => p.width ? `
        width: ${p.width}px;
        padding: 10px 0 0 0;
      ` : `
        max-width: 1681px;
        padding: 0 121px;
      `}
      margin: ${(p) => p.noMargin ? '0' : '21px'} auto;
    }

    @media (max-width: ${(p) => p.desktopMinWidth}px) {
      ${(p) => p.maxWidthMobile ? `
        max-width: ${p.maxWidthMobile}px;
        margin: auto;
        padding: 0 15px;
      ` : ''}
      padding: ${(p) => p.mobilePadding || '0 24px'};
    }

    > div:first-of-type {
      display: flex;

      > svg {
        cursor: pointer;

        @media (max-width: ${(p) => p.desktopMinWidth}px) {
          width: 122px;
        }
        
        @media (min-width: ${(p) => p.desktopMinWidth}px) {
          margin-right: 90px;
        }
      }

      > div {
        @media (max-width: ${(p) => p.desktopMinWidth}px) {
          display: none;
        }

        display: flex;
        align-items: center;
        min-width: 491px;
        margin-right: 90px;

        > a {
          font-family: 'Basis Grotesque Pro';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          color: #383b3d;
          margin-right: 32px;

          :hover {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }

    > div:last-of-type {
      display: flex;
      align-items: center;

      > div:nth-of-type(1) {
        @media (max-width: ${(p) => p.desktopMinWidth}px) {
          display: none;
        }

        display: flex;
        align-items: center;
        padding: 13px 24px;
        height: 48px;
        width: 191px;
        color: white;
        background: ${palette.greenDark};
        border-radius: 8px;
        margin-right: 16px;

        > span {
          font-family: 'Basis Grotesque Pro';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;

          :nth-of-type(1) {
            margin-right: 4px;
          }

          :nth-of-type(2) {
            opacity: 0.5;
          }
        }

        :hover {
          cursor: pointer;
        }
      }

      > div:nth-of-type(2) {
        @media (min-width: ${(p) => p.desktopMinWidth}px) {
          display: none;
        }

        display: flex;
        align-items: center;

        > div {
          color: ${palette.greenDark};
          margin-right: 24px;
          font-family: 'Basis Grotesque Pro';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-decoration: underline;
          cursor: pointer;
        }

        > svg {
          cursor: pointer;
        }
      }
    }
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  background: ${palette.greenDark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 16px 40px 16px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;

  > div {
    display: flex;
    justify-content: space-between;
    padding: 0 14px 0 8px;

    :nth-of-type(1) {
      flex-direction: column;

      > div:nth-of-type(1) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 72px;

        > svg:nth-of-type(2) {
          cursor: pointer;
        }
      }

      > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;

        color: #f6f8fa;
        font-family: 'Tiempos Headline';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        > a {
          color: #f6f8fa;
          cursor: pointer;

          :not(:last-of-type) {
            margin-bottom: 24px;
          }
        }
      }
    }

    :nth-of-type(2) {
      flex-direction: column;

      > div {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Basis Grotesque Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
      }
      
      > div:nth-of-type(1) {
        border: 1px solid rgba(255, 255, 255, 0.6);
        border-radius: 8px;
        color: white;
      }
    }
  }

  > img {
    position: absolute;
    left: 0;
    bottom: 88px;
    width: 100%;
    z-index: -1;
  }
`;
