import React, { PropsWithChildren, ReactNode } from 'react';
import { Button } from '../ui';
import { Wrapper, Message, Image, ButtonContainer } from './style';

interface Props {
  title?: string;
  message?: string;
  buttons?: Array<{
    title: string;
    variant?: 'primary' | 'secondary';
    width?: number;
    marginTop?: string;
    onClick: () => void;
  }>;
  height?: string;
  img?: ReactNode;
  imgMargin?: string;
  fullWidth?: boolean;
  inline?: boolean;
  color?: string;
  marginTop?: number;
}

export const NoData = ({ title = 'Oops', inline, message, buttons, height, children, img, imgMargin, fullWidth, color, marginTop }: PropsWithChildren<Props>) => {

  return <Wrapper height={height} marginTop={marginTop}>
    <Message imgMargin={imgMargin} fullWidth={fullWidth} inline={inline} color={color}>
      {img || <Image inline={inline}/>}
      {!inline && <h1>{title}</h1>}
      {message && <h2>{message}</h2>}
      {children}
      {buttons && <ButtonContainer>
        {buttons.map(({ title, variant, width, marginTop, onClick }, i) => <Button
          key={i}
          variant={variant}
          marginTop={marginTop || '30px'}
          onClick={onClick}
          maxWidth={width}
        >{title}</Button>)}
      </ButtonContainer>}
    </Message>
  </Wrapper>;
};
