import React, { useEffect } from 'react';
import { ButtonStyle } from './style';
import { ReactComponent as ArrowImage } from './images/arrow.svg';

interface ButtonProps {
  text: string;
  active?: boolean;
  arrow?: boolean;
  onClick: () => void;
}

export const ButtonHomepage = ({ text, active, arrow, onClick }: ButtonProps) => {
  useEffect(() => {
    if ( active ) {
      const handler = (e: KeyboardEvent) => {
        if ( e.key === 'Enter' ) {
          onClick();
        }
      };

      window.addEventListener('keydown', handler);
      return () => window.removeEventListener('keydown', handler);
    }
  }, [active, onClick]);

  return <ButtonStyle active={active} arrow={arrow} onClick={() => onClick()}>
    {arrow && <ArrowImage />}
    <span>{text}</span>
  </ButtonStyle>;
};
