import styled from 'styled-components';
import theme from '../../../themes';

export const Container = styled.div<{width?: string; minWidth?: string; disabled?: boolean}>`
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  height: 100%;

  position: relative;
  border-radius: 8px;
  background-color: ${theme.palette.grayMediumLight};

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.minWidth ? `min-width: ${p.minWidth};` : ''}

  ${(p) => p.disabled ? 'opacity: 0.6;' : ''}

  > span {
    border-radius: 8px;
    padding: 22px 0 0 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Placeholder = styled.div<{focused?: boolean}>`
  position: absolute;
  top: ${(p) => !p.focused ? '23px' : '9px'};
  left: 12px;

  font-family: "Basis Grotesque Pro", Arial;
  font-size: ${(p) => !p.focused ? '1rem' : '0.75rem'};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;

  color: var(--color-grey-dark);
  pointer-events: none;
  transition: all 0.1s;

  width: calc(100% - 24px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  user-select: none;
`;

export const Calendar = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: calc(50% - 320px);
  display: flex;
  flex-direction: column;

  padding: 20px;

  border-radius: 8px;
  background-color: ${theme.palette.grayMediumLight};

  box-shadow: 0px 8px 16px 0px rgba(43, 70, 86, 0.16);

  z-index: 10;

  > div {
    display: flex;

    :first-of-type {
      font-weight: 600;
      border-bottom: 1px solid black;
    }

    > div {
      :first-of-type {
        font-weight: 600;
      }

      width: 50px;
      padding: 10px;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
