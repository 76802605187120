/* eslint-disable @typescript-eslint/no-explicit-any */

import { WritableDraft } from "immer/dist/internal";
import { AppState } from "../calculate";
import { PayloadAction } from "@reduxjs/toolkit";
import { ComparablesFilter, ComparablesItem, ProjectType, Unit, UnitType, bedroomIsValid, unitTypes } from "../../../../../types";
import { getUnitTypesByProjectType, unitIsResidental } from "../../../shared";

export const comparablesReducers = {
  setComparablesList(state: WritableDraft<AppState>, action: PayloadAction<ComparablesItem[]>) {
    state.analysePage.comparablesService.getList = action.payload;
  },
  setComparablesFilter(state: WritableDraft<AppState>, action: PayloadAction<ComparablesFilter>) {
    state.analysePage.comparablesService.comparablesFilter = action.payload;
  },
  setSelectedComparables(state: WritableDraft<AppState>, action: PayloadAction<ComparablesItem[]>) {
    state.analysePage.comparablesService.selectedComparables = action.payload;
  },
  setIsShowAll(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.comparablesService.isShowAll = action.payload;
  },
  setComparablesPage(state: WritableDraft<AppState>, action: PayloadAction<number>) {
    state.analysePage.comparablesService.page = action.payload;
  },
  setComparablesLimit(state: WritableDraft<AppState>, action: PayloadAction<number>) {
    state.analysePage.comparablesService.limit = action.payload;
  },
  setMaxComparables(state: WritableDraft<AppState>, action: PayloadAction<number>) {
    state.analysePage.comparablesService.maxComparables = action.payload;
  },
  updateComparablesFilter(state: WritableDraft<AppState>, action: PayloadAction<{projectType?: ProjectType, units?: Unit[]}>) {
    if (action.payload.units) {
      state.analysePage.comparablesService.comparablesFilter.type = action.payload.units.filter(unitIsResidental).map((unit) => unit.property_type).filter((type) => unitTypes.includes(type)) as UnitType[];
      state.analysePage.comparablesService.comparablesFilter.bedroom = action.payload.units.filter(unitIsResidental).map((unit) => unit.bedroom_no).filter(bedroomIsValid);
    }

    if (!action.payload.units || (state.analysePage.comparablesService.comparablesFilter.type || []).length === 0) {
      state.analysePage.comparablesService.comparablesFilter.type = getUnitTypesByProjectType(action.payload.projectType);
    }

    if (!action.payload.units || (state.analysePage.comparablesService.comparablesFilter.bedroom || []).length === 0) {
      state.analysePage.comparablesService.comparablesFilter.bedroom = [0, 1, 2, 3, 4, 5, 6];
    }
  },
};
