import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import AppReducer, { initialState as appInitialState } from './app/app';
import AuthReducer, { initialState as authInitialState } from './auth/auth';
import CalculateReducer, { initialState as calculateInitialState } from './calculate/calculate';
import TemplateReducer, { initialState as templateInitialState } from './template/template';
const history = createBrowserHistory();

export const rootReducer = combineReducers({
  App: AppReducer,
  Auth: AuthReducer,
  calculate: CalculateReducer,
  Template: TemplateReducer,
});

export const initialState = {
  App: appInitialState,
  Auth: authInitialState,
  calculate: calculateInitialState,
  Template: templateInitialState,
};

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

const store = configureStore({
  reducer: rootReducer,
});

export { store, history };
