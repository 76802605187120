import { LocaleOptions } from '../types';

export let lang = 'en-GB';
export let decimalSeparator = '.';
export let thousandsSeparator = ',';
export let currency = '£';
export let distance = 'ft.';
export let square = 'sq.ft.';

export const setLang = (newLocale: string) => {
  lang = newLocale;
  decimalSeparator = (1.1).toLocaleString(lang).substring(1, 2) || '.';
  thousandsSeparator = (1000).toLocaleString(lang).substring(1, 2).replace(/0-9/, '') || ',';
};

export const setCurrency = (value: string) => currency = value;
export const setDistance = (value: string) => distance = value;
export const setSquare = (value: string) => square = value;
export const setDecimalSeparator = (value: string) => decimalSeparator = value;
export const setThousandsSeparator = (value: string) => thousandsSeparator = value;

export const setLocaleOptions = (locale: LocaleOptions) => {
  if (locale.lang) {
    setLang(locale.lang);
  }
  if (locale.currency) {
    setCurrency(locale.currency);
  }
  if (locale.decimalSeparator) {
    setDecimalSeparator(locale.decimalSeparator);
  }
  if (locale.thousandsSeparator) {
    setThousandsSeparator(locale.thousandsSeparator);
  }
  if (locale.distance) {
    setDistance(locale.distance);
  }
  if (locale.square) {
    setSquare(locale.square);
  }
}

export const toLocaleValue = (arg: {
  value?: number | string | Date;
  money?: boolean;
  percent?: boolean;
  length?: number;
  time?: boolean;
  shortTime?: boolean;
  noDate?: boolean;
  noDateIfToday?: boolean;
  noSpace?: boolean;
  na?: string;
} | string | Date, changeLocale: LocaleOptions = {}) => {
  const locale = { ...changeLocale };
  locale.lang = locale.lang || lang;
  locale.decimalSeparator = locale.decimalSeparator || decimalSeparator;
  locale.thousandsSeparator = locale.thousandsSeparator || thousandsSeparator;
  locale.currency = locale.currency || currency;
  locale.distance = locale.distance || distance;
  locale.square = locale.square || square;
  const item = typeof arg === 'string' || arg instanceof Date ? { value: arg } : arg;
  const { value, money, percent, length, na, noSpace } = item;
  if ( typeof value === 'number' ) {
    if ( Number.isFinite(value) ) {
      const round = (() => {
        if ( Math.abs(value) > 100 ) {
          return 1;
        } else if ( Math.abs(value) > 5 ) {
          return 10;
        } else {
          return 100;
        }
      })();
      return `${money ? `${locale.currency}${noSpace ? '' : ' '}` : ''}${(+(Math.round(value * round) / round).toFixed(4)).toLocaleString(locale.lang)}${percent ? ' %' : ''}`;
    } else {
      return '—';
    }
  } else if ( typeof value === 'string' ) {
    if ( length && value.length >= length ) {
      return value.slice(0, length) + '…';
    } else {
      return value;
    }
  } else if ( value instanceof Date ) {
    const date = value.toLocaleDateString(locale.lang);
    const dateWithoutYear = date.replace(value.getFullYear().toString(), '').replace(/^[^\d]/, '').replace(/[^\d]$/, '');
    const displayDate = item.shortTime ? dateWithoutYear : date;
    const today = (new Date()).toLocaleDateString(locale.lang);
    const time = value.toLocaleTimeString(locale.lang);
    const timeWithoutSec = time.match(/^\d\d:\d\d:\d\d$/) ? time.replace(/:\d\d$/, '') : time;
    return [
      (date !== today || !item.noDateIfToday) && !item.noDate ? displayDate : null,
      (item.time || item.shortTime ) && (!item.shortTime || date === today) ? timeWithoutSec : null
    ].filter((item) => item).join(' ');
  } else {
    return value || na || 'N/A';
  }
}