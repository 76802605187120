import Tippy from "@tippyjs/react";
import debounce from "lodash.debounce";
import React, { BaseSyntheticEvent, createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { setIsFormChanged } from "../../../../../redux/calculate/calculate";
import { useLocale } from "../../../../../shared";
import key from "../../../images/png/key.png";
import './custom-input.css';
import { ReactComponent as CopyIcon } from "./images/copy.svg";

const thousandsSeparator = {
  'en': ',',
  'swe': ' ',
};

function Props(value: any) {
  return value;
}

interface Props {
  shouldHandleOutsideClick?: boolean;
  autoFillList?: string[];
  placeholder?: string;
  maxWidth: string;
  color?: string;
  disabled?: boolean;
  isAlreadyCompleted?: boolean;
  inputValue?: string | number;
  isNumber?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  isCopyMode?: boolean;
  isDebounce?: boolean;
  required?: boolean;
  errorMessage?: string;
  isDirty?: boolean;
  useSeparator?: boolean;
  readOnly?: boolean;
  decimalScale?: number;
  small?: boolean;
  valueChanges?: typeof Props;
  disabledClick?: () => void;
  onFocus?: () => void;
  onBlur?: typeof Props;
  onAutoFillClicked?: (elem: string) => void;
  onClick?: () => void;
}

export const CustomInput = ({ shouldHandleOutsideClick = false, autoFillList, placeholder, maxWidth, color, disabled, isAlreadyCompleted,
  inputValue, isNumber, isCurrency, isPercent, isDebounce = false, required, isCopyMode, errorMessage, isDirty, useSeparator, readOnly,
  decimalScale, small, valueChanges, disabledClick, onFocus, onBlur, onAutoFillClicked, onClick }: Props) => {

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { currencySign } = useLocale({ defaultCurrencyValue: '' });

  const wrapperRef = createRef<HTMLInputElement>();
  const inputRef = createRef<HTMLInputElement>();

  const [currentValue, setCurrentValue] = useState<any>();
  const [isCopied, setIsCopied] = useState(false);
  const [isCurrentlyFocused, setIsCurrentlyFocused] = useState(false);
  const [wereClickedOutside, setWereClickedOutside] = useState(false);
  const [isAutofillSuggestion, setIsAutofillSuggestion] = useState(false);

  const debouncedResults = useMemo(() => {
    return debounce((value: any) => {
      if (valueChanges !== undefined) {
        if (value) dispatch(setIsFormChanged(true));
        valueChanges(value);
        debouncedResults.cancel();
      }
    }, 700);
  }, [valueChanges]);

  useEffect(() => {
    const timer1 = setInterval(() => {
      setIsCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [isCopied]);

  useEffect(() => {
    if ((inputRef?.current && (!autoFillList || autoFillList?.length === 0))) {
      setIsAutofillSuggestion(false);
    } else if (autoFillList && autoFillList.length > 0) {
      setIsAutofillSuggestion(true);
    }
  }, [autoFillList, inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsAutofillSuggestion(false);
        setWereClickedOutside(true);
        onBlur?.(currentValue);
        setIsCurrentlyFocused?.(false);
      }
    };

    if (shouldHandleOutsideClick && isCurrentlyFocused) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } else if (!shouldHandleOutsideClick && !isCurrentlyFocused) {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [wrapperRef, shouldHandleOutsideClick, isCurrentlyFocused, currentValue,
    setIsAutofillSuggestion, setWereClickedOutside, onBlur, setIsCurrentlyFocused]);

  const copyValue = async () => {
    await navigator.clipboard
      .writeText(`${inputValue ? inputValue : ""}`)
      .then(() => {
        setIsCopied(true);
      });
  };

  const fitNumber = useCallback((number: string | number) => {
    if (typeof number === 'string') {
      if (number.length === 0) return 0;
      return Number.parseFloat(number.replace(/[^\d.-]|[\s%]/g, ''));
    } else {
      return number;
    }
  }, []);

  const getPrefix = useCallback(() => {
    if (isCurrency) {
      return `${currencySign} `;
    } else if (isPercent) {
      return '% ';
    }
  }, [currencySign, isCurrency, isPercent]);

  const checkPlaceholderPosition = useCallback(() => {
    if (isCurrentlyFocused) return 'custom-input_empty';
    else if (currentValue || currentValue === 0 || inputValue || inputValue === 0) return 'custom-input_empty';
    else return '';
  }, [currentValue, inputValue, isCurrentlyFocused]);

  return (
    <div
      ref={wrapperRef}
      className={`
    ${disabled
      ? "custom-input-wrapper custom-input-wrapper-disabled "
      : "custom-input-wrapper " + ((errorMessage && isDirty) ? "custom-input-wrapper-error " : "")}
    ${checkPlaceholderPosition()}`
      }
      style={{ maxWidth, height: small ? '36px' : undefined }}
      onClick={() => {
        if (disabled && disabledClick) {
          disabledClick();
        } if (onClick) {
          onClick();
        } else {
          inputRef.current?.focus();
        }
      }}
    >
      {isNumber && (isCurrency || isPercent || useSeparator) && (
        <NumericFormat
          onFocus={() => { setWereClickedOutside(false); setIsCurrentlyFocused(true); onFocus?.(); }}
          onBlur={() => { onBlur?.(currentValue); setIsCurrentlyFocused(false); }}
          getInputRef={inputRef}
          className={`custom-input ${!placeholder ? 'custom-input-no-placeholder' : ''}`}
          style={{ backgroundColor: color ? color : "#F3F6F9FF" }}
          onChange={(event) => {
            if (isDebounce) {
              setCurrentValue(fitNumber(event.target.value));
              if (valueChanges !== undefined) {
                debouncedResults(fitNumber(event.target.value));
              }
            } else {
              setCurrentValue(fitNumber(event.target.value));
              if (valueChanges !== undefined) {
                if (event?.target?.value) dispatch(setIsFormChanged(true));
                valueChanges(fitNumber(event.target.value));
              }
            }
          }}
          disabled={disabled || readOnly}
          placeholder=""
          value={inputValue}
          prefix={getPrefix()}
          thousandSeparator={`${isPercent ? '' : thousandsSeparator[i18n.language]}`}
          decimalScale={decimalScale}
          allowLeadingZeros
        />
      )}
      {!isCurrency && !isPercent && !useSeparator && (
        <input
          onFocus={() => { setWereClickedOutside(false); setIsCurrentlyFocused(true); onFocus?.(); }}
          onBlur={() => {
            if (!isAutofillSuggestion) {
              onBlur?.(currentValue);
              setIsCurrentlyFocused(false);
            }
          }}
          ref={inputRef}
          disabled={disabled || readOnly}
          className={`custom-input ${!placeholder ? 'custom-input-no-placeholder' : ''}`}
          type={isNumber ? "number" : "text"}
          style={{ backgroundColor: color ? color : "#f3f6f9" }}
          onWheel={(e: BaseSyntheticEvent<WheelEvent>) => e.target.blur()}
          onChange={(event) => {
            let value: string | number = event.target.value;
            if (isNumber) {
              if (value?.includes('-')) value = value.replace('-', '');
              if (value?.length > 0) value = Number.parseFloat(value);
            }
            if (isDebounce) {
              setCurrentValue(value);
              if (valueChanges !== undefined) {
                debouncedResults(value);
              }
            } else {
              setCurrentValue(value);
              if (valueChanges !== undefined) {
                if (value !== undefined) {
                  dispatch(setIsFormChanged(true));
                }
                valueChanges(value);
              }
            }
          }}
          placeholder=""
          value={inputValue !== undefined ? inputValue : ''}
          onKeyDown={(event) => {
            if (event.key === "Tab") {
              onBlur?.(currentValue);
              setIsCurrentlyFocused(false);
            }
          }}
        />
      )}
      <div className="disabled-message">
        {disabled && isAlreadyCompleted && (
          <Tippy
            content={
              <div className="message-hover">
                <p>
                    Field locked as there is data in the pop-up-click to open and
                    edit
                </p>
              </div>
            }
          >
            <div className="disabled-icon-img-wrapper">
              <img className="disabled-icon-img" src={key} alt="key" />
            </div>
          </Tippy>
        )}
        <div
          className="custom-input-label"
        >
          <div className="form-input-text-placeholder">{`${placeholder}${required ? '*' : ''}`}{" "}</div>
        </div>
      </div>
      {(errorMessage && isDirty) && <label className={`form-input-error-message ${small ? 'small-error' : ''}`}>{`*${errorMessage || "required"}`}</label>}

      <div className="copy-wrapper">
        {isCopyMode && (
          <CopyIcon className="copy-icon" onClick={() => copyValue()} />
        )}
        {isCopied && <div className="copied-notification">Copied!</div>}
      </div>

      {(autoFillList && autoFillList?.length > 0 && isAutofillSuggestion && !wereClickedOutside && isCurrentlyFocused) && <div className="autofill-wrapper">
        {autoFillList.map((el, i) => <div key={i} onClick={() => {
          if (inputRef?.current) {
            inputRef.current.value = el;
            onAutoFillClicked?.(el);
            setIsAutofillSuggestion(false);
            inputRef?.current?.focus();
          }
        }} className="autofill-item">{el}</div>)}
      </div>}
    </div>
  );
};
