import React, { useEffect } from 'react';
import { Popup } from '../../../popup';
import { Content } from './style';
import { CashflowTable, CashflowTableProps } from '../../cashflow-table';
import { Button } from '../../../ui';

interface Props extends CashflowTableProps {
  onClose: () => void;
}

export const CashflowPopup = (props: Props) => {
  const { onClose, ...cashflowProps } = props;

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Escape' ) {
        onClose();
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [onClose]);

  return <Popup padding={[40, 40, 40, 40]} noMaxHeight>
    <Content>
      <div>
        <CashflowTable {...cashflowProps} noFullscreen />
      </div>
      <div>
        <Button variant='primary' onClick={() => onClose()}>Close</Button>
      </div>
    </Content>
  </Popup>;
};
