/* eslint-disable @typescript-eslint/no-explicit-any */

import { WritableDraft } from "immer/dist/internal";
// import { AppState } from "..";
import { AppState } from "../calculate";
import { PayloadAction } from "@reduxjs/toolkit";
export const analysePageReducers = {
  setLocationGeoCodes(state: WritableDraft<AppState>, action: {
    payload: any;
    type: string;
  }) {
    if (state.analysePage.locationService) {
      state.analysePage.locationService.getGeoCodes = action.payload;
    } else {
      state.analysePage.locationService = { getGeoCodes: action.payload };
    }
  },
  setAnalyseMenu(state: WritableDraft<AppState>, action: {
    payload: any;
    type: string;
  }) {
    state.analysePage.currentMenu = action.payload;
  },
  setScenarioCreatedAt(state: WritableDraft<AppState>, action: PayloadAction<Date>) {
    state.analysePage.scenarioCreatedAt = action.payload;
  },
  setIsFormChanged(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.isFormChanged = action.payload;
  },
  setAddressAutofillList(state: WritableDraft<AppState>, action: PayloadAction<string[]>) {
    state.analysePage.addressAutofill = action.payload;
  },
  setIsAutofillClicked(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.isAutofillClicked = action.payload;
  },
  setIsBlockApisUsage(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.isBlockApisUsage = action.payload;
  },
  setShowInfoPanel(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.showInfoPanel = action.payload;
  },
  setIsBasic(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.isBasic = action.payload;
  },
  setSelectorSq_ft_Sq_m(state: WritableDraft<AppState>, action: {
    payload: any;
    type: string;
  }) {
    state.analysePage.selectorSq_ft_Sq_m = action.payload;
  },
  setUnitsArray(state: WritableDraft<AppState>, action: {
    payload: any;
    type: string;
  }) {
    state.analysePage.editingData.units_array = action.payload;
  },
  setRiskProfile(state: WritableDraft<AppState>, action: PayloadAction<number>) {
    state.analysePage.riskProfile = action.payload;
  },
  setTermsAndConditionsAcceptance(state: WritableDraft<AppState>, action: PayloadAction<boolean>) {
    state.analysePage.termsAndConditionsAcceptance = action.payload;
  },
};
