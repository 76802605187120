import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as LogoImage } from '../../images/logo-mini.svg';
import { ReactComponent as NavianLogoImage } from '../../images/nn-logo.svg';

const { palette } = theme;

export const menuExpandedWidth = 234;
export const menuCollapsedWidth = 118;

export const Background = styled.div`
  display: none;
  .mobile .adaptive &&, .tablet .adaptive && {
    display: block;
    background: #00000077;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
`;

export const MobileMenuBar = styled.div`
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  > div {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  > div:nth-child(2) {
    > div:last-child {
      margin-left: 20px;
    }
  }
`;

export const Menu = styled.div`
  .mobile .adaptive &&, .tablet .adaptive && {
    position: fixed;
    z-index: 10;
    width: 1%;
    opacity: 0;
    box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.1);
    background: ${palette.grayBg};
    top: 0;
    bottom: 0;
    margin-top: 0;
    max-width: 400px;
    padding-top: 30px;
    overflow: auto;
    > div:first-child > div {
      width: 95%;
    }
    animation: fade-in-mobile-menu .2s ease-in-out forwards;
    @keyframes fade-in-mobile-menu {
      0% { width: 1%; opacity: 0; }
      100% { width: 97%; opacity: 1; }
    }
  }
  .desktop && {
    max-height: 100vh;
    /* overflow: auto; */ /* This will cause hints to be outside visible area */
  }
  > div:first-child > div {
    width: ${menuCollapsedWidth}px;
  }
  a {
    text-decoration: none;
  }
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;
  .mobile .adaptive &&, .tablet .adaptive && {
    justify-content: space-between;
  }
`;

export const Logo = styled(LogoImage)`
  width: 118px;
  height: 41px;
  cursor: pointer;
`;

export const LogoCircle = styled(NavianLogoImage)`
  width: 40px;
  height: 30px;
  cursor: pointer;
`;

export const Item = styled.div<{ active?: boolean; disabled?: boolean; }>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${(p) => p.active ? 'black' : palette.grayDark};
  cursor: pointer;
  & > div:first-of-type {
    width: 3px;
    background: ${(p) => p.active ? palette.greenDark : 'transparent'};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 40px;
    transition: all .2s;
  }
  svg {
    opacity: ${(p) => p.disabled ? .6 : 1};
    margin: 25px 0px 11px 46px;
    width: 20px;
    height: 20px;
    /* * {
      fill: ${(p) => p.active ? palette.greenDark : palette.grayDark};
    } */
  }
  p {
    max-width: 142px;
    opacity: ${(p) => p.disabled ? .6 : 1};
    margin: 12px 0 0 0;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Arrow = styled.div`
  width: 20px;

  font-size: 22px;
  color: ${palette.grayDark};

  :hover {
    cursor: pointer;
  }
`;

export const UserPic = styled.div`
  margin-left: 39px;
  margin-bottom: 20px;
`;
