import React from 'react';
import { TemplateSalesItem } from '../../../../../../types';
import { CustomInput } from '../../../calculator/components/ui';
import { ReactComponent as AddImage } from '../../images/add.svg';
import { ReactComponent as CopyImage } from '../../images/copy.svg';
import { ReactComponent as RemoveImage } from '../../images/remove.svg';
import { AddItem, FieldContainer, FieldHeader, Label, TabContainer, TabContent } from '../../style';

const defaultSalesItem: TemplateSalesItem = {
  name: '',
  isPredefined: false,
};

interface Props {
  items: TemplateSalesItem[];
  defaultItems?: TemplateSalesItem[];
  setItems?: (items: TemplateSalesItem[]) => void;
}

export const SalesTab = ({ items, defaultItems, setItems }: Props) => {
  return <TabContent>
    <div>
      <Label header bold margin='0 0 15px 0'>Sales</Label>
      <TabContainer>
        {items.map((item, i) => <div key={i}>
          {/* {(item.isPredefined || !setItems) && <Label>{item.name}</Label>}
          {(!item.isPredefined && setItems) && <CustomInput */}
          {!setItems && <Label>{item.name}</Label>}
          {setItems && <CustomInput
            placeholder='Cost name'
            maxWidth='200px'
            inputValue={item.name}
            valueChanges={(value) => setItems([
              ...items.slice(0, i),
              { ...item, name: value },
              ...items.slice(i + 1),
            ])}
          />}
          <FieldContainer>
            {defaultItems && i === 0 && <FieldHeader>% of total sales value</FieldHeader>}
            {defaultItems && <CustomInput
              placeholder='Default'
              maxWidth='240px'
              inputValue={defaultItems.find((defaultItem) => item.name === defaultItem.name)?.percentTotalSales || 0}
              readOnly
              isNumber
              useSeparator
            />}
            <CustomInput
              placeholder={defaultItems ? 'Set' : '% of total sales value'}
              maxWidth='240px'
              inputValue={item.percentTotalSales}
              readOnly={!setItems}
              valueChanges={(value) => setItems?.([
                ...items.slice(0, i),
                { ...item, percentTotalSales: value },
                ...items.slice(i + 1),
              ])}
              isNumber
              useSeparator
            />
          </FieldContainer>
          <FieldContainer>
            {defaultItems && i === 0 && <FieldHeader>Total cost</FieldHeader>}
            {defaultItems && <CustomInput
              placeholder='Default'
              maxWidth='240px'
              inputValue={defaultItems.find((defaultItem) => item.name === defaultItem.name)?.totalCost || 0}
              readOnly
              isNumber
              useSeparator
            />}
            <CustomInput
              placeholder={defaultItems ? 'Set' : 'Total cost'}
              maxWidth='240px'
              inputValue={item.totalCost}
              readOnly={!setItems}
              valueChanges={(value) => setItems?.([
                ...items.slice(0, i),
                { ...item, totalCost: value },
                ...items.slice(i + 1),
              ])}
              isNumber
              useSeparator
            />
          </FieldContainer>
          {setItems && <>
            <CopyImage onClick={() => setItems([...items.slice(0, i), items[i], ...items.slice(i)])} />
            <RemoveImage onClick={() => setItems([...items.slice(0, i), ...items.slice(i + 1)])} />
          </>}
        </div>)}
        {items.length === 0 && <Label>No costs</Label>}
        {setItems && <AddItem onClick={() => setItems([...items, defaultSalesItem])}>
          <AddImage />
          <span>Add cost</span>
        </AddItem>}
      </TabContainer>
    </div>
  </TabContent>;
};
