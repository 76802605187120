import styled from 'styled-components';

export const PopupContent = styled.div`
  background: var(--color-whiteish-2);
  border-radius: 8px;
  height: auto;
  width: 550px;
  font-family: 'Basis Grotesque Pro', Arial;

  input[type='checkbox'] {
    background: var(--color-white);
    border: 1px solid #d2d6dd;
    border-radius: 4px;
    height: 20px;
    width: 20px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin: 69px 24px 0;
  display: inline-block;
  width: 500px;
  overflow: auto;
`;

export const Double = styled.div<{noMargin?: boolean}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 0px;

  > div {
    background-color: var(--color-white);
    border: 1px solid var(--color-whiteish-3);
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;

    &.overflow {
      overflow: hidden;
    }

    .inputs-wrapper {
      margin-bottom: 10px;
    }

    .custom-input-wrapper:not(:first-of-type) {
      margin-top: 19px;
    }

    > div:first-of-type {
      align-items: center;
      display: flex;
      gap: 5px;

      margin-bottom: 18px;
    }
  }

  ${(p) => !p.noMargin ? `}
    margin-bottom: 12px;
  ` : ''}
`;


export const ItemContent = styled.div<{centered?: boolean, wrapped?: boolean}>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > input {
      margin: 0;
    }

    > span {
      display: flex;
      align-items: center;
      user-select: none;
      margin-bottom: 0;
    }

    ${(p) => p.centered ? `
      flex-direction: column;
    ` : ''}
  }

  ${(p) => p.centered ? `
    align-items: center;
  ` : ''}

  ${(p) => p.wrapped ? `
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 40px;
  ` : ''}
`;

export const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-greyish);
  box-shadow: 0px 4px 24px rgba(43, 70, 86, 0.06);
  position: absolute;
  border-radius: 8px 8px 0 0;

  > img {
    position: absolute;
    right: 25px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid var(--color-greyish);
  box-shadow: 0px -8px 24px rgba(43, 70, 86, 0.06);
  background: var(--color-white);
  position: relative;
  top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;

  > div{
    :nth-of-type(1) {
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: var(--color-black-2);
      cursor: pointer;
    }

    :nth-of-type(2) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 12px;
      grid-row-gap: 0px;


      > button {
        width: auto;
      }
    }
  } 
`;
