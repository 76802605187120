import React, { useState, useEffect } from 'react';
import { CSSProperties } from 'styled-components';
import { UserPic } from '../../components/ui/user-pic';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

interface Props {
  id?: string;
  incomingMessages?: number;
  size?: number;
  style?: CSSProperties;
  noPicture?: boolean;
  borderColor?: string;
  className?: string;
  onClick?: () => void;
}

export const CurrentUserPic = ({ id, style, size, incomingMessages, noPicture, borderColor, className, onClick }: Props) => {

  const history = useHistory();
  const { user, loadPictureStatus } = useSelector((state: RootState) => state.Auth);

  const [hash, setHash] = useState('');

  useEffect(() => {
    if ( loadPictureStatus === 'success' ) {
      setHash('' + Date.now());
    }
  }, [loadPictureStatus]);

  return <UserPic
    id={id}
    className={className}
    userName={user?.name || user?.email || ''}
    style={style}
    size={size}
    incomingMessages={incomingMessages}
    onIncomingMessagesClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      history.push('/chats');
    }}
    noPicture={noPicture}
    borderColor={borderColor}
    hash={hash}
    onClick={onClick}
  />;
};
