import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config, Layout, ShortUser, SupportTicket, SupportTicketNote, UpdateStatus, User } from '../../../../types';
export * from './actions';

interface CreateUserError {
  type: string;
  text: string;
}

interface State {
  config?: Config;
  configStatus: UpdateStatus;
  supportUpdateStatus: UpdateStatus;
  supportTickets: SupportTicket[];
  supportTicket?: {
    ticket: SupportTicket;
    notes: SupportTicketNote[];
  };
  configError?: string;
  menuExpanded: boolean;
  notificationBarExpanded: boolean;
  createSupportRequest: boolean;
  sendFeedbackStatus: UpdateStatus;
  userListStatus: UpdateStatus;
  userList: User[];
  shortUserList: ShortUser[];
  userStatus: UpdateStatus;
  user?: User;
  createUserStatus: UpdateStatus;
  createUserError?: CreateUserError;
  layout: Layout;
}

export const initialState: State = {
  configStatus: 'new',
  supportUpdateStatus: 'new',
  supportTickets: [],
  menuExpanded: window.localStorage.getItem('menu-opened') !== 'no' ? true : false,
  notificationBarExpanded: false,
  createSupportRequest: false,
  sendFeedbackStatus: 'new',
  userListStatus: 'new',
  userList: [],
  shortUserList: [],
  userStatus: 'new',
  createUserStatus: 'new',
  layout: 'desktop',
};

const Slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<Config>) {
      state.config = action.payload;
    },
    setConfigStatus(state, action: PayloadAction<UpdateStatus>) {
      state.configStatus = action.payload;
      state.configError = action.payload === 'error' ? state.configError : undefined;
    },
    setSupportUpdateStatus(state, action: PayloadAction<UpdateStatus>) {
      state.supportUpdateStatus = action.payload;
    },
    setSupportTickets(state, action: PayloadAction<SupportTicket[]>) {
      state.supportTickets = action.payload;
    },
    setSupportTicket(state, action: PayloadAction<{ ticket: SupportTicket, notes: SupportTicketNote[] }>) {
      state.supportTicket = action.payload;
    },
    setConfigError(state, action: PayloadAction<string>) {
      state.configError = action.payload;
    },
    setMenuExpanded(state, action: PayloadAction<boolean>) {
      state.menuExpanded = action.payload;
      window.localStorage.setItem('menu-opened', action.payload ? 'yes' : 'no');
    },
    setNotificationBarExpanded(state, action: PayloadAction<boolean>) {
      state.notificationBarExpanded = action.payload;
    },
    setCreateSupportRequest(state, action: PayloadAction<boolean>) {
      state.createSupportRequest = action.payload;
    },
    setSendFeedbackStatus(state, action: PayloadAction<UpdateStatus>) {
      state.sendFeedbackStatus = action.payload;
    },
    setUserListStatus(state, action: PayloadAction<UpdateStatus>) {
      state.userListStatus = action.payload;
    },
    setUserList(state, action: PayloadAction<User[]>) {
      state.userList = action.payload;
    },
    setShortUserList(state, action: PayloadAction<ShortUser[]>) {
      state.shortUserList = action.payload;
    },
    setUserStatus(state, action: PayloadAction<UpdateStatus>) {
      state.userStatus = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload || undefined;
    },
    setCreateUserStatus(state, action: PayloadAction<UpdateStatus>) {
      state.createUserStatus = action.payload;
    },
    setCreateUserError(state, action: PayloadAction<CreateUserError | null>) {
      state.createUserError = action.payload || undefined;
    },
    setLayout(state, action: PayloadAction<Layout>) {
      state.layout = action.payload;
    },
  },
});

export const {
  setMenuExpanded,
  setNotificationBarExpanded,
  setCreateSupportRequest,
  setSupportTickets,
  setSupportTicket,
  setConfig,
  setConfigStatus,
  setConfigError,
  setSendFeedbackStatus,
  setUserListStatus,
  setUserList,
  setShortUserList,
  setUserStatus,
  setUser,
  setCreateUserStatus,
  setCreateUserError,
  setLayout,
  setSupportUpdateStatus,
} = Slice.actions;

export default Slice.reducer;
