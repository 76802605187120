import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const CardStyle = styled.div<{ maxWidth?: number; minWidth?: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(p) => p.maxWidth ? `max-width: ${p.maxWidth}px;` : ''}
  ${(p) => p.minWidth ? `min-width: ${p.minWidth}px;` : ''}
  .mobile .adaptive && {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .tablet .adaptive && {
    min-width: fit-content;
    margin: 10px;
  }
`;

export const Block = styled.div<{ withPadding?: boolean; roundedBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${palette.grayBg};
  overflow: hidden;
  padding: ${(p) => p.withPadding ? 25 : 0}px;
  margin: 3px 0;
  box-sizing: border-box;
  border-bottom-left-radius: ${(p) => p.roundedBottom ? 15 : 0}px;
  border-bottom-right-radius: ${(p) => p.roundedBottom ? 15 : 0}px;
`;

export const Header = styled(Block)<{ clickable?: boolean }>`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 0;
  padding: 0 25px;
  height: 60px;
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  > div {
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    min-width: 0;
    > svg {
      height: 18px;
      margin-right: 10px;
    }
  }
`;

export const Footer = styled(Block)`
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 0;
  padding: 0 25px;
  height: 60px;
`;
