import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { InputStyle, Dropdown } from './style';
import { ReactComponent as EyeImage } from './images/eye.svg';
import { ReactComponent as SmallArrowImage } from './images/small-arrow.svg';

interface Props {
  value?: string;
  placeholder?: string;
  type: 'text' | 'password' | 'dropdown' | 'textarea';
  items?: Array<{
    value: string;
    label: string;
  }>;
  marginBottom?: number;
  error?: boolean;
  hint?: string;
  noHint?: boolean;
  name?: string;
  autoComplete?: string;
  onChange?: (value: string) => void;
}

export const InputHomepage = ({ value, placeholder, type, items, marginBottom, error, hint, noHint, name, autoComplete, onChange }: Props) => {
  const [hideValue, setHideValue] = useState(type === 'password');
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [ref, setShowDropdown]);

  return <InputStyle showDropdown={showDropdown} marginBottom={marginBottom} error={error}>
    <div>
      {type === 'textarea' ? <textarea
        value={value}
        placeholder={placeholder || ''}
        onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value as string)}
        name={name}
        autoComplete={autoComplete}
      /> : <input
        value={(type === 'dropdown' ? items?.find((item) => item.value === value)?.label : value) || ''}
        type={hideValue ? 'password' : 'text'}
        placeholder={placeholder || ''}
        onChange={type !== 'dropdown' ? (e:React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value as string) : undefined}
        {...(type === 'dropdown' ? { readOnly: true } : {} ) }
        onClick={type === 'dropdown' ? () => setShowDropdown(true) : undefined}
        onFocus={() => {
          setShowDropdown(true);
          ref.current?.focus?.();
        }}
        name={name}
        autoComplete={autoComplete}
      />}
      {type === 'dropdown' && <SmallArrowImage onClick={() => setShowDropdown(!showDropdown)}/>}
      {type === 'password' && <EyeImage onClick={() => setHideValue(!hideValue)}/>}
      {showDropdown && <Dropdown ref={ref}>
        {items?.map((item, i) => <div key={i} onClick={() => {
          onChange?.(item.value);
          setShowDropdown(false);
        }}>{item.label}</div>)}
      </Dropdown>}
    </div>
    {!noHint && (hint ? <span>{hint}</span> : <span>&nbsp;</span>)}
  </InputStyle>;
};
