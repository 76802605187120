import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Country } from '../../../../../../../types';
import { setAnalyseMenu } from '../../../../../redux/calculate/calculate';
import { useLocale } from '../../../../../shared';
import optionIcon from './images/option.png';
import { Container, Items } from './style';

interface Props {
  country: Country;
  currentMenu: string;
}

export const DetailedDropdown = ({ country, currentMenu }: Props) => {
  const dispatch = useDispatch();

  const { resultMenu } = useLocale({ defaultCurrencyValue: '' });

  const containerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [isActive, setIsActive] = useState(false);

  const items = useMemo(() => resultMenu.map((value) => ({ value, label: value })), [resultMenu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <Container ref={containerRef}>
      <button type='button' onClick={() => setIsActive(!isActive)}>
        {currentMenu}
        <img src={optionIcon} alt={''} style={{ width: '24px', height: '24px' }} />
      </button>
      <Items active={isActive}>
        <div>
          {items.map((elem, i) => <div
            key={i}
            onClick={() => {
              dispatch(setAnalyseMenu(elem?.label));
              setIsActive(false);
            }}>{elem.label}</div>,
          )}
        </div>
      </Items>
    </Container>
  );
};

