import styled from 'styled-components';

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    display: flex;
    align-items: center;
    gap: 5px;

    > span:first-of-type {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    > span:last-of-type {
      font-family: 'Basis Grotesque Pro', Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;
