import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const SwitchBigStyle = styled.div<{ withPadding?: boolean; center?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.center ? 'center' : 'left'};
  margin: ${(p) => p.withPadding ? '20px 0' : '0'};
  text-align: ${(p) => p.center ? 'center' : 'inherit'};
  h1 {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${palette.black};
    white-space: nowrap;
  }
  h2 {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: ${palette.grayMedium};
    white-space: nowrap;
  }
`;

export const Area = styled.div<{ width?: string }>`
  width: ${(p) => p.width || 'auto'};
  min-width: 200px;
  display: flex;
  background: ${palette.grayBg};
  position: relative;
  height: 54px;
  border-radius: 4px;
  cursor: pointer;
`;

export const BackgroundLabel = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 2px;
  right: ${(p) => p.position === 'left' ? 0 : 50}%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 50px;
  min-width: 100px;
  h1 {
    font-size: 14px;
    color: ${palette.black};
    margin: 0;
  }
  h2 {
    font-size: 12px;
    color: ${palette.grayMedium};
    margin: 0;
  }
`;

export const ActiveButton = styled(BackgroundLabel)`
  background: ${palette.white};
  left: ${(p) => p.position === 'left' ? 0 : 50}%;
  box-shadow: 0px 4px 4px rgba(43, 70, 86, 0.08), 0px 0px 4px rgba(43, 70, 86, 0.04);
  border-radius: 4px;
  transition: left .3s;
`;
