import styled from 'styled-components';
import theme from '../../themes';

const { palette } = theme;

export const Item = styled.div`
  position: relative;
  text-align: left;
  color: ${palette.grayDark};
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  padding: 15px;
  h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0 0 0;
  }
  svg {
    top: 10px;
    right: 10px;
    position: absolute;
    cursor: pointer;
  }
`;
