export const squareUnitTypesList = ['Sq.ft', 'Sq.m'] as const;

export type SquareUnit = typeof squareUnitTypesList[number];

export const optionList = [
  { label: 'Basic', value: 'Basic' },
  { label: 'Site & purchase', value: 'Site & purchase' },
  { label: 'Proposed units', value: 'Proposed units' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Timeline & cashflow', value: 'Timeline&cashflow' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Rental', value: 'Rental' },
  { label: 'Multiples', value: 'Multiples' },
  { label: 'Sensitivity analysis', value: 'Sensitivity analysis' },
  { label: 'Local area', value: 'LocalArea' },
  { label: 'Local market', value: 'LocalMarket' },
  { label: 'Comparables', value: 'Comparables' },
];