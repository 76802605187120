import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Popup, SupportRequestCreated } from '..';
import { Confirmation } from '../..';
import { SupportTicketCreateRequest, supportTypeOptions } from '../../../../../types';
import { checkEmail } from '../../../shared';
import { ButtonHomepage, InputHomepage } from '../../ui';
import { Buttons, Padding, Subtitle, Title, Captcha } from './style';

interface Props {
  mobile?: boolean;
  error?: string;
  reCaptchaSiteKey?: string;
  onSubmit: (args: SupportTicketCreateRequest) => void;
  onClose: () => void;
  onClearError: () => void;
}

export const SupportRequestHomepage = ({ mobile, error, reCaptchaSiteKey, onSubmit, onClose, onClearError }: Props) => {

  const [type, setType] = useState<string>();
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const [ticketCreatedEmail, setTicketCreatedEmail] = useState<string | null>();
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (error) {
      setTicketCreatedEmail(null);
    }
  }, [error]);

  if (!error && ticketCreatedEmail) {
    return <SupportRequestCreated
      email={ticketCreatedEmail}
      mobile={mobile}
      onClose={onClose}
    />;
  } else {
    return <Popup
      size={[468, 610]}
      paddingTitle={30}
      adaptive
      maxWidthMobile='calc(100vw - 10px)'
      onClose={onClose}
      closeButtonTop={24}
    >
      {error && <Confirmation
        title='An error occured'
        text={error}
        buttons={[
          { text: 'Close', variant: 'primary', onClick: onClearError },
        ]}
        onClose={() => onClearError()}
      />}
      <Title>Get help</Title>
      <Padding>
        <Subtitle>Please describe your issue</Subtitle>
        <InputHomepage
          value={type}
          type='dropdown'
          placeholder='Select topic'
          items={supportTypeOptions}
          onChange={setType}
          error={showErrors && !type}
          hint={showErrors && !type ? 'Please, select topic' : undefined}
        />
        <InputHomepage
          value={message}
          type='textarea'
          placeholder='Enter message'
          onChange={setMessage}
          error={showErrors && !message}
          hint={showErrors && !message ? 'Please, enter message' : undefined}
        />
        <InputHomepage
          value={email}
          type='text'
          placeholder='Enter your email'
          onChange={setEmail}
          error={showErrors && (!email || !checkEmail(email))}
          hint={showErrors && (!email || !checkEmail(email)) ? 'Please, enter email' : undefined}
        />
        {reCaptchaSiteKey && <Captcha>
          <ReCAPTCHA
            sitekey={reCaptchaSiteKey}
            onChange={setCaptcha}
          />
        </Captcha>}
        <Buttons>
          <ButtonHomepage
            text= 'Send feedback'
            onClick={() => {
              if (!type || (!email || !checkEmail(email)) || !message || !captcha) {
                setShowErrors(true);
              } else {
                setTicketCreatedEmail(email);
                onSubmit({ type, email, message, captcha: captcha ?? undefined, documents: [] });
              }
            }}
            active
          />
        </Buttons>
      </Padding>
    </Popup>;
  }

};
