import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { escapeRegExp } from '../../../../shared';
import { Actions } from '../../redux';
import { getPages, MainMenuImage } from '../../shared/main-menu';
import theme from '../../themes';
import { ComingSoon } from '../coming-soon';
import { CurrentUserPic } from '../current-user-pic';
import { HoverHint } from '../hover-hint';
import { ReactComponent as CalculatorImage } from './images/calculator.svg';
import { ReactComponent as MenuImage } from './images/menu.svg';
import { ReactComponent as ReportsImage } from './images/reports.svg';
import { ReactComponent as UsersImage } from './images/users.svg';
import { ReactComponent as SettingsImage } from './images/settings.svg';
import { Arrow, Head, Item, LogoCircle, Menu, MobileMenuBar, UserPic } from './style';

const { palette } = theme;

// eslint-disable-next-line no-unused-vars
const images: { [name in MainMenuImage]: React.ReactNode } = {
  calculator: <CalculatorImage/>,
  users: <UsersImage/>,
  reports: <ReportsImage/>,
  settings: <SettingsImage/>,
};

interface Props {
  adaptive?: boolean;
}

export const MainMenu = ({ adaptive }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const layout = useSelector((state: RootState) => state.App.layout);
  const user = useSelector((state: RootState) => state.Auth.user);
  const scenarioShortId = useSelector((state: RootState) => state.calculate.scenarioShortId);

  const mobile = !!(adaptive && ['mobile', 'tablet'].includes(layout));

  const logoClick = useCallback(() => {
    history.push(`/calculator${scenarioShortId ? '/' + scenarioShortId : ''}`);
  }, [history, scenarioShortId]);

  const location = document.location.href.replace(escapeRegExp(document.location.origin), '');

  return <>
    {mobile && <MobileMenuBar>
      <div>
        <MenuImage/>
      </div>
      <div>
        <CurrentUserPic
          className='user-pic'
          size={46}
          borderColor={palette.grayLight}
          onClick={() => history.push('/profile')}
        />
        <Arrow onClick={() => dispatch(Actions.App.setNotificationBarExpanded(true))}>←</Arrow>
      </div>
    </MobileMenuBar>}
    {(!mobile) && <Menu className='main-menu' >
      <div>
        <div>
          {<Head>
            <LogoCircle className='menu-logo' onClick={logoClick}/>
          </Head>}
        </div>
        {user && <div className='menu-items'>
          {getPages(scenarioShortId).map((item, i) => {
            const disabled = !!item.comingSoonFeature;
            if (item.image && images[item.image] && (!item.check || item.check(user))) {
              const Content = <HoverHint label={item.name} type='left' marginTop={10}>
                <Item
                  className='menu-item'
                  active={!!location.match(item.match)}
                  disabled={disabled}
                >
                  <div/>
                  {images[item.image]}
                </Item>
              </HoverHint>;
              if (item.comingSoonFeature) {
                return <ComingSoon key={i}>{Content}</ComingSoon>;
              } else if (item.link) {
                return <Link key={i} to={item.link} onClick={() => {
                  if (mobile) {
                    dispatch(Actions.App.setMenuExpanded(false));
                  }
                }}>{Content}</Link>;
              }
            }
            return null;
          })}
        </div>}
      </div>
      <UserPic>
        <CurrentUserPic
          className='user-pic'
          size={40}
          borderColor={palette.grayLight}
          onClick={() => history.push('/profile')}
        />
      </UserPic>
    </Menu>}
  </>;
};
