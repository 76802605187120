import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Title = styled.div<{ center?: boolean }>`
  color: ${palette.black};
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: ${(p) => p.center ? 'center' : 'left'};
  span {
    color: ${palette.greenDark};
  }
`;

export const Subtitle = styled.div<{ center?: boolean }>`
  color: ${palette.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: ${(p) => p.center ? 'center' : 'left'};
  span {
    color: ${palette.blue};
  }
`;

export const Tab = styled.div<{ tall?: boolean }>`
  padding: 20px 0 0 0;
  height: ${(p) => p.tall ? 400 : 320}px;
`;
