import React, { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { escapeRegExp } from '../../../../shared';
import { RootState } from '../../redux/store';
import { getPages } from '../../shared/main-menu';
import { Tabs } from '../tabs';
import { Button, TitleAndBack } from '../ui';
import { Body, Child, ContentControls, ContentWrapper, Controls, Header, HeaderControls, MobileBeforeBodyControls, MobileButtons, Padding, Scale, SingleTab, TitleWithButton } from './style';

interface Props {
  title?: string;
  back?: boolean;
  startNewDeal?: boolean;
  children?: ReactNode | ReactNode[];
  withPadding?: boolean;
  headerControls?: ReactNode;
  contentControls?: ReactNode;
  beforeBodyControls?: ReactNode;
  buttons?: ReactNode;
  tab?: number;
  tabNames?: string[];
  minDesktopWidth?: number | (number | null)[];
  renderOnOpenTab?: boolean;
  contentHeight?: string;
  tabControls?: ReactNode;
  titleIcon?: ReactNode;
  hasTour?: boolean;
  adaptive?: boolean;
  noHead?: boolean;
  inlineMobileHead?: boolean;
  tabIcons?: {
    [name: string]: ReactNode;
  },
  noMobileButtonsPadding?: boolean;
  noMobileHeadPadding?: boolean;
  noTabsWrap?: boolean;
  scaleWithFlex?: boolean;
  onOpenTab?: (tab: number) => void;
  setCurrentWidth?: (width: number) => void;
  onTabClick?: (tab: number) => void;
}

export const Content = ({ children, title, back, startNewDeal, withPadding, headerControls, beforeBodyControls, tab, tabNames, hasTour, adaptive, contentControls,
  minDesktopWidth, buttons, renderOnOpenTab, contentHeight, tabControls, titleIcon, tabIcons, noHead, inlineMobileHead, noMobileButtonsPadding, noMobileHeadPadding,
  noTabsWrap, scaleWithFlex, onOpenTab, setCurrentWidth, onTabClick }: Props) => {

  const wrapperRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const bodyRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const bodyControlsRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const history = useHistory();
  const lastLocation = useLastLocation();

  const layout = useSelector((state: RootState) => state.App.layout);
  const menuExpanded = useSelector((state: RootState) => state.App.menuExpanded);
  const user = useSelector((state: RootState) => state.Auth.user);

  const [height, setHeight] = useState<number>();
  const [scale, setScale] = useState<number>();

  const mobileOrTablet = adaptive && ['mobile', 'tablet'].includes(layout);
  const mobile = mobileOrTablet && layout === 'mobile';

  const minWidthCurrent = useMemo(() => {
    if (!mobileOrTablet) {
      if (Array.isArray(minDesktopWidth) && tab) {
        return minDesktopWidth[tab-1];
      } else if (!Array.isArray(minDesktopWidth)) {
        return minDesktopWidth;
      }
    }
  }, [mobileOrTablet, minDesktopWidth, tab]);

  useEffect(() => {
    if ( minWidthCurrent && wrapperRef.current ) {
      setScale(Math.min(wrapperRef.current.getBoundingClientRect().width / minWidthCurrent, 1));
    }
    setCurrentWidth?.(wrapperRef?.current?.getBoundingClientRect()?.width);
  }, [minWidthCurrent, wrapperRef, menuExpanded, setCurrentWidth]);

  useEffect(() => {
    const handler = () => {
      if ( minWidthCurrent ) {
        setScale(Math.min(wrapperRef.current.getBoundingClientRect().width / minWidthCurrent, 1));
      }
      setCurrentWidth?.(wrapperRef?.current?.getBoundingClientRect()?.width);
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [minWidthCurrent, wrapperRef, menuExpanded, setCurrentWidth]);

  useEffect(() => {
    setCurrentWidth?.(wrapperRef.current.getBoundingClientRect().width);
  }, [wrapperRef, setCurrentWidth]);

  useEffect(() => {
    const handler = () => {
      const rect = bodyRef.current?.getBoundingClientRect();
      setHeight(rect?.top);
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [contentHeight]);

  const childrenWithPadding = useMemo<ReactNode | ReactNode[]>(() => {
    const Controls = contentControls && <ContentControls>{contentControls}</ContentControls>;
    const scaleProps = { scale, strictHeight: !!contentHeight, offset: bodyControlsRef?.current?.getBoundingClientRect().height, withFlex: scaleWithFlex || false };
    if (withPadding && !mobileOrTablet) {
      if (Array.isArray(children) && tabNames) {
        return children.map((child, i) => (child ?
          <Padding key={i}>
            {!minWidthCurrent ? <Child>{Controls}{child}</Child> : <Scale {...scaleProps}>{Controls}{child}</Scale>}
          </Padding> :
          <Child key={i}>{Controls}{child}</Child>
        ));
      } else {
        return <Padding>
          {!minWidthCurrent ? <Child>{Controls}{children}</Child> : <Scale {...scaleProps}>{Controls}{children}</Scale>}
        </Padding>;
      }
    } else if (minWidthCurrent) {
      if (Array.isArray(children) && tabNames) {
        return children.map((child, i) => (child ? <Scale key={i} {...scaleProps}>{Controls}{child}</Scale> : null));
      } else {
        return <Scale {...scaleProps}>{Controls}{children}</Scale>;
      }
    } else {
      if (Array.isArray(children) && tabNames) {
        return children.map((child, i) => (child ? <Child key={i}>{Controls}{child}</Child> : null));
      } else {
        return <Child>{Controls}{children}</Child>;
      }
    }
  }, [contentControls, scale, contentHeight, scaleWithFlex, withPadding, mobileOrTablet, minWidthCurrent, children, tabNames]);

  const previousPageName = useMemo(() => {
    const curLocation = document.location.href.replace(escapeRegExp(document.location.origin), '');
    const prevLocation = lastLocation?.pathname.replace(escapeRegExp(document.location.origin), '');
    const pageName = getPages().find((item) => back && (!item.check || item.check(user)) ? prevLocation?.match(item.match) && !curLocation.match(item.match) : false)?.name;
    return pageName;
  }, [lastLocation, user, back]);

  return <>
    <ContentWrapper
      className='content-wrapper'
      ref={wrapperRef}
    >
      {!noHead && <Header inlineMobileHead={inlineMobileHead} noMobilePadding={noMobileHeadPadding}>
        {title &&<TitleWithButton>
          <TitleAndBack
            title={title}
            icon={titleIcon}
            titleBack={previousPageName ? `Back to ${previousPageName.toLowerCase()}` : undefined}
            onBack={previousPageName ? () => history.go(-1) : undefined}
            hasTour={hasTour}
          />
        </TitleWithButton>}
        {mobile && <MobileBeforeBodyControls ref={bodyControlsRef}>{beforeBodyControls}</MobileBeforeBodyControls>}
        {mobile && (startNewDeal || buttons) && <MobileButtons noPadding={noMobileButtonsPadding}>
          {startNewDeal && <Button className='start-new-deal' onClick={() => history.push('/calculator')}>New project</Button>}
          {buttons}
        </MobileButtons>}
        {(headerControls || (!mobile && (startNewDeal || buttons))) && <Controls>
          {headerControls && <HeaderControls className='header-controls'>{headerControls}</HeaderControls>}
          {startNewDeal && !mobile && <div><Button className='start-new-deal' onClick={() => history.push('/calculator')}>New project</Button></div>}
          {buttons && !mobile && <div>{buttons}</div>}
        </Controls>}
      </Header>}
      <Body
        ref={bodyRef}
        heightFromTop={height ? height + (withPadding ? 66 : 20) : undefined}
        strictHeight={!!contentHeight}
        withScale={!!minWidthCurrent}
      >
        {!mobile && <div ref={bodyControlsRef}>{beforeBodyControls}</div>}
        {tabNames ? <Tabs
          type='line'
          tab={tab}
          tabNames={tabNames}
          tabIcons={tabIcons}
          renderOnOpenTab={renderOnOpenTab}
          controls={tabControls}
          onOpenTab={onOpenTab}
          height={contentHeight}
          onTabClick={onTabClick}
          noWrap={noTabsWrap}
        >
          {childrenWithPadding}
        </Tabs> : <SingleTab height={contentHeight}>
          {childrenWithPadding}
        </SingleTab>}
      </Body>
    </ContentWrapper>
  </>;
};
