import styled from 'styled-components';
import theme from '../../../themes';
import { ReactComponent as ArrowImage } from '../../../images/arrow2.svg';

const { palette } = theme;

export const TitleStyle = styled.div<{ width?: string }>`
  margin: 20px 0;
  padding: 0 20px 0 0;
  position: relative;
  display: flex;
  width: ${(p) => p.width || '100%'};
  align-items: center;
  justify-content: space-between;
  h2 {
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    > span {
      color: ${palette.blue};
    }
  }
  a {
    font-size: 14px;
    text-decoration: none;
    color: ${palette.grayMedium}; 
  }
`;

export const HeaderWrapper = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
`;

export const SwitchWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 30px;
  > span {
    margin-right: 10px;
  }
`;

export const Left = styled.span`
  display: flex;
  align-items: center;
  > a {
    color: ${palette.greenMedium};
    margin-left: 30px;
  }
`;

export const Arrow = styled(ArrowImage)`
  margin-right: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

export const ArrowUp = styled(Arrow)`
  transform: rotate(-90deg);
`;

export const ArrowDown = styled(Arrow)`
  transform: rotate(90deg);
`;
