import styled from 'styled-components';
import theme from '../../themes';
import { ReactComponent as LogoSVG } from '../../images/logo.svg';

const { palette } = theme;

export const Page = styled.div<{ delay?: number }>`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${palette.grayMedium};
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 80px;
    font-weight: 800;
    margin-bottom: 25px;
  }
  p {
    color: ${palette.black};
    font-family: 'Gotham Pro', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
  }
  a {
    color: ${palette.blue} !important;
    cursor: pointer;
    text-decoration: underline !important;
  }
  opacity: 0;
  animation: fade-error-page .5s linear forwards;
  animation-delay: ${(p) => p.delay || 0}s;
  @keyframes fade-error-page {
    0% { opacity: 0 }
    50% { opacity: 0 }
    100% { opacity: 1 }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Logo = styled(LogoSVG as any)`
  position: absolute;
  width: 118px;
  bottom: 5%;
  left: 50%;
  margin-left: -59px;
`;
