export interface AveragePricesPrice {
  minPrice: number;
  medianPrice: number;
  maxPrice: number;
  count: number;
  noOfBeds: number;
}

export const averagePricesStatPropertyTypes = ['flat', 'terraced', 'detached', 'semi-detached'] as const;

export type AveragePricesStatPropertyType = typeof averagePricesStatPropertyTypes[number];

export interface AveragePricesStat {
  sale: {
    [propertyType: string]: AveragePricesPrice[]
  },
  rent: {
    [propertyType: string]: AveragePricesPrice[]
  }
}
