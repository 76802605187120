import styled from 'styled-components';
import theme from '../../../../themes';
import { ReactComponent as DeleteSVG } from './images/delete.svg';

const { palette } = theme;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  .mobile .adaptive & {
    flex-direction: column-reverse;
    > button {
      margin: 40px 0 0 0;
      width: 100%;
    }
  }
  > button {
    margin: 40px 0 0 15px;
    width: 150px;
  }
`;

export const Dropzone = styled.div<{highlight?: boolean}>`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${(p) => p.highlight ? palette.black : palette.grayMedium};
  border: 3px solid ${palette.grayBg};
  background-color: ${(p) => p.highlight ? palette.grayBg : 'white'};
`;

export const File = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: inherit;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    > svg {
      margin-right: 10px;
      min-width: 14px;
    }
  }
  > svg {
    cursor: pointer;
    min-width: 16px;
  }
`;

export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  height: 250px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 15px;
  background: ${palette.grayBg};
  width: 272px;
  height: 229px;
  padding: 20px 15px;
  font-size: 14px;
  margin-bottom: 20px;
  > :first-child {
    display: flex;
    justify-content: space-between;
    > :last-child {
      color: ${palette.grayDark};
      cursor: pointer;
      svg {
        margin-left: 10px;
      }
    }
  }
  > :last-child {
    border-radius: 15px;
    background: ${palette.white};
    overflow: auto;
    padding: 10px;
    height: 82px;
  }
`;

export const DeleteImage = styled(DeleteSVG).withConfig({
  shouldForwardProp: (prop) => !['red'].includes(prop as string),
})<{ red?: boolean }>`
  filter: grayscale(${(p) => p.red ? 0 : 1});
  cursor: pointer;
`;

export const Green = styled.span`
  color: ${palette.greenDark};
  font-weight: 600;
`;

export const Red = styled.span`
  color: ${palette.red};
  font-weight: 600;
`;
