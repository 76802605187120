import React from 'react';
import { ListButtonWrapper, TextWrapper, Title, Text, IconWrapper } from './style';
import { ReactComponent as ArrowImage } from '../../images/arrow.svg';

export interface Props {
  title: string;
  titleBold: string;
  text: string;
  disabled?: boolean;
  value: string;
  onClick: (value: string) => void;
}

export const SelectButton = ({ children, title, titleBold, text, disabled, value, onClick }: React.PropsWithChildren<Props>) => {
  return <ListButtonWrapper onClick={() => onClick(value)} disabled={disabled || false}>
    <IconWrapper>
      {children}
    </IconWrapper>
    <TextWrapper>
      <Title>{title} <b>{titleBold}</b></Title>
      <Text>{text}</Text>
    </TextWrapper>
    <IconWrapper>
      <ArrowImage />
    </IconWrapper>
  </ListButtonWrapper>;
};
