import styled from 'styled-components';

export const Popup = styled.div`
  background: var(--color-white);
  border-radius: 8px;
`;

export const Header = styled.div`
  position: relative;

  width: 100%;
  padding: 12px 0;
  margin: 0;
  border-bottom: 1px solid var(--color-greyish);

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Basis Grotesque Pro", Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--color-black-1);
`;

export const Content = styled.div`
  height: 600px;
  max-height: 650px;
  padding: 24px;
  overflow: auto;
  background: var(--color-whiteish-2);

  display: flex;
  flex-direction: column;
  gap: 24px;

  > div:nth-of-type(2) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const Buttons = styled.div`
  border-top: 1px solid var(--color-greyish);
  padding: 0 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  > div {
    height: 100%;
    padding: 16px 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;

export const Button = styled.button<{variant: 'submit' | 'cancel'}>`
    border: 1px solid var(--color-greyish);
    border-radius: 8px;
    padding: 12px 24px;

    font-family: "Basis Grotesque Pro", Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    ${(p) => p.variant === 'submit' ? `
      background: var(--color-aqua);
      color: var(--color-white);
    ` : `
      background: var(--color-white);
      color: var(--color-black-1);
    `}
`;
