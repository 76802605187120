import React from 'react';
import { Item, SwitcherButton, Wrapper } from './style';

interface Props {
  title: string;
  items: string[];
  active: number;
  width: number;
  disabled?: number[];
  margin?: string;
  setActive: () => void;
}

export const Switcher = ({ title, items, active, width, disabled, margin, setActive }: Props) => {
  return <Wrapper width={width} margin={margin}>
    <span>{title}</span>
    <div onClick = {() => setActive()}>
      <div>
        {items.map((item, i) => <Item key={i} count={items.length} disabled={disabled?.includes?.(i)}>{item}</Item>)}
      </div>
      <SwitcherButton move={active} count={items.length} />
    </div>
  </Wrapper>;
};
