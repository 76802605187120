import React from 'react';
import { ReactComponent as ChatImage } from './images/chat.svg';
import { ReactComponent as CompareImage } from './images/compare.svg';
import { ReactComponent as CreditImage } from './images/credit.svg';
import { ReactComponent as DeleteImage } from './images/delete.svg';
import { ReactComponent as EditImage } from './images/edit.svg';
import { ReactComponent as ExpertImage } from './images/expert.svg';
import { ReactComponent as ReportImage } from './images/report.svg';
import { ReactComponent as SendImage } from './images/send.svg';
import { ReactComponent as SettingsImage } from './images/settings.svg';
import { Item, ToolsStyle } from './style';

interface Tool {
  icon: 'compare' | 'settings' | 'edit' | 'send' | 'report' | 'delete' | 'expert' | 'chat' | 'credit';
  title: string;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
interface Props {
  list: Tool[];
}

const icons = {
  compare: <CompareImage/>,
  settings: <SettingsImage/>,
  edit: <EditImage/>,
  send: <SendImage/>,
  report: <ReportImage/>,
  delete: <DeleteImage/>,
  expert: <ExpertImage/>,
  chat: <ChatImage/>,
  credit: <CreditImage/>,
};

export const Tools = ({ list }: Props) => {

  return <ToolsStyle>
    {list.map((item, i) => <Item
      key={i}
      onClick={item.onClick}
      disabled={!item.onClick}
      color={item.color}
    >
      {icons[item.icon] || null}
      {item.title}
    </Item>)}
  </ToolsStyle>;
};

