import styled from 'styled-components';
import theme from '../../../themes';
import { ReactComponent as PlaneImage } from './images/plane.svg';

const { palette } = theme;

export const Icon = styled(PlaneImage).withConfig({
  shouldForwardProp: (prop) => !['big'].includes(prop as string),
})<{ big?: boolean }>`
  position: absolute;
  top: 25px;
  left: 30px;
  ${(p) => p.big ? `
    top: 70px;
    left: 70px;
    width: 40px;
    height: 40px;

    .mobile .adaptive &&, .tablet .adaptive && {
      top: 40px;
    }
  ` : ''}

  .mobile .adaptive &&, .tablet .adaptive && {
    left: auto;
  }
`;

export const Text = styled.p<{ thin?: boolean }>`
  margin-top: 20px;
  margin-bottom: 0;
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: ${(p) => p.thin ? 400 : 800};
  color: black;
  a {
    color: ${palette.blue};
    cursor: pointer;
  }
`;

export const Link = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-weight: 700;
  color: ${palette.greenDark};
  text-decoration: none !important;
`;

export const Title = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
  font-family: 'Gotham Pro', Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 800;
  color: black;
`;
