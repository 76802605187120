import { useMemo } from 'react';
import { chartRound, rounding, useLocale } from '..';

const barColors = ['#BADCE5', '#A9B6E4', '#D2C3EFFF', '#93B4B9FF'];

const titleWeights = {
  'Pre-construction': 0,
  'Construction': 1,
  'Professional fees': 2,
  'Contingency': 3,
  'Total development costs': 4,
};

interface TableDataItem {
  type: string;
  total: string;
  '% of total costs': string;
  'per unit': string;
  'per sqft': string;
}

const sortByTitle = (a: TableDataItem, b: TableDataItem) => (titleWeights[a.type.split(' - ')[0]] || 0) - (titleWeights[b.type.split(' - ')[0]] || 0);

// TBD: add types
export const useConstructionResult = (input, output, sizeUnit) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '' });

  const sizeUnitShort = useMemo(() => sizeUnit === 'Sq.m' ? 'sqm' : 'sqft', [sizeUnit]);

  const columns = useMemo(() => ['Type', 'Total', '% of total costs', 'Per unit', `Per ${sizeUnitShort}`], [sizeUnitShort]);
  const tableData = useMemo(() => output?.['Build costs breakdown']?.map?.((item) => ({
    type: item.title === 'Total construction costs' ? 'Total development costs' : item.title,
    total: toCurrency(item.total),
    '% of total costs': `${rounding(item.percentTotalCost, '%')}%`,
    'per unit': toCurrency(item.perUnit),
    [`per ${sizeUnitShort}`]: toCurrency(item.perSqFt, '0'),
  })).sort(sortByTitle), [output, sizeUnitShort, toCurrency]);

  const unit = useMemo(() => +output?.["Total costs"] >= 1000000 ? 'M' : 'k' as 'M' | 'k', [output]);

  const costsBreakdown = useMemo(() => input?.construction_costs_array?.filter?.((item) => !!item.total_cost)?.reduce((sum, item) => ({
    ...sum,
    [item?.construction_cost_category]: (sum[item?.construction_cost_category] || 0) + chartRound(unit, item.total_cost),
  }), {}), [input, unit]);

  const costBreakdownTotal = useMemo(() => Object.values(costsBreakdown || {}).reduce<number>((sum, item) => sum + (item as number), 0), [costsBreakdown]);

  const contingency = useMemo(() => {
    if (input?.contingency_total) {
      return chartRound(unit, input?.contingency_total);
    } else if (input?.contingency) {
      return Math.round((costBreakdownTotal / 100 * input?.contingency) * 10) / 10;
    }
  }, [costBreakdownTotal, input, unit]);

  const chartData = useMemo(() => [{
    name: "Total costs",
    "Construction costs": Math.round((costBreakdownTotal + (contingency || 0)) * 10) / 10,
    "Other costs": Math.round(((chartRound(unit, output?.["Total costs"]) || 0) - costBreakdownTotal - (contingency || 0)) * 10) / 10,
    line: Math.ceil(output?.["Total costs"]) / (unit === 'M' ? 1000000 : 1000),
  }, {
    name: 'Costs breakdown',
    ...costsBreakdown,
    ...(contingency ? { Contingency: contingency } : {}),
    line: costBreakdownTotal,
  }], [contingency, costBreakdownTotal, costsBreakdown, output, unit]);

  const bars = useMemo(() => ([{
    name: 'Construction costs',
    color: '#BADCE5',
  }, {
    name: 'Other costs',
    color: '#A9B6E4',
  },
  ...([...Object.keys(costsBreakdown || {}), ...(contingency ? ['Contingency'] : [])]?.map?.((item, i) => ({ name: item, color: barColors[i % barColors.length] })) || []),
  ]), [contingency, costsBreakdown]);

  return { columns, tableData, chartData, unit, bars };
};

