import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

const SIZE = 1.25;
const CheckboxStyle = styled.div<{ margin?: string }>`
  ${(p) => p.margin && `
    margin: ${p.margin};
  `}

  .custom-control-label
  {
    font-size: 14px;
    line-height: 1.3rem;
  }

  .custom-control-input
  {
    width: ${SIZE}rem;
    height: ${SIZE}rem;
  }

  .custom-control-label:before
  {
    border-radius: 5px;
    box-sizing: border-box;
  }

  .custom-control-label:after
  {
    background-size: 60% 60%;
  }

  .custom-control-label:before,
  .custom-control-label:after
  {
    top: 0;
    width: ${SIZE}rem;
    height: ${SIZE}rem;
  }

  .custom-control-input:checked ~ .custom-control-label:before
  {
    background-color: ${palette.greenMedium};
    border: 1px solid ${palette.greenMedium};
  }
`;

export { CheckboxStyle };
