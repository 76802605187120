import { setPresets, setSelected, setTemplates } from './template';
import { Template } from '../../../../types';
import { apiFetch } from '../api';
import { AppThunk } from '../store';

export const loadTemplates = (selectId?: string): AppThunk => async (dispatch) => {
  try {

    const response = await apiFetch('storage/template', {
      method: 'get',
      returnError: true,
    }) as { items: Template[] } | { error: string };

    if ( 'error' in response ) {
      throw new Error(response.error);
    } else {
      dispatch(setTemplates(response.items.filter((item) => !item.section)));
      dispatch(setPresets(response.items.filter((item) => !!item.section)));

      const defaultId = selectId || response.items.find((item) => item.isDefault)?.id;

      if (defaultId) {
        dispatch(setSelected(defaultId));
      }
    }

  } catch (error) {
    console.error(error);
    dispatch(setTemplates([]));
  }
};

export const storeTemplate = (template: Omit<Template, 'id'>, id?: string): AppThunk => async (dispatch, getState) => {
  try {
    const response = await apiFetch(`storage/template${id ? `/${id}` : ''}`, {
      method: 'post',
      returnError: true,
      body: JSON.stringify(template),
    }) as { id: string } | { error: string };

    if ( 'error' in response ) {
      throw new Error(response.error);
    } else {
      dispatch(loadTemplates(response.id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteTemplate = (id: string, type: 'template' | 'preset'): AppThunk => async (dispatch, getState) => {
  try {
    const response = await apiFetch(`storage/template/${id}`, {
      method: 'delete',
      returnError: true,
    }) as { id: string } | { error: string };

    if ( 'error' in response ) {
      throw new Error(response.error);
    } else {
      const items = type === 'preset' ? getState().Template.presets : getState().Template.templates;

      const index = items.findIndex((item) => item.id === id);
      const newId = items[index - 1].id;

      dispatch(loadTemplates(newId));
    }

  } catch (error) {
    console.log(error);
  }
};
