import styled from 'styled-components';
import theme from '../../themes';
import { TabsType } from './tabs';
import CounterImage from './images/counter.svg';

const { palette } = theme;

export const TabsWrapper = styled.div<{ background?: string; height?: string }>`
  width: 100%;
  height: ${(p) => p.height || '100%'};
  background: ${(p) => p.background || 'white'};
  user-select: none;
`;

export const Head = styled.div<{ type: TabsType; padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: auto;

  ${(p) => p.padding && `
    padding: ${p.padding};
  `}

  > div:first-child {
    display: flex;
    justify-content: space-between;
    width: ${(p) => ['basic', 'thin-line'].includes(p.type) ? '100%' : 'auto'};
  }

  ::-webkit-scrollbar { display: none; }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

interface TabProps {
  width: number;
  active?: boolean;
  background?: string;
  triggerBackground?: string;
  type: TabsType;
  noPadding?: boolean;
  linePadding?: number;
  fontSize?: number;
  noUpperCase?: boolean;
  noWrap?: boolean;
}

export const Tab = styled.div<TabProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.type === 'basic' ? `${p.width}%` : 'auto'};
  height: 57px;
  font-weight: 700;
  padding: ${(p) => ['line', 'thin', 'thin-line'].includes(p.type) ? `0 ${p.linePadding || 40}px` : '0'};
  white-space: ${(p) => p.noWrap ? 'nowrap' : 'normal'};

  .desktop .adaptive &&, .tablet .adaptive &&  {
    font-size: ${(p) => p.fontSize || 14}px;
  }

  .mobile .adaptive && {
    font-size: ${(p) => p.fontSize || 12}px;
  }

  .mobile .adaptive &:first-of-type {
    padding-left: 10px;
  }

  a.info-anchor {
    margin: -3px 0;
  }

  ${(p) => !p.noUpperCase && p.type !== 'thin-line' ? `
    text-transform: uppercase;
  ` : ''}

  color: ${(p) => p.active ? (['basic', 'thin'].includes(p.type) ? palette.greenMedium : 'black') : palette.grayMedium};
  background: ${(p) => {
    if (p.active) {
      return {
        basic: p.triggerBackground || p.background || 'white',
        thin: p.triggerBackground || p.background || palette.grayBg,
        'thin-line': 'none',
        line: 'none',
      }[p.type];
    } else {
      return { basic: palette.grayLight, thin: 'transparent', 'thin-line': 'none', line: 'none' }[p.type];
    }
  }};
  border-top: ${(p) => p.type === 'basic' ? `3px solid ${p.active ? palette.greenMedium : palette.grayLight}` : ''};
  border-top-left-radius: ${(p) => ['thin', 'thin-line'].includes(p.type) ? 15 : 0}px;
  border-top-right-radius: ${(p) => ['thin', 'thin-line'].includes(p.type) ? 15 : 0}px;
  cursor: ${(p) => p.active ? 'default' : 'pointer'};
  :after {
    content: ' ';
    display: ${(p) => ['line', 'thin-line'].includes(p.type) && p.active ? 'block' : 'none'};
    position: absolute;
    left: ${(p) => ['line', 'thin-line'].includes(p.type) ? `${p.linePadding || 40}px` : '0'};
    right: ${(p) => ['line', 'thin-line'].includes(p.type) ? `${p.linePadding || 40}px` : '0'};
    bottom: 0;
    height: 3px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background: ${palette.greenMedium};
    box-shadow: 2px 2px 2px #80808059;
  }
  .mobile .adaptive &:first-of-type:after {
    left: 10px;
  }

  span {
    flex: 1;
    display: flex;
    justify-content: center;
  }
`;

export const Body = styled.div<{ visibleBlock: number; type: TabsType; border?: string }>`
  width: 100%;
  height: calc(100% - 60px);
  ${(p) => p.type === 'line' ? `border-top: ${p.border || `2px solid ${palette.grayBg}`};` : ''}
  & > :not(:nth-child(${(p) => p.visibleBlock})) {
    display: none;
  }
`;

export const Counter = styled.div`
  width: 20px;
  height: 27px;
  text-align: center;
  margin: 0 0 16px 10px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-image: url(${CounterImage});
`;
