import React from 'react';
import { Popup } from '..';
import { Button } from '../../ui';
import { Buttons, SentImage, Subtitle, Title } from './style';

interface Props {
  email: string;
  mobile?: boolean;
  myTicketsDisabled?: boolean;
  onOpenTickets?: () => void;
  onClose: () => void;
}

export const SupportRequestCreated = ({ mobile, email, myTicketsDisabled, onOpenTickets, onClose }: Props) => {

  return <Popup
    padding={mobile ? [30, 20, 30, 20] : [60, 50, 60, 50]}
    size={[600, 397]}
    adaptive
    maxWidthMobile='calc(100vw - 10px)'
  >
    <SentImage/>
    <div>
      <Title center><span>Thank you</span> for your request</Title>
      <Subtitle center>You will receive a message to <span>{email}</span> soon</Subtitle>
    </div>
    <Buttons singleButton={!onOpenTickets}>
      {!!onOpenTickets && <Button variant='secondary' disabled={myTicketsDisabled} onClick={onOpenTickets}>My Tickets</Button>}
      <Button variant='primary' onClick={onClose}>Ok</Button>
    </Buttons>
  </Popup>;

};
