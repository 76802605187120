import React, { MutableRefObject, useEffect, useRef, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { BackgroundBlur, Window, CloseButton, Content } from './style';

interface Props {
  size?: [number | null, number | null];
  title?: string;
  padding?: [number, number, number, number];
  onClose?(): void;
  closeOnClickOutside?: boolean;
  paddingTitle?: number;
  maxHeight?: number;
  closeButtonColor?: string;
  bottomContent?: ReactNode;
  topOffset?: number;
  noMarginTop?: boolean;
  customTitle?: boolean;
  closeButtonTop?: number;
  maxWidthMobile?: string;
  noBlur?: boolean;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  adaptive?: boolean;
  notFixed?: boolean;
  noMaxHeight?: boolean;
  noPortal?: boolean;
}

export const Popup = ({ size, title, padding = [0, 0, 0, 0], children, closeOnClickOutside, paddingTitle,
  maxHeight, closeButtonColor, bottomContent, topOffset, noMarginTop, noBlur, customTitle, top, left, right, bottom,
  closeButtonTop, maxWidthMobile, adaptive, notFixed, noMaxHeight, noPortal, onClose }: React.PropsWithChildren<Props>) => {

  const windowRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const [parentOffset, setParentOffset] = useState<{ top: number, right: number, bottom: number, left: number }>();

  useEffect(() => {
    let element: HTMLElement = windowRef.current;
    if (element) {
      while (element.parentElement) {
        const { transform } = window.getComputedStyle(element);
        if (transform.includes('matrix') || transform.includes('scale')) {
          const elementBoundingClientRect = element.getBoundingClientRect();
          const bodyBoundingClientRect = (document.querySelector('body') as HTMLBodyElement).getBoundingClientRect();
          setParentOffset({
            top: elementBoundingClientRect.top,
            right: bodyBoundingClientRect.right - elementBoundingClientRect.right,
            bottom: bodyBoundingClientRect.bottom - elementBoundingClientRect.bottom,
            left: elementBoundingClientRect.left,
          });
          return;
        }
        element = element.parentElement;
      }
    }
  }, [windowRef]);

  useEffect(() => {
    if (closeOnClickOutside) {
      const handler = (event: MouseEvent) => {
        if (windowRef.current && !windowRef.current.contains(event.target as HTMLElement)) {
          onClose?.();
        }
      };
      document.addEventListener('mousedown', handler);
      return () => document.removeEventListener('mousedown', handler);
    }
  }, [closeOnClickOutside, onClose, windowRef]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Escape' ) {
        onClose?.();
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [onClose]);

  useEffect(() => {
    if ( windowRef?.current && !notFixed ) {
      windowRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [windowRef, notFixed]);

  // TBD: confirmation in units can run callbacks if popup is in portal - fix
  return (document.querySelector('body') && !noPortal) ? createPortal(<BackgroundBlur
    noBlur={noBlur}
    notFixed={notFixed}
    parentDOMRect={parentOffset}
    className={adaptive ? 'adaptive' : undefined}
  >
    <Window
      ref={windowRef}
      className='popup'
      padding={padding}
      size={size}
      title={title}
      paddingTitle={paddingTitle}
      maxHeight={maxHeight}
      topOffset={topOffset}
      noMarginTop={noMarginTop}
      maxWidthMobile={maxWidthMobile}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      {onClose && <CloseButton hasTitle={!!title || customTitle} onClick={onClose} color={closeButtonColor} closeButtonTop={closeButtonTop} />}
      <Content padding={padding} noMaxHeight={noMaxHeight}>
        {children}
      </Content>
      {bottomContent}
    </Window>
  </BackgroundBlur>, document.querySelector('body') as HTMLBodyElement) : <BackgroundBlur
    noBlur={noBlur}
    notFixed={notFixed}
    parentDOMRect={parentOffset}
    className={adaptive ? 'adaptive' : undefined}
  >
    <Window
      ref={windowRef}
      className='popup'
      padding={padding}
      size={size}
      title={title}
      paddingTitle={paddingTitle}
      maxHeight={maxHeight}
      topOffset={topOffset}
      noMarginTop={noMarginTop}
      maxWidthMobile={maxWidthMobile}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      {onClose && <CloseButton hasTitle={!!title || customTitle} onClick={onClose} color={closeButtonColor} closeButtonTop={closeButtonTop} />}
      <Content padding={padding} noMaxHeight={noMaxHeight}>
        {children}
      </Content>
      {bottomContent}
    </Window>
  </BackgroundBlur>;
};
