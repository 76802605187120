import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Calendar, Text, TwoLevelDropdown } from './components';
import { toValue } from './helpers';
import { Auto, AutoWrapper, Cal, FieldContainer, InputWrapper, Mandatory, Pen, Placeholder, SignWrapper, Unit } from './style';
import { CalendarValue, ComponentBaseProps, MultipleCalendarValue, NumberValue, Props, TextProps, TextValue, DropdownValue, MultipleDropdownValue, DropdownProps, CalendarProps, MultipleCalendarProps, MultipleDropdownProps, NumberProps, TwoLevelDropdownProps, TwoLevelDropdownValue } from './types';

export const Input = (props: Props) => {

  const fieldRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const { mandatory, disabled, expert, placeholder, hidePlaceholder, unit, unitSize, sign, noPadding, style,
    inline, marginBottom, preventFocus, preventFocusByFieldClick, minWidth, maxWidth,
    color, colorOnHover, className, marginTop, lang = 'en', onClick, onFocus, onBlur } = props;

  const wrapperRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(props.value);

  useEffect(() => setValue(toValue(props.type, props.value, lang)), [lang, props.value, props.type]);

  useEffect(() => {
    if (focus) {
      fieldRef.current?.focus?.();
    }
  }, [fieldRef, focus]);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLDivElement)) {
        // fieldRef?.current?.blur();
        setFocus(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [wrapperRef]);

  useEffect(() => {
    if (focus) {
      onFocus?.();
    } else {
      onBlur?.();
    }
  }, [focus, onFocus, onBlur]);

  const filled = useMemo(() => {
    if (props.type === 'dropdown') {
      return !!(value as DropdownValue)?.value;
    } else {
      return !!value;
    }
  }, [props.type, value]);

  const restrictedValues = useMemo(() => 'restrictedValues' in props ? props.restrictedValues?.filter((cur) => cur !== value).reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {} as { [name: string]: boolean }) || {} : {}, [props, value]);

  const isRestricted = typeof value === 'string' && focus && restrictedValues[value];
  const isError = isRestricted || (props.error && !focus);
  const isAutoCalculated = ('autoCalculatedValue' in props) && value === props.autoCalculatedValue && !isError;

  const componentProps = useMemo(() => ({
    currentValue: value,
    setCurrentValue: setValue,
    focus,
    setFocus,
  } as ComponentBaseProps), [focus, value]);

  return <InputWrapper
    className={className}
    title={props.title}
    isAutoCalculated={isAutoCalculated}
    style={style}
    error={isError}
    ref={wrapperRef}
    marginBottom={marginBottom}
    marginTop={marginTop}
    color={color}
    colorOnHover={focus ? color : colorOnHover}
    minWidth={minWidth}
    maxWidth={maxWidth}
  >
    {(!props.type || props.type === 'text' || props.type === 'number' || props.type === 'password') && <FieldContainer
      disabled={disabled}
      expert={expert}
      error={isError}
      noUnderline={!focus && !!inline}
      auto={!!props.autoCalculatedValue}
      onClick={!focus ? (ev) => {
        onClick?.(ev);
        if ( !(preventFocusByFieldClick || preventFocus || disabled) ) {
          setFocus(true);
        }
      } : undefined}
      noHorizontalPadding={props.noHorizontalPadding || ((!unit || ['%', 'sq ft', 'sq.m.'].includes(unit)) && !inline)} // TBD: remove hardcode here and below
      noPadding={noPadding}
      unitSize={unit ? unit.length * (unitSize || 7) : undefined}
    >
      <Text
        {...props as TextProps | NumberProps}
        {...componentProps as ComponentBaseProps<TextValue | NumberValue>}
      />
    </FieldContainer>}

    {(props.type === 'dropdown' || props.type === 'multiple-dropdown') && <FieldContainer
      disabled={disabled}
      expert={expert}
      error={isError}
      onClick={onClick}
      noUnderline={!focus && !!inline}
      auto={!!props.autoCalculatedValue}
      noHorizontalPadding={props.noHorizontalPadding || (!unit && !inline)}
      noPadding={noPadding}
    >
      <Dropdown
        {...props as DropdownProps | MultipleDropdownProps}
        {...componentProps as ComponentBaseProps<DropdownValue | MultipleDropdownValue>}
      />
    </FieldContainer>}

    {(props.type === 'calendar' || props.type === 'calendar-range') && <FieldContainer
      disabled={disabled}
      expert={expert}
      error={isError}
      onClick={onClick}
      auto={!!props.autoCalculatedValue}
      noHorizontalPadding={!unit && !inline}
      noPadding={noPadding}
    >
      <Calendar
        {...props as CalendarProps | MultipleCalendarProps}
        {...componentProps as ComponentBaseProps<CalendarValue | MultipleCalendarValue>}
      />
    </FieldContainer>}

    {props.type === 'two-level-dropdown' && <FieldContainer
      disabled={disabled}
      expert={expert}
      error={isError}
      onClick={onClick}
      auto={!!props.autoCalculatedValue}
      noHorizontalPadding={!unit && !inline}
      noPadding={noPadding}
    >
      <TwoLevelDropdown
        {...props as TwoLevelDropdownProps}
        {...componentProps as ComponentBaseProps<TwoLevelDropdownValue>}
      />
    </FieldContainer>}

    {((mandatory || placeholder) && !(hidePlaceholder && (filled || focus))) && <Placeholder
      active={filled || focus || props.placeholderOnTop || ( props.type === 'password' && !value && !props.newPassword )}
      disabled={disabled}
      expert={expert}
      isDefaultCursor={props.type === 'dropdown'}
      onClick={() => setFocus(true)}
      noPadding={noPadding}
    >
      {mandatory && <Mandatory>*</Mandatory>}
      <span>{placeholder}</span>
    </Placeholder>}

    {unit && (filled || focus) && (!inline || focus) && <Unit disabled={disabled} hasPlaceholder={!!placeholder} noPadding={noPadding} after={['%', 'sq ft', 'sq.m.'].includes(unit)}>{unit}</Unit>}

    {props.autoCalculatedValue && <AutoWrapper
      isAutoCalculated={isAutoCalculated}
      className='autocalculate'
      color={props.autoCalculatedHint?.color}
      showHint={props.autoCalculatedHint !== undefined}
      hintTop={props.autoCalculatedHint?.top}
      hintLeft={props.autoCalculatedHint?.left}
      hintWidth={props.autoCalculatedHint?.width}
      noPadding={props.noPadding}
      onClick={!disabled ? () => {
        if ( value !== toValue(props.type, props.autoCalculatedValue, lang) ) {
          setValue(toValue(props.type, props.autoCalculatedValue, lang));
          if (props.onChange && props.autoCalculatedValue) {
            if (props.type === 'dropdown') {
              props.onChange(props.autoCalculatedValue as DropdownValue);
            } else if (props.type === 'multiple-dropdown') {
              props.onChange(props.autoCalculatedValue as MultipleDropdownValue);
            } else if (props.type === 'calendar') {
              props.onChange(new Date(props.autoCalculatedValue).toDateString());
            } else if (props.type === 'calendar-range') {
              props.onChange(props.autoCalculatedValue);
            } else if (props.type === 'two-level-dropdown') {
              props.onChange(props.autoCalculatedValue);
            } else {
              props.onChange(props.autoCalculatedValue);
            }
          }
        }
      } : undefined}
    >
      <Auto/>
      <div>
        <div>{props.autoCalculatedHint?.title || ''}</div>
        <div>{props.autoCalculatedHint?.text || ''}</div>
      </div>
    </AutoWrapper>}

    {!props.autoCalculatedValue && !!sign && !inline && <SignWrapper hasPlaceholder={!!placeholder}>
      {(!props.type || props.type === 'text' || props.type === 'number') && <Pen className='sign'/>}
      {props.type === 'calendar' && <Cal className='sign'/>}
    </SignWrapper>}
  </InputWrapper>;
};
