import styled from 'styled-components';
import theme from '../../../themes';
export { ReactComponent as CheckImage } from './images/check.svg';

const { palette } = theme;

export const CheckboxStyle = styled.div<{ marginBottom?: string; fontSize?: string }>`
  display: flex;
  align-items: center;
  label {
    margin: 0 0 0 5px;

    ${(p) => p.fontSize && `
      font-size: ${p.fontSize};
    `}
  }

  ${(p) => p.marginBottom && `
    margin-bottom: ${p.marginBottom};
  `}
`;

export const Check = styled.div<{ size: number; checked?: boolean; radio?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: ${(p) => p.radio ? '50%' : Math.round(p.size / 5) + 'px'};
  border: 1px solid ${(p) => p.checked ? palette.greenMedium : palette.grayMedium};
  background-color: ${(p) => p.checked ? palette.greenMedium : ( p.disabled ? palette.whiteMedium : 'white' )};
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
  svg {
    width: 70%;
  }
`;

export const RadioCheck = styled.div`
  width: 40%;
  height: 40%;
  border-radius: 50%;
  background: white;
`;
