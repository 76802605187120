import React, { ReactNode } from 'react';
import { HoverHintStyle, Hint } from './style';

export type HoverHintType = 'left' | 'right' | 'top' | 'bottom';

interface Props {
  label: string;
  type: HoverHintType;
  noHint?: boolean;
  children: ReactNode | ReactNode[];
  marginTop?: number;
  space?: number;
  alwaysOn?: boolean;
  maxWidth?: number;
}

export const HoverHint = ({ label, type, noHint, children, marginTop, space, alwaysOn, maxWidth }: Props) => {
  return <HoverHintStyle noHint={noHint} alwaysOn={alwaysOn}>
    {children}
    <Hint className='hint' type={type} marginTop={marginTop} space={space} maxWidth={maxWidth}>{label}</Hint>
  </HoverHintStyle>;
};
