import { PaymentSchedule } from '../../../../../types';
import { monthLabels } from './schedule';

export const genLabel = (schedule: PaymentSchedule, noYear?: boolean) => {
  const months = Object.entries(schedule).filter(([, value]) => !!value).map(([date]) => date);

  // Group dates by year
  const groupedByYear = months.reduce((acc, date) => {
    const [year, month] = date.split('-');
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(Number.parseInt(month, 10));
    return acc;
  }, {});

  // Helper function to format months of a single year
  const formatYearMonths = (months) => {
    months.sort((a, b) => a - b);
    const ranges: string[] = [];
    let last = null;
    let rangeStart = null;

    months.forEach(month => {
      if (last === null || month === last + 1) {
        if (rangeStart === null) {
          rangeStart = month;
        }
      } else {
        if (rangeStart === last) {
          ranges.push(monthLabels[(rangeStart || 0) - 1]);
        } else {
          ranges.push(`${monthLabels[(rangeStart || 0) - 1]}-${monthLabels[last - 1]}`);
        }
        rangeStart = month;
      }
      last = month;
    });

    // Handle the last range or month
    if (rangeStart !== null) {
      if (rangeStart === last) {
        ranges.push(monthLabels[rangeStart - 1]);
      } else {
        ranges.push(`${monthLabels[rangeStart - 1]}-${monthLabels[(last || 0) - 1]}`);
      }
    }

    return ranges.join(', ');
  };

  // Format the grouped data
  const result: string[] = [];
  for (const year in groupedByYear) {
    const formattedMonths = formatYearMonths(groupedByYear[year]);

    result.push(noYear ? formattedMonths : `${year} - ${formattedMonths}`);
  }

  return result.join(', ');
};
