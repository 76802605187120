import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  > button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const Error = styled.p`
  font-size: 11px;
  color: ${palette.red};
  margin: 0;
  height: 11px;
`;
