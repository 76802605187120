import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const PieStyle = styled.div<{ size: number }>`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div:first-child {
    width: ${(p) => p.size}px;
  }
  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 14px;
      border-bottom: 1px solid ${palette.grayLight};
    }
    span:last-child {
      border-bottom: none;
    }
  }
`;

export const Circle = styled.div<{ color: string }>`
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  background: ${(p) => p.color};
  border-radius: 50%;
  margin-right: 5px;
`;
