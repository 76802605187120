import { useMemo } from 'react';
import { chartRound, rounding, useLocale } from '..';

const barColors = ['#CAD3F4', '#BAC3E8', '#A9B6E4'];

const columns = ['Type', 'Total funds', 'Cost', 'Rate', 'Fees', 'Profit share', 'LTC', 'LTGDV'];

// TBD: add types
export const useFinanceResult = (input, output) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '' });

  const unit = useMemo(() => +output?.["Total costs"] >= 1000000 ? 'M' : 'k' as 'M' | 'k', [output]);

  const totalFinanceAmounts = useMemo(() => output?.["Finance costs breakdown"]?.reduce?.((sum, item) => sum + item?.amount, 0), [output]);
  const chartFinanceAmounts = Object.fromEntries((output?.["Finance costs breakdown"]?.map?.((item) => [item.type, chartRound(unit, item.amount)]) || []));

  const chartData = useMemo(() => [{
    name: "Net profit",
    "Net profit": chartRound(unit, output?.['Net profit']),
    line: chartRound(unit, output?.['Net profit']),
  }, {
    name: "Costs & Financing",
    ...chartFinanceAmounts,
    line: chartRound(unit, totalFinanceAmounts),
  }], [output, chartFinanceAmounts, totalFinanceAmounts, unit]);

  const bars = useMemo(() => ([{
    name: 'Net profit',
    color: '#BADCE5',
  },
  ...(output?.["Finance costs breakdown"]?.map?.((item, i) => ({ name: item.type, color: barColors[i % barColors.length] })) || []),
  ]), [output]);

  const finance_type_array = useMemo(() => output?.['Finance costs breakdown']?.map?.((item) => item.type), [output]);

  const funding_array = useMemo(() => finance_type_array?.map?.((type, i) => ({
    type,
    profit_share: input?.['funding_array']?.[i]?.profit_share,
    interest: input?.['funding_array']?.[i]?.interest,
    fees: input?.['funding_array']?.[i]?.fees,
    rolled_up_interest: input?.['funding_array']?.[i]?.rolled_up_interest,
    section: input?.['funding_array']?.[i]?.section,
  }), []), [finance_type_array, input]);

  const finance_table_data = useMemo(() => funding_array?.map?.((item, i) => ({
    type: item.type,
    'total funds': output?.['Finance costs breakdown']?.[i]?.amount,
    rate: item.interest,
    fees: item.fees,
    'profit share': item.profit_share,
    ltc: output?.['Finance costs breakdown']?.[i]?.ltc,
    ltgdv: output?.['Finance costs breakdown']?.[i]?.ltgdv,
    cost: output?.['Finance costs breakdown']?.[i]?.cost,
    rolled_up_interest: item.rolled_up_interest,
    section: item.section,
  })), [output, funding_array]);

  const tableData = useMemo(() => {
    const totals = (finance_table_data || []).reduce((acc, cur) => ({
      ...acc,
      'total funds': acc['total funds'] + (cur['total funds'] || 0),
      'profit share': acc['profit share'] + +rounding((cur['profit share'] || 0)),
      ltc: acc.ltc + +rounding(cur.ltc || 0, '%'),
      ltgdv: acc.ltgdv + +rounding(cur.ltgdv || 0, '%'),
      cost: acc.cost + (cur.cost || 0),
    }), {
      rate: '-',
      fees: '-',
      type: 'Total',
      'total funds': 0,
      'profit share': 0,
      ltc: 0,
      ltgdv: 0,
      cost: 0,
      rolled_up_interest: false,
    });

    return [...(finance_table_data || []), totals]?.map?.((item) => ({
      ...item,
      'total funds': item['total funds'] !== undefined ? `${toCurrency(item['total funds'])}${item.rolled_up_interest ? '*' : ''}` : 'N/A',
      rate: item.rate === '-' ? '-' : (item.rate !== undefined ? `${rounding(item.rate, '%')}%` : 'N/A'),
      fees: item.fees === '-' ? '-' : (item.fees !== undefined ? `${rounding(item.fees, '%')}%` : 'N/A'),
      'profit share': item['profit share'] !== undefined && item['section'] === 'Equity' ? `${rounding(item['profit share'], '%')}%` : '-',
      ltc: item.ltc !== undefined ? `${rounding(item.ltc, '%')}%` : 'N/A',
      ltgdv: item.ltgdv !== undefined ? `${rounding(item.ltgdv, '%')}%` : 'N/A',
      cost: item.cost !== undefined ? `${toCurrency(item.cost)}` : 'N/A',
    }));
  }, [finance_table_data, toCurrency]);

  const hint = useMemo(() => finance_table_data?.some?.((item) => item.rolled_up_interest) ? '* used rolled up interest' : undefined, [finance_table_data]);

  return { columns, tableData, hint, chartData, unit, bars };
};
