import styled from 'styled-components';
import { ReactComponent as LeftRightImage } from './images/left-right.svg';
import theme from '../../themes';

const { palette } = theme;

export const Wrapper = styled.div<{width: number; height: number}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  margin: 0 0px;
  position: relative;
`;

export const Slider = styled.div<{width: number; height: number}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  overflow: hidden;
  margin: 0 0px;
  position: relative;
  border-radius: 15px;
`;

export const SliderContent = styled.div<{ offset: number; transition: number; width: number; height: number }>`
  ${(p) => p.offset ? `transform: translateX(-${p.offset}px);` : ''}
  ${(p) => p.transition ? `transition: transform ease-out ${p.transition}s; ` : ''}
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  display: flex;
`;

export const Slide = styled.div<{url: string; width: number; height: number; portrait?: boolean}>`
  position: relative;

  > div:nth-of-type(1) {
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
    background-image: url('${(p) => p.url}');
    background-color: ${palette.whiteMedium};
    background-size: cover;

    ${(p) => p.portrait ? `
      filter: blur(6px) grayscale(0.4) opacity(0.8);
      background-position: center;
    ` : ''};
  }

  > div:nth-of-type(2) {
    display: flex;
    position: absolute;
    top: 0;
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
    background-image: url('${(p) => p.url}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }
`;

export const PhotoLeftRight = styled(LeftRightImage)`
  position: absolute;
  width: 46px;
  height: 46px;
  cursor: pointer;
  opacity: .7;
  transition: all .5s ease;
  :hover {
    opacity: 1;
  }
`;

export const PhotoLeft = styled(PhotoLeftRight)`
  @media print {
    display: none;
  }

  position: absolute;
  top: calc(50% - 23px);
  left: -25px;
`;

export const PhotoRight = styled(PhotoLeftRight)`
  @media print {
    display: none;
  }

  transform: scaleX(-1);
  position: absolute;
  top: calc(50% - 23px);
  right: -25px;
`;
