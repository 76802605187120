import React from 'react';
import { CSSProperties } from 'styled-components';
import { Hint } from './horizontal-line.style';

interface Props {
  text: string;
  space?: number;
  marginTop?: string;
  marginBottom?: string;
  style?: CSSProperties;
}

export const HorizontalLine = ({ text, style, space, marginTop, marginBottom }: Props) => {
  return <Hint style={style} space={space} marginTop={marginTop} marginBottom={marginBottom}>
    <hr /><span>{text}</span><hr />
  </Hint>;
};

export default HorizontalLine;
