import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { setIsFormChanged } from '../../../../../redux/calculate/calculate';
import { CalendarIcon, DatePickerLabel, DatePickerWrapper, DatepickerInput, ErrorLabel } from './style';

interface Props {
  placeholder?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  height?: string;
  disabled?: boolean;
  required?: boolean;
  isDirty?: boolean;
  errorMessage?: string;
  value?: string;
  onChange?: (value?: string) => void;
}

export const DatePicker = ({ placeholder, width, minWidth, color, height, disabled, required, isDirty, errorMessage, value, onChange }: Props) => {
  const dispatch = useDispatch();

  const [isCurrentlyFocused, setIsCurrentlyFocused] = useState(false);

  return <DatePickerWrapper
    width={width}
    minWidth={minWidth}
    height={height}
    color={color}
    disabled={disabled}
    error={!!errorMessage && isDirty}
  >
    <ReactDatePicker
      showYearDropdown
      showMonthDropdown
      disabled={disabled}
      minDate={new Date()}
      selected={value ? new Date(value) : null}
      onFocus={() => setIsCurrentlyFocused(true)}
      onBlur={() => setIsCurrentlyFocused(false)}
      onChange={(date) => {
        if (onChange) {
          if (date) {
            dispatch(setIsFormChanged(true));
          }
          onChange(moment(date).format('YYYY-MM-DD'));
        }
      }}
      popperProps={{ strategy: 'fixed' }}
      customInput={<DatepickerInput>
        {value ? moment(value).format('YYYY-MM-DD') : ''}
        {(errorMessage && isDirty) && <ErrorLabel>*{errorMessage}</ErrorLabel>}
        <DatePickerLabel focused={isCurrentlyFocused || !!value}>{`${placeholder}${required ? '*' : ''}`} </DatePickerLabel>
        <CalendarIcon />
      </DatepickerInput>}
    />
  </DatePickerWrapper>;
};
