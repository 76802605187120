import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Country, Currency, currencyByCountry, langByCurrency } from '../../../../types';
import { RootState } from '../../redux';

const resultMenuItems = ['Site & purchase', 'Proposed units', 'Construction', 'Finance', 'Timeline & cashflow',
  'Sales', 'Rental', 'Multiples', 'Sensitivity analysis', 'Local Area', 'Local Market', 'Comparables', 'Logs', 'Attachments'] as const;

const reportMenuItems = [
  'Site & purchase', 'Proposed units', 'Construction', 'Finance', 'Timeline & cashflow',
  'Sales', 'Rental', 'Project Multiples', 'Sensitivity analysis', 'Local area', 'Local Market', 'Comparables'] as const;

interface Props {
  defaultCurrencyValue?: string;
}

export const useLocale = ({ defaultCurrencyValue }: Props) => {
  const currencyScenario = useSelector((state: RootState) => state.calculate.analysePage.editingData.currency);
  const countryScenario = useSelector((state: RootState) => state.calculate.analysePage.editingData.country);
  const resultDataState = useSelector((state: RootState) => state.calculate.resultData);

  const resultData = useMemo(() => resultDataState && resultDataState[0] === '{' ? JSON.parse(resultDataState) : {}, [resultDataState]);

  const country = useMemo(() => (countryScenario || resultData?.input?.country || 'SWE') as Country, [countryScenario, resultData?.input?.country]);

  const currency = useMemo(() => ((currencyScenario || resultData?.input?.currency || (country ? currencyByCountry[country] : 'SEK')) || 'SEK') as Currency,
    [currencyScenario, resultData?.input?.currency, country]);

  const toCurrency = useCallback((val?: number | string, defaultValue?: string, postfix?: string, countryParam?: Country, currencyParam?: Currency) => {
    const countryLocal = countryParam || country;
    const currencyLocal = currencyParam || (countryParam ? currencyByCountry[countryLocal] : currency) || currency;

    const result = (val === undefined || val === null) ? defaultValue || defaultCurrencyValue : new Intl.NumberFormat(langByCurrency[currencyLocal || currency] || 'en-UK', {
      style: 'currency',
      currency: currencyLocal,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 0,
    }).format(typeof val === 'number' ? val : +val);

    return `${result}${postfix || ''}`;
  }, [country, currency, defaultCurrencyValue]);

  const menuFilter = useCallback((item: string) => {
    if (['SWE', 'ESP', 'DEU'].includes(country) && item.toLowerCase() === 'rental') {
      return false;
    } else if (['ESP', 'DEU'].includes(country) && ['local area', 'local market', 'comparables'].includes(item.toLowerCase())) {
      return false;
    }

    return true;
  }, [country]);

  const currencySign = useMemo(() => toCurrency(0).replace('0', '').trim(), [toCurrency]);

  return {
    toCurrency,
    currencySign,
    resultMenu: resultMenuItems.filter(menuFilter),
    reportMenu: reportMenuItems.filter(menuFilter),
  };
};
