import React, { Fragment, ReactNode, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { escapeRegExp } from '../../../../shared';
import { Checkbox } from '../ui';
import { SearchWrapper, Input, Options, Option, Item, ItemTitle, ItemSubtitle } from './style';

interface Selected {
  [id: string]: boolean;
}

interface CommonProps {
  list: Array<{
    id: string;
    title: string;
    subtitle?: string;
    prefix?: ReactNode | string;
  }>;
  value?: string;
  width?: string;
  placeholder?: string;
  withEmbeddedList?: boolean;
  chooseText?: string;
  showSearchListLength?: number;
  disabled?: boolean;
  onType?: (text: string) => void;
}

interface SingleOptionProps extends CommonProps {
  multiple?: false;
  onSelect?: (id: string) => void;
}

interface MultipleOptionsProps extends CommonProps {
  multiple: true;
  onSelect?: (selected: Selected) => void;
}

type Props = SingleOptionProps | MultipleOptionsProps;

export const Search = ({ list, width, placeholder, multiple, withEmbeddedList, chooseText, showSearchListLength, value: initialValue, disabled, onType, onSelect }: Props) => {

  const [value, setValue] = useState(initialValue || '');
  const [selected, setSelected] = useState<Selected>({});
  const [focus, setFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const variantsRef = useRef<HTMLUListElement>() as MutableRefObject<HTMLUListElement>;

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as HTMLElement) && variantsRef.current && !variantsRef.current.contains(event.target as HTMLElement)) {
        setFocus(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, [inputRef, variantsRef]);

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const filteredList = useMemo(() => {
    if ( value ) {
      return list.filter((item) => item.title.match(escapeRegExp(value)) || item.subtitle?.match(escapeRegExp(value))).slice(0, 5);
    } else if ( withEmbeddedList ) {
      return list;
    } else {
      return [];
    }
  }, [list, value, withEmbeddedList]);

  return <SearchWrapper width={width}>
    {(!showSearchListLength || (showSearchListLength || 0) <= list.length) && <Input
      ref={inputRef}
      type='text'
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onType?.(e.target.value);
      }}
      onFocus={!disabled ? () => setFocus(true) : undefined}
    />}
    {((focus && filteredList.length > 0) || withEmbeddedList) && !disabled && <Options
      width={(inputRef.current?.getBoundingClientRect().width || '300') + 'px'}
      withEmbeddedList={withEmbeddedList}
      ref={variantsRef}
    >
      {(value || withEmbeddedList) && filteredList.map((item, i) => {
        const title = item.title.length > 20 ? item.title.slice(0, 20)+ '…' : item.title;
        const subtitle = item.subtitle && item.subtitle.length > 20 ? item.subtitle.slice(0, 20)+ '…' : item.subtitle;
        const split = title.split(escapeRegExp(value));
        const matches = title.match(escapeRegExp(value));
        const splitSubtitle = subtitle?.split(escapeRegExp(value));
        const matchesSubtitle = subtitle?.match(escapeRegExp(value));
        return <Option
          key={i}
          withoutPadding={!!withEmbeddedList}
          onClick={multiple ? () => {
            const newSelected = { ...selected, [item.id]: !selected[item.id] };
            (onSelect as (selected: Selected) => void)?.(newSelected);
            setSelected(newSelected);
          } : () => {
            (onSelect as (id: string) => void)?.(item.id);
            setFocus(false);
            setValue(item.title);
          }}
        >
          <div>
            {item.prefix || null}
            <Item hasPrefix={!!item.prefix}>
              <ItemTitle>
                {split.map((part, j) => <Fragment key={j}>
                  {part}
                  {matches?.[j] && <b>{matches?.[j]}</b>}
                </Fragment>)}
              </ItemTitle>
              {subtitle && splitSubtitle && <ItemSubtitle>
                {splitSubtitle.map((part, j) => <Fragment key={j}>
                  {part}
                  {matchesSubtitle?.[j] && <b>{matchesSubtitle?.[j]}</b>}
                </Fragment>)}
              </ItemSubtitle>}
            </Item>
          </div>
          <div>
            {multiple ? <Checkbox
              value={!!selected[item.id]}
            /> : chooseText || 'Choose'}
          </div>
        </Option>;
      })}
    </Options>}
  </SearchWrapper>;
};
