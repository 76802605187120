import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const PointsStyle = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  width: ${(p) => p.width}px;
  height: 8px;
`;

export const Point = styled.div<{active?: boolean}>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(p) => p.active ? palette.greenMedium : palette.grayLight};
`;
