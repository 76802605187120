import { useCallback, useMemo } from 'react';
import { firstUppercase } from '../../../../shared';
import { LocalArea, LocalAreaStatType, School, Station } from '../../../../types';
import { handlePopup } from '../../features/calculator/components/project-summary/components/popups/utils';
import { useLocale } from './use-locale';

const columnsStations = ['Name', 'Terminus', 'Journey time to terminus', 'Trains per hour', 'Distance from site', 'Travel time from site'];
const columnsSchools = [
  { key: 'name', title: 'Name', width: '30%' },
  { key: 'type', title: 'Type', width: '30%' },
  { key: 'roll', title: 'School roll', width: '10%' },
  { key: 'grade', title: 'Grade', width: '10%' },
  { key: 'distance', title: 'Distance from site', width: '10%' },
  { key: 'travelTime', title: 'Travel time from site', width: '10%' },
];

interface Props {
  localArea?: LocalArea;
  stations?: Station[];
  schools?: School[];
  isReport?: boolean;
}

export const useLocalAreaResult = ({ localArea, stations, schools }: Props) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '-' });

  const checkDistrictInfo = useCallback((type: LocalAreaStatType) => {
    if (type === 'population') {
      return !!localArea?.districtInfo?.population || !!localArea?.districtInfo?.populationChanges || localArea?.districtInfo?.foreignBackground?.byType ||
        localArea?.districtInfo?.foreignBackground?.byLocation || localArea?.districtInfo?.migration ||localArea?.districtInfo?.highestEducationLevel;
    } else if (type === 'median-income') {
      return !!localArea?.districtInfo?.medianIncome;
    } else if (type === 'unemployment-rate') {
      return !!localArea?.districtInfo?.openUnemploymentRate;
    } else if (type === 'housing') {
      return !!localArea?.districtInfo?.housingByTypeAndOwnership || !!localArea?.districtInfo?.housingInMultiDwellingAndOtherBuildingBySize;
    }
  }, [localArea]);

  const title = useMemo(() => localArea?.title ? firstUppercase(localArea?.title) : '', [localArea]);
  const narrative = useMemo(() => localArea?.narrative || '', [localArea]);
  const unemploymentRate = useMemo(() => localArea?.districtInfo?.openUnemploymentRate ?
    `${localArea?.districtInfo?.openUnemploymentRate.slice(-1)[0][3]}%` : undefined, [localArea]);

  const dataBase = useMemo(() => [{
    title: 'Settlement name',
    value: localArea?.districtInfo?.name || (localArea?.locality ? firstUppercase(localArea?.locality) : '-'),
    difference: localArea?.districtInfo?.name ? 'Stockholm' : undefined,
  }, {
    title: 'Population',
    value: localArea?.population || '-',
    difference: checkDistrictInfo('population') ? 'Details' : undefined,
    differenceCallback: checkDistrictInfo('population') ? () => handlePopup('local-area-population') : undefined,
  }, {
    title: 'Median Income',
    value: toCurrency(localArea?.local.meanIncomeLocal),
    difference: checkDistrictInfo('median-income') ? 'Details' : `Region: ${localArea?.region.meanIncomeRegion || '-'}`,
    differenceCallback: checkDistrictInfo('median-income') ? () => handlePopup('local-area-median-income') : undefined,
  }, {
    title: 'Median age',
    value: localArea?.local.medianAgeLocal || '-',
    difference: !checkDistrictInfo('housing') ? `Region: ${localArea?.region.medianAgeRegion || '-'}` : '',
  }, {
    title: checkDistrictInfo('unemployment-rate') ? 'Unemployment rate' : 'Crime rate',
    value: checkDistrictInfo('unemployment-rate') ? unemploymentRate : localArea?.local.crimesPer10000Local || '-',
    difference: checkDistrictInfo('unemployment-rate') ? 'Details' : `Region: ${localArea?.region.crimesPer10000Region || '-'}`,
    differenceCallback: checkDistrictInfo('unemployment-rate') ? () => handlePopup('local-area-unemployment-rate') : undefined,
  }], [localArea, unemploymentRate, toCurrency, checkDistrictInfo]);

  const dataStations = useMemo(() => (stations || []).map((item) => ({
    name: item.name,
    terminus: item.closestDestination.name,
    'journey time to terminus': item.closestDestination.meanTripTimeToClosest,
    'trains per hour': item.closestDestination.trainCounter,
    'distance from site': `${item.distance}km`,
    'travel time from site': item.time.message,
  })), [stations]);

  const dataSchools = useMemo(() => (schools || []).map((item) => ({
    name: item.school,
    type: item.type,
    roll: item.totalNumberOfPupils,
    grade: item.ofstedRating,
    distance: `${item.distance}km`,
    travelTime: item.time.message,
  })), [schools]);

  const markers = useMemo(() => [
    ...(stations || []).map((item) => ({
      location: {
        longitude: item.coordinates.geometry.coordinates[0],
        latitude: item.coordinates.geometry.coordinates[1],
      },
      info: {
        objectType: 'Station',
        name: item.name,
      },
    })),
    ...(schools || []).map((item) => ({
      location: {
        longitude: item.coordinates.geometry.coordinates[0],
        latitude: item.coordinates.geometry.coordinates[1],
      },
      info: {
        objectType: 'School',
        name: item.school,
      },
    })),
  ], [schools, stations]);

  return { title, narrative, dataBase, dataStations, dataSchools, columnsStations, columnsSchools, markers };
};
