import styled from 'styled-components';

export const Container = styled.div<{width?: string; margin?: string; fullscreen?: boolean}>`
  background: #f9fbfc;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;

  font-family: 'Basis Grotesque Pro';
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
  letter-spacing: 0em;

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
  ${(p) => p.fullscreen ? 'height: 500px;' : ''}

  > table {
    table-layout: fixed;
    position: relative;
  }
`;

export const Header = styled.th<{isScrolled?: boolean}>`
  background: #f9fbfc;
  color: #7e869b;
  position: sticky;
  z-index: 1;
  font-weight: 200;
  text-align: right;
  padding: 0;

  // chrome 1px bug
  top: -0.5px;
  padding-top: 0.5px;

  :first-of-type {
    left: 0;
    width: 170px;
    z-index: 2;

    ${(p) => p.isScrolled ? `
      box-shadow: 0px 0px 4px 0px #2B465614;
      box-shadow: 6px 0px 4px 0px #2B46560A;
    `: ''}
  }

  :not(:first-of-type) {
    width: 90px;
  }

  > div {
    height: 24px;
    padding-bottom: 8px;
    padding-right: 8px;
    border-bottom: 1px solid #dfe5eb;
  }
`;

export const BaseCell = styled.td<{isTotal?: boolean; isScrolled?: boolean}>`
  height: 34px;
  padding: 0;
  background: #f9fbfc;

  :first-of-type {
    width: 170px;
    position: sticky;
    left: 0;
    z-index: 1;
    white-space: nowrap;
    
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px;

      ${(p) => p.isScrolled ? `
        box-shadow: 0px 0px 4px 0px #2B465614;
        box-shadow: 6px 0px 4px 0px #2B46560A;
      `: ''}
    }
  }
  
  :not(:first-of-type) {
    width: 90px;
    text-align: right;

    > div {
      display: flex;
      align-items: center;
      padding-right: 8px;
      justify-content: flex-end;
    }
  }

  > div {    
    height: 34px;
    ${(p) => p.isTotal ? 'border-top: 1px solid #dfe5eb;' : ''}
  }
`;

export const FullscreenButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  margin: 0 auto 0 0px;

  border-radius: 5px;
  border: 1px solid #82899eaa;

  cursor: pointer;

  :hover {
    background: #82899e20;
  }

  :active {     
    background: #82899e30;
  }

  > svg {
    width: 12px;
    height: 12px;

    > path {
      stroke: #82899eaa;
      fill: #82899eaa;
    }
  }
`;
