import React, { MutableRefObject, useEffect, useRef } from 'react';
import Select from 'react-select';
import { InlineWrapper, Pen } from '../../style';
import { dropdownStyle } from './style';
import { DropdownProps, DropdownValue, MultipleDropdownValue, Props } from './types';

export const Dropdown = (props: Props) => {

  const fieldRef = useRef<Select>() as MutableRefObject<Select>;

  const { currentValue, focus, unit, disabled, inline, preventFocus, preventFocusByFieldClick, preventFocusBySignClick, setCurrentValue, setFocus } = props;
  const { openSelectTop, maxMenuHeight } = props as DropdownProps;

  useEffect(() => {
    if (focus) {
      fieldRef.current?.focus?.();
      fieldRef.current?.onMenuOpen?.();
    }
  }, [fieldRef, focus]);

  return <>
    {(inline && !focus) && <InlineWrapper
      right={inline === 'right'}
      className='field inline'
      onClick={preventFocusByFieldClick || preventFocus || disabled ? undefined : () => setFocus(true)}
    >
      {unit ? `${unit} ` : ''}
      {(currentValue as DropdownValue)?.label}
      <Pen className='sign' onClick={!preventFocusByFieldClick || preventFocus || preventFocusBySignClick || disabled ? undefined : () => setFocus(true)} />
    </InlineWrapper>}
    {(!inline || focus) && <Select
      ref={fieldRef}
      className='field'
      classNamePrefix='dropdown'
      isDisabled={disabled}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMenuClose={() => setFocus(false)}
      // menuIsOpen={true} // Comment out previous lines and uncomment this one to leave menu open
      menuPlacement={openSelectTop ? 'top' : 'auto'}
      closeMenuOnScroll={false}
      maxMenuHeight={maxMenuHeight}
      closeMenuOnSelect={true}
      styles={dropdownStyle}
      options={props.options}
      value={props.type === 'dropdown' ? (currentValue || null) as DropdownValue : (currentValue || null) as MultipleDropdownValue }
      onChange={(item) => {
        setFocus(false);
        if ( props.type === 'dropdown' ) {
          setCurrentValue(item as DropdownValue);
          props.onChange?.(item as DropdownValue);
        } else if ( props.type === 'multiple-dropdown' ) {
          setCurrentValue(item as MultipleDropdownValue);
          props.onChange?.(item as MultipleDropdownValue);
        }
      }}
      autocomplete={props.autoComplete}
      isMulti={props.type === 'multiple-dropdown'}
    />}
  </>;
};
