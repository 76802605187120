import React, { useMemo } from 'react';
import { LocalMarketMappingValues } from '../../../../types';
import { useLocale } from '../../shared';
import { Legend } from './style';

interface Props {
  marketMappingValues?: LocalMarketMappingValues;
}

interface LegendData {
  [color: string]: { min: number, max: number }
}

export const MarketMappingValuesLegend = ({ marketMappingValues }: Props) => {
  const { toCurrency } = useLocale({ defaultCurrencyValue: '-' });

  const legend: LegendData = useMemo(() => (marketMappingValues?.result || []).reduce((acc, cur) => ({
    ...acc,
    [cur.color]: {
      min: cur.price < acc[cur.color]?.min ? cur.price : acc[cur.color]?.min || cur.price,
      max: cur.price > acc[cur.color]?.max ? cur.price : acc[cur.color]?.max || cur.price,
    },
  }), {}), [marketMappingValues]);

  return <Legend>
    {Object.entries(legend).sort((a, b) => b[1].min - a[1].min).map(([color, { min, max }]) => <div key={color}>
      <span style={{ backgroundColor: color }}></span>
      <span>{`${toCurrency(min)} - ${toCurrency(max)}`}</span>
    </div>)}
  </Legend>;
};
