import { toast } from 'react-toastify';
import { setConfig, setConfigError, setConfigStatus, setCreateUserError, setCreateUserStatus, setSendFeedbackStatus, setShortUserList,
  setSupportTicket, setSupportTickets, setSupportUpdateStatus, setUser, setUserList, setUserListStatus, setUserStatus } from './app';
import { setLocaleOptions } from '../../../../shared';
import { Config, ShortUser, SupportTicketCreateRequest, User, Role  } from '../../../../types';
import { apiFetch } from '../api';
import { AppThunk } from '../store';

export const loadConfig = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setConfigStatus('load'));
    const config = await apiFetch('config', {
      method: 'get',
      returnError: true,
    }) as Config | { error: string };

    if ( 'error' in config ) {
      dispatch(setConfigStatus('error'));
      dispatch(setConfigError(config.error));
    } else {
      dispatch(setConfig(config));
      dispatch(setConfigStatus('success'));
      setLocaleOptions(config.locale);
    }

  } catch (error) {
    console.error(error);
    dispatch(setConfigStatus('error'));
    dispatch(setConfigError((error as Error).message));
  }
};

export const sendFeedback = (data: { name?: string; email?: string; point?: string; message?: string }): AppThunk => async (dispatch) => {
  try {
    dispatch(setSendFeedbackStatus('load'));

    const res = await apiFetch(`send-feedback`, {
      method: 'post',
      body: JSON.stringify(data),
    }) as { id?: string } | { error?: string };

    if ( 'error' in res ) {
      dispatch(setSendFeedbackStatus('error'));
    } else {
      dispatch(setSendFeedbackStatus('success'));
    }
  } catch (error) {
    console.error(error);
    dispatch(setSendFeedbackStatus('error'));
  }
};

export const getUserList = (short?: boolean): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserListStatus('load'));

    const userList = await apiFetch(`user/list${short ? '/short' : ''}`) as User[] | ShortUser[];

    if (!userList) {
      throw new Error('Can not update user list');
    }

    if (short) {
      dispatch(setShortUserList((userList as ShortUser[]).sort((a, b) => a.displayName.toUpperCase() < b.displayName.toUpperCase() ? -1 : 1 )));
    } else {
      dispatch(setUserList((userList as User[])));
    }
    dispatch(setUserListStatus('success'));
  } catch (error) {
    toast('Unable to get user list', { type: 'error' });
    console.error(error);
    dispatch(setUserList([]));
    dispatch(setUserListStatus('error'));
  }
};

export const getUser = (userId?: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserStatus('load'));

    const user = await apiFetch(`user${userId ? `/${userId}` : ''}`);

    if (!user) {
      throw new Error('Can not update user');
    }

    dispatch(setUser(user));
    dispatch(setUserStatus('success'));
  } catch (error) {
    toast('Unable to load user data', { type: 'error' });
    console.error(error);
    dispatch(setUser(null));
    dispatch(setUserStatus('error'));
  }
};

export const changeCurrentUserRole = (role: Role): AppThunk => async (dispatch, getState) => {

  const user = getState().App.user;

  if ( user ) {
    try {

      dispatch(setUser({
        ...user,
        role,
      }));

      const response = await apiFetch(`user/set-role/${user.id}`, {
        returnError: true,
        method: 'post',
        body: JSON.stringify({ role }),
      });

      if ('error' in response) {
        throw new Error(response.error);
      }

      toast('User successfully migrated');

    } catch (error) {
      toast('Unable to migrate user', { type: 'error' });
      console.error(error);
      dispatch(setUser(user));
      dispatch(setUserStatus('error'));
    }
  }
};

export const createUser = (email: string, name: string, surname: string, subscription: boolean): AppThunk => async (dispatch) => {
  try {

    const response = await apiFetch(`user/create`, {
      returnError: true,
      method: 'post',
      body: JSON.stringify({ email, name, surname, subscription }),
    });

    if ('error' in response) {
      dispatch(setCreateUserError(response.error));
      dispatch(setCreateUserStatus('error'));
      throw new Error(response.error);
    }

    dispatch(setCreateUserStatus('success'));
    toast('User created successfully');
  } catch (error) {
    dispatch(setCreateUserStatus('error'));
    console.error(error);
  }
};

export const deleteUser = (id: string): AppThunk => async (dispatch, getState) => {
  const userList = getState().App.userList;
  try {
    const resp = await apiFetch(`user/${id}`, {
      returnError: true,
      method: 'delete',
    });

    if (resp && resp.error) {
      throw new Error(resp?.error || 'Unknown error');
    }

    dispatch(setUserList(userList.filter((item) => item.id !== id)));
  } catch (error) {
    console.error(error);
  }
};

export const sendInvitation = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await apiFetch(`user/invite`, {
      returnError: true,
      method: 'post',
      body: JSON.stringify({ id }),
    });

    if ('error' in response) {
      throw new Error(response.error);
    }

    toast('Invitation sent successfully');
  } catch (error) {
    console.error(error);
  }
};

export const loadSupportTickets = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setSupportUpdateStatus('load'));

    const resp = await apiFetch('support', { returnError: true });

    if (resp && !resp.error) {
      dispatch(setSupportTickets(resp));
      dispatch(setSupportUpdateStatus('success'));
    } else {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    console.error(error);
    dispatch(setSupportUpdateStatus('error'));
  }
};

export const loadSupportTicket = (ticketId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setSupportUpdateStatus('load'));

    const resp = await apiFetch(`support/${ticketId}`, { returnError: true });

    if (resp && !resp.error) {
      dispatch(setSupportTicket(resp));
      dispatch(setSupportUpdateStatus('success'));
    } else {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    console.error(error);
    dispatch(setSupportUpdateStatus('error'));
  }
};

export const addSupportTicket = (ticket: SupportTicketCreateRequest, callback?: (error?: string) => void): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setSupportUpdateStatus('load'));

    const resp = await apiFetch(`support`, { method: 'post', returnError: true, body: JSON.stringify({
      type: ticket.type,
      message: ticket.message,
      email: ticket.email,
      captcha: ticket.captcha,
      documents: await Promise.all(ticket.documents.map(async (document) => {
        let binaryString = '';
        const bytes = new Uint8Array(await document.arrayBuffer());
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binaryString += String.fromCharCode(bytes[i]);
        }
        return {
          name: document.name,
          type: document.type,
          data: binaryString,
        };
      })),
    }) });

    if (resp && !resp.error) {
      const supportTickets = getState().App.supportTickets;
      dispatch(setSupportTickets([...supportTickets, resp]));
      dispatch(setSupportUpdateStatus('success'));
      callback?.();
    } else {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    console.error(error);
    dispatch(setSupportUpdateStatus('error'));
    callback?.((error as Error).toString());
  }
};

export const closeSupportTicket = (ticketId: string, callback?: (error?: string) => void): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setSupportUpdateStatus('load'));

    const resp = await apiFetch(`support/${ticketId}`, { method: 'delete', returnError: true });

    if (resp && !resp.error) {
      const supportTickets = getState().App.supportTickets;
      dispatch(setSupportTickets(supportTickets.map((ticket) => ticket.id !== ticketId ? ticket : { ...ticket, closedAt: new Date().toString() })));
      dispatch(setSupportUpdateStatus('success'));
      callback?.();
    } else {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    console.error(error);
    dispatch(setSupportUpdateStatus('error'));
    callback?.((error as Error).toString());
  }
};

export const sendReportLink = (email: string, reportId: string, projectName: string, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const resp = await apiFetch(`email/report-link`, { method: 'post', body: JSON.stringify({ email, reportId, projectName }), returnError: true });

    if (resp && !resp.error) {
      callback?.();
    } else {
      throw new Error(resp?.error || 'Unknown error');
    }
  } catch (error) {
    console.error(error);
    toast(`Unable to share report`, { type: 'error' });
  }
};
