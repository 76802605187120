import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { escapeRegExp } from '../../../../shared';
import { RootState } from '../../redux';
import { useLocalStorage } from '../../shared/hooks';
import { getPages } from '../../shared/main-menu';
import { Button } from '../ui';
import { Logo, Page } from './style';
import { Helmet } from 'react-helmet';

export const AdaptiveCheck = () => {

  const history = useHistory();
  const layout = useSelector((state: RootState) => state.App.layout);

  const curLocation = document.location.href.replace(escapeRegExp(document.location.origin), '');
  const pageName = getPages().find((item) => curLocation.match(item.match))?.name || document.location.pathname;

  const [hidden, setHidden] = useLocalStorage(`adaptive-check-${pageName}`, false, true);

  return hidden || !['mobile', 'tablet'].includes(layout) ? <Helmet>
    {/* Allow to scale only after user ignore the warning */}
    {['mobile', 'tablet'].includes(layout) && <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=yes'/>}
  </Helmet> : <Page>
    <Logo/>
    <div>
      <h1>Oops!</h1>
      <p>Page is not available<br/>for mobile devices yet</p>
    </div>
    <div>
      <Button variant='primary' onClick={() => history.go(-1)}>Back</Button>
      <Button variant='secondary' onClick={() => setHidden(true)}>Open desktop version</Button>
    </div>
  </Page>;
};
