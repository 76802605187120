import styled from 'styled-components';

export const Text = styled.div`
  .mobile .adaptive &&, .tablet .adaptive && {
    width: auto;
  }

  width: 322px;
  font-size: 14px;
  font-weight: 400;
`;

export const CheckboxContainer = styled.div<{marginBottom?: boolean}>`
  margin-top: 30px;

  ${(p) => p.marginBottom ? `
    margin-bottom: 10px;
  ` : ''}
`;

export const ButtonContainer = styled.div<{vertical?: boolean}>`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(p) => p.vertical ? 'column' : 'row'};

  > div {
    flex: 1;

    ${(p) => !p.vertical ? `
      :not(:last-of-type) {
        margin-right: 15px;
      }
    ` : ''}
  }
`;

export const ControlsContainer = styled.div`
  margin-top: 30px;
  font-size: 14px;
`;
