/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setPopupOpen } from "../../../../../../../../redux/calculate/calculate";
import PopupWrapper from "../../../../shared/components/popup-wrapper/PopupWrapper";
import CloseButton from "../../../../shared/elements/close-button/close-button";
import { RootState } from "../../../../../../../../redux/store";
import "./comparables-table-row.scss";
import { useLocale } from "../../../../../../../../shared";

export default function ComparablesTableRowPopup() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { toCurrency } = useLocale({ defaultCurrencyValue: '-' });

  const popupPayload = useSelector((state : RootState) => state.calculate.openedPopup.popupData);

  return (
    <PopupWrapper
      onPressEscape={() => dispatch(setPopupOpen(null))}
      onOutssideClick={() => dispatch(setPopupOpen(null))}
      popupHeight={658}
    >
      <div className="comparables-table-row-popup">
        <div className="comparables-header">
          {`${popupPayload?.propertyTypeFullDescription}, ${toCurrency(popupPayload?.price?.amount)}`}
          <CloseButton closeClicked={() => dispatch(setPopupOpen(null))} />
        </div>
        <div className="comparables-content">
          <div className="comparables-visual-content">
            <div
              className="comparables-block"
              style={{
                backgroundImage: `url(${
                  popupPayload?.propertyImages?.mainMapImageSrc
                    ? popupPayload.propertyImages.mainMapImageSrc
                    : ""
                })`,
              }}
            ></div>
            {/* TODO: blocked untill multiple images will be invented due to task NCV2-219 */}
            {/* <div className="comparables-block comparables-block_quarter">
                            <div className="image-wrapper"></div>
                            <div className="image-wrapper"></div>
                            <div className="image-wrapper"></div>
                            <div className="image-wrapper"></div>
                        </div> */}
            <div
              className="comparables-block"
              style={{
                backgroundImage: `url(${
                  popupPayload?.floorplans?.length > 0
                    ? popupPayload?.floorplans[0].url
                    : ""
                })`,
              }}
            ></div>
          </div>
          <div className="comparables-properties-content">
            <div className="comparables-properties-content-item">
              <p className="comparables-title">Address</p>
              <p className="comparables-property">
                {popupPayload?.displayAddress
                  ? popupPayload.displayAddress
                  : "-"}
              </p>
            </div>
            <div className="comparables-properties-content-item">
              <p className="comparables-title">Type</p>
              <p className="comparables-property">
                {popupPayload?.propertySubType
                  ? popupPayload.propertySubType
                  : "-"}
              </p>
            </div>
            <div className="comparables-properties-content-item">
              <p className="comparables-title">Price</p>
              <p className="comparables-property">{toCurrency(popupPayload?.price?.amount)}
              </p>
            </div>
            <div className="comparables-properties-content-item">
              <p className="comparables-title">Date listed</p>
              <p className="comparables-property">
                {popupPayload?.firstVisibleDate
                  ? t("intlDateTime", {
                    val: new Date(popupPayload?.firstVisibleDate),
                    formatParams: {
                      val: { year: "numeric", month: "long", day: "numeric" },
                    },
                  })
                  : "-"}
              </p>
            </div>
          </div>
          <div className="comparables-description-content">
            <p className="comparables-title">About the Property</p>
            <p className="comparables-description">
              {popupPayload?.summary ? popupPayload.summary : "-"}{" "}
            </p>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
