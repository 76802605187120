import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Checkbox } from '../../../ui/checkbox';
import { Field } from '../../style';
import { TwoLevelMenu, TwoLevelMenuItem, MultiSelectOptions } from './style';
import { Props } from './types';
import { ReactComponent as RemoveSVG } from '../../images/remove.svg';

export const TwoLevelDropdown = (props: Props) => {

  const fieldRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const twoLevelMenuRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const { currentValue, disabled, preventFocusByFieldClick, focus, level1Name, level2Name, menu, setFocus, setCurrentValue } = props;

  const setFilteredCurrentValue = (value: typeof currentValue) => {
    let newValue = value ? Object.keys(value).reduce((acc, level1) => {
      if (value[level1].length > 0) {
        acc[level1] = value[level1];
      }
      return acc;
    }, {} as typeof value) : null;
    newValue = newValue && Object.keys(newValue).length > 0 ? newValue : null;
    setCurrentValue(newValue);
    props.onChange?.(newValue);
  };

  const onFocus = (e: React.FocusEvent | React.MouseEvent) => {
    if (disabled || preventFocusByFieldClick) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setFocus(true);
  };

  const [selectedLevel1, setSelectedLevel1] = useState<string | null>();

  useEffect(() => {
    if (!selectedLevel1) {
      setSelectedLevel1(Object.keys(props.menu)[0]);
    }
  }, [props, selectedLevel1]);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (focus && ![twoLevelMenuRef, fieldRef].find((ref) => ref.current?.contains(e.target as HTMLElement)) ) {
        setFocus(false);
      }
    };
    window.addEventListener('click', clickOutside);
    return () => window.removeEventListener('click', clickOutside);
  }, [focus, setFocus]);

  useEffect(() => {
    if (focus) {
      fieldRef.current?.focus?.();
    } else {
      fieldRef.current?.blur?.();
    }
  }, [fieldRef, focus]);

  return <>
    <Field
      ref={fieldRef}
      className='field'
      value=''
      readOnly
      disabled={disabled}
      onFocus={onFocus}
    />
    {currentValue && <MultiSelectOptions hasPlaceholder={!!props.placeholder} count={Object.keys(currentValue).length} onClick={onFocus}>
      <div>
        {Object.keys(currentValue).filter((item) => currentValue[item].length > 0).map((item, i) => <div key={i}>
          <p>{item} ({currentValue[item].join(', ')})</p>
          <RemoveSVG onClick={() => setFilteredCurrentValue({ ...currentValue, [item]: [] })}/>
        </div>)}
      </div>
    </MultiSelectOptions>}
    {focus && <TwoLevelMenu ref={twoLevelMenuRef}>
      <div>
        <h2>{level1Name}</h2>
        <ul>
          {Object.keys(menu).map((level1, i) => <TwoLevelMenuItem active={level1 === selectedLevel1} key={i} onClick={() => setSelectedLevel1(level1)}>
            <Checkbox
              value={currentValue?.[level1]?.length === menu[level1].length}
              onChange={() => setFilteredCurrentValue({ ...currentValue, [level1]: currentValue?.[level1]?.length === menu[level1].length ? [] : menu[level1] })}
              label={level1}
              size={16}
            />
          </TwoLevelMenuItem>)}
        </ul>
      </div>
      <div>
        <h2>{level2Name}</h2>
        <ul>
          {selectedLevel1 && menu[selectedLevel1]?.map((level2, i) => <TwoLevelMenuItem key={i}>
            <Checkbox
              value={!!currentValue?.[selectedLevel1]?.includes(level2)}
              onChange={() => setFilteredCurrentValue({
                ...(currentValue || {}),
                [selectedLevel1]: [
                  ...(currentValue?.[selectedLevel1] || []).filter((item) => item !== level2),
                  ...(!currentValue?.[selectedLevel1]?.includes(level2) ? [level2] : []),
                ],
              })}
              label={level2}
              size={16}
            />
          </TwoLevelMenuItem>)}
        </ul>
      </div>
    </TwoLevelMenu>}
  </>;
};
