import React, { useState } from 'react';
import { Confirmation, Metrics, NoData } from '../../..';
import { SupportTicket } from '../../../../../../types';
import { Button } from '../../../ui';
import { Buttons, Card, Cards, DeleteImage, Green, Red } from './style';

interface Props {
  tickets: SupportTicket[];
  onDelete: (ticketId: string) => void;
  onClose: () => void;
}

export const Tickets = ({ tickets, onDelete, onClose }: Props) => {

  const [deleteId, setDeleteId] = useState<string | null>();

  return <>
    {deleteId && <Confirmation
      title='Do you want to close this ticket?'
      text='This can not be undone'
      buttons={[
        { text: 'Cancel', variant: 'secondary', onClick: () => setDeleteId(null) },
        { text: 'Close', variant: 'primary', onClick: () => {
          onDelete(deleteId);
          setDeleteId(null);
        } },
      ]}
      onClose={() => setDeleteId(null)}
    />}
    {tickets.length > 0 && <Cards>
      {tickets.map((ticket) => <Card key={ticket.id}>
        <div>
          <b>Ticket #{ticket.id}</b>
          {!ticket.closedAt && <span onClick={() => setDeleteId(ticket.id)}>Delete <DeleteImage/></span>}
        </div>
        <Metrics variant='horizontal' metrics={[
          { title: 'Date', value: new Date(ticket.createdAt), time: true },
          { title: 'Status', value: ticket.closedAt ? <Green>Resolved</Green> : <Red>In progress</Red> },
        ]}/>
        <div>
          {ticket.message}
        </div>
      </Card>)}
    </Cards>}
    {tickets.length === 0 && <NoData message='No tickets here yet' height='250px' inline/>}
    <Buttons>
      <Button variant='primary' onClick={onClose}>Close</Button>
    </Buttons>
  </>;
};
