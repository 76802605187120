import React, { useEffect, useMemo, useState } from 'react';
import { CSSProperties } from 'react';
import Joyride, { Step } from 'react-joyride';
import { useLocalStorage } from '../../shared/hooks';
import theme from '../../themes';
import { Item } from './style';
import { ReactComponent as CloseImage } from './images/close.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const { palette } = theme;

interface Props {
  id: string;
  delay?: number;
  adaptive?: boolean;
  steps: Array<{
    target: string;
    text: string;
    delay?: number;
    before?: () => void;
    after?: () => void;
  }>;
  debug?: boolean;
  noScroll?: boolean;
  disableScrollParentFix?: boolean;
  beforeStart?: () => void;
  onStart?: () => void;
  onEnd?: () => void;
}

const shownTourPrefix = 'tour.shown';

const buttonStyle: CSSProperties = {
  width: '113px',
  height: '44px',
  marginBottom: '10px',
};

export const Tour = ({ onStart, onEnd, beforeStart, id, delay, adaptive, debug, noScroll, disableScrollParentFix, ...props }: Props) => {

  const readyInitialStatus = delay || beforeStart ? false : true;

  const layout = useSelector((state: RootState) => state.App.layout);

  const [shown, setShown] = useLocalStorage(`${shownTourPrefix}.${id}`, false);
  const [ready, setReady] = useState(readyInitialStatus);
  const [stepIndex, setStepIndex] = useState<number | null>(0);

  useEffect(() => {
    if (!ready && !shown && (delay || beforeStart)) {
      const timer = setTimeout(() => {
        beforeStart?.();
        setReady(true);
      }, delay || 0);
      return () => clearTimeout(timer);
    }
  }, [beforeStart, delay, ready, shown]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const findClass = (e: HTMLElement | SVGSVGElement, className: string) => {
        if (e.classList.contains(className)) {
          return true;
        }
        if (e.parentElement) {
          return findClass(e.parentElement, className);
        }
      };
      if (findClass(e.target as HTMLElement | SVGSVGElement, 'start-tour')) {
        setShown(false);
      }
    };
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [setShown]);

  useEffect(() => {
    setReady(readyInitialStatus);
    setStepIndex(0);
  }, [readyInitialStatus, shown]);

  const steps = useMemo(() => props.steps.map((step, i) => ({
    target: step.target,
    content: <Item>
      <h1>Tour step {i + 1} of {props.steps.length}</h1>
      <p>{step.text}</p>
      <CloseImage onClick={() => {
        setShown(true);
        onEnd?.();
      }}/>
    </Item>,
    locale: {
      next: 'Next',
      back: 'Back',
      skip: 'End',
      last: 'Close',
    },
    disableBeacon: true,
  })) as Step[], [onEnd, props.steps, setShown]);

  return !shown && ready && stepIndex !== null ? <Joyride
    disableScrollParentFix={disableScrollParentFix}
    steps={steps}
    stepIndex={stepIndex}
    disableScrolling={noScroll}
    callback={(data) => {
      if (debug) {
        console.log(data);
      }
      switch (data.type) {
        case 'tour:start':
          return onStart?.();
        case 'tour:end':
          setShown(true);
          return onEnd?.();
        case 'step:before':
          return data.index === 0 && props.steps[data.index]?.before?.();
        case 'step:after':
          props.steps[data.index]?.after?.();
          if (data.action === 'prev') {
            if (steps[stepIndex - 1]) {
              props.steps[stepIndex - 1]?.before?.();
              if ( props.steps[stepIndex - 1]?.delay ) {
                setStepIndex(null);
                setTimeout(() => setStepIndex(stepIndex - 1), props.steps[stepIndex - 1].delay);
              } else {
                setStepIndex(stepIndex - 1);
              }
            }
          }
          else {
            if (steps[stepIndex + 1]) {
              props.steps[stepIndex + 1]?.before?.();
              if ( props.steps[stepIndex + 1]?.delay ) {
                setStepIndex(null);
                setTimeout(() => setStepIndex(stepIndex + 1), props.steps[stepIndex + 1].delay);
              } else {
                setStepIndex(stepIndex + 1);
              }
            } else {
              setStepIndex(stepIndex + 1);
            }
          }
      }
    }}
    styles={{
      tooltip: {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      },
      tooltipContent: {
        padding: '0',
      },
      buttonNext: {
        ...buttonStyle,
        marginRight: '15px',
        boxShadow: '0px 7px 20px rgba(26, 79, 112, 0.25)',
        outline: 'none',
      },
      buttonBack: {
        ...buttonStyle,
      },
      buttonSkip: {
        ...buttonStyle,
      },
      buttonClose: {
        display: 'none',
      },
      options: {
        arrowColor: 'transparent',
        backgroundColor: palette.white,
        overlayColor: 'rgba(0, 0, 0, 0.4)',
        primaryColor: palette.blue,
        textColor: '#1B2124',
        width: layout === 'mobile' && adaptive ? undefined : 480,
        zIndex: 100,
      },
    }}
    run={!shown}
    continuous
    debug={debug}
  /> : null;
};
