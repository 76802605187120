import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme;

export const Trigger = styled.span<{ withPadding?: boolean }>`
  padding: ${(p) => p.withPadding ? '10px' : 0};
  cursor: pointer;
`;

export const Menu = styled.ul<{ width?: string; position?: [number, number] | null; rightAligment?: boolean; maxHeight?: number }>`
  position: ${(p) => p.position ? 'fixed' : 'absolute'};
  ${(p) => p.position ? `
    top: ${p.position[1]}px;
    left: ${p.position[0]}px;
  ` : ''}
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-weight: 400;
  margin-top: 10px;
  min-width: ${(p) => p.width || '170px'};
  background: white;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  border: 1px solid ${palette.grayBg};
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  z-index: 400;
  ${(p) => p.rightAligment && `
    right: 3px;
    left: auto;
  `}

  ${(p) => p.maxHeight ? `
    max-height: ${p.maxHeight}px;
    overflow-y: auto;
  ` : ''}
`;

export const Item = styled.li<{ type?: 'primary' | 'warning'; disabled?: boolean }>`
  list-style: none;
  padding: 0 18px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${(p) => p.disabled ? palette.grayLight : ({ primary: palette.blue, warning: palette.red })[p.type || ''] || palette.grayDark};
  border-bottom: 1px solid ${palette.grayBg};
  white-space: nowrap;
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
  :hover {
    background: ${palette.grayBg};
  }
  :last-child {
    border-bottom: none;
  }
`;
