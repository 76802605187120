/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useLocale } from '../../shared';

interface Props {
  data: any;
  bar: any;
  unit: 'M' | 'k';
}

export const BarChartComponent = ({ data, bar, unit }: Props) => {
  const { currencySign } = useLocale({ defaultCurrencyValue: '' });

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={data}
        stackOffset='sign'
        margin={{
          top: 0,
          right: 0,
          left: 5,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='500 1' vertical={false}/>
        <XAxis tickLine={false} dataKey='name' />
        <YAxis tickSize={4} dataKey='line' unit={unit} tickFormatter={(tick) => tick < 0 ? `-${currencySign}${tick*-1}` : `${currencySign}${tick}`} />
        <Tooltip />
        {bar.map((elem, i) => <Bar key={i} dataKey={`${elem.name}`} fill={`${elem.color}`} unit={unit} stackId='a' />)}
      </BarChart>
    </ResponsiveContainer>
  );
};
