import styled from 'styled-components';

export const ArrowStyle = styled.div<{direction: 'left' | 'right'; clickable?: boolean; zIndex?: number}>`
  height: 20px;
  width: 20px;
  position: relative;
  left: -12px;
  top: 50%;
  border-radius: 5px;
  background: #f5f7fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.zIndex || 7};
  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};

  ${(p) => p.direction === 'left' && `
    > svg {
      transform: rotate(-180deg);
    }
  `}
`;
